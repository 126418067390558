import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faBorderNone, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "experts";
const tableName = "experts";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function Experts() {
    const [recordsList, setRecordsList] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "experts-details": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [proficiencyList, setProficiencyList] = useState([]);
    const [skillList, setSkillList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [expertsForm] = Form.useForm();
    const [filterForm] = Form.useForm();

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'User',
            dataIndex: 'name'
        },
        {
            title: 'Proficiency',
            dataIndex: 'proficiency_name'
        },
        {
            title: 'Skill',
            dataIndex: 'skill_name'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteSkills(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];



    useEffect(() => {
        theObject["id"] = generateFuzzyId("experts")
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "proficiency-types" }, setProficiencyList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/common/skills` }, { "doc_name": "skills" }, setSkillList);

    }, []);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
   

    const deleteSkills = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/common/skills/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
  
    const onFinish = (values) => {
        values["upsert"] = true;
        AxiosNetwork.axiosPost({ "url": `api/experts/find-user-skills/skills/${theObject.id}` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "skills-details": "none", "records-list-table": "block", "user-skills-list-table": "none" });
    }
   
    const afterNewRecordSubmit = () => {
        message.info('Your Experts details has been saved.');

    }
    const onFilterFinish = (values) => {
        var filters = [];
        if (values["proficiency_id"] != undefined) {
            filters.push({ "col_name": "proficiency_id", "operator": "=", "data_type": "string", "string_value": values["proficiency_id"] });
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/experts/find-user-skills/skills/${values["skill_id"]}` }, { "filters": filters }, setRecordsList, afterFilterData);
    }
    const afterFilterData = (data) => {
        setRecordsList([...data]);
        showHideAllProps({ "experts-details": "none", "records-list-table": "block" });
    }

    const expertsFormFailed = () => {

    }

   
    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>
                        <Card>
                            <Form form={filterForm} name="horizontal_login" layout="inline" onFinish={onFilterFinish}>
                                <Form.Item style={{ width: '30%' }} name="skill_id" rules={[{ required: true, message: 'Please select any skill' }]}>
                                    <Select showSearch allowClear placeholder="Select Skills"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {skillList.map(eachItem => (
                                            <Option key={eachItem["id"]}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ width: '30%' }} name="proficiency_id">
                                    <Select
                                        showSearch allowClear
                                        showArrow={false}
                                        placeholder="Select Proficiency">
                                        {proficiencyList.map(eachItem => (
                                            <Option key={eachItem["id"]}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faFilter} /></Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" onClick={() => { filterForm.resetFields(); }}><FontAwesomeIcon icon={faRefresh} /></Button>
                                </Form.Item>
                            </Form>
                        </Card>


                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                        </section>

                        <section style={{ "display": presentObjects["experts-details"] }}>

                            <Form layout="horizontal" name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                onFinish={onFinish}
                                form={expertsForm} initialValues={theObject}
                                onFinishFailed={expertsFormFailed} autoComplete="off">
                                <Form.Item
                                    label="Skills"
                                    name="skill_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select your Skills',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Select your Skills">
                                        {skillList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Proficiency"
                                    name="proficiency_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select your Proficiency',
                                        }
                                    ]}
                                >
                                    <Select mode="tags"
                                        placeholder="Select your Proficiency">
                                        {proficiencyList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>

                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}