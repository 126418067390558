import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { faUndoAlt, faLink, faFileArrowDown, faBars, faClose, faSortDown, faGripLinesVertical, faEllipsisV, faStar, faFileWord, faPalette, faTrashCan, faBorderAll, faCircleUser, faGlobe, faPlus, faGrip, faFolder, faTag, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faEye, faLock, faUnlock, faCopy } from '@fortawesome/free-solid-svg-icons'
import { openWindow, appProfile, footerLine, menuBarItems, searchTextLength, profileBarItems, generateFuzzyId } from "../Config";
import '../App.css';
//import '../antd.css';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Tabs, Layout, Menu, Typography, Badge, Avatar, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Space, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
//https://raw.githack.com/uiwjs/react-codemirror/doc3/index.html
import CodeMirror from '@uiw/react-codemirror';
//import 'codemirror/keymap/sublime';
//import 'codemirror/theme/monokai.css';
import copy from 'copy-to-clipboard';
import { faFirefoxBrowser } from '@fortawesome/free-brands-svg-icons';

const { Search } = Input;
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const { TabPane } = Tabs;
const appId = 29;
const appPath = "site-settings";
const tableName = "site_settings";
var activeDataColumns = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };


//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const cdnFileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/cdn-file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function WebPage() {
    const [tempList, setTempList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "new-data-form": "none", "records-list-table": "block", "file-assets-section": "none", "active": "block", "bin": "none", "newData": "block", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [newLabelDrawer, setNewLabelDrawer] = useState(false);
    const [newFolderDrawer, setNewFolderDrawer] = useState(false);
    const [theObject, setTheObject] = useState({ "application": "EMAIL", "file_assets": [], "cdn_file_assets":[], "body": "Type your content here", "id": tableName + "-" + uuid() });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [siteSettingList, setSiteSettingList] = useState([]);
    const [appList, setAppList] = useState(false);
    const [divShow, setDivShow] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [theFilesList, setTheFilesList] = useState([]);
    const [theCdnFilesList, setTheCdnFilesList] = useState([]);
    const [theForm] = Form.useForm();

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }


    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    


    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'View',
            render: (record) => <a onClick={(e) => openWindow("site-setting/" + record["id"])}><FontAwesomeIcon icon={faFirefoxBrowser} /></a>,
            width: 60
        },
        {
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 60
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 60
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>,
            width: 60
        }
    ];


    const fileAssetDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Access',
            render: (record) => <span>{((record.access == 0) ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />)}</span>
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
        },
        {
            title: 'Action',
            render: (record) => <span><a href={appProfile.serviceLocation + "api/file-assets/download/" + record.id + "/" + record.name}><FontAwesomeIcon icon={faCloudArrowDown} /></a> &#x02003; <a onClick={e => copy(appProfile.serviceLocation + "auth/file-assets/view/" + record.id + "/" + record.name)}><FontAwesomeIcon icon={faCopy} /></a></span>
        }
    ];


    const editTableRow = (selectedRow) => {
        delete dataMassage["newRecordId"];
        formData = {};
        dataMassage["recordId"] = selectedRow.id
        formData["parent_id"] = selectedRow.id;
        formData["folder_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, dataFetchCallback);
        showHideAllProps({"new-data-form":"block", "records-list-table":"none", "file-assets-section":"block"});
        setDivShow(true);
    }

    const dataFetchCallback = (data) => {
        setTheCdnFilesList(data["cdnFileAssets"]);
        setTheFilesList(data["fileAssets"]);
    }


    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const saveTemplate = () => {
        theObject["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/update/` + dataMassage["recordId"], "id": dataMassage["recordId"] }, theObject, recordsList, setRecordsList, afterSaveCallback);
        showHideAllProps({"new-data-form":"none", "records-list-table":"block"});
        return;
    }

    const afterSaveCallback = () =>
    {
        message.info("Saved successfully...");
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
    }

    const infoTableRow = (selectedRow) => {
        setTheObject(selectedRow);
        showHideProps("showInfo", true);
    }


    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
    }, []);


    const filterByFolder = (currentFolder) => {
        showHideProps("newData", "none");
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id":currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-data-form": "none", "records-list-table": "block" });
    }

    const filterByLabel = (currentLabel) => {
        showHideProps("newData", "none");
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id":currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-data-form": "none", "records-list-table": "block" });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const onChangeBodyPrefix = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["body_prefix"] = newValue;
    }
    const onChangeBodySufix = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["body_sufix"] = newValue;
    }
    const onChangeHead = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["header"] = newValue;
    }
    const onChangeFoot = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["footer"] = newValue;
    }

    const newRecord = () => {
        theForm.resetFields();
        setTheObject({"file_assets":[], "cdn_file_assets":[]});
        dataMassage["recordId"] = generateFuzzyId(appPath);
        console.log(dataMassage["recordId"]);
        showHideAllProps({ "new-data-form": "block", "records-list-table": "none", "file-assets-section":"none" });
    }

    const findFromBin = (filter) => {
        activeDataColumns = columnSchema;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        console.log(activeDataColumns);
        showHideAllProps({ "new-data-form": "none", "records-list-table": "block" });
    }


    const findAll = () => {
        console.log("findAll");
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        showHideAllProps({ "new-data-form": "none", "records-list-table": "block" });
    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'new-page',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content>
                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Row gutter={[16, 16]}>
                                <Table style={{ width: "100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                            </Row>
                        </section>

                        <section style={{ "display": presentObjects["new-data-form"] }} key={theObject["id"]}>
                            <Row style={{ "backgroundColor": "#fafafa", "padding": "20px" }}>
                                <Col xs={24} sm={24} md={24}>
                                    <Form form={theForm} initialValues={theObject} name="basic" labelCol={{ span: 4}} wrapperCol={{ span: 20}}>
                                    <Form.Item label="Enter the name">
                                        <Input placeholder="Enter the name.." defaultValue={theObject.name} onChange={(e) => { theObject.name = e.target.value }} />
                                        </Form.Item>
                                    <Form.Item label="Enter the application">
                                        <Select onChange={(e) => { theObject["application"] = e }} allowClear defaultValue={theObject["application"]} style={{width:"100%"}} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            <Option key={"WEBSITE"}>WEBSITE</Option>
                                            <Option key={"BLOG"}>BLOG</Option>
                                        </Select>
                                        </Form.Item>
                                    </Form>
                                    <div>
                                        <Tabs type="card" style={{ "marginTop": "10px" }}
                                            items={[
                                                {
                                                    label: `Header`,
                                                    key: '1',
                                                    children: <CodeMirror
                                                        value={((theObject.header == null) ? "" : theObject.header)}
                                                        height="500px"
                                                        options={{
                                                            theme: 'monokai',
                                                            keyMap: 'sublime',
                                                            mode: 'html',
                                                        }}
                                                        onChange={onChangeHead}
                                                    />
                                                },
                                                {
                                                    label: `Body Prefix`,
                                                    key: '2',
                                                    children: <CodeMirror
                                                        value={((theObject["body_prefix"] == null) ? "" : theObject["body_prefix"])}
                                                        height="500px"
                                                        options={{
                                                            theme: 'monokai',
                                                            keyMap: 'sublime',
                                                            mode: 'html',
                                                        }}
                                                        onChange={onChangeBodyPrefix}
                                                    />
                                                },
                                                {
                                                    label: `Body Sufix`,
                                                    key: '3',
                                                    children: <CodeMirror
                                                        value={((theObject["body_sufix"] == null) ? "" : theObject["body_sufix"])}
                                                        height="500px"
                                                        options={{
                                                            theme: 'monokai',
                                                            keyMap: 'sublime',
                                                            mode: 'html',
                                                        }}
                                                        onChange={onChangeBodySufix}
                                                    />
                                                },
                                                {
                                                    label: `Footer`,
                                                    key: '4',
                                                    children: <CodeMirror
                                                        value={((theObject.footer == null) ? "" : theObject.footer)}
                                                        height="500px"
                                                        options={{
                                                            theme: 'monokai',
                                                            keyMap: 'sublime',
                                                            mode: 'html',
                                                        }}
                                                        onChange={onChangeFoot}
                                                    />

                                                }
                                            ]} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Dragger {...fileUploadProps}>
                                        <p className="ant-upload-drag-icon">
                                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Col>
                                <Col span={12}>
                                    <Dragger {...cdnFileUploadProps}>
                                        <p className="ant-upload-drag-icon">
                                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Col>
                            </Row>

                            <section style={{ "margin-top":"50px", "display": presentObjects["file-assets-section"] }}>
                                <Title level={5}>Files</Title>
                                <Row>
                                    <Table pagination={false} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} columns={fileAssetDataColumns} dataSource={[...theObject["file_assets"], ...theObject["cdn_file_assets"]]} />
                                </Row>
                            </section>

                            <Button type="primary" size={12} onClick={(e) => saveTemplate()}>Save</Button> &#x2003;
                            <Button type="primary" size={12} onClick={(e) => showHideAllProps(showHideAllProps({"new-data-form":"none", "records-list-table":"block"}))}>Cancel</Button>

                            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                                </div>
                            </Drawer>

                        </section>
                    </Content>
                </Layout>
            </Layout>
            <Footer>{footerLine}</Footer>
        </Layout>

    )
}