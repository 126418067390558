import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId, dateTimeUtils } from "../../Config";
import '../../App.css';
import { Form, Input, DatePicker, Space, Upload, Button, message, Card, Row, Col, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave, faEdit, faBackward } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 105;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "know-your-customers";
const tableName = "know-your-customers";
var activeDataColumns = [];
let ckEditorData = "";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function HumanResourceKyc() {
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourcekycForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [employeeDocumentsList, setEmployeeDocumentsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("know-your-customers")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employee-documents" }, setEmployeeDocumentsList);
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/know-your-customers` }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "records-list-table": "block", "kyc-details": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const humanresourcekycFailed = () => {
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["issued_at"] = moment(values["issued_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["expiry_at"] = moment(values["expiry_at"]).format("yyyy-MM-DD HH:mm:ss");
        const selectDocument = employeeDocumentsList.filter(each => each.id = values["document_type_id"]);
        if (selectDocument.length > 0) {
            values["name"] = selectDocument[0]["name"];
        }

        values["status_id"] = "root-kyc-document-submitted";
        AxiosNetwork.axiosPut({ "url": "/api/know-your-customers/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/know-your-customers/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, kycCallBack);
    }
    const kycCallBack = (data) => {
        if (data["issued_at"] != undefined) data["issued_at"] = moment(data["issued_at"]);
        if (data["expiry_at"] != undefined) data["expiry_at"] = moment(data["expiry_at"]);
        setTheObject({ ...data });
        humanresourcekycForm.setFieldsValue({ ...data });
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "kyc-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const afterNewRecordSubmit = () => {
        message.info('Your kyc details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const applyKyc = () => {
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "kyc-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
        humanresourcekycForm.resetFields();
    }
    const columnSchema = [
        {
            title: 'Identification',
            dataIndex: 'identification',
        },
        {
            title: 'Issue date',
            dataIndex: 'issued_at',
        },
        {
            title: 'Employee Documents',
            dataIndex: 'document_type_id',
        },
        {
            title: 'Expiry date',
            dataIndex: 'expiry_at',
        },
        {
            title: 'Issued Place',
            dataIndex: 'issued_place',
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50,
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 50
        },
    ];


    return (
        <section>
            <Layout className='contentLayout'>
                <section style={{ "display": presentObjects["button-controls-section"] }}>
                    <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                        <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "kyc-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                    </Space>
                </section>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                    <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyKyc()}>   KYC  </Button>
                </section>
                <section className="thCard" style={{ "width": 700, "display": presentObjects["kyc-details"] }} >
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                        onFinish={onFinish}
                        form={humanresourcekycForm} initialValues={theObject}
                        onFinishFailed={humanresourcekycFailed} autoComplete="off">
                        <Form.Item label="Identification" name="identification">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Employee Documents" name="document_type_id"
                            rules={[{ required: true, message: 'Select the employee documents ', },]}>
                            <Select showSearch allowClear style={{ width: '100%' }} placeholder="Change Employee Documents">
                                {employeeDocumentsList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Issue Date" name="issued_at">
                                <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Expiry Date" name="expiry_at">
                                <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Issue Place" name="issued_place"
                            rules={[{ required: true, message: 'Type  your issue place in this field' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                        </Form.Item>
                    </Form>
                </section>
            </Layout>
        </section>
    )
}