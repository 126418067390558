import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Divider, Form, Input,Row,Col, Upload, Button, message, Space,Card, Select, Table, DatePicker, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan,faSave, faEdit, faBackward } from '@fortawesome/free-solid-svg-icons'
import { generateFuzzyId, dateTimeUtils } from "../../Config";
import moment from 'moment';

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-holidays";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";
var nextTenYears = [];

export default function HumanResourceHolidays() {
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section":"none","personal-information": "none","human-resource-holidays":"none","human-resource-employee-payroll":"none","human-resource-employer-documents": "none","edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourceHolidayForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [holidaysList, setHolidaysList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [countriesList, setCountriesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("holidays")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "holidays-type" }, setHolidaysList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-select-any-multi` }, { "doc_name": "countries", "limit":300, "select": ["id", "name"] }, setCountriesList);
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanResourceHolidayForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/common/holidays/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({"records-list-table":"none","button-controls-section":"block", "holidays-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/holidays` }, {"filters":[{"col_name":"due_at", "operator":">", "data_type":"date", "date_value":moment().startOf('month').format(dateTimeUtils.dateTimeFormat)}]}, setRecordsList);
        showHideAllProps({"records-list-table":"block","button-controls-section":"none", "holidays-details": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }

    const humanResourceHolidayFailed = () => {
    }

    const onFinishFailed = () => {
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["due_at"] = moment(values["due_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["year"] = " ";
        AxiosNetwork.axiosPut({ "url": "api/common/admin/holidays/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your holidays details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    const  applyHolidays = ( ) => {
        showHideAllProps({"records-list-table":"none","button-controls-section":"block", "holidays-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
        humanResourceHolidayForm.resetFields();
    }
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Due At',
            dataIndex: 'due_at',
        },
        {
            title: 'Genre',
            dataIndex: 'genre',
        },
        {
            title: 'State',
            dataIndex: 'state_id',
        },
        {
            title: 'Country',
            dataIndex: 'country_id',
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];


    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "holidays-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
           </section>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyHolidays()}> New    </Button>

                    </section>
                    <section className="thCard" style={{ "width": 700, "display": presentObjects["holidays-details"] }} >
                            <Form size="large" labelAlign="right" layout="vertical" name="basic"
                                onFinish={onFinish}
                                form={humanResourceHolidayForm} initialValues={theObject}
                                onFinishFailed={humanResourceHolidayFailed} autoComplete="off">
                                <Form.Item label="Name" name="name"
                                    rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                    <Input />
                                </Form.Item>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Due at" name="due_at">
                                    <DatePicker format={dateTimeUtils.dateFormat} onOk={onOk} />
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="Holiday types" name="type_id">
                                    <Select autoComplete="none" showSearch allowClear style={{ width: '100%' }} placeholder="Change  Holidays">
                                        {holidaysList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Country" name="country_id">
                                    <Select showSearch allowClear style={{ width: '100%' }} placeholder="Change Country">
                                        {countriesList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="State" name="state_id" rules={[{ required: true, message: 'Type  your  State Id in this field' }]}>
                                    <Input />
                                </Form.Item>
                                </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                                </Form.Item>
                            </Form>
                    </section>
                </Layout>
        </section>
    )
}