import React, { useState, useEffect } from 'react';

import './App.css';
import store from 'store2';
import { AxiosNetwork } from "./axiosService";
import { Button, Card, Form, Input, Typography, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string';
import Password from 'antd/lib/input/Password';

function ResetPassword() {

  const params = useParams();
  const [tempList, setTempList] = useState([]);
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    AxiosNetwork.axiosGet({ "url": "api/public/user/new-password-request-validity?token="+queryParams["token"] }, {}, setTempList);
    setQueryParams(queryParams);
  }, []);

  const onFinish = (values) => {
    AxiosNetwork.axiosPut({ "url": "api/public/user/new-password-submit" }, Object.assign({}, values, queryParams), [], setTempList, dataPostCallback);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const dataPostCallback = (data) => {
    console.log(data);
    message.info(data["ui_message"]);
  }


  return (
    <div><center><img src="https://storage.googleapis.com/static.techiehug.com/icons/logo.png" style={{padding: 40}} /></center>
      <div className="pageCenter" style={{ width: 700 }}>
      <center><strong>Reset Password</strong></center><br/>
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">

          <Form.Item
            name="password"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              }
            ]}>
            <Password />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label="Re-enter Password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              }
            ]}>
            <Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
            <center><Button type="primary" htmlType="submit" className="login-form-button">Reset</Button></center>
          </Form.Item>

          <hr />

          <center>
            <a href="/">Login</a>
          </center>

        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;