import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faEdit, faSave,faTrashCan, } from '@fortawesome/free-solid-svg-icons'
import { profileBarItems, generateFuzzyId, menuBarItems } from "../Config";
import '../App.css';
import moment from 'moment-timezone';
import { Layout, Menu, Row, Tabs, DatePicker, Card, Button, Table, Form, Input, Radio, message, Select, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { faFirefox, faWpforms } from '@fortawesome/free-brands-svg-icons';

const { Header, Content, Sider } = Layout;
var formData = {};
const { Option } = Select;
const appId = 55;
const appPath = "surveys";
const tableName = "surveys";
const { TabPane } = Tabs;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
let ckEditorData = "";

export default function Survey() {
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "fields": [], "mime_assets": [] });
    const [recordsList, setRecordsList] = useState([{}]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "new-survey": "none", "form": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [formList, setFormList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [surveyForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const activeDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 150,
        },
        {
            title: 'Forms',
            dataIndex: 'form_id',
            width: 150,
        },
        {
            title: 'Modified At',
            width: 150,
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            title: 'Preview',
            width: 150,
            render: (record) => <a onClick={(e) => window.location.href = window.location.origin + `/apps/${tableName}/survey-collection?id=` + record.id}><FontAwesomeIcon icon={faFirefox} /></a>,
        },

        {
            title: 'Responses',
            width: 150,
            render: (record) => <a onClick={(e) => window.location.href = window.location.origin + `/apps/${tableName}/survey-responses?id=` + record.id}><FontAwesomeIcon icon={faWpforms} /></a>,
        },
        {
            title: 'Edit',
            width: 150,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        selectedRow["fields"] = [];
        setUiLabels({ "save": "Update" });
        setTheObject({ ...selectedRow });
        surveyForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "edit-selected-record": "block", "new-survey": "block", "records-list-table": "block" });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": "/api/common/folders/appid/" + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": "/api/common/labels/appid/" + appId }, setLabelList);
        const filter = { "col_name": "genre", "operator": "=", "data_type": "string", "string_value": "SURVEY" };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "forms", "filter": [filter] }, setFormList);
        findAll();
    }, []);

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-survey": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-survey": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = () => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "new-survey": "none", "records-list-table": "block" });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-survey": "none", "records-list-table": "block" });
    }
    const onFinish = (values) => {
        console.log(values);
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["survey_id"] = "abc";
        AxiosNetwork.axiosPut({ "url": `/api/${tableName}/update/` + theObject["id"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    };
    const afterNewRecordSubmit = () => {
        message.info('Your survey has been saved.');
        findAll();
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "new-survey": "block", "records-list-table": "none" });
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </section>
                        <section className="thCard" style={{ marginBottom: "50px", "width": 600, "display": presentObjects["new-survey"] }}>
                            <Card>
                                <Form size="large" labelAlign="right" layout="vertical"
                                    name="basic"
                                    onFinish={onFinish}
                                    form={surveyForm} initialValues={theObject}
                                    onFinishFailed={onFinishFailed} autoComplete="off">

                                    <Form.Item label="Name" name="name"
                                        rules={[{ required: true, message: 'Type  your   name in this field' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col span={12} >
                                            <Form.Item name="form_id" label="Forms" rules={[{ required: true, message: 'Enter the Forms', }]}>
                                                <Select style={{ width: '100%' }} placeholder="Select Forms">
                                                    {formList.map(eachItem => (
                                                        <Option key={eachItem["id"]}>{eachItem.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} >
                                            <Form.Item label="Anonymous Survey" name="anonymous"  >
                                                <Radio.Group name="radiogroup" >
                                                    <Radio value={true}>Yes</Radio>
                                                    <Radio value={false}>No</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={8} >
                                            <Form.Item label="Start Date" name="start_date">
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} >
                                            <Form.Item label="End Date" name="end_date">
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} >
                                            <Form.Item label="Modify" name="modify"  >
                                                <Radio.Group name="radiogroup" >
                                                    <Radio value={true}>Yes</Radio>
                                                    <Radio value={false}>No</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </section>

                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                        </section>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}