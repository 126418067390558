import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faEye, faTrashCan, faRefresh, faFilter, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHourglassStart, faHourglassEnd, faHourglassHalf, faUserClock, faShareAltSquare, faUser, faUsers, faExclamationTriangle, faEnvelope, faQuestionCircle, faClock, faShareNodes, faFolder, faSatellite, faTimeline, faFastForward, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { appProfile, menuBarItems, profileBarItems, generateFuzzyId } from "../Config";
import '../App.css';
import '../style.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment';
import { Layout, Menu, Typography, Space, Collapse, Breadcrumb, Row, Divider, Dropdown, Button, Radio, Table, Form, Input, Card, Upload, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { v4 as uuid } from 'uuid';
import copy from 'copy-to-clipboard';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import FormItem from 'antd/lib/form/FormItem';

const { Dragger } = Upload;
const { TextArea } = Input;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;


var formData = {};
const appId = 46;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "tickets";
const tableName = "tickets";
var activeDataColumns = [];

const style = { padding: '8px 0' };
let ckEditorData = "";


const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};
const infiniteLoop = [];


export default function Ticket() {
    const [tempList, setTempList] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [theActivity, setTheActivity] = useState({ "description": "Type your summary here" });
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [ticketActivityList, setTicketActivityList] = useState([{ "name": "EMAIL", "mime_assets": [], "description": "Type your content here" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [reproducabilityList, setReproducabilityList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [severityList, setSeverityList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [priorityList, setPriorityList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [nameList, setNameList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [statusList, setStatusList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "create-new-sub-record-section": "none", "edit-selected-record": "none", "records-list-table": "block", "new-tickets": "block", "newStatusDrawer": false, "newLabelDrawer": false, "newFolderDrawer": false, "create-new-record-section": "none", "composeTicket": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [form] = Form.useForm();
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [selectionType, setSelectionType] = useState('checkbox');
    const [ticketForm] = Form.useForm();
    const [ticketActivityForm] = Form.useForm();
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [filter, setFilter] = useState([]);
    const [filterForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);



    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, { "doc_name": "tickets" }, setNameList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/priorities` }, { "doc_name": "priorities" }, setPriorityList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/severities` }, { "doc_name": "severities" }, setSeverityList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/statuses` }, { "doc_name": "statuses" }, setStatusList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "reproducibility" }, setReproducabilityList);
        composeTicket();
    }, infiniteLoop);
    const afterSeverityListLoaded = (data) => {
        data.sort(function (a, b) { return ((a.name > b.name) ? 1 : -1); });
        setSeverityList([...data]);
    }

    const afterPriorityListLoaded = (data) => {
        data.sort(function (a, b) { return ((a.name > b.name) ? 1 : -1); });
        setPriorityList([...data]);
    }


    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }


    const columnSchema = [
        {
            title: 'Ticket',
            render: (record) => <span>{((record["access"] == 0) ? <FontAwesomeIcon icon={faUnlock} /> : <FontAwesomeIcon icon={faLock} />)} &#x2002; {record["name"]}</span>
        },
        {
            title: 'Activity',
            width: 50,
            dataIndex: 'activity_count',
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            title: 'Category',
            render: (record) => <span style={{ textTransform: 'capitalize' }}>{record["folder_id"]}</span>
        },
        {
            title: 'Severity',
            render: (record) => <span style={{ textTransform: 'capitalize' }}>{record["severity_id"]}</span>
        },
        {
            title: 'Priority',
            render: (record) => <span style={{ textTransform: 'capitalize' }}>{record["priority_id"]}</span>
        },
        {
            title: 'Status',
            render: (record) => <span style={{ textTransform: 'capitalize' }}>{record["status_id"]} {record["user_id"]}</span>
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "view",
            title: 'View',
            width: 50,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faEye} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "create-new-sub-record-section": "none", "create-new-record-section": "none", "records-list-table": "block" });
    }
    const viewTableRow = () => {
        showHideAllProps({ "relationship-section": "block", "create-new-record-section": "none", "records-list-table": "none", "edit-selected-record": "block", "create-new-sub-record-section": "block" });

    }

    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        ticketForm.setFieldsValue({ ...selectedRow });
        formData = {};
        dataMassage["ticketId"] = selectedRow.id;
        dataMassage["ticketActivityId"] = generateFuzzyId("ticket-activity");
        formData["parent_id"] = dataMassage["ticketActivityId"];
        formData["folder_id"] = dataMassage["ticketId"];
        AxiosNetwork.axiosGetMulti({ "url": "/api/tickets/" + selectedRow.id + "/activities", "id": selectedRow.id }, ticketActivityList, setTicketActivityList);
        AxiosNetwork.axiosGet({ "url": "/api/tickets/" + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "relationship-section": "none", "create-new-record-section": "block", "records-list-table": "none", "edit-selected-record": "block", "create-new-sub-record-section": "none" });
    }


    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };



    const onStatusFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "/api/common/status/create" }, values, statusList, setStatusList);
        console.log('Success:', values);
        showHideProps("newStatusDrawer", false)
    };

    const onLabelFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "/api/common/label/create" }, values, labelList, setLabelList);
        console.log('Success:', values);
        showHideProps("newLabelDrawer", false)
    };

    const onLabelFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": `/api/tickets/` + dataMassage["ticketId"] + `/update` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }

    const afterNewRecordSubmit = () => {
        message.info('Your tickets has been saved.');
        findAll();
    }

    const onActivityFinish = (values) => {
        values["id"] = dataMassage["ticketActivityId"];
        values["description"] = ckEditorData;
        AxiosNetwork.axiosPost({ "url": "/api/tickets/" + dataMassage["ticketId"] + "/activities/create" }, values, ticketActivityList, setTicketActivityList);
        console.log('Success:', values);
    }
    const findAll = (filter = "all") => {
        console.log("findAll called");
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList, dataListProcess);
        showHideAllProps({ "create-new-sub-record-section": "none", "create-new-record-section": "none", "records-list-table": "block" });
    }

    const dataListProcess = (data) => {
        console.log("dataListProcess", data);
    }
    const onFilterFinish = (values) => {
        console.log(values);
        var filters = [];
        if (values["severity_id"] != undefined) {
            filters.push({ "col_name": "severity_id", "operator": "=", "data_type": "string", "string_value": values["severity_id"] });
        }
        if (values["priority_id"] != undefined) {
            filters.push({ "col_name": "priority_id", "operator": "=", "data_type": "string", "string_value": values["priority_id"] });
        }
        if (values["folder_id"] != undefined) {
            filters.push({ "col_name": "folder_id", "operator": "=", "data_type": "string", "string_value": values["folder_id"] });
        }
        if (values["status_id"] != undefined) {
            filters.push({ "col_name": "status_id", "operator": "=", "data_type": "string", "string_value": values["status_id"] });
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters": filters }, setRecordsList, dataListProcess);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }

    const composeTicket = () => {
        showHideProps("composeTicket", "block");
        theObject["emailAddress"] = [];
        formData = {};
        dataMassage["ticketId"] = "ticket-" + uuid();
        formData["parent_id"] = dataMassage["ticketId"]
        formData["folder_id"] = dataMassage["ticketId"];
    };

    const ticketFormFailed = () => {

    }
    const onFinishFailed = () => {

    }

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "create-new-sub-record-section": "none", "create-new-record-section": "none", "records-list-table": "block" });
    }


    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "create-new-sub-record-section": "none", "create-new-record-section": "none", "records-list-table": "block" });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }


    const newRecord = () => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        setUiLabels({ "save": "Save" });

        showHideAllProps({ "edit-selected-record": "none", "create-new-record-section": "block", "records-list-table": "none", "create-new-sub-record-section": "none" });
    }
    const showFindAll = () => {
        showHideAllProps({ "edit-selected-record": "none", "create-new-record-section": "none", "records-list-table": "block", "create-new-sub-record-section": "none" });

    }
    const onSeveritySelect = (value) => {
        if (value.length > 1) {
            ticketForm.setFieldValue("severity_id", value.pop());
        }
    }
    const onStatusSelect = (value) => {
        if (value.length > 1) {
            ticketForm.setFieldValue("status_id", value.pop());
        }
    }
    const onPrioritySelect = (value) => {
        if (value.length > 1) {
            ticketForm.setFieldValue("priority_id", value.pop());
        }
    }
    const onCategorySelect = (value) => {
        if (value.length > 1) {
            ticketForm.setFieldValue("folder_id", value.pop());
        }
    }
   
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200} >
                    <Menu className='sidemenu bold' mode="inline" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New Ticket</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Row style={{ width: '1700px', height: '180px', marginLeft: '80px', textAlign: 'center', position: 'fixed' }}>
                        <Col className="dashboard"><FontAwesomeIcon icon={faUser} /><br />My Open
                            <div style={{ width: '40px', maxWidth: '40px', background: 'red', color: 'white', marginLeft: '182px', marginTop: '-95px', textAlign: 'center', border: '2pt solid rgb(197, 194, 194)', borderRight: 'none', borderTop: 'none', zIndex: '10000' }}>1</div>
                        </Col>
                        <Col className="dashboard"><FontAwesomeIcon icon={faUsers} /><br />All Open
                            <div style={{ width: '40px', maxWidth: '40px', background: 'blue', color: 'white', marginLeft: '182px', marginTop: '-95px', textAlign: 'center', border: '2pt solid rgb(197, 194, 194)', borderRight: 'none', borderTop: 'none', zIndex: '10000' }}>32</div>
                        </Col>
                        <Col className="dashboard"><FontAwesomeIcon icon={faExclamationTriangle} /><br />Overdue
                            <div style={{ width: '40px', maxWidth: '40px', background: 'green', color: 'white', marginLeft: '182px', marginTop: '-95px', textAlign: 'center', border: '2pt solid rgb(197, 194, 194)', borderRight: 'none', borderTop: 'none', zIndex: '10000' }}>5</div>
                        </Col>
                        <Col className="dashboard"><FontAwesomeIcon icon={faQuestionCircle} /><br />Unassigned
                            <div style={{ width: '40px', maxWidth: '40px', background: 'orange', color: 'white', marginLeft: '182px', marginTop: '-95px', textAlign: 'center', border: '2pt solid rgb(197, 194, 194)', borderRight: 'none', borderTop: 'none', zIndex: '10000' }}>10</div>
                        </Col>
                        <Col className="dashboard"><FontAwesomeIcon icon={faEnvelope} /><br />Closed
                            <div style={{ width: '40px', maxWidth: '40px', background: 'purple', color: 'white', marginLeft: '182px', marginTop: '-95px', textAlign: 'center', border: '2pt solid rgb(197, 194, 194)', borderRight: 'none', borderTop: 'none', zIndex: '10000' }}>3</div>
                        </Col>
                    </Row>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                            marginTop: '10%'
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}  >
                            <Card>
                                <Form form={filterForm} name="horizontal_login" layout="inline" onFinish={onFilterFinish}>

                                    <Form.Item style={{ width: '21%' }} name="priority_id">
                                        <Select
                                            showSearch allowClear
                                            showArrow={false}
                                            placeholder="Select priority">
                                            {priorityList.map(eachItem => (
                                                <Option key={eachItem.name}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ width: '21%' }} name="folder_id">
                                        <Select
                                            showSearch allowClear
                                            showArrow={false}
                                            placeholder="Select Folder">
                                            {folderList.map(eachItem => (
                                                <Option key={eachItem.name}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>


                                    <Form.Item style={{ width: '21%' }} name="severity_id">
                                        <Select
                                            showSearch allowClear
                                            showArrow={false}
                                            placeholder="Select severity">
                                            {severityList.map(eachItem => (
                                                <Option key={eachItem.name}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item style={{ width: '21%' }} name="status_id">
                                        <Select
                                            showSearch allowClear
                                            showArrow={false}
                                            placeholder="Select status">
                                            {statusList.map(eachItem => (
                                                <Option key={eachItem.name}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faFilter} /></Button>&#x2002;
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" onClick={() => { filterForm.resetFields(); }}><FontAwesomeIcon icon={faRefresh} /></Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </section>

                        <section style={{ "display": presentObjects["edit-selected-record"] }} className="site-layout-background" collapsible collapsed={siderCollapsed} trigger={null}>
                            <Button style={{ marginBottom: "20px" }} onClick={(e) => showFindAll()}>Back</Button>


                            <section className="thCard" style={{ "width": 700, "display": presentObjects["relationship-section"] }} >
                                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                                    onFinish={onFinish}
                                    form={ticketForm} initialValues={theObject}
                                    onFinishFailed={ticketFormFailed} autoComplete="off">
                                    <Form.Item label="Current issue" name="current_issue" >
                                        <Select showSearch allowClear>
                                            <Select.Option value="parent of">parent of</Select.Option>
                                            <Select.Option value="child of">child of</Select.Option>
                                            <Select.Option value="duplicate of">duplicate of</Select.Option>
                                            <Select.Option value="has duplicate">has duplicate</Select.Option>
                                            <Select.Option value="related to"> related to</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Ticket Id" name="ticket_id"
                                        rules={[{ required: true, message: 'Type the ticket id' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Add</Button>
                                    </Form.Item>
                                </Form>
                            </section>

                            <Card key={theObject.id}
                                style={{ marginTop: 16, marginBottom: "20px" }} title={theObject.name}>
                                <Row key={'row-' + theObject.id}>
                                    <Col span={8}>
                                        <div style={{ padding: '8px 0' }}>
                                            <FontAwesomeIcon icon={faFolder} /> &#x2002; {theObject["folder_id"]}
                                        </div>
                                        <div style={{ padding: '8px 0' }}>
                                            <FontAwesomeIcon icon={faSatellite} /> &#x2002; {theObject["status_id"]}
                                        </div>
                                        <div style={{ padding: '8px 0' }}>
                                            <FontAwesomeIcon icon={faTimeline} /> &#x2002; {theObject["priority_id"]}
                                        </div>
                                        <div style={{ padding: '8px 0' }}>
                                            <FontAwesomeIcon icon={faFastForward} /> &#x2002; {theObject["severity_id"]}
                                        </div>
                                        <div style={{ padding: '8px 0' }}>
                                            <FontAwesomeIcon icon={faClock} /> &#x2002; {moment(theObject.createdAt).format("llll")}
                                        </div>
                                        <div style={{ padding: '8px 0' }}>
                                            <FontAwesomeIcon icon={faUser} /> &#x2002; {theObject["user_id"]}
                                        </div>
                                        <div style={{ padding: '8px 0' }}>
                                            {((theObject["access"] == 0) ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />)} &#x2002; <a href="javascript:void(0);" onClick={(e) => copy(appProfile.websiteLocation + "apps/tickets/activity/" + theObject.id)}><FontAwesomeIcon icon={faShareNodes} /></a>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: theObject.description }} />
                                            <div style={{ marginTop: "20px" }}>
                                                {theObject["mime_assets"].map(eachFile => (
                                                    <p key={eachFile.id}><a href={appProfile.serviceLocation + "api/file-assets/mime/download/" + eachFile.id + "/" + eachFile.name}><FontAwesomeIcon icon={faDownload} /></a> &#x2002; <span>{eachFile.name}</span></p>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>

                            <Divider orientation="center">Activities</Divider>
                            {ticketActivityList.map(eachActivity => (
                                <Card style={{ marginBottom: "20px" }} key={"card-" + eachActivity.id}>
                                    <Row key={'row-' + eachActivity.id}>
                                        <Col span={8}>
                                            <div style={{ padding: '8px 0' }}>
                                                <FontAwesomeIcon icon={faClock} /> &#x2002; {moment(eachActivity.createdAt).format("llll")}
                                            </div>
                                            <div style={{ padding: '8px 0' }}>
                                                <FontAwesomeIcon icon={faUser} /> &#x2002; {eachActivity["user_id"]}
                                            </div>
                                            <div style={{ padding: '8px 0' }}>
                                                {((eachActivity["access"] == 0) ? <FontAwesomeIcon icon={faUnlock} /> : <FontAwesomeIcon icon={faLock} />)} &#x2002; <a href="javascript:void(0);" onClick={(e) => copy(appProfile.websiteLocation + "apps/tickets/activity/" + eachActivity.id)}><FontAwesomeIcon icon={faShareNodes} /></a>
                                            </div>
                                        </Col>
                                        <Col span={16}>
                                            <div style={{ padding: '8px 0' }}>
                                                <div dangerouslySetInnerHTML={{ __html: eachActivity.description }} />
                                                <div>{eachActivity["mime_assets"].map(eachFile => (
                                                    <p key={eachFile.id}><a href={appProfile.serviceLocation + "api/file-assets/mime/download/" + eachFile.id + "/" + eachFile.name}><FontAwesomeIcon icon={faDownload} /></a> <span>{eachFile.name}</span></p>
                                                ))}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </section>
                        <section className="thCard" style={{ "width": 700, "display": presentObjects["create-new-record-section"] }} >
                            <Form size="large" labelAlign="right" layout="vertical" name="basic"
                                onFinish={onFinish}
                                form={ticketForm} initialValues={theObject}
                                onFinishFailed={ticketFormFailed} autoComplete="off">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Folder" name="folder_id">
                                            <Select
                                                showSearch allowClear
                                                onChange={onCategorySelect}
                                                defaultActiveFirstOption={false}
                                                showArrow={false} filterOption={true} placeholder="Select Category">
                                                {folderList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Reproducability" name="reproducability_id">
                                            <Select showSearch allowClear style={{ width: '100%' }} placeholder="Change Reproducability">
                                                {reproducabilityList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Severity" name="severity_id">
                                            <Select showSearch allowClear
                                                onChange={onSeveritySelect}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={true} placeholder="Select Severity">
                                                {severityList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Status" name="status_id">
                                            <Select showSearch allowClear
                                                onChange={onStatusSelect}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={true} placeholder="Select Status">
                                                {statusList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item name="priority_id" label="Priority">
                                            <Select showSearch allowClear
                                                onChange={onPrioritySelect}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={true} placeholder="Select Priority">
                                                {priorityList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Assign to" name="assignTo" >
                                            <Select showSearch allowClear>
                                                <Select.Option value="Automatic Ticket Assignment">Automatic Ticket Assignment</Select.Option>
                                                <Select.Option value="Round Robin">Round Robin</Select.Option>
                                                <Select.Option value="Load balance">Load Balancec</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Summary" name="name" rules={[{ required: true, message: 'Type the Summary' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="View Status" name="access">
                                            <Select value={0} defaultValue={0}>
                                                <Select.Option value={0}>Public</Select.Option>
                                                <Select.Option value={1}>Private</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Description" name="description"
                                    rules={[{ required: true, message: 'Type theDescription' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                    />
                                </Form.Item>

                                <Form.Item label="Upload Files" name="uploadFiles">
                                    <Dragger {...fileUploadProps}>
                                        <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
                                        <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
                                    </Dragger>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 10,
                                        span: 10,
                                    }}>
                                    <Button className="form-button" type="primary" htmlType="submit">
                                        Submit Issue
                                    </Button>
                                </Form.Item>
                           

                            </Form>
                        </section>
                        <section className="thCard" style={{ "width": 700, "display": presentObjects["create-new-sub-record-section"] }} >
                            <Form size="large" labelAlign="right" layout="vertical" name="basic"
                                onFinish={onActivityFinish}
                                form={ticketActivityForm} initialValues={ticketActivityForm}
                                onFinishFailed={onFinishFailed} autoComplete="off">
                                <Form.Item label="View Status" name="access">
                                    <Select value={0} defaultValue={0}>
                                        <Select.Option value={0}>Public</Select.Option>
                                        <Select.Option value={1}>Private</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Description" name="description"
                                    rules={[{ required: true, message: 'Type the Description' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Upload Files" name="uploadFiles">
                                    <Dragger {...fileUploadProps}>
                                        <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
                                        <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
                                    </Dragger>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 10,
                                        span: 10,
                                    }}>
                                    <Button  type="primary" htmlType="submit">
                                        Submit Activity
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>
                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>

                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}
