//https://www.npmjs.com/package/storagedb2
import StorageDB from 'storagedb2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHomeLg, faSignOut, faSignOutAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid';
import { Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox, Space } from 'antd';
import { AxiosNetwork } from "./axiosService";


export const deleteFileAssetsRow = (theObject, dataList, setDataList) => {
    if(theObject.access===1)
    {
        AxiosNetwork.axiosDelete({ "url": `/api/cdn-file-assets/delete/` + theObject.id, "id": theObject.id }, dataList, setDataList);
    }
    else
    {
        AxiosNetwork.axiosDelete({ "url": `/api/file-assets/delete/` + theObject.id, "id": theObject.id }, dataList, setDataList);
    }
}
