import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan,faEye, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, configMenus } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import CodeMirror from '@uiw/react-codemirror';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
var formData = {};
const appId = 117;
const appPath = "admin-approved-queries";
const tableName = "approved-queries";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function ApprovedQuery() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [formatRecordsList, setFormatRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [appovedQueriesFormatList, setAppovedQueriesFormatList] = useState([{ "id": 1, "name": "Waiting to load" }]);

    const [presentObjects, setPresentObjects] = useState({ "selected-record": "none","edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "user_position":0, "mime_assets": [], "sql_query":"" });
    const [theFormatterObject, setTheFormatterObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [theLabel, setTheLabel] = useState({});
    const [approvedQueriesForm] = Form.useForm();
    const [approvedQueriesFormatForm] = Form.useForm();


    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: 'Application',
            render: (record) => <span>{record["app_name"]}</span>,
            width: 200
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 100,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "view",
            title: 'View',
            width: 100,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faEye} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 100,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const formatterColumns = [
        {
            title: 'Format',
            dataIndex: 'format',
        },
        {
            key: "edit",
            title: 'Edit',
            width: 100,
            render: (record) => <a onClick={(e) => editFormatterTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "trash",
            title: 'Trash',
            width: 100,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const editFormatterTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        dataMassage["approvedQueryFormatId"] = selectedRow.id;
        setUiLabels({ "save": "Update" });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosFetchMono({ "url": `/api/admin-approved-queries/${theObject.id}/formatters/${selectedRow.id}`  }, setTheFormatterObject, formatterObjectCallback);
    }
    
    const formatterObjectCallback = (data) =>
    {
        setTheFormatterObject({...data});
        approvedQueriesFormatForm.setFieldsValue({...data});
        showHideAllProps({ "selected-record": "block","new-record-format-section": "block", "edit-selected-record": "block", "new-record": "none", "records-list-table": "block" });
    }
    
    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        dataMassage["approvedQueryId"] = selectedRow.id;
        dataMassage["approvedQueryFormatId"] = generateFuzzyId("queries-format-activity");
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosFetchMono({ "url": `/api/admin-approved-queries/${selectedRow.id}` }, setTheObject, afterQueryFetchCallback);
    }

    const afterQueryFetchCallback = (data) =>
    {
        setTheObject({ ...data });
        approvedQueriesForm.setFieldsValue({ ...data });
        showHideAllProps({ "selected-record": "none","new-record-format-section": "none", "edit-selected-record": "block", "new-record": "block", "records-list-table": "block" });
        document.getElementById('new-record-section').scrollIntoView({ behavior: 'smooth' });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    
    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/configurations/reports`);
        AxiosNetwork.axiosFetch({ "url": `api/common/apps` }, setApplicationList, afterApplicationListLoaded);
    }, []);

    const approvedQueryLoadedCallback = (data) =>
    {
        setAppovedQueriesFormatList([...data]);
        console.log(111);
    }

   
    const afterApplicationListLoaded = (data) => {
        data.sort(function(a, b){ return ((a.name > b.name) ? 1 : -1);});
        applicationListGlobal = [...data];
        setApplicationList([...data]);
        findAll();
    }

    const newRecord = () => {
        dataMassage["approvedQueryId"] = generateFuzzyId(tableName);
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        setUiLabels({ "save": "Save" });
        showHideAllProps({"selected-record": "none","new-record-format-section": "none",  "new-record": "block", "records-list-table": "none" });
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "selected-record": "none","new-record-format-section": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block" });

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findAll = (filter = "all") => {
        console.log("findAll called");
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin-approved-queries` }, {}, appListPostProcess);
        showHideAllProps({ "selected-record": "none","new-record-format-section": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block" });
    }

    const appListPostProcess = (data) => {
        setRecordsList(data);
    }

    const onFinish = (values) => {
        values["sql_query"] = theObject["sql_query"];
        AxiosNetwork.axiosPut({ "url": `/api/admin-approved-queries/${dataMassage["approvedQueryId"]}/update`, "id":dataMassage["approvedQueryId"] }, values, recordsList, setRecordsList, newRecordCallback);
    }

    const newRecordCallback = (data) => {
        message.info('Approved Queries has been created');
        approvedQueriesForm.resetFields();
        console.log(data);
    }
    const onApprovedQueriesFormatFinish = (values) => {
        values["name"] = theObject["name"];
        values["id"] = dataMassage["approvedQueryFormatId"];
        values["header"] = theFormatterObject["header"];
        values["description"] = theFormatterObject["description"];
        values["footer"] = theFormatterObject["footer"];
        AxiosNetwork.axiosPut({ "url": `/api/admin-approved-queries/${dataMassage['approvedQueryId']}/formatters/${dataMassage['approvedQueryFormatId']}/update` }, values, formatRecordsList, setFormatRecordsList, newFormatCallBack);
    }
    const newFormatCallBack = (data) => {
        message.info('Approved Queries Format has been created');
        approvedQueriesFormatForm.resetFields();
        console.log(data);
    }
    const approvedQueriesFormFailed = () => {

    }

    const viewTableRow = (selectedRow) => {
        dataMassage["approvedQueryId"] = selectedRow.id;
        dataMassage["approvedQueryFormatId"] = generateFuzzyId("query-formattter");
        formData = {};
        setUiLabels({ "save": "Save" });
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosFetch({ "url": `/api/admin-approved-queries/${selectedRow.id}/formatters`  }, setFormatRecordsList);
        showHideAllProps({ "selected-record": "block","edit-selected-record": "none", "new-record": "none", "new-record-format-section": "block", "records-list-table": "block" });
    }

    const onapprovedQueriesFormatFormFailed = () =>
    {
        
    }
    const onChangeHead = (newValue, e) => {
        console.log('onChange', newValue, e);
        theFormatterObject["header"] = newValue;
    }
    const onChangeDescription = (newValue, e) => {
        console.log('onChange', newValue, e);
        theFormatterObject["description"] = newValue;
    }
    const onChangeFooter = (newValue, e) => {
        console.log('onChange', newValue, e);
        theFormatterObject["footer"] = newValue;
    }

    const onChangeQuery = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["sql_query"] = newValue;
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll("all")} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin()} rel="noopener noreferrer">Bin</a>)
                            }
                        ]}>
                    </Menu>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={configMenus}>
                    </Menu>
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} pagination={{ pageSize: 50}}/>
                        </section>

                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                        </section>


                        <section className="thCard" style={{ "width":800, "display": presentObjects["new-record"] }} id="new-record-section">
                                <Form size="large" layout="vertical" labelAlign="right"
                                    name="basic"
                                    onFinish={onFinish}
                                    form={approvedQueriesForm} initialValues={theObject} 
                                    onFinishFailed={approvedQueriesFormFailed} autoComplete="off">
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        extra={theObject.id}
                                        rules={[ { required: true, message: 'Enter your name'}]}>
                                        <Input style={{width:500}} />
                                    </Form.Item>
                                    <Form.Item label="Query">
                                      <CodeMirror
                                            value={((theObject["sql_query"] == null) ? "" : theObject["sql_query"])}
                                            height="200px"
                                            options={{ theme: 'monokai', keyMap: 'sublime', mode: 'html'}}
                                            onChange={ onChangeQuery }
                                        />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item label="Application" name="app_id" rules={[{ required: true, message: 'Enter the application' }]}>
                                                <Select showSearch style={{ width: '100%' }} placeholder="Select Application" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                                    {applicationList.map(eachItem => (
                                                        <Select.Option key={eachItem["id"]}>{eachItem["name"]}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                    </Col>
                                        <Col span={4}>
                                            <Form.Item label="User Position" name="user_position" >
                                                <InputNumber />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Visual" name="visual" >
                                                <Select>
                                                    <Select.Option key="Line Chart">Line chart</Select.Option>
                                                    <Select.Option key="Bar Chart">Bar Chart</Select.Option>
                                                    <Select.Option key="Pie Chart">Pie Chart</Select.Option>
                                                    <Select.Option key="Straight Line Chart">Straight Line Chart</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                    </Form.Item>
                                </Form>
                        </section>

                        <section style={{ "width":800, marginBottom:"20px", "display": presentObjects["selected-record"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={formatterColumns} dataSource={formatRecordsList} pagination={false}/>
                        </section>


                        <section className="thCard" style={{ "width":800, "display": presentObjects["new-record-format-section"] }}>
                            <Form labelAlign="right"  layout="vertical"
                                name="basic" onFinish={onApprovedQueriesFormatFinish}
                                form={approvedQueriesFormatForm} initialValues={approvedQueriesFormatForm} size={"large"}
                                onFinishFailed= {onapprovedQueriesFormatFormFailed} autoComplete="off">
                                <Form.Item label="Format" name="format">
                                <Select>
                                    <Select.Option value="XML">XML</Select.Option>
                                    <Select.Option value="JSON">JSON</Select.Option>
                                    <Select.Option value="XHTML">XHTML</Select.Option>
                                    <Select.Option value="CSV">CSV</Select.Option>
                                </Select>
                                </Form.Item> 
                                <Form.Item label="Header">
                                       <CodeMirror value={((theFormatterObject.header == null) ? "" : theFormatterObject.header)}
                                                height="200px"
                                                options={{ theme: 'monokai', keyMap: 'sublime', mode: 'html'}}
                                                onChange={onChangeHead}
                                            />
                                    </Form.Item>      
                                    <Form.Item label="Description">
                                      <CodeMirror
                                            value={((theFormatterObject.description == null) ? "" : theFormatterObject.description)}
                                            height="200px"
                                            options={{ theme: 'monokai', keyMap: 'sublime', mode: 'html'}}
                                            onChange={onChangeDescription}
                                        />
                                    </Form.Item> 
                                    <Form.Item label="Footer">
                                        <CodeMirror
                                            value={((theFormatterObject.footer == null) ? "" : theFormatterObject.footer)}
                                            height="200px"
                                            options={{ theme: 'monokai', keyMap: 'sublime', mode: 'html'}}
                                            onChange={onChangeFooter}
                                        />
                                    </Form.Item> 
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                    </Form.Item>
                       </Form>
                       </section>
                        <Drawer title="Info Label" placement="right" onClose={() => showHideAllProps({"showInfo": false})} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}