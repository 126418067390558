import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faTimeline, faClock, faUser, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { appProfile, searchTextLength, menuBarItems, profileBarItems,dateTimeUtils, generateFuzzyId } from "../Config";
import '../App.css';
import { v4 as uuid } from 'uuid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Avatar, Space, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import MiniTodos from './MiniTodos';

const { Dragger } = Upload;
const { SubMenu } = Menu;
const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
var activeDataColumns = [];
let ckEditorData = "";

const appId = "todo";
const appPath = "todo";
const tableName = "todos";
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const dateTimeFormat = "yyyy-MM-DD HH:mm:ss";

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const infiniteLoop = [];

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export default function Todos() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [tempList, setTempList] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [reminderList, setReminderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [priorityList, setPriorityList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "create-new-record-section": "none", "records-list-table": "block", "edit-selected-record": "none", "composeTodo": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "new-todo": "none" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theTodo, setTheTodo] = useState({ "application": "EMAIL", "fileAssets": [], "body": "Type your content here" });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [todoForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", "/apps/todos");
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/priorities` }, { "doc_name": "priorities" }, setPriorityList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi` }, { "doc_name": "reminders", "select": ["id", "name"] }, setReminderList);
        composeTodo();
        findAll();
    }, infiniteLoop);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    function disabledDate(current) {
        return current && current < moment().endOf('day');
    }
    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    const { RangePicker } = DatePicker;
    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    };
    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'genre',
        },
        {
            title: 'Due At',
            render: (record) => <span>{(record.dueAt !== null && moment(record.dueAt).format("lll"))}</span>,
            width: 100
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 50
        },
        {
            key:"trash",
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 50
        },
        {
            key: "done",
            title: 'Done',
            render: (record) => <a onClick={(e) => markAsDone(record)}><FontAwesomeIcon icon={faCircleCheck} /></a>,
            width: 50
        },
        {
            key: "info",
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 50
        },
        {
            key:"restore",
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>,
        }
    ];
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        todoForm.setFieldsValue({ ...selectedRow });
        formData = {};
        dataMassage["todoId"] = selectedRow.id;
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": "/api/todos/" + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "create-new-record-section": "block", "edit-selected-record": "block" });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const markAsDone = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/mark-as-done/` + selectedRow.id, "id": selectedRow.id }, theTodo, recordsList, setRecordsList);
    }

    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "edit-selected-record": "none", "create-new-record-section": "block", "records-list-table": "none" })
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["due_date"] = moment(values["due_date"]).format("yyyy-MM-DD HH:mm:ss");
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/todos/update/" + dataMassage["todoId"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your todo has been saved.');
        findAll();

    }
     const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({"button-controls-section":"none", "create-new-record-section": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({"button-controls-section":"none",  "create-new-record-section": "none", "records-list-table": "block" });
    }
    const deleteFolder = (currentFolder) => {
        console.log(currentFolder);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/folder/` + currentFolder.id, "id": currentFolder.id }, folderList, setFolderList);
    }
    const deleteLabel = (currentLabel) => {
        console.log(currentLabel);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/label/` + currentLabel.id, "id": currentLabel.id }, labelList, setLabelList);
    }
    const updateFolder = (e) => {
        console.log(theFolder);
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/folder/` + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
    }
    const updateLabel = (e) => {
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/label/` + theLabel.id, "id": theLabel.id }, theLabel, labelList, setTheLabel);
        console.log(theLabel);
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const changeDueAt = (e) => {
        theTodo["dueAt"] = e.format(dateTimeFormat);
    }
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "create-new-record-section": "none", "records-list-table": "block", });
    }
    const findTaskBy = (filter) => {
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/${filter}` }, recordsList, setRecordsList);
        showHideProps("active", "block");
    }
    const findByDateRange = (afterBy, beforeBy, timeZone) => {
        console.log(afterBy, beforeBy);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters": [{ "col_name": "due_at", "data_type": "date", "operator": ">", "date_value": afterBy }, { "col_name": "due_at", "data_type": "date", "operator": "<", "date_value": beforeBy }] }, setRecordsList);
        showHideProps("active", "block");
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "create-new-record-section": "none", "records-list-table": "block" });
    }
    const composeTodo = () => {
        showHideProps("composeTodo", "block");
        theObject["emailAddress"] = [];
        formData = {};
        dataMassage["todoId"] = "todo-" + uuid();
        formData["parent_id"] = dataMassage["todoId"]
        formData["folder_id"] = dataMassage["todoId"];
    };

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Sider width={200}>
                        <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                            items={[
                                {
                                    key: 'create-new-item',
                                    label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                                },
                                {
                                    key: 'all-records',
                                    label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                                },
                                {
                                    key: 'sub-folders',
                                    label: (<a onClick={(e) => findAll()}>Folders</a>),
                                    children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                                },
                                {
                                    key: 'sub-labels',
                                    label: (<a onClick={(e) => findAll()}>Labels</a>),
                                    children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                                },
                                {
                                    key: 'bin-records',
                                    label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                                }
                            ]} />
                    </Sider>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}>
                        <Menu.Item key="missed-todo" onClick={(e) => findByDateRange("1983-05-06 00:00:00", moment().add(-1, 'days').endOf('day').format(dateTimeFormat), moment.tz.guess())}>Overdue</Menu.Item>
                        <Menu.Item key="today-todo" onClick={(e) => findByDateRange(moment().startOf('day').format(dateTimeFormat), moment().endOf('day').format(dateTimeFormat), moment.tz.guess())}>Today</Menu.Item>
                        <Menu.Item key="yesterday-todo" onClick={(e) => findByDateRange(moment().add(-1, 'days').startOf('day').format(dateTimeFormat), moment().add(-1, 'days').endOf('day').format(dateTimeFormat), moment.tz.guess())}>Yesterday</Menu.Item>
                        <Menu.Item key="tomorrow-todo" onClick={(e) => findByDateRange(moment().add(1, 'days').startOf('day').format(dateTimeFormat), moment().add(1, 'days').endOf('day').format(dateTimeFormat), moment.tz.guess())}>Tomorrow</Menu.Item>
                        <Menu.Item key="next3days-todo" onClick={(e) => findByDateRange(moment().add(3, 'days').startOf('day').format(dateTimeFormat), moment().add(3, 'days').endOf('day').format(dateTimeFormat), moment.tz.guess())}>Next 3 days</Menu.Item>
                        <Menu.Item key="next5days-todo" onClick={(e) => findByDateRange(moment().add(5, 'days').startOf('day').format(dateTimeFormat), moment().add(5, 'days').endOf('day').format(dateTimeFormat), moment.tz.guess())}>Next 5 days</Menu.Item>
                        <Menu.Item key="week-todo" onClick={(e) => findByDateRange(moment().startOf('week').format(dateTimeFormat), moment().endOf('week').format(dateTimeFormat), moment.tz.guess())}>This week</Menu.Item>
                        <Menu.Item key="next-week" onClick={(e) => findByDateRange(moment().add(1, 'week').startOf('week').format(dateTimeFormat), moment().add(1, 'week').endOf('week').format(dateTimeFormat), moment.tz.guess())}>Next week</Menu.Item>
                        <Menu.Item key="next-2-week" onClick={(e) => findByDateRange(moment().add(2, 'week').startOf('week').format(dateTimeFormat), moment().add(2, 'week').endOf('week').format(dateTimeFormat), moment.tz.guess())}>Next 2 week</Menu.Item>
                        <Menu.Item key="month-today" onClick={(e) => findByDateRange(moment().startOf('month').format(dateTimeFormat), moment().endOf('month').format(dateTimeFormat), moment.tz.guess())}>This month</Menu.Item>
                        <Menu.Item key="next-month" onClick={(e) => findByDateRange(moment().add(1, 'months').startOf('month').format(dateTimeFormat), moment().add(1, 'months').endOf('month').format(dateTimeFormat), moment.tz.guess())}>Next Month</Menu.Item>
                        <Menu.Item key="next 3-month" onClick={(e) => findByDateRange(moment().add(3, 'months').startOf('month').format(dateTimeFormat), moment().add(3, 'months').endOf('month').format(dateTimeFormat), moment.tz.guess())}>Next 3 Month</Menu.Item>
                        <Menu.Item key="next 6-month" onClick={(e) => findByDateRange(moment().add(6, 'months').startOf('month').format(dateTimeFormat), moment().add(6, 'months').endOf('month').format(dateTimeFormat), moment.tz.guess())}>Next 6 Month</Menu.Item>
                        <Menu.Item key="current-year" onClick={(e) => findByDateRange(moment().add('year').format(dateTimeFormat), moment().endOf('year').format(dateTimeFormat), moment.tz.guess())}>This year</Menu.Item>
                        <Menu.Item key="next-year" onClick={(e) => findByDateRange(moment().add(1, 'years').startOf('year').format(dateTimeFormat), moment().add(1, 'years').endOf('year').format(dateTimeFormat), moment.tz.guess())}>Next year</Menu.Item>
                        <Menu.Item key="completed" onClick={(e) => findTaskBy("completed")}>Completed</Menu.Item>
                        <Menu.Item key="unplanned" onClick={(e) => findTaskBy("unplanned")}>Unplanned</Menu.Item>
                    </Menu>
                </Sider>
                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>
                        <MiniTodos key={"key"+new Date().getTime()} presentObjects={presentObjects}/>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}