import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan, faEdit, faUndoAlt, faFilter, faEraser, faRefresh, faSave, faBoxOpen, faList, faWallet, faDownload } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, appProfile } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col, DatePicker } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import ReimbursementReviewer from "./ReimbursementReviewer";
import ReimbursementConfiguration from "./ReimbursementConfiguartion";

const { Dragger } = Upload;
const dataMassage = { "emailAddress": {}, "associateId": "1", "id":1 };
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {"parent_type":164};
const appId = 164;
const appPath = "reimbursements";
const tableName = "reimbursements";
var activeDataColumns = [];
var activeReimbursementItemColumn = [];
let ckEditorData = "";
const queryParams = queryString.parse(window.location.search);

const fileUploadProps = {
    id: new Date().getTime(),
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.recordsList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function Reimbursement() {
    const [recordsList, setRecordsList] = useState([]);
    const [itemsRecords, setItemsRecords] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "reimbursements": "none", "reimbursement-single-bill-details": "none", "reimbursement-configs": "none", "reviewer": "none", "reviewer-details": "none", "reimbursement-items-record-list-table": "none", "reimbursement-reviewer-items-record-list-table": "none", "reimbursement-items-details": "none", "edit-selected-record": "none", "reimbursement-details": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [theItemsObject, setTheItemObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [reimbursementForm] = Form.useForm();
    const [reimbursementSingleBillForm] = Form.useForm();
    const [reimbursementItemsForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [costCentreList, setCostCentreList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [currenciesList, setCurrenciesList] = useState([]);
    const [reimbursementConfigList, setReimbursementConfigList] = useState([]);
    const [reviewList, setReviewList] = useState([]);
    const [reimbursementItemCategoryList, setReimbursementItemCategoryList] = useState([]);
    const [mimeAssets, setMimeAssets] = useState([]);
    console.log(queryParams);

    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "organizations" }, setCostCentreList);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/currencies` }, setCurrenciesList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/statuses/appid/reimbursement` }, setStatusList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-select-any-multi` }, { "doc_name": "reimbursement_configs", "select": ["id", "name"] }, setReimbursementConfigList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "reimbursement-item-category" }, setReimbursementItemCategoryList);
        findAll();
    }, []);

    const reimbursementItemColumn = [
        {
            title: 'Name',
            dataIndex: 'category_name',
        },
        {
            title: 'Bill No.',
            dataIndex: 'purchase_id',
            width:200
        },
        {
            title: 'Bill Date.',
            width:200,
            render: (record) => <span>{record["bill_at"] && moment(record["bill_at"]).format("lll")}</span>,
        },
        {
            title: <FontAwesomeIcon icon={faWallet}/>,
            width:120,
            render: (record) => <span>{record.amount} {record.currency} </span>,
        },
        {
            key: "edit",
            title: <FontAwesomeIcon icon={faEdit}/>,
            width: 50,
            render: (record) => <a onClick={(e) => editReimbursementItem(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: <FontAwesomeIcon icon={faTrashCan}/>,
            width: 50,
            render: (record) => <a onClick={(e) => deleteReimbursementItems(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }
    ];

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Reviewer',
            dataIndex: 'reviewer_name'
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width:100
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            width:100
        },
        {
            title: 'Status',
            dataIndex: 'status_name'
        },
        {
            title: 'Submitted At',
            render: (record) => <span>{record["submitted_at"] && moment(record["submitted_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 20,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "view",
            title: 'View',
            width: 20,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faList} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 20,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>,
            width: 60
        }
    ];



    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    }

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "reviewer": "none", "reimbursement-configs": "none", "records-list-table": "none", "reviewer-records-list-table": "none", "reimbursement-items-record-list-table": "none", "edit-selected-record": "none", "reimbursement-items-details": "none", "reimbursement-details": "none", "records-list-table": "block" });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/reimbursements/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const deleteReimbursementItems = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/reimbursements/${dataMassage["reimbursementId"]}/items/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        const deleteIndex = recordsList.findIndex((obj => obj.id == selectedRow.id));
        if(deleteIndex!=-1) recordsList.splice(deleteIndex, 1);
        setRecordsList([...recordsList]);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, itemsRecords, setItemsRecords);
    }

    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        setUiLabels({ "save": "Update" });
        selectedRow["mime_assets"] = [];
        dataMassage["reimbursementId"] = selectedRow.id;
        dataMassage["reimbursementItemId"] = generateFuzzyId("reimbursement_item");
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/reimbursements/` + selectedRow.id, "id": selectedRow.id }, setTheObject, afterReimbursements);
    }

    const afterReimbursements = (data) => {
        setTheObject({ ...data });
        reimbursementForm.setFieldsValue({ ...data });
        showHideAllProps({ "reimbursement-single-bill-details": "none", "reimbursement-items-record-list-table": "none", "reimbursement-items-details": "none", "edit-selected-record": "block", "reimbursement-details": "block", "records-list-table": "block" });
        document.getElementById('reimbursement-details-form-section').scrollIntoView({ behavior: 'smooth' });
    }

    const editReimbursementItem = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        dataMassage["reimbursementItemId"] = selectedRow.id;
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/reimbursements/${dataMassage["reimbursementId"]}/items/${dataMassage["reimbursementItemId"]}` }, setTheItemObject, getReimbursementItemCallback);
    }

    const getReimbursementItemCallback = (data) => {
        setTheItemObject({ ...data });
        if(data["bill_at"]!==undefined) data["bill_at"] = moment(data["bill_at"]);
        reimbursementItemsForm.setFieldsValue({ ...data });
        showHideAllProps({ "reimbursement-single-bill-details": "none", "reimbursement-items-record-list-table": "block", "reimbursement-items-details": "block", "reimbursement-details": "none", "records-list-table": "block" });
        setMimeAssets(data["mime_assets"]);
        document.getElementById('reimbursement-items-details-section').scrollIntoView({ behavior: 'smooth' });
    }

    const viewTableRow = (selectedRow) => {
        setTheObject({ ...selectedRow });
        dataMassage["theObject"] = selectedRow;
        dataMassage["reimbursementId"] = selectedRow.id;
        dataMassage["reimbursementItemId"] = generateFuzzyId("reimbursement_item");
        formData["parent_id"] = dataMassage["reimbursementId"];
        console.log(formData);
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `api/reimbursements/${selectedRow.id}/items` }, {}, setItemsRecords, afterReimbursementItems);
    }

    const afterReimbursementItems = (data) => {
        activeReimbursementItemColumn = [...reimbursementItemColumn];
        if(dataMassage["theObject"]["submitted_at"] != undefined) activeReimbursementItemColumn = removeBinHeaders(reimbursementItemColumn);
        setItemsRecords([...data]);
        showHideAllProps({ "reviewer": "none", "reimbursement-single-bill-details": "none", "reimbursement-items-record-list-table": "block", "reimbursement-items-details": "block", "reimbursement-details": "none", "records-list-table": "block" });
        document.getElementById('reimbursement-items-details-section').scrollIntoView({ behavior: 'smooth' });
    }

    const onReimbursementItemFinish = (values) => {
        values["upsert"] = true;
        AxiosNetwork.axiosPost({ "url": `/api/reimbursements/${dataMassage["reimbursementId"]}/items/${dataMassage["reimbursementItemId"]}/upsert` }, values, itemsRecords, setItemsRecords, afterItemsSubmit);
    }
    const onReimbursementSubmitForApproval = () => {
        AxiosNetwork.axiosPost({ "url": `/api/reimbursements/${dataMassage["reimbursementId"]}/submit` }, {}, itemsRecords, setItemsRecords, afterItemsSubmit);
    }

    const afterItemsSubmit = (data) => {
        message.info('Your reimbursement items details has been saved.');
        reimbursementItemsForm.resetFields();
        console.log("afterItemsSubmit", data);
        viewTableRow({ ...theObject });
    }

    const findAll = (filter) => {
        var filters = [];
        if (filter == "submitted") {
            filters.push({ "col_name": "submitted_at", "operator": "IS NOT", "data_type": "date", "date_value": null });
            filters.push({ "col_name": "reviewer_remark_at", "operator": "IS", "data_type": "date", "date_value": null });
        }
        if (filter == "approved") {
            filters.push({ "col_name": "reviewer_remark_at", "operator": "IS NOT", "data_type": "date", "date_value": null });
        }
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/reimbursements` }, { "doc_name": tableName, "filters":filters }, setRecordsList);
        showHideAllProps({ "reviewer": "none", "reimbursement-configs": "none", "records-list-table": "none", "reviewer-records-list-table": "none", "reimbursement-items-record-list-table": "none", "edit-selected-record": "none", "reimbursement-items-details": "none", "reimbursement-details": "none", "records-list-table": "block" });
    }

    const onFinish = (values) => {
        values["upsert"] = true;
        values["description"] = ckEditorData;
        AxiosNetwork.axiosPost({ "url": `/api/reimbursements/${dataMassage["reimbursementId"]}/upsert` }, values, recordsList, setRecordsList, newRecordCallback);
        showHideAllProps({ "reimbursement-items-record-list-table": "none", "edit-selected-record": "none", "reimbursement-items-details": "none", "reimbursement-details": "none", "records-list-table": "block" });
    }

    const newRecordCallback = (data) => {
        message.info('Reimbursement has been created');
        reimbursementForm.resetFields();
        console.log(data);
        findAll();
    }
    const reimbursementFormFailed = () => {

    }

    const reimbursementItemsFormFailed = () => {

    }

    const findUserLike = (value) => {
        if (value === null || value === "") return;
        setReviewList([...[]]);
        var payload = { "select":["id", "name"], "doc_name": "users", "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi-like` }, payload, setReviewList, setReviewListCallBack);
    }

    const setReviewListCallBack = (data) => {
        setReviewList([...data]);
    }

    const showOtherComponents = (sectionName) => {
        var toggleSections = { "reimbursement-reviewer-items-record-list-table": "none", "reviewer-records-list-table": "none", "reimbursement-items-details": "none", "reimbursement-single-bill-details": "none", "reimbursement-details": "none", "reviewer-details": "none", "reimbursement-configuration-details": "none", "reimbursement-items-record-list-table": "none", "reimbursements": "none", "reviewer": "none", "reimbursement-configs": "none", "records-list-table": "none" };
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }
    const reviewerFormFailed = () => {

    }
    const reimbursementSingleBillFormFailed = () => {

    }
    
    const newMultiBillRecord = () => {
        dataMassage["reimbursementId"] = generateFuzzyId(tableName);
        formData["parent_type"] = 164;
        formData["parent_id"] = dataMassage["reimbursementId"];
        setUiLabels({ "save": "Save" });
        showHideAllProps({ "reviewer":"none", "reimbursement-configs":"none", "reimbursement-single-bill-details": "none", "reimbursement-items-record-list-table": "none", "reimbursement-items-details": "none", "reimbursement-details": "block", "records-list-table": "block" });
    }

    const newSingleBillRecord = () => {
        dataMassage["reimbursementId"] = generateFuzzyId(tableName);
        formData["parent_id"] = dataMassage["reimbursementId"];
        formData["parent_type"] = 164;
        setUiLabels({ "save": "Save" });
        showHideAllProps({ "reviewer":"none", "reimbursement-configs":"none", "reimbursement-single-bill-details": "block", "reimbursement-items-record-list-table": "none", "reimbursement-items-details": "none", "reimbursement-details": "none", "records-list-table": "block" });
    }

    const onReimbursementSingleBillFinish = (values) => {
        dataMassage["reimbursementId"] = generateFuzzyId(tableName);
        dataMassage["reimbursementItemId"] = generateFuzzyId("reimbursement_item");
        values["description"] = ckEditorData;
        values["reimbursement"] = { ...values };
        values["upsert"] = true;
        AxiosNetwork.axiosPost({ "url": `/api/reimbursements/${dataMassage["reimbursementId"]}/items/${dataMassage["reimbursementItemId"]}/upsert` }, values, itemsRecords, setItemsRecords, afterSingleBillAdded);
        showHideAllProps({ "reimbursement-single-bill-details": "none", "reimbursement-items-record-list-table": "none", "reimbursement-items-details": "none", "reimbursement-details": "none", "records-list-table": "block" });

    }
    const afterSingleBillAdded = (data) => {
        message.info('Reimbursement single bill has been created');
        reimbursementSingleBillForm.resetFields();
        console.log(data);
        findAll();
    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                label: "New", "key": "new-reimbursements", children: [
                                    {
                                        key: 'single-bill',
                                        label: (<a onClick={(e) => newSingleBillRecord()} rel="noopener noreferrer">Single bill</a>)
                                    },
                                    {
                                        key: 'multi-bill',
                                        label: (<a onClick={(e) => newMultiBillRecord()} rel="noopener noreferrer">Multi bill</a>)
                                    },
                                ],
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'all-submitted-records',
                                label: (<a onClick={(e) => findAll("submitted")} rel="noopener noreferrer">Submitted</a>)
                            },
                            {
                                key: 'all-approved-records',
                                label: (<a onClick={(e) => findAll("approved")} rel="noopener noreferrer">Approved</a>)
                            },
                            {
                                key: 'all-bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            },
                            { type: 'divider' },
                            {
                                key: 'reviewer',
                                label: (<a onClick={e => showOtherComponents("reviewer")} href="#">Manager</a>)
                            },
                            { type: 'divider' },
                            {
                                key: 'reimbursement-configs',
                                label: (<a onClick={e => showOtherComponents("reimbursement-configs")} href="#">Reimbursements</a>), "key": "reimbursement-configs"
                            }
                        ]}>
                    </Menu>
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        { (presentObjects["reviewer"]=="block") && 
                            <section>
                                <ReimbursementReviewer />
                            </section>
                        }

                        { (presentObjects["reimbursement-configs"]=="block") && 
                            <section>
                                <ReimbursementConfiguration />
                            </section>
                        }

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} pagination={{ pageSize: 50 }} footer={() => "List of reimbursements raised"} />
                        </section>

                        <section style={{ "width": 1024, marginBottom: "20px", "display": presentObjects["reimbursement-items-record-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeReimbursementItemColumn} dataSource={itemsRecords} pagination={ false } footer={(e) => "List of reimbursement item particulars"} />
                            { (itemsRecords.length>0 && theObject["submitted_at"]==undefined) && <Button onClick={(e) => onReimbursementSubmitForApproval(e) } style={{"marginTop":"10pt"}} type="primary" htmlType="submit"><FontAwesomeIcon icon={faBoxOpen} /> &#x2002; Submit for Approval</Button> }
                        </section>

                        <section className="thCard" style={{ "width": 750, "display": presentObjects["reimbursement-details"] }} id="reimbursement-details-form-section">
                            <Form size="large" labelAlign="right" layout="vertical"
                                name="basic" onFinish={onFinish}
                                form={reimbursementForm} initialValues={theObject}
                                onFinishFailed={reimbursementFormFailed} autoComplete="off">
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Enter your name' },]}>
                                    <Input />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Type" name="reimbursement_config_id">
                                            <Select style={{ width: '100%' }} placeholder="Select  Reimburation Configuartion">
                                                {reimbursementConfigList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Currency" name="currency"
                                            rules={[{ required: true, message: 'Enter your currency' }]}>
                                            <Select style={{ width: '100%' }} placeholder="Change Currencies">
                                                {currenciesList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Reviewer" name="reviewer_id"
                                            rules={[{ required: true, message: 'Enter your Review', }]}>
                                            <Select notFoundContent={null} showSearch onSearch={findUserLike} placeholder="Select User">
                                                {reviewList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Cost Center" name="cost_center_id"
                                            rules={[{ required: true, message: 'Enter your Cost center' },]}>
                                            <Select defaultActiveFirstOption={true}
                                                placeholder="Select Cost Centre">
                                                {costCentreList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Status" name="status_id"
                                            rules={[{ required: true, message: 'Enter your Status', },]}>
                                            <Select style={{ width: '100%' }} placeholder="Change  Status">
                                                {statusList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item></Col>
                                </Row>
                                <Form.Item label="Description" name="description"
                                    rules={[{ required: false, message: 'Type the Description' }]}>
                                    <CKEditor
                                        editor={DecoupledEditor}
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change(writer => {
                                                writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                                            });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                        </section>
                        
                        { (theObject["submitted_at"]===undefined) && 
                        <section className="thCard" style={{ width:750, "display": presentObjects["reimbursement-items-details"] }} id="reimbursement-items-details-section">
                            <Form size="large" labelAlign="right" layout="vertical" name="basic" onFinish={onReimbursementItemFinish} form={reimbursementItemsForm} initialValues={theObject} onFinishFailed={reimbursementItemsFormFailed} autoComplete="off">
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Category" name="category_id" rules={[{ required: true, message: 'Enter your Category', }]}>
                                            <Select style={{ width: '100%' }} placeholder="Change Category">
                                                {reimbursementItemCategoryList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Bill or Invoice Number" name="purchase_id" rules={[{ required: false, message: 'Enter your name', }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Bill Date" name="bill_at" rules={[{ required: false, message: 'Enter your supplier' }]}>
                                            <DatePicker format={"yyyy-MM-DD"}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Supplier" name="supplier_id" rules={[{ required: false, message: 'Enter your supplier' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Amount" name="amount" rules={[{ required: true, message: 'Enter your amount', }]}>
                                            <InputNumber style={{"width": 100}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Currency" name="currency" rules={[{ required: true, message: 'Enter your currency', }]}>
                                            <Select style={{ width: '100%' }} placeholder="Change Cuurrencies">
                                                {currenciesList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Notes" name="notes" rules={[{ required: false, message: 'Enter your notes', }]}>
                                    <Input />
                                </Form.Item>

                                <Form.Item>
                                    <Dragger {...fileUploadProps}>
                                        <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={<i class="fa fa-upload" aria-hidden="true"></i>} /></p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button> &#x2002;
                                </Form.Item>
                            </Form>
                            <p><small>Add the reimbursement items. Multiple items can be added to a reimbursement record.</small></p>
                            { (mimeAssets.length>0) && <section style={{width:500}}><Table size="small" rowKey="id" columns={[{width:50, title: <FontAwesomeIcon icon={faDownload}/>, render: (record) => <a onClick={(e) => AxiosNetwork.downloadMimeAsset(record)}><FontAwesomeIcon icon={faDownload} /></a>},{width:50, title: <FontAwesomeIcon icon={faTrashCan}/>,render: (record) => <a onClick={(e) => AxiosNetwork.deleteMimeAsset(record.id, mimeAssets, setMimeAssets)}><FontAwesomeIcon icon={faTrashCan} /></a>},{title: 'Name',dataIndex: 'name'}]} dataSource={mimeAssets} pagination={false} showHeader={false}/></section> }
                        </section>
                        }

                        <section className="thCard" style={{ "width": 750, "display": presentObjects["reimbursement-single-bill-details"] }} >
                            <Form size="large" labelAlign="right" layout="vertical"
                                name="basic" onFinish={onReimbursementSingleBillFinish} form={reimbursementSingleBillForm} initialValues={theObject} onFinishFailed={reimbursementSingleBillFormFailed} autoComplete="off">
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Enter your name' },]}>
                                    <Input />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Type" name="reimbursement_config_id">
                                            <Select style={{ width: '100%' }} placeholder="Select Reimburation Configuartion">
                                                {reimbursementConfigList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Currency" name="currency"
                                            rules={[{ required: true, message: 'Enter your currency' }]}>
                                            <Select style={{ width: '100%' }} placeholder="Change Currencies">
                                                {currenciesList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Reviewer" name="reviewer_id"
                                            rules={[{ required: true, message: 'Enter your Review', }]}>
                                            <Select showSearch onSearch={findUserLike}
                                                placeholder="Select User">
                                                {reviewList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Cost Center" name="cost_center_id"
                                            rules={[{ required: true, message: 'Enter your Cost center' },]}>
                                            <Select defaultActiveFirstOption={true}
                                                placeholder="Select Cost Centre">
                                                {costCentreList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item label="Status" name="status_id"
                                            rules={[{ required: true, message: 'Enter your Status', },]}>
                                            <Select style={{ width: '100%' }} placeholder="Change  Status">
                                                {statusList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Bill or Invoice Number" name="purchase_id" rules={[{ required: true, message: 'Enter your name', }]}>
                                            <Input style={{ width: 200 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Category" name="category_id"
                                            rules={[{ required: true, message: 'Enter your Category', }]}>
                                            <Select style={{ width: '100%' }} placeholder="Change Category">
                                                {reimbursementItemCategoryList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Amount" name="amount" rules={[{ required: true, message: 'Enter your amount', }]}>
                                            <InputNumber style={{ width: 300 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Supplier" name="supplier_id"
                                            rules={[{ required: false, message: 'Enter your supplier' }]}>
                                            <Input style={{ width: 300 }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item>
                                    <Dragger {...fileUploadProps}>
                                        <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={<i class="fa fa-upload" aria-hidden="true"></i>} /></p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Form.Item>


                                <Form.Item label="Description" name="description"
                                    height="200px"
                                    rules={[{ required: false, message: 'Type the Description' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change(writer => {
                                                writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                                            });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                        </section>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}