import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { faUndoAlt, faBackward, faTrashCan, faEdit, faInfoCircle, faCloudUploadAlt, faSave, faCloudArrowDown, faLock, faUnlock, faCopy } from '@fortawesome/free-solid-svg-icons'
import { openWindow, generateFuzzyId, appProfile, footerLine, menuBarItems, searchTextLength, profileBarItems, dateTimeUtils, fileUploadProps, cdnFileUploadProps } from "../Config";
import { deleteFileAssetsRow } from "../CommonEvents";
import '../App.css';
//import '../antd.css';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Tabs, Layout, Menu, Typography, Space, Row, Button, Table, Form, Input, Upload, message, Select, DatePicker, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CodeMirror from '@uiw/react-codemirror';
import { githubLight, githubLightInit, githubDark, githubDarkInit } from '@uiw/codemirror-theme-github';
import copy from 'copy-to-clipboard';
import { faFirefoxBrowser } from '@fortawesome/free-brands-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const { Search } = Input;
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const { TabPane } = Tabs;
const appId = 29;
const appPath = "web-pages";
const tableName = "web_pages";
var activeDataColumns = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };

let ckEditorData = "";

export default function WebPage() {
    const [tempList, setTempList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "new-data-form": "none", "records-list-table": "block", "active": "block", "bin": "none", "newData": "block", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [theForm] = Form.useForm();
    const [theObject, setTheObject] = useState({ "application": "EMAIL", "file_assets": [], "body": "", "id": tableName + "-" + uuid() });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [siteSettingList, setSiteSettingList] = useState([]);
    const [appList, setAppList] = useState(false);
    const [divShow, setDivShow] = useState(false);
    const [newContactHeaderView, setContactHeaderView] = useState(false);
    const [selectedRowsLength, setSelectedRowsLength] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentSelectId, setCurrentSelectId] = useState('');
    const [timezonesList, setTimezonesList] = useState([]);
    const [allFileAssets, setAllFileAssets] = useState([]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Scheduled',
            render: (record) => <span>{record["scheduled_at"] && moment(record["scheduled_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Active',
            render: (record) => <span>{(record["active"] ? "Yes" : "No")}</span>,
            width: 50
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "view",
            title: 'View',
            render: (record) => <a onClick={(e) => openWindow(record["url_name"])}><FontAwesomeIcon icon={faFirefoxBrowser} /></a>,
            width: 60
        },
        {
            key: "edit",
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 60
        },
        {
            key: "trash",
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        },
        {
            key: "info",
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 60
        },
        {
            key: "restore",
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>,
            width: 60
        }
    ];


    const fileAssetDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Access',
            render: (record) => <span>{((record.access == 0) ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />)}</span>
        },
        {
            title: 'Uploaded At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
        },
        {
            title: 'Action',
            render: (record) => <span><a href={appProfile.serviceLocation + "api/file-assets/download/" + record.id + "/" + record.name}><FontAwesomeIcon icon={faCloudArrowDown} /></a> &#x02003; <a onClick={e => copy(appProfile.serviceLocation + "auth/file-assets/view/" + record.id + "/" + record.name)}><FontAwesomeIcon icon={faCopy} /></a></span>
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteFileAssetsRow(record, allFileAssets, setAllFileAssets)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }

    ];


    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        dataMassage["newRecordId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id; 
        formData["folder_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, dataFetchCallback);
        setDivShow(true);
    }

    const dataFetchCallback = (data) => {
        if (data["scheduled_at"] != undefined) data["scheduled_at"] = moment(data["scheduled_at"]);
        setAllFileAssets([...data["cdn_file_assets"], ...data["file_assets"]])
        setTheObject({ ...data });
        theForm.setFieldsValue({ ...data });
        showHideAllProps({ "button-controls-section": "block", "new-data-form": "block", "records-list-table": "none" });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    }

    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const onFinish = (values) => {
        values["description"] = theObject["description"];
        values["header"] = theObject["header"];
        values["footer"] = theObject["footer"];
        values["scheduled_at"] = moment(values["scheduled_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["upsert"] = true;
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/${dataMassage["newRecordId"]}/upsert` }, values, recordsList, setRecordsList, afterSaveWebPage);
    }

    const afterSaveWebPage = () => {
        message.info("Saved");
        showHideAllProps({ "button-controls-section": "none", "new-data-form": "none", "records-list-table": "block" });
        findAll();
    }

    const infoTableRow = (selectedRow) => {
        setTheObject(selectedRow);
        showHideProps("showInfo", true);
    }

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/timezones` }, setTimezonesList);
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}/site-settings` }, setSiteSettingList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        findAll();
    }, []);


    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-data-form": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-data-form": "none", "records-list-table": "block" });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": `/api/${appPath}/restore/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const onChangeBody = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["description"] = newValue;
    }
    const onChangeHead = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["header"] = newValue;
    }
    const onChangeFoot = (newValue, e) => {
        console.log('onChange', newValue, e);
        theObject["footer"] = newValue;
    }

    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        dataMassage["newRecordId"] = generateFuzzyId(tableName);
        showHideAllProps({ "new-data-form": "block", "records-list-table": "none" });
    }

    const findFromBin = (filter) => {
        activeDataColumns = columnSchema.filter(item => !["trash", "edit", "info", "view"].includes(item.key));
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        console.log(activeDataColumns);
        showHideAllProps({ "new-data-form": "none", "records-list-table": "block" });
    }
   
    const findAll = () => {
        activeDataColumns = columnSchema.filter(item => !["restore"].includes(item.key));
        AxiosNetwork.axiosFetchMulti({ "url": `/api/web-pages` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "new-data-form": "none", "records-list-table": "block" });
    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content>
                        <section style={{ "display": presentObjects["button-controls-section"] }}>
                            <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                                <Button onClick={(e) => showHideAllProps({ "new-data-form": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                            </Space>
                        </section>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Row gutter={[16, 16]}>
                                <Table style={{ width: "100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                            </Row>
                        </section>
                        <section className="thCard" style={{ "width": 700, "display": presentObjects["new-data-form"] }} >
                            <Form size="large" labelAlign="right" layout="vertical" name="basic"
                                onFinish={onFinish}
                                form={theForm} initialValues={theObject}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Enter the name" name="name">
                                            <Input defaultValue={theObject.name} onChange={(e) => { theObject.name = e.target.value }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Enter the url path" name="url_name">
                                            <Input defaultValue={theObject["url_name"]} onChange={(e) => { theObject["url_name"] = e.target.value }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item name="site_setting_id" label="Site Setting">
                                            <Select style={{ width: '100%' }} placeholder="Apply Site Setting" onChange={(e) => { theObject["site_setting_id"] = e }}>
                                                {siteSettingList.map(eachItem => (
                                                    <Option key={eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Active" name="active">
                                            <Select showSearch allowClear placeholder="Choose active" defaultValue={true}>
                                                <Select.Option value={true}>Yes</ Select.Option>
                                                <Select.Option value={false}>No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Scheduled" name="scheduled_at">
                                            <DatePicker style={{ width: '100%' }} format={dateTimeUtils.dateFormat} onOk={onOk} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                    <Form.Item label="Timezone" name="timezone"
                                            rules={[{ required: true, message: 'Enter your  TimeZone ', }]}>
                                            <Select showSearch allowClear placeholder="Change  Timezone">
                                                {timezonesList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        
                                    </Col>
                                </Row>
                                <div>
                                    <Tabs type="card" style={{ "marginBottom": "20px" }}
                                        items={[
                                            {
                                                label: `Header`,
                                                key: '1',
                                                children: <CodeMirror
                                                    value={((theObject.header == null) ? "" : theObject.header)}
                                                    height="500px"
                                                    theme={githubLight}
                                                    onChange={onChangeHead}
                                                />
                                            },
                                            {
                                                label: `Body`,
                                                key: '2',
                                                children: <CodeMirror
                                                    value={((theObject.description == null) ? "" : theObject.description)}
                                                    height="500px"
                                                    theme={githubLight}
                                                    onChange={onChangeBody}
                                                />
                                            },
                                            {
                                                label: `Footer`,
                                                key: '3',
                                                children: <CodeMirror
                                                    value={((theObject.footer == null) ? "" : theObject.footer)}
                                                    height="500px"
                                                    theme={githubLight}
                                                    onChange={onChangeFoot}
                                                />
                                            }
                                        ]} />
                                </div>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                            <Row gutter={16}>
                                <Col span={12} style={{ marginBottom: "50px" }}>
                                    <Dragger {...fileUploadProps(formData, theObject, setTheObject) }>
                                        <p className="ant-upload-drag-icon">
                                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Col>
                                <Col span={12} style={{ marginBottom: "50px" }}>
                                    <Dragger {...cdnFileUploadProps(formData, theObject, setTheObject) }>
                                        <p className="ant-upload-drag-icon">
                                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Col>
                            </Row>
                            
                            <Table key={'id-'+new Date().getTime()} size="small" pagination={false} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} columns={fileAssetDataColumns} dataSource={allFileAssets} />

                            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} appType={appId} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                                </div>
                            </Drawer>
                        </section>
                    </Content>
                </Layout>
            </Layout>
            <Footer>{footerLine}</Footer>
        </Layout>

    )
}