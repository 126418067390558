import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faLaptopHouse, faBoxOpen, faList, faDownload } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col, DatePicker } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const dataMassage = { "emailAddress": {}, "associateId": "1" };
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "reviewer";
const tableName = "reviewer";
var activeDataColumns = [];
let ckEditorData = "";
const queryParams = queryString.parse(window.location.search);

export default function ReimbursementReviewer() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [itemsRecords, setItemsRecords] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "reimbursements":"none","reviewer":"none","reimbursement-configs":"none","reimbursement-items-record-list-table": "none","reimbursement-reviewer-items-record-list-table": "none", "reimbursement-items-details": "none", "edit-selected-record": "none", "reviewer-details": "none", "reviewer-records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [theItemsObject, setTheItemObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [reviewerForm] = Form.useForm();
    const [reimbursementItemsForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [statusList, setStatusList] = useState([]);
    const [currenciesList, setCurrenciesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [reimbursementConfigList, setReimbursementConfigList] = useState([]);
    const [reviewList, setReviewList] = useState([]);
    const [mimeAssets, setMimeAssets] = useState([]);
    console.log(queryParams);

    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/reimbursements/${tableName}`);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/currencies` }, setCurrenciesList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/statuses/appid/reimbursement` }, setStatusList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-select-any-multi` }, { "doc_name": "reimbursement_configs", "select": ["id", "name"] }, setReimbursementConfigList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "reimbursement-category" }, setCategoryList);
        findAll();
    }, []);

    const reimbursementItemColumn = [
        {
            title: 'Category',
            dataIndex: 'category_name',
            width: 300
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            width: 300
        },
        {
            title: 'Bill No.',
            dataIndex: 'purchase_id',
            width: 200
        },
        {
            title: 'Bill Date.',
            width:200,
            render: (record) => <span>{record["bill_at"] && moment(record["bill_at"]).format("lll")}</span>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 100
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            width: 100
        },
        {
            title: 'Bills',
            render: (record) => <a onClick={(e) => reviewerViewBills(record)}><FontAwesomeIcon icon={faList} /></a>,
        }
    ];
    
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            render: (record) => <span dangerouslySetInnerHTML={{ __html: record["description"] }} />,
        },
        {
            title: 'Type',
            dataIndex: 'reimbursement_config_name',
            width: 200
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width: 100
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            width: 100
        },
        {
            title: 'Submitted At',
            render: (record) => <span>{record["submitted_at"] && moment(record["submitted_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "view",
            title: 'View',
            width: 50,
            render: (record) => <a onClick={(e) => reviewerViewTableRow(record)}><FontAwesomeIcon icon={faList} /></a>,
        }

    ];

    const reviewerViewBills = (data) =>
    {
        setMimeAssets([...data["mime_assets"]]);
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const infoTableRow = (selectedRow) => {
        setTheObject({ ...selectedRow });
        showHideProps("showInfo", true);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const deleteReimbursementItems = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/reimbursements/${dataMassage["reimbursementId"]}/items/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, itemsRecords, setItemsRecords);
    }
    const newRecord = () => {
        dataMassage["reimbursementId"] = generateFuzzyId("reimbursements");
        setUiLabels({ "save": "Save" });
        showHideAllProps({ "reimbursement-reviewer-items-record-list-table": "none", "reimbursement-items-details": "none", "reviewer-details": "block", "reviewer-records-list-table": "none" });
    }

    const reviewerViewTableRow = (selectedRow) => {
        dataMassage["reimbursementId"] = selectedRow.id;
        dataMassage["reimbursementItemId"] = generateFuzzyId("reimbursement_item");
        formData = {};
        formData["parent_id"] = selectedRow.id;
        setUiLabels({ "save": "Save" });
        setMimeAssets([]);
        AxiosNetwork.axiosFetchMulti({ "url": `api/reviewer/reimbursements/${selectedRow.id}/items` }, {}, setItemsRecords, afterReimbursementItems);
    }

    const afterReimbursementItems = (data) => {
        setItemsRecords([...data]);
        showHideAllProps({ "reimbursement-reviewer-items-record-list-table": "block", "reimbursement-items-details": "block", "reviewer-details": "none", "reviewer-records-list-table": "block" });
        document.getElementById('reimbursement-reviewer-items-record-list-section').scrollIntoView({ behavior: 'smooth' });
    }

    const findAll = () => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/reviewer/reimbursements` }, { }, setRecordsList);
        showHideAllProps({  "reimbursements":"none","reviewer": "none", "reimbursement-configs":"none","records-list-table": "none" ,"reimbursement-reviewer-items-record-list-table": "none", "edit-selected-record": "none", "reimbursement-items-details": "none", "reviewer-details": "none", "reviewer-records-list-table": "block", "records-list-table":"none"});
    }

    const onFinish = (values) => {
        values["upsert"] = true;
        values["description"] = ckEditorData;
        values["status_id"] = values["reviewer_remark_id"]; //todo we need this
        AxiosNetwork.axiosPut({ "url": `/api/reviewer/reimbursements/${dataMassage["reimbursementId"]}`, "id":dataMassage["reimbursementId"] }, values, recordsList, setRecordsList, newRecordCallback);
        showHideAllProps({ "reimbursement-reviewer-items-record-list-table": "none", "edit-selected-record": "none", "reimbursement-items-details": "none", "reviewer-details": "none", "reviewer-records-list-table": "block" });
    }

    const newRecordCallback = (data) => {
        message.info('Reviwer has been created');
        reviewerForm.resetFields();
        console.log(data);
    }

    const reviewerFormFailed = () => {

    }

    const reimbursementItemsFormFailed = () => {

    }

    const findUserLike = (value) => {
        if (value === null || value === "") return;
        var payload = { "doc_name": "users", "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/find-any-multi-like` }, payload, setReviewList, setreviewListCallBack);
    }

    const setreviewListCallBack = (data) => {
        setReviewList([...data]);
    }
    const approvalDetails = () => {
        showHideAllProps({ "reimbursement-reviewer-items-record-list-table": "block", "reimbursement-items-details": "block", "reviewer-details": "block", "reviewer-records-list-table": "block" });

    }
    return (
        <Layout className='contentLayout'>
                <section style={{ "display": presentObjects["reviewer-records-list-table"] }}>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }}  footer={(e) => "Reimbursement submitted for approval request"} />
                </section>
                <section id="reimbursement-reviewer-items-record-list-section" style={{"display": presentObjects["reimbursement-reviewer-items-record-list-table"] }}>
                    <Table style={{ marginBottom: "20px" }} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={reimbursementItemColumn} dataSource={itemsRecords} pagination={ false } footer={(e) => "Reimbursement items particulars"} />
                    {(mimeAssets.length>0) && <section style={{width:500}}><Table size="small" rowKey="id" columns={[{title: <FontAwesomeIcon icon={faDownload}/>, width:50, render:(record) => <a onClick={(e) => AxiosNetwork.downloadMimeAsset(record)}><FontAwesomeIcon icon={faDownload} /></a>},{title: 'Name', dataIndex: 'name', width:100}]} dataSource={mimeAssets} pagination={false} footer={(e) => "Attached file for review"} showHeader={false}/></section>}
                    <Button style={{ marginTop: "20px" }} type="primary" onClick={(e) => approvalDetails()}>Review</Button>
                </section>


                <section className="thCard" style={{ "width": 500, "display": presentObjects["reviewer-details"] }} >
                        <Form size="large" labelAlign="right" layout="vertical"
                            name="basic" onFinish={onFinish} form={reviewerForm} initialValues={theObject} onFinishFailed={reviewerFormFailed} autoComplete="off">
                            <Form.Item label="Status" name="reviewer_remark_id" rules={[{ required: true, message: 'Enter your Status', },]}>
                                <Select style={{ width: '100%' }} placeholder="Change  Status">
                                    {statusList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Comment" name="reviewer_note" rules={[{ required: true, message: 'Enter your message', },]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>
                        </Form>
                </section>

                <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                    <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                        {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                    </div>
                </Drawer>
       </Layout>
    )
}