import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHourglassStart, faHourglassEnd, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import { findUrlPath, appProfile, footerLine, menuBarItems, searchTextLength, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"

import {
    Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form,
    Input, Card, Upload,
    Radio, message,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Mentions,
    Space,
    TimePicker, Drawer, AutoComplete, Col, Checkbox
} from 'antd';
import { AxiosNetwork } from "../axiosService";

const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 44;
const appPath = "time-trackers";
const tableName = "time_trackers";
var activeDataColumns = [];


export default function TimeTracker() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "body": "Type your content here" });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            title: 'Starts At',
            render: (record) => <span>{record["starts_at"] && moment(record["starts_at"]).format("lll")}</span>
        },
        {
            title: 'Ends At',
            render: (record) => <span>{record["ends_at"] && moment(record["ends_at"]).format("lll")}</span>
        },
        {
            title: 'Total',
            render: (record) => <span>{(record["total"] != null) && Math.round(record["total"] / 60000)} Min.</span>
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <span>{((record["starts_at"] == null) && <a onClick={(e) => timeTrackerAction("start", record)}><FontAwesomeIcon icon={faHourglassStart} />&#x2002;Start</a>)} {((record["starts_at"] != null && record["ends_at"] == null) && <a onClick={(e) => timeTrackerAction("end", record)}><FontAwesomeIcon icon={faHourglassEnd} />&#x2002;End</a>)} {((record["starts_at"] != null && record["ends_at"] != null) && <a onClick={(e) => timeTrackerAction("resume", record)}><FontAwesomeIcon icon={faHourglassHalf} />&#x2002;Resume</a>)}</span>,
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }


    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };



    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
    }, []);


    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        presentObjects.folderEdit = "block";
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folderId": currentFolder.id }, setRecordsList);
    }


    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "labelId": currentLabel.id }, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findFromBin = (filter) => {
        activeDataColumns = columnSchema;
        presentObjects["new-form"] = "none";
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        setPresentObjects({ ...presentObjects });
    }

    const findAll = (filter) => {
        presentObjects["new-form"] = "block";
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        setPresentObjects({ ...presentObjects });
    }

    const applyLabels = (e) => {
        theObject.labels = e;
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        console.log(recordsList);
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/create`, "unshift":1 }, values, recordsList, setRecordsList);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const timeTrackerAction = (action, record) => {
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/action/${action}/${record.id}`, "id":record.id }, record, recordsList, setRecordsList);
    }

    const deleteTableRow = (selectedRow) => {
        console.log(recordsList);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id}, recordsList, setRecordsList);
    }

    const infoTableRow = (selectedRow) => {
        console.log(recordsList);
        setTheObject({ ...selectedRow });
        showHideProps("showInfo", true);
    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{textAlign:"right"}} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content>
                        <Card>
                        <div style={{ "display": presentObjects["new-form"] }}>
                            <Form
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 8,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off">
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input project name',
                                        },
                                    ]}>
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Activity Name"
                                    name="activity"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input activity name',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>


                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit"> Create </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        </Card>

                        <Row gutter={[16, 16]}>
                            <Table rowKey={record => record.id} pagination={{ pageSize: 50 }} style={{ "display": presentObjects["active"] }} onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </Row>



                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>


                    </Content>
                </Layout>
            </Layout>
            <Footer>{footerLine}</Footer>
        </Layout>
    )
}