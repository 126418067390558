import React, { useState, useEffect } from 'react';
import '../../App.css';
import {  Form, Card,Input, InputNumber,Space, Select, Button, message, Table, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { generateFuzzyId } from "../../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel,faSave, faEdit,faBackward, faTrashCan } from '@fortawesome/free-solid-svg-icons'

const { Option } = Select;
const { Content } = Layout;
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-employee-salary";
const tableName = "human-resource";
let ckEditorData = "";
var formData = {};

export default function HumanResourceEmployeeSalary(props) {
  const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none","edit-selected-record":"none","personal-information": "none", "human-resource-holidays":"none","human-resource-employee-payroll":"none","human-resource-employer-documents": "none","edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
  const [selectedRows, setSelectedRows] = useState([]);
  const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
  const [humanresourceemployeesalaryForm] = Form.useForm();
  const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
  const [renumerationList, setRenumerationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
  const [theEmployee, setTheEmployee] = useState(props.employee);
  const [currenciesList, setCurrenciesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
  const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

  useEffect(() => {
    theObject["id"] = generateFuzzyId("salaries")
    setUiLabels({ "save": "Save" });
    AxiosNetwork.axiosFetch({ "url": `api/public/common/currencies`}, setCurrenciesList);
    AxiosNetwork.axiosFetchMulti({ "url": `/api/shared-common/find-any-multi` }, { "doc_name": "remunerations" }, setRenumerationList);
    findAll();
  }, [props.employee.id]);

  const showHideAllProps = (props) => {
    for (var prop in props) {
      presentObjects[prop] = props[prop];
    }
    setPresentObjects({ ...presentObjects });
  }
  const onSelectChange = (selectedRowIds) => {
    console.log('selectedRowKeys changed: ', selectedRowIds);
    setSelectedRows(selectedRowIds);
  };
  const findAll = (filter) => {
    AxiosNetwork.axiosFetch({ "url": `/api/human-resource/admin/employees/${theEmployee["user_id"]}/salaries` }, setRecordsList);
    showHideAllProps({"button-controls-section":"none", "records-list-table":"block","salary-details": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

  }
  const humanresourceemployeesalaryFailed = () => {
  }
  const editTableRow = (selectedRow) => {
    console.log(selectedRow);
    selectedRow["mime_assets"] = [];
    setUiLabels({ "save": "Update" });
    setTheObject({ ...selectedRow });
    humanresourceemployeesalaryForm.setFieldsValue({ ...selectedRow });
    formData = {};
    formData["parent_id"] = selectedRow.id;
    AxiosNetwork.axiosGet({ "url": `/api/human-resource/admin/employees/${theEmployee["user_id"]}/salaries/`+ selectedRow.id, "id": selectedRow.id  }, theObject, setTheObject);
    showHideAllProps({ "button-controls-section":"block","records-list-table":"none","salary-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
  }
  const onFinish = (values) => {
    setUiLabels({ "save": "Save" });
    values["description"] = ckEditorData;
    values["upsert"] = true;
    values["payroll_id"] = "abc123";
    values["name"] = "salary";
    AxiosNetwork.axiosPut({ "url": `/api/human-resource/admin/employees/${theEmployee["user_id"]}/salaries/` + theObject["id"] + `/update` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
    console.log('Success:', values);
  }
  const afterNewRecordSubmit = () => {
    message.info('Your salary details has been saved.');
    findAll();
  }

  const deleteTableRow = (selectedRow) => {
    console.log(selectedRow);
    AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": "employees", "id": selectedRow.id }, recordsList, setRecordsList);
    showHideAllProps({"button-controls-section":"none", "records-list-table": "block", "human-resource-dashboard": "none" });
  }

  const applySalary = ( ) => {
    showHideAllProps({ "button-controls-section":"block","records-list-table":"none","salary-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    humanresourceemployeesalaryForm.resetFields();
  }
  const columnSchema = [
    {
      title: 'Renumeration',
      dataIndex: 'remuneration_id',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
    },
    {
      key: "edit",
      title: 'Edit',
      width: 50,
      render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
    },
    {
      key: "trash",
      title: 'Trash',
      width: 50,
      render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
    },
  ];


  return (
    <section>
      <Layout className='contentLayout'>
      <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "salary-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
        <section style={{ "display": presentObjects["records-list-table"] }}>
            <Table style={{marginBottom:"20px"}} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
            <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applySalary()}>  New Salary </Button>

          </section>
          <section className="thCard" style={{ "width": 500, "display": presentObjects["salary-details"] }} >
            <Form size="large" labelAlign="right" layout="vertical"
                name="basic"
                onFinish={onFinish}
                form={humanresourceemployeesalaryForm} initialValues={theObject}
                onFinishFailed={humanresourceemployeesalaryFailed} autoComplete="off">
                <Form.Item label="Renumeration" name="remuneration_id">
                  <Select  showSearch  allowClear style={{ width: '100%' }} placeholder="Change  Renumeration">
                    {renumerationList.map(eachItem => (
                      <Option key={eachItem.id}>{eachItem.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Amount" name="amount">
                  <InputNumber />
                </Form.Item>
                <Form.Item label="Currency" name="currency">
                  <Select style={{ width: '100%' }} placeholder="Change Currencies">
                    {currenciesList.map(eachItem => (
                      <Option key={eachItem.id}>{eachItem.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                </Form.Item>
              </Form>
           </section>
        </Layout>
    </section>
  )
}