import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faBorderNone, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, configMenus } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "skills";
const tableName = "skills";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function Skills() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [skillRecordsList, setSkillRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "users-details": "none", "skills-details": "none", "user-skills-list-table": "none", "edit-selected-record": "none", "new-record": false, "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [userList, setUserList] = useState([]);
    const [proficiencyList, setProficiencyList] = useState([]);


    const [theForm] = Form.useForm();

    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Position',
            dataIndex: 'position'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 100,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faUserGroup} /></a>,
        }
    ];

    const userSkillsColumn = [
        {
            title: 'User',
            dataIndex: 'name'
        },
        {
            title: 'Score',
            dataIndex: 'score',
            width:50
        },
        {
            title: 'Proficiency',
            dataIndex: 'proficiency_name',
            width:50
        },
        
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        }
    ];

    useEffect(() => {
        console.log("UseEffect called");
        theObject["id"] = generateFuzzyId("skills")

        localStorage.setItem("rest-cloud-user-recent-app", `/apps/configurations/${tableName}`);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/common/skills` }, { "doc_name": tableName }, setRecordsList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "proficiency-types" }, setProficiencyList);

    }, []);

    const afterSkillAreFetched = (data) => {

    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": false, "records-list-table": "block" });

    }
    const deleteSkillFromUser = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/common/users/skills/${selectedRow["user_skill_id"]}`, "id": selectedRow.id }, skillRecordsList, setSkillRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        values["upsert"] = true;
        AxiosNetwork.axiosPost({ "url": `api/admin/common/skills/${theObject.id}/upsert` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "skills-details": "none", "records-list-table": "block", "user-skills-list-table": "none" });
    }

    const onUserFinish = (values) => {
        values["skill_id"] = theObject.id;
        AxiosNetwork.axiosPost({ "url": `api/experts/user-skills/${theObject.id}/upsert` }, values, skillRecordsList, setSkillRecordsList, afterUserSkillsAddedCallback);
        showHideAllProps({ "users-details": "none", "records-list-table": "none", "user-skills-list-table": "block" });

    }


    const afterUserSkillsAddedCallback = (data) => {
        viewTableRow({ ...theObject });
        showHideAllProps({ "users-details": "block", "records-list-table": "none", "user-skills-list-table": "block" });

    }

    const newRecord = () => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        console.log({ ...theObject });
        theForm.resetFields();
        setUiLabels({ "save": "Save", "title": "New Skill" });
        showHideAllProps({ "new-record": true, "records-list-table": "block" });
    }

    const afterNewRecordSubmit = () => {
        message.info('Your Skills details has been saved.');
        newSkills();

    }

    const skillsFormFailed = () => {

    }
    const usersFormFailed = () => {

    }
    const newSkills = () => {
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/common/skills` }, { "doc_name": tableName }, setRecordsList, afterSkillsUpdate);
        showHideAllProps({ "users-details": "none", "records-list-table": "block", "user-skills-list-table": "none" });
    }
    const afterSkillsUpdate = (data) => {
        console.log(data);
    }
    const viewTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        AxiosNetwork.axiosFetchMulti({ "url": `api/experts/find-user-skills/skills/${selectedRow.id}` }, { "doc_name": tableName }, setSkillRecordsList, userSkillList);
        showHideAllProps({ "users-details": "block", "skills-details": "none", "records-list-table": "block", "user-skills-list-table": "block" });
    }
    const userSkillList = (data) => {
        console.log(data);
    }

    const findUserLike = (value) => {
        if (value === null || value === "") return;
        var payload = { "doc_name": "users", "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/find-any-multi-like` }, payload, setUserList, setUserListCallBack);
    }

    const setUserListCallBack = (data) => {
        setUserList([...data]);
        showHideAllProps({ "users-details": "block", "skills-details": "none", "records-list-table": "block", "user-skills-list-table": "block" });
    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            }
                        ]}>
                    </Menu>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={configMenus}>
                    </Menu>
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>

                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                        </section>

                        <section style={{ "display": presentObjects["user-skills-list-table"] }}>
                            <h3>User with the skills</h3>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={userSkillsColumn} dataSource={skillRecordsList} pagination={{ pageSize: 50 }} />
                        </section>


                        <Drawer title={uiLabels.title} placement="right" onClose={(e) => showHideProps("new-record", false)} open={presentObjects["new-record"]}>
                            <section>
                                <Form layout="vertical" labelAlign="left" key={"form-"+new Date().getTime()}
                                    name="basic"
                                    onFinish={onFinish}
                                    form={theForm} initialValues={theObject}
                                    onFinishFailed={skillsFormFailed} autoComplete="off">
                                    <Form.Item
                                        label="Skill"
                                        name="name"
                                        rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </section>
                        </Drawer>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideAllProps({"showInfo": false})} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}