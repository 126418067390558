import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt, faTrashCan, faPen, faPeace, faEdit, faSave, faBars, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { findUrlPath, appProfile, footerLine, menuBarItems, profileBarItems } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import moment from 'moment';
import { Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { v4 as uuid } from 'uuid';


const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
const appId = 21;
var formData = {"parent_type": appId};
const appPath = "templates";
const tableName = "templates";
var activeDataColumns = [];

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const cdnFileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/cdn-file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};




export default function Template() {
    const [tempList, setTempList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [form] = Form.useForm();
    const [theObject, setTheObject] = useState({ "application": "EMAIL", "cdnFileAssets":[], "fileAssets": [], "body": "Type your content here", "id": tableName + "-" + uuid() });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [theFilesList, setTheFilesList] = useState([]);
    const [theCdnFilesList, setTheCdnFilesList] = useState([]);
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    



    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 60
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 60
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>,
            width: 60
        }
    ];

    const fileAssetDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteFileAssetRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        },
        {
            title: 'Action',
            render: (record) => <a href={appProfile.serviceLocation + "api/" + findUrlPath(record) +"/download/" + record.id + "/" + record.name}><FontAwesomeIcon icon={faCloudArrowDown} /></a>,
            width: 60
        }
    ];


    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        formData = {"parent_type": appId};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, dataFetchCallback);
    }

    const dataFetchCallback = (data) => {
        setTheCdnFilesList(data["cdnFileAssets"]);
        setTheFilesList(data["fileAssets"]);
    }



    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const saveTemplate = () => {
        theObject["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/update/` + theObject.id, "id": theObject.id }, theObject, recordsList, setRecordsList);
    }

    const onLabelFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/create/label` }, values, labelList, setLabelList);
        console.log('Success:', values);
        showHideProps("newLabelDrawer", false);
    };

    const onLabelFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const infoTableRow = (selectedRow) => {
        setTheObject(selectedRow);
        showHideProps("showInfo", true);
    }

    const onFolderFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/create/folder` }, values, folderList, setFolderList);
        console.log('Success:', values);
        showHideProps("newFolderDrawer", false);;
    };

    const onFolderFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const createNewTemplate = (application) => {
        AxiosNetwork.axiosPostMono({ "url": `/api/${appPath}/create` }, { "application": application, "name": "Untitled " + moment().format("llll") }, recordsList, setRecordsList);
    };



    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
    }, []);

    const updateFolder = (e) => {
        console.log(theFolder);
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/folder/` + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
    }
    const updateLabel = (e) => {
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/label/` + theLabel.id, "id": theLabel.id }, theLabel, labelList, setTheLabel);
        console.log(theLabel);
    }

    const afterNewRecordSubmit = () => {
        message.info('Your template has been saved.');
        findAll();
    }
    const templateFormFailed = () => {

    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetch({ "url": "/api/${appPath}?folder=" + currentFolder.id }, setRecordsList);
    }


    const deleteFolder = (currentFolder) => {
        console.log(currentFolder);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/folder/` + currentFolder.id, "id": currentFolder.id }, folderList, setFolderList);
    }
    const deleteLabel = (currentLabel) => {
        console.log(currentLabel);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/label/` + currentLabel.id, "id": currentLabel.id }, labelList, setLabelList);
    }


    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}?label=` + currentLabel.id }, setRecordsList);
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const deleteFileAssetRow = (selectedRow) => {
        console.log(selectedRow);
        if(selectedRow.type==56) AxiosNetwork.axiosDelete({ "url": `/api/cdn-file-assets/delete/` + selectedRow.id, "id": selectedRow.id }, theCdnFilesList, setTheCdnFilesList);
        if(selectedRow.type==28) AxiosNetwork.axiosDelete({ "url": `/api/file-assets/delete/` + selectedRow.id, "id": selectedRow.id }, theFilesList, setTheFilesList);
    }


    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": `/api/${appPath}/restore/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }


    const findFromBin = (filter) => {
        activeDataColumns = columnSchema;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        console.log(activeDataColumns);
    }


    const findAll = () => {
        console.log("findAll");
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
    }

    let ckEditorData = "";

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{textAlign:"right"}} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content>
                        <Row gutter={[16, 16]}>
                            <Table style={{ width:"100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </Row>

                        <input defaultValue={theObject.name} onChange={(e) => { theObject.name = e.target.value }} />

                        <Select style={{ width: '100%' }} defaultValue="EMAIL" placeholder="Apply Folder" onChange={(e) => { theObject["application"] = e }}>
                            <Option key="EMAIL">Email</Option>
                            <Option key="WHATSAPP">WhatsApp</Option>
                            <Option key="TELEGRAM">TELEGRAM</Option>
                            <Option key="SMS">SMS</Option>
                        </Select>
                        <div style={{ border: "1px solid gray" }}>

                            <CKEditor
                                onReady={editor => {
                                    console.log('Editor is ready to use!', editor);

                                    // Insert the toolbar before the editable area.
                                    editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );

                                    //this.ckEditor = editor;
                                }}
                                onError={(error, { willEditorRestart }) => {
                                    // If the editor is restarted, the toolbar element will be created once again.
                                    // The `onReady` callback will be called again and the new toolbar will be added.
                                    // This is why you need to remove the older toolbar.
                                    if (willEditorRestart) {
                                        this.editor.ui.view.toolbar.element.remove();
                                    }
                                }}
                                onChange={(event, editor) => {
                                    ckEditorData = editor.getData();
                                    theObject["body"] = ckEditorData;
                                    console.log({ event, editor, ckEditorData });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                                editor={DecoupledEditor}
                                data={((theObject.body == null) ? "" : theObject.body)}
                            />
                        </div>
                        <Button type="primary" size={12} onClick={(e) => saveTemplate()}>Save</Button>

                        <div>
                            <Table rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} columns={fileAssetDataColumns} dataSource={theObject.fileAssets} />
                        </div>
                        <div>
                            <Table rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} columns={fileAssetDataColumns} dataSource={theObject.cdnFileAssets} />
                        </div>

                        <Row gutter={16}>
                            <Col>
                                <Dragger {...fileUploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </Col>
                            <Col>
                                <Dragger {...cdnFileUploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </Col>
                        </Row>


                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>

                    </Content>

                </Layout>
            </Layout>
            <Footer>{footerLine}</Footer>
        </Layout>
    )
}