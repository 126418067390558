import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUnlock, faLockOpen, faSwitch, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Switch, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "switch";
const tableName = "switch";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function PosPreference() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [productList, setProductList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "terminal":"none","brands": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [posBrandForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    console.log(queryParams);

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/pos/${tableName}`);
        setUiLabels({ "save": "Save" });
        findAll();
    }, []);

    const columnSchema = [
        {
            title: 'Product',
            dataIndex: 'name'
        },
        {
            key: "active",
            title: 'Active',
            width: 50,
            render: (record) => <Switch defaultChecked={record.active} onChange={(e) => updateOrganizationProduct(e, record, "active") } />
        },
        {
            key: "frequent",
            title: 'Frequent',
            width: 50,
            render: (record) => <Switch defaultChecked={record.frequent} onChange={(e) => updateOrganizationProduct(e, record, "frequent") } />
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },

    ];

    const updateOrganizationProduct = (e, product, field) =>
    {
        var payload = {};
        payload[field] = e;
        AxiosNetwork.axiosPut({ "url": `/api/pos/terminal/organization-products/${product.id}/update` }, payload, recordsList, setRecordsList, newRecordCallback);
    }

    const newRecordCallback = (data) =>
    {

    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const findAll = (filter) => {
        showHideAllProps({ "records-list-table": "block" });
        fetchIfOutletSelected();
    }

    const fetchIfOutletSelected = () => {
        if(queryParams.outlet==undefined) return;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/pos/terminal/organizations/${queryParams.outlet}/reports/products` }, {}, setRecordsList);
        showHideAllProps({ "records-list-table": "block" })
    }

    return (
        <section>
            <Layout className='contentLayout'>
            

                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                    </section>
                
            </Layout>
        </section>
    )
}