import React, {useState, useEffect } from 'react';
import { generateFuzzyId } from "../Config";
import '../App.css';
import { Layout, Typography,  Row, message,  Button,  Form,   Col  } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { FormElement } from './FormElement';
import queryString from 'query-string';
const {  Content } = Layout;
const appId = 55;
const appPath = "forms";
const tableName = "forms";
const dataMassage = { "emailAddress": {}, "associateId": "1", "id":generateFuzzyId("form-response") };
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


export default function FormsDataCollection(props) {
    const [theObject, setTheObject] = useState({"fields":[], "name": "Type your summary here", "mime_assets": [] });
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "after-form-submission":"none", "form-submission": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [formItems, setFormItems] = useState([]);
    const queryParams = queryString.parse(window.location.search);
    console.log(queryParams);
    console.log(props);

    if((props["appDoc"]!==undefined) && (props["appDoc"]["form_id"]!==undefined))
    {
        queryParams["id"] = props["appDoc"]["form_id"];
        queryParams["doc_id"] = props["appDoc"]["id"];
    }

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    useEffect(() => {
        if(queryParams["doc_id"]!==undefined)
        {
            AxiosNetwork.axiosGet({ "url": `/api/form-responses/doc-id/${queryParams["doc_id"]}/${queryParams.id}`, "id": queryParams.id }, theObject, setTheObject, afterReadingFormResponse);
        }
        else if(queryParams["id"]!==undefined)
        {
            AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + queryParams.id, "id": queryParams.id }, theObject, setTheObject, afterReadingForm);
        }
        showHideAllProps({ "edit-selected-record": "block", "new-form": "block", "records-list-table": "block" });
    }, []);


    const afterReadingForm = (data) => {
        if((data["fields"]===undefined) || (!Array.isArray(data["fields"]))) data["fields"] = [];
        if(data["message_after_submit"]===undefined) data["message_after_submit"] = "<p>Thank you for your response.</p>";
        dataMassage["id"] = data["id"];
        setTheObject({...data});
        setFormItems([...data["fields"]]);
    }

    const afterReadingFormResponse = (data) => {
        if((data["fields"]===undefined) || (!Array.isArray(data["fields"]))) data["fields"] = [];
        if(data["message_after_submit"]===undefined) data["message_after_submit"] = "<p>Thank you for your response.</p>";
        dataMassage["id"] = data["id"];
        if(data["form"]!=null)
        {
            var formResponses = data["responses"];
            var formFields = data.form["fields"];
            for(var i=0; i<formFields.length; i++)
            {
                formFields[i]["value"] = formResponses[formFields[i]["name"]];
            }
            data.form["fields"] = formFields;
            console.log(formFields);
            setTheObject({...data.form});
            setFormItems([...data.form["fields"]]);    
        }
    }

    const onFinish = (values) => {
        if(queryParams["id"]===undefined)
        {
            message.info("Invalid access");
            return;
        }
        var data = {};
        data["responses"] = values;
        data["form_id"] = queryParams["id"];
        if(queryParams["doc_id"]!==undefined) data["doc_id"] = queryParams["doc_id"];
        if(queryParams["app_type"]!==undefined) data["app_type"] = (queryParams["app_type"] * 1);


        data["upsert"] = true;
        data["name"] = "";
        AxiosNetwork.axiosPost({ "url": `/api/form-responses/${dataMassage["id"]}/upsert` }, data, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', data);
        showHideAllProps({"after-form-submission":"block", "form-submission":"none"});
    };

    const afterNewRecordSubmit = (data) => {
        console.log(data);
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content >
                    <section className="thCard" style={{width:"800px", display: presentObjects["form-submission"] }}>
                        <Typography.Title level={1} style={{ margin: 0 }}>{theObject.name}</Typography.Title>
                        <div dangerouslySetInnerHTML={{ __html: theObject["description"] }} />

                        <Row gutter={[16, 16]}>
                            <Col span={24} >
                                <Form size="large" labelAlign="right"  layout="vertical"
                                    name="basic"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off">
                                    {formItems.map(item => <FormElement key={item.id} id={item.id} form={item} />)}
                                    <Form.Item {...tailFormItemLayout}>
                                        <Button type="primary" htmlType="submit">Save</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </section>
                    <section style={{ border: "1px solid gray", display: presentObjects["after-form-submission"] }}>
                        <div dangerouslySetInnerHTML={{ __html: theObject["message_after_submit"] }} />
                    </section>
                </Content>
</Layout>
    )
}