import React, { useState, useEffect } from 'react';
import './style.css';
import './App.css';
import store from 'store2';
import { AxiosNetwork } from "./axiosService";
import { Button, Card, Form, Input, Typography, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'

function Forgot() {

  const params = useParams();
  const [tempList, setTempList] = useState([]);

  useEffect(() => {
    console.log(params);
  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
    values["email"] = values["username"];
    values["url"] = window.location.origin;
    AxiosNetwork.axiosPost({ "url": "api/public/user/forgot-password-request" }, values, [], setTempList, dataPostCallback);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const dataPostCallback = (data) => {
    console.log(data);
    message.info(data["ui_message"]);
  }


  return (
    <div><center><img src="https://storage.googleapis.com/static.techiehug.com/icons/logo.png" style={{padding: 40}} /></center>
      <div className="pageCenter" style={{ width: 600 }}>
      <center><strong>Forgot Password?</strong></center><br/>
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">

          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
            <center><Button type="primary" htmlType="submit" className="login-form-button">remind me!</Button></center>
          </Form.Item>

          <hr />

          <center>
            <a href="/">Login</a>
          </center>

        </Form>
      </div>
    </div>
  );
}

export default Forgot;