import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import { Layout, Select, Typography, Breadcrumb, Row, Form, Dropdown, Button } from 'antd';
import { AxiosNetwork } from "./axiosService";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
const { Option } = Select;
var theBlog = {};

//https://www.npmjs.com/package/jsoneditor-react
export default function CommonShare(props) {
    console.log(props);

    const [jsonDoc, setJsonDoc] = useState({});
    const [theObjectList, setTheObjectList] = useState([]);
    const [labelList, setLabelList] = useState([]);
    const [sharedUsers, setSharedUsers] = useState([]);
    const [userList, setUserList] = useState([]);
    const [folderList, setFolderList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": "none" });


    useEffect(() => {
        var payload = {"doc_name":props["table"], "doc_id":props.id };
        AxiosNetwork.axiosPost({ "url": `api/common/find-permissions` }, payload, sharedUsers, setSharedUsers);
    }, []);

    const findNameLike = (value) =>
    {
        var payload = {"doc_name":"users", "col_name":"name", "col_value": value};
        AxiosNetwork.axiosPost({ "url": `/api/common/find-any-multi-like` }, payload, userList, setUserList, setUserListCallBack);
    }
    const setUserListCallBack = (value) =>
    {
        setUserList([...value]);
    }

    let ckEditorData = "";

    const shareDocument = (values) => {
        values["message"] = ckEditorData;
        values["doc_id"] = props.id;
        values["doc_name"] = props.table;
        console.log(values);
        AxiosNetwork.axiosPost({ "url": `/api/common/share-any-with-users` }, values, sharedUsers, setSharedUsers);
    }


    return (
        <div>
            <div>
                <Form
                    onFinish={shareDocument}>
                    <Form.Item label="Recipients" name="user_ids">
                        <Select onSearch={findNameLike} mode="multiple" style={{ width: '100%' }} placeholder="Email Ids">
                            {userList.map(eachItem => (
                                <Option key={eachItem.id}>{eachItem.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Access" name="access">
                        <Select style={{ width: '100%' }}>
                            <Option key="READ">READ</Option>
                            <Option key="WRITE">WRITE</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="message" name="message">
                        <CKEditor
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);

                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );

                                //this.ckEditor = editor;
                            }}
                            onError={(error, { willEditorRestart }) => {
                                // If the editor is restarted, the toolbar element will be created once again.
                                // The `onReady` callback will be called again and the new toolbar will be added.
                                // This is why you need to remove the older toolbar.
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                //theBlog["body"] = ckEditorData;
                                //console.log({ event, editor, ckEditorData });
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                            editor={DecoupledEditor}
                            data={((theBlog.body == null) ? "" : theBlog.body)}
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Share</Button>

                </Form>
            </div>


        </div>
    )
}