import React, { useState } from 'react';
import { Divider, Slider,  Typography,  Tabs, Form, Input,  Upload, Radio, message, Select, DatePicker, InputNumber,  Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { appProfile, dateTimeUtils} from "../Config";

const { Dragger } = Upload;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;
const { Option } = Select;
var formData = {};

const fileUploadProps = {
  name: 'file',
  multiple: true,
  action: appProfile.serviceLocation + 'api/file-assets/upload/single',
  data: function (file) {
      console.log("Data being triggered");
      return formData;
  },
  headers: {
      Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
  },
  onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
          console.log(info.file, info.fileList);
      }
      if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
      }
  },
  onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
  },
};

function onChange(value, dateString) {
 // console.log('Selected Time: ', value);
 // console.log('Formatted Selected Time: ', dateString);
}
function onOk(value) {
  console.log('onOk: ', value);
}
function changeTab(key) {
  console.log(key);
}
const Thinput = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required:props.form.required  }]}>
  <Input defaultValue={props.form.value}/>
  </Form.Item>
}
const Thinputnumber = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
  <InputNumber defaultValue={props.form.value}/>
  </Form.Item>
}
const Thtextarea = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <TextArea defaultValue={props.form.value} rows={4} placeholder="maxLength is 100" maxLength={100} />
  </Form.Item>
}
const Thselect = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]} extra={props.form.extra}>
    <Select defaultValue={props.form.value} mode="tags" style={{ width: '100%' }} placeholder={props.form.label} >
    {props.form.options.map(eachItem => (<Option key ={'unique-'+ eachItem}>{eachItem}</Option>))}
    </Select>
  </Form.Item>
}
const Thmultiselect = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Select defaultValue={props.form.value} mode="multiple" style={{ width: '100%' }} placeholder={props.form.label} >
    {props.form.options.map(eachItem => (<Option key={'unique-' + eachItem}>{eachItem}</Option>))}
  </Select>
  </Form.Item>
}
const Thtagsselect = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Select defaultValue={props.form.value} mode="tags" style={{ width: '100%' }} placeholder= {props.form.label} >
    {props.form.options.map(eachItem => (<Option key={'unique-' + eachItem}>{eachItem}</Option>))}
    </Select>
  </Form.Item>
}
const Thradio = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
     <Radio.Group defaultValue={props.form.value} mode="tags" style={{ width: '100%' }} placeholder={props.form.label} onChange={onChange}>
      {props.form.options.map(eachItem => (<Radio value={'unique-' + eachItem}>{eachItem}</Radio>))}
    </Radio.Group>
  </Form.Item>
}
const Thcheckbox = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required  }]}>
  <Checkbox defaultValue={props.form.value} style={{ width: '100%' }} valuePropName="checked" placeholder={props.form.label} onChange={onChange}>
  {props.form.options.map(eachItem => (<Checkbox key={'unique-' + eachItem}>{eachItem}</Checkbox>))}
  </Checkbox>
  </Form.Item>
}
const Thupload = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required  }]}>
    <Dragger defaultValue={props.form.value}{...fileUploadProps}>
      <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
      <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
    </Dragger>
  </Form.Item>
}
const Thslider = (props) => {
  let marks = {}; marks[props.form.min] = props.form.min; marks[props.form.max] = props.form.max;
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required  }]}>
    <Slider defaultValue={props.form.value} marks={marks} min={props.form.min} max={props.form.max} disabled={false} />
  </Form.Item>
}
const Thtitle = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required  }]}>
    <Title defaultValue={props.form.value}>{props.form.name}</Title>
   </Form.Item>
}
const Thdivider = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required  }]}>
  <Divider plain defaultValue={props.form.value}>{props.form.name} </Divider>
  </Form.Item>
}

const Thdatepicker = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required  }]}>
  <DatePicker  format={dateTimeUtils.dateTimeFormat} showTime={true} onChange={onChange} onOk={onOk} />
  </Form.Item>
}

const Thdaterangepicker = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
  <RangePicker 
    format={dateTimeUtils.dateTimeFormat} showTime={true}
    onChange={onChange}
    onOk={onOk}
    />
  </Form.Item>
}

const findElement = (form) => {
  switch (form.type) {
    case "input":
      return <Thinput form={form} />;
    case "input-number":
      return <Thinputnumber form={form} />;
    case "textarea":
      return <Thtextarea form={form} />;
    case "select":
      return <Thselect form={form} />;
    case "multi-select":
      return <Thmultiselect form={form} />;
    case "tags-select":
      return <Thmultiselect form={form} />;
    case "divider":
      return <Thdivider form={form} />;
    case "slider":
      return <Thslider form={form} />;
    case "title":
      return <Thtitle form={form} />;
    case "date-picker":
      return <Thdatepicker form={form} />;
    case "checkbox":
      return <Thcheckbox form={form} />;
    case "radio":
      return <Thradio form={form} />;
    case "upload":
        return <Thupload form={form} />;
    case "date-range-picker":
      return <Thdaterangepicker form={form} />;
    default:
      break;
  }
}

export function FormElement(props) {
  const [tempList, setTempList] = useState([]);

  return (
    <div>
      {findElement(props.form)}
    </div>
  );

}