import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, profileBarItems, dateTimeUtils } from "../../Config";
import '../../App.css';
import moment from 'moment';
import { Layout, Menu, Typography, Row, Form, List, Card, message, Select, Drawer, Col, Table } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import CustomFieldEmbedEditor from "../../custom-field/CustomFieldEmbedEditor";
import HumanResourceBasic from "./HumanResourceBasic";
import HumanResourceAddress from "./HumanResourceAddress";
import HumanResourceEmergencyContacts from "./HumanResourceEmergencyContacts";
import HumanResourceDependant from "./HumanResourceDependant";
import HumanResourceNominee from "./HumanResourceNominee";
import HumanResourcePreviousEmployment from "./HumanResourcePreviousEmployment";
import HumanResourceBank from "./HumanResourceBank";
import HumanResourceKyc from "./HumanResourceKyc";
import HumanResourceSalary from "./HumanResourceSalary";
import HumanResourceSalaryStructure from "./HumanResourceSalaryStructure";
import HumanResourcePayslip from "./HumanResourcePayslip";
import HumanResourceDeclaration from "./HumanResourceDeclaration";
import HumanResourceDocument from "./HumanResourceDocument";
import HumanResourceHRDocuments from "./HumanResourceHRDocuments";
import HumanResourceLeave from './HumanResourceLeave';
import HumanResourceLeaveMine from './HumanResourceLeaveMine';
import HumanResourceLeaveMyTeam from './HumanResourceLeaveMyTeam';
import { DataUtils } from 'three';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 45;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource";
const tableName = "human-resource";
var activeDataColumns = [];
const style = { padding: '8px 0' };
let ckEditorData = "";
const infiniteLoop = [];

export default function HumanResource() {
    const [theEmployee, setTheEmployee] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [theObject, setTheObject] = useState({ "name": "", "mime_assets": [] });
    const [holidays, setHolidays] = useState([{ "name": "EMAIL", "mime_assets": [], "description": "Type your content here" }]);
    const [announcements, setAnnouncements] = useState([{ "name": "EMAIL", "mime_assets": [], "description": "Type your content here" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "human-resource-declaration": "none", "human-resource-salary-structure": "none", "human-resource-payslip": "none", "human-resource-hr-documents": "none", "human-resource-emergency-contacts": "none", "human-resource-leave-mine": "none", "human-resource-leave-my-team": "none", "human-resource-leave": "none", "human-resource-dashboard": "block", "human-resource-document": "none", "human-resource-salary": "none", "human-resource-kyc": "none", "human-resource-bank": "none", "human-resource-previous-employment": "none", "human-resource-nominee": "none", "human-resource-basic": "none", "human-resource-address": "none", "human-resource-dependant": "none", "edit-selected-record": "none", "records-list-table": "block", "new-ticket": "block", "newStatusDrawer": false, "newLabelDrawer": false, "newFolderDrawer": false, "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [humanresourceForm] = Form.useForm();
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/holidays` }, { "filters": [{ "col_name": "due_at", "operator": ">", "data_type": "date", "date_value": moment().startOf('month').format(dateTimeUtils.dateTimeFormat) }] }, setHolidays);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/blogs` }, {}, setAnnouncements, afterAnnouncementCallback);
    }, []);

    const afterAnnouncementCallback = (data) => {
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const columnSchema = [
        {
            title: 'Name',
            render: (record) => <span>{((record["access"] == 0) ? <FontAwesomeIcon icon={faUnlock} /> : <FontAwesomeIcon icon={faLock} />)} &#x2002; {record["name"]}</span>
        },
        {
            title: 'Activity',
            width: 50,
            dataIndex: 'activity_count',
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            title: 'Status',
            render: (record) => <span>{record["status_id"]} {record["user_id"]}</span>
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "records-list-table": "block" });
    }

    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "records-list-table": "block" });
    }

    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanresourceForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        formData["folder_id"] = dataMassage["humanresourceId"];
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/admin/employees/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "records-list-table": "block" });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        AxiosNetwork.axiosPut({ "url": `/api/${tableName}/admin/employees/update/` + theObject["id"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your human resource has been saved.');
        findAll();
    }
    const humanresourceFormFailed = () => {

    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const showOtherComponents = (sectionName) => {
        var toggleSections = { "human-resource-declaration": "none", "human-resource-salary-structure": "none", "human-resource-payslip": "none", "human-resource-hr-documents": "none", "human-resource-emergency-contacts": "none", "human-resource-leave-mine": "none", "human-resource-dashboard": "none", "human-resource-leave-my-team": "none", "human-resource-leave-mine": "none", "human-resource-leave": "none", "human-resource-document": "none", "human-resource-salary": "none", "human-resource-nominee": "none", "human-resource-previous-employment": "none", "human-resource-bank": "none", "human-resource-kyc": "none", "human-resource-basic": "none", "human-resource-address": "none", "human-resource-dependant": "none" };
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }
    const newReport = () => {

    }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            { label: (<a onClick={e => showOtherComponents("human-resource-dashboard")} href="#">Dashboard</a>), "key": "human-resource-dashboard" },
                            {
                                label: "Details", "key": "human-resource-menu", children: [
                                    { label: (<a onClick={e => showOtherComponents("human-resource-basic")} href="#">Basic </a>), "key": "human-resource-basic" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-address")} href="#">Address</a>), "key": "human-resource-address" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-emergency-contacts")} href="#">Emergency Contacts</a>), "key": "human-resource-emergency-contacts" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-dependant")} href="#">Dependant </a>), "key": "human-resource-dependant" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-nominee")} href="#">Nominee </a>), "key": "human-resource-nominee" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-previous-employment")} href="#">Previous Employment </a>), "key": "human-resource-previous-employment" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-bank")} href="#">Bank</a>), "key": "human-resource-bank" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-kyc")} href="#">KYC </a>), "key": "human-resource-kyc" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-document")} href="#">Document </a>), "key": "human-resource-document" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-hr-documents")} href="#"> HR Document </a>), "key": "human-resource-hr-documents" },
                                ]
                            },
                            {
                                label: "Renumeration", "key": "human-resource-salary", children: [
                                    { label: (<a onClick={e => showOtherComponents("human-resource-payslip")} href="#">Payslip </a>), "key": "human-resource-payslip" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-salary-structure")} href="#">Salary Structure </a>), "key": "human-resource-salary-structure" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-declaration")} href="#">Declaration </a>), "key": "human-resource-declaration" },
                                ]
                            },
                            {
                                label: "Leave", "key": "human-resource-leave", children: [
                                    { label: (<a onClick={e => showOtherComponents("human-resource-leave-mine")} href="#">My Leave</a>), "key": "human-resource-leave-mine" },
                                    { label: (<a onClick={e => showOtherComponents("human-resource-leave-my-team")} href="#">My Team Leave</a>), "key": "human-resource-leave-my-team" }
                                ]
                            },
                            { type: 'divider' },
                            { label: (<a onClick={e => window.location.href = window.location.origin + "/apps/human-resource-admin"} href="#">HR Admin</a>), "key": "human-resource-admin" },
                            { type: 'divider' },
                            {
                                key: 'report',
                                label: (<a onClick={(e) => newReport()} rel="noopener noreferrer">Report</a>)
                            },
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                   
                        <section style={{ "display": presentObjects["human-resource-dashboard"] }}>
                            <div className="site-card-wrapper">
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Card title="Announcement" bordered={false}>
                                            <List
                                                dataSource={announcements}
                                                renderItem={(item, index) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            title={<span><a href="https://ant.design">{item.name}</a>, Date: {item['created_at']}</span>}
                                                            description={<div><div dangerouslySetInnerHTML={{ __html: item["description"] }} /></div>}
                                                        />
                                                    </List.Item>
                                                )}
                                            />

                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Holiday" bordered={false}>
                                            <Table size="small" rowKey="id" columns={[{ title: 'Name', dataIndex: 'name' }, { title: 'Date', dataIndex: 'due_at' },]} dataSource={holidays} pagination={false} showHeader={false} />
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Leave Requests" bordered={false}>

                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                        {(presentObjects["human-resource-basic"] == "block") &&
                            <section>
                                <HumanResourceBasic employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-address"] == "block") &&
                            <section>
                                <HumanResourceAddress employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-emergency-contacts"] == "block") &&
                            <section>
                                <HumanResourceEmergencyContacts employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-dependant"] == "block") &&
                            <section>
                                <HumanResourceDependant employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-nominee"] == "block") &&
                            <section>
                                <HumanResourceNominee employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-previous-employment"] == "block") &&
                            <section>
                                <HumanResourcePreviousEmployment employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-bank"] == "block") &&
                            <section>
                                <HumanResourceBank employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-kyc"] == "block") &&
                            <section>
                                <HumanResourceKyc employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-salary"] == "block") &&
                            <section>
                                <HumanResourceSalary employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-payslip"] == "block") &&
                            <section>
                                <HumanResourcePayslip employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-declaration"] == "block") &&
                            <section>
                                <HumanResourceDeclaration employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-salary-structure"] == "block") &&
                            <section>
                                <HumanResourceSalaryStructure employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-document"] == "block") &&
                            <section>
                                <HumanResourceDocument employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-hr-documents"] == "block") &&
                            <section>
                                <HumanResourceHRDocuments employee={theEmployee} />
                            </section>
                        }

                        {(presentObjects["human-resource-leave"] == "block") &&
                            <section>
                                <HumanResourceLeave employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-leave-mine"] == "block") &&
                            <section>
                                <HumanResourceLeaveMine employee={theEmployee} />
                            </section>
                        }
                        {(presentObjects["human-resource-leave-my-team"] == "block") &&
                            <section>
                                <HumanResourceLeaveMyTeam employee={theEmployee} />
                            </section>
                        }

                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                        </section>
                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} appType={appId} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                   
                </Layout>
            </Layout>
        </Layout>
    )
}
