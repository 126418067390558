import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col, DatePicker } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const dataMassage = { "emailAddress": {}, "associateId": "1" };
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "reimbursement-configs";
const tableName = "reimbursement-configs";
var activeDataColumns = [];
let ckEditorData = "";
const queryParams = queryString.parse(window.location.search);

export default function ReimbursementConfiguration() {
    const [recordsList, setRecordsList] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "reimbursement-configs": "none", "reviewer": "none", "reviewer-details": "none", "reimbursement-items-record-list-table": "none", "reimbursement-reviewer-items-record-list-table": "none", "reimbursement-items-details": "none", "edit-selected-record": "none", "reimbursement-configuration-details": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [reimbursementConfigurationForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [currencyList, setCurrenciesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [workFlowList, setWorkFlowList] = useState([]);

    console.log(queryParams);

    useEffect(() => {
        console.log("UseEffect called");
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/reimbursements/${tableName}`);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/currencies` }, setCurrenciesList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "reimbursement-category" }, setCategoryList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "workFlows" }, setWorkFlowList);
        findAll();
    }, []);


    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Category',
            dataIndex: 'category_name'
        },
        {
            title: 'Currency',
            dataIndex: 'currency'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 20,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 20,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },

    ];
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const infoTableRow = (selectedRow) => {
        setTheObject({ ...selectedRow });
        showHideProps("showInfo", true);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/reimbursements/configs/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }


    const newRecord = () => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        setUiLabels({ "save": "Save" });
        showHideAllProps({ "reimbursement-configuration-details": "block", "records-list-table": "none" });
    }
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        setUiLabels({ "save": "Update" });
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/reimbursements/configs/` + selectedRow.id, "id": selectedRow.id }, setTheObject, afterReimbursements);
    }


    const afterReimbursements = (data) => {
        setTheObject({ ...data });
        reimbursementConfigurationForm.setFieldsValue({ ...data });
        showHideAllProps({ "edit-selected-record": "block", "reimbursement-configuration-details": "block", "records-list-table": "block" });
        document.getElementById('new-record-section').scrollIntoView({ behavior: 'smooth' });
    }

    const findAll = (filter) => {

        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/reimbursements/configs` }, { "filters": [{ "col_name": "active", "integer_value": 1, "operator": "=", "data_type": "integer" }] }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "reimbursement-configuration-details": "none", "records-list-table": "block" });
    }

    const onFinish = (values) => {
        values["upsert"] = true;
        values["description"] = ckEditorData;
        AxiosNetwork.axiosPost({ "url": `/api/admin/reimbursements/configs/${theObject.id}/upsert` }, values, recordsList, setRecordsList, newRecordCallback);
        showHideAllProps({ "edit-selected-record": "none", "reimbursement-configuration-details": "none", "records-list-table": "block" });
    }
    const newRecordCallback = (data) => {
        message.info('Reimbursement Configuration has been created');
        reimbursementConfigurationForm.resetFields();
        console.log(data);
        findAll();
    }
    const reimbursementConfigurationFormFailed = () => {

    }
    const newReimbursementConfiguration = () => {
        setUiLabels({ "save": "Save" });
        showHideAllProps({ "reimbursement-configuration-details": "block", "records-list-table": "block" });

    }


    return (
        <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={false} />
                <Button style={{ marginBottom: "20px", marginTop: "20px" }} type="primary" onClick={(e) => newReimbursementConfiguration()}>New</Button>
            </section>

            <section style={{ "display": presentObjects["edit-selected-record"] }}>
            </section>
            <section className="thCard" style={{ "width": 600, "display": presentObjects["reimbursement-configuration-details"] }}>
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onFinish}
                    form={reimbursementConfigurationForm} initialValues={theObject}
                    onFinishFailed={reimbursementConfigurationFormFailed} autoComplete="off">
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Enter your name' },]}>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Currency" name="currency"
                                rules={[{ required: true, message: 'Enter your currency' }]}>
                                <Select style={{ width: '100%' }} placeholder="Change Currencies">
                                    {currencyList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Category" name="category_id"
                                rules={[{ required: true, message: 'Enter your Category', }]}>
                                <Select style={{ width: '100%' }} placeholder="Change Category">
                                    {categoryList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="WorkFlow" name="workflow_id"
                                rules={[{ required: true, message: 'Enter your WorkFlow', }]}>
                                <Select style={{ width: '100%' }} placeholder="Change WorkFlow">
                                    {workFlowList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Description" name="description"
                        height="200px"
                        rules={[{ required: false, message: 'Type the Description' }]}>
                        <CKEditor
                            className="sarampeema"
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                                editor.editing.view.change(writer => {
                                    writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
                                });
                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                            }}
                            onError={(error, { willEditorRestart }) => {
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                console.log(ckEditorData);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                            editor={DecoupledEditor}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </section>
            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>
        </Layout>
    )
}