import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEdit, faUndoAlt, faSave, faInfoCircle, faCloudUploadAlt, faBackward, faPlus } from '@fortawesome/free-solid-svg-icons'
import { findUrlPath, appProfile, generateFuzzyId, dateTimeUtils, fileUploadProps, footerLine, menuBarItems, socialAuthProps } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Row, Button, Table, Form, Space, Input, Upload, message, Select, DatePicker, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import { v4 as uuid } from 'uuid';
import { deleteFileAssetsRow } from "../CommonEvents";


const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 151;
const appPath = "questions";
const tableName = "questions";
let ckEditorData = "";
var activeDataColumns = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };

export default function MiniQuestion(props) {
    const [theObject, setTheObject] = useState({ "body": "", "name": "Type your summary here", "mime_assets": [] });
    const [questionList, setQuestionList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "new-record": " none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "composeQuestion": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [questionForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [reviewList, setReviewList] = useState([]);
    const [statusList, setStatusList] = useState([{ "id": 1, "name": "Waiting to load" }]);

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/` + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/` + appId }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/statuses` }, { "doc_name": "statuses" }, setStatusList);
        composeQuestion();
        findAll();
        if (props.presentObjects !== undefined) showHideAllProps({ ...props.presentObjects })

    }, []);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }


    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Assigned',
            dataIndex: 'assigned_user',
            width: 200
        },
        {
            title: 'Resolved',
            render: (record) => <span>{(record["resolved"] ? "Yes" : "No")}</span>,
            width: 60
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            key: "info",
            title: 'Info',
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];
    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        dataMassage["questionId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/common/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, theCallback);
    }
    const theCallback = (data) => {
        if (data["resolved_at"] != undefined) data["resolved_at"] = moment(data["resolved_at"]);
        setTheObject({ ...data });
        questionForm.setFieldsValue({ ...data });
        showHideAllProps({ "records-list-table": "none", "button-controls-section": "block", "new-record": "block", "edit-selected-record": "block" });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }

    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        dataMassage["questionId"] = generateFuzzyId(tableName);
        formData = {};
        formData["parent_id"] = dataMassage["questionId"];
        formData["folder_id"] = dataMassage["questionId"];
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "button-controls-section": "block", "edit-selected-record": "block", "new-record": "block", "records-list-table": "none", });
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": "/api/common/questions", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        var filters = [];
        if (props.appDoc !== undefined) {
            filters.push({ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": props.appDoc.id });
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/questions` }, { "doc_name": tableName, "filters": filters }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "block", "button-controls-section": "none", "new-record": "none", "records-list-table": "block" });
    }

    const handleChange = (formData) => {
        console.log(formData);
        //setJsonDoc(formData);
    }

    const composeQuestion = () => {
        showHideProps("composeQuestion", "block");
        theObject["emailAddress"] = [];
        formData = {};
        dataMassage["questionId"] = "question-" + uuid();
        formData["parent_id"] = dataMassage["questionId"]
        formData["folder_id"] = dataMassage["questionId"];
    };

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        if (props.appDoc !== undefined) values["doc_id"] = props.appDoc.id;
        AxiosNetwork.axiosPost({ "url": `api/common/questions/${dataMassage["questionId"]}/upsert` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values)
    }

    const afterNewRecordSubmit = () => {
        message.info('Your question has been saved.');
        showHideAllProps({ "edit-selected-record": "block", "button-controls-section": "none", "new-record": "none", "records-list-table": "none" });
        findAll();
    }

    const questionFormFailed = () => {
    }
    const findUserLike = (value) => {
        if (value === null || value === "") return;
        setReviewList([...[]]);
        var payload = { "select": ["id", "name"], "doc_name": "users", "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi-like` }, payload, setReviewList, setReviewListCallBack);
    }

    const setReviewListCallBack = (data) => {
        setReviewList([...data]);
    }

    return (
        <section>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "new-record-": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table pagination={false}rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                <div style={{ marginTop: 10 }}>
                    <Space size={10}>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => newRecord()}><FontAwesomeIcon icon={faPlus} />New</Button>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => findFromBin()}> Bin </Button>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => findAll()}> All </Button>
                    </Space>
                </div>
            </section>
            <section className="thCard" style={{ "width": 700, "display": presentObjects["new-record"] }} >
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onFinish} form={questionForm} onFinishFailed={questionFormFailed}
                    autoComplete="off" initialValues={theObject}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Enter your task' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Assign To" name="assigned_to" rules={[{ required: true, message: 'Enter your AssignTo', }]}>
                                <Select notFoundContent={null} showSearch onSearch={findUserLike} placeholder="Select User">
                                    {reviewList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Status" name="status_id">
                                <Select showSearch allowClear defaultActiveFirstOption={false}
                                    placeholder="Select Status">
                                    {statusList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Resolved" name="resolved">
                                <Select showSearch allowClear placeholder="Choose resolved" defaultValue={false}>
                                    <Select.Option value={true}>Yes</ Select.Option>
                                    <Select.Option value={false}>No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Type theDescription' }]}>
                        <CKEditor
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                                editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                            }}
                            onError={(error, { willEditorRestart }) => {
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                console.log(ckEditorData);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                            editor={DecoupledEditor} data={theObject["description"]}
                        />
                    </Form.Item>
                    <Row>
                        <Col style={{ marginBottom: "20px" }}>
                            <Dragger {...fileUploadProps(formData, theObject, setTheObject)}>
                                <p className="ant-upload-drag-icon">
                                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>


            </section>
            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>
        </section>
    )
}
