import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faCloudUploadAlt, faFilter, faEraser, faRefresh, faSave, faEye } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, appProfile, profileBarItems } from "./Config";
import './App.css';
//import './antd.css';
import moment from 'moment';
import { PlusOutlined, LoadingOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, Avatar, DatePicker, Typography, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col, Space } from 'antd';
import { AxiosNetwork } from "./axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Title } = Typography;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "profile";
const tableName = "profile";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";
//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};
export default function Profile() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "upload-details": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [profileForm] = Form.useForm();
    const [skillsForm] = Form.useForm();

    const [proficiencyList, setProficiencyList] = useState([]);

    console.log(queryParams);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("profile")
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosGet({ "url": `/api/users` }, setTheObject, afterSetProfile);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "proficiency-types" }, setProficiencyList);

        findAll();
    }, []);

    const afterSetProfile = (data) => {
        console.log(data);
        profileForm.setFieldsValue({ ...data });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosGet({ "url": `/api/users` }, setTheObject, afterProfileUpdate);
        showHideAllProps({ "skills-details": "none", "upload-details": "none", "edit-selected-record": "none", "profile-details": "block", "records-list-table": "none" });
    }
    const afterProfileUpdate = (data) => {

    }
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },

        {
            title: 'Proficiency',
            dataIndex: 'proficiency_id'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        }
      
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "records-list-table": "none" });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        // AxiosNetwork.axiosDelete({ "url": `api/admin/pos/brands/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["birthday"] = moment(values["birthday"]).format("yyyy-MM-dd");
        AxiosNetwork.axiosPut({ "url": "/api/users/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "skills-details": "none", "upload-details": "none", "profile-details": "block", "records-list-table": "none" });
    }

    const afterNewRecordSubmit = (values) => {
        message.info('Your profile details has been saved.');
        profileForm.resetFields();
        console.log("afterNewRecordSubmit", values);
        findAll();
    }

    const profileFormFailed = () => {

    }
    const skillsFormFailed = () => {

    }

    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        profileForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/users/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "upload-details": "none", "profile-details": "none", "edit-selected-record": "block" });
    }
    const newEdit = () => {
        showHideAllProps({ "profile-details": "block", "edit-selected-record": "block", "upload-details": "block" });

    }
    const newSkills = () => {
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/common/skills` }, { "doc_name": tableName }, setRecordsList, afterSkillsUpdate);
        showHideAllProps({ "profile-details": "none", "upload-details": "none", "skills-details": "block", "records-list-table": "block", "user-skills-list-table": "none" });
    }
    const afterSkillsUpdate = (data) => {
        console.log(data);

    }
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    const onSkillsFinish = (values) => {
        values["upsert"] = true;
        AxiosNetwork.axiosPost({ "url": `api/admin/common/skills/${theObject.id}/upsert` }, values, recordsList, setRecordsList, afterSkillsAdded);
        showHideAllProps({ "skills-details": "block", "upload-details": "none", "profile-details": "none", "records-list-table": "block", "user-skills-list-table": "none" });
    }

    const afterSkillsAdded = () => {
        message.info('Your Skills details has been saved.');
        newSkills();

    }
    return (

        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-skills',
                                label: (<a onClick={(e) => newSkills()} rel="noopener noreferrer">Skill</a>)
                            },
                            {
                                key: 'reports',
                                label: (<a onClick={(e) => newSkills()} rel="noopener noreferrer">Skill</a>)
                            },
                        ]}>
                    </Menu>
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <div>
                            <center>
                                <Avatar className="profile" size={128} icon={<UserOutlined />} />
                                <Avatar size="small" onClick={(e) => newEdit()} icon={<EditOutlined />} />

                            </center>
                        </div>

                        <section style={{ "display": presentObjects["upload-details"] }}>
                            <center>
                                <Upload {...fileUploadProps}>
                                    <Button className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></Button>
                                </Upload>
                              
                            </center>
                        </section>

                        <section style={{ "display": presentObjects["records-list-table"] }}>

                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />

                        </section>
                        <section style={{ "display": presentObjects["profile-details"] }}>

                            <Form labelAlign="left" layout="vertical"
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                onFinish={onFinish}
                                form={profileForm} initialValues={theObject}
                                onFinishFailed={profileFormFailed} autoComplete="off">
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter your name',
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Identifier"
                                    name="id">
                                    <Input disabled={true}/>
                                </Form.Item>

                                <Form.Item
                                    label="Middle Name"
                                    name="middle_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Enter your  middle name',
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter your  last name',
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Nick Name"
                                    name="display_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Enter your  nick name',
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Birthday" name="birthday">
                                    <DatePicker format={"yyyy-MM-dd"} />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Enter your  username',
                                        }
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ required: false, message: 'Type theDescription' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                        data={((theObject["description"] != undefined) ? theObject["description"] : "")}

                                    />
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>
                        <section style={{ "display": presentObjects["skills-details"] }}>

                            <Form layout="horizontal" name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                onFinish={onSkillsFinish}
                                form={skillsForm} initialValues={theObject}
                                onFinishFailed={skillsFormFailed} autoComplete="off">
                                <Form.Item
                                    label="Skill"
                                    name="name"
                                    rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Proficiency"
                                    name="proficiency_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select your Proficiency',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Select your Proficiency">
                                        {proficiencyList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>

                    </Content>
                </Layout>
            </Layout>

        </Layout>
    )
}