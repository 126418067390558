import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faBorderNone, faUserGroup, faPlus } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, configMenus } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Space, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 168;
const appPath = "user-doc-roles";
const tableName = "user_doc_roles";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function UserDocRoles(props) {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "users-details": "none", "new-roles-section": "none", "user-roles-list-table": "none", "edit-selected-record": "none", "new-record": false, "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [roles, setRoles] = useState([]);
    const [userList, setUserList] = useState([]);
    

    const [usersRolesForm] = Form.useForm();

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'user_name'
        },
        {
            title: 'Role',
            dataIndex: 'role_name',
            width: 200
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "trash",
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        }
    ];

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/user/common/user-doc-role/${selectedRow.id}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }


    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/configurations/roles`);
        AxiosNetwork.axiosFetchMulti({ "url": `api/user/common/user-doc-roles` }, { "doc_name": tableName, "filters": [{ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": props.appDoc["id"]}] }, setRecordsList, afterRoleAreFetched);
        AxiosNetwork.axiosFetch({ "url": `api/admin/common/roles` }, setRoles);
    }, []);

    const afterRoleAreFetched = (data) => {
        console.log(data);
        setRecordsList([...data]);
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    
    const onUserRoleFinish = (values) =>
    {
        values["doc_id"] = props.appDoc["id"];
        AxiosNetwork.axiosPost({ "url": `/api/user/common/user-doc-role` }, values, userRecordsList, setUserRecordsList, afterUserRoleAddedCallback);
    }


    const afterUserRoleAddedCallback = (data) =>
    {
        AxiosNetwork.axiosFetchMulti({ "url": `api/user/common/user-doc-roles` }, { "doc_name": tableName, "filters": [{ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": props.appDoc["id"]}] }, setRecordsList, afterRoleAreFetched);
    }

    const usersFormFailed = () => {

    }
    

    const findUserLike = (value) => {
        if (value === null || value === "") return;
        setUserList([]);
        var payload = { "doc_name": "users", "col_name": "name", "col_value": value, "select":["name"] };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/find-select-any-multi-like` }, payload, setUserList, setUserListCallBack);
    }

    const setUserListCallBack = (data) => {
        setUserList([...data]);
        showHideAllProps({ "users-details": "block", "new-roles-section": "none", "records-list-table": "block", "user-roles-list-table": "block" });
    }

    return (
        <section style={{width:800}}>

            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" pagination={false} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
            </section>


            <section className='thCard' style={{marginTop:20, width:400}}>
                    <Form layout="vertical" labelAlign="left"
                        name="basic"
                        onFinish={onUserRoleFinish}
                        form={usersRolesForm} initialValues={theObject}
                        onFinishFailed={usersFormFailed} autoComplete="off">
                        <Form.Item
                            label="User"
                            name="user_id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter your User',
                                }
                            ]}>
                            <Select showSearch allowClear onSearch={findUserLike} placeholder="Select User">
                                {userList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Role"
                            name="role_id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter the role',
                                }
                            ]}>
                            <Select showSearch placeholder="Select Role">
                                {roles.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
            </section>


            <Drawer title="Info Label" placement="right" onClose={() => showHideAllProps({"showInfo": false})} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>
        </section>
    )
}