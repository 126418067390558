import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faBackward, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faBorderNone, faUserGroup, faPlay } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, configMenus } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Tabs, Form, Space, Slider, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import WorkflowTasks from "../workflows/WorkflowTasks";
import MiniNote from "../note/MiniNote";
import MiniTodo from "../todo/MiniTodos";
import MiniEmails from "../emails/MiniEmails";
import MiniQuestion from "../question/MiniQuestion";
import FormsDataCollection from "../forms/FormsDataCollection"
import MiniCalendar from "../calendar/MiniCalendar"
import UserDocRoles from '../commons/UserDocRole';
import DealInfo from './DealInfo';
const dataMassage = { "emailAddress": {}, "associateId": "1", "id": 1 };
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "deals";
const tableName = "deals";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function Deals() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "deals-form": "none", "button-controls-section": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [dealsForm] = Form.useForm();
    const [nameList, setNameList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [currenciesList, setCurrenciesList] = useState([]);
    const [inputValue, setInputValue] = useState(1);
    const [guidelineList, setGuidelineList] = useState([{ "id": "g1", "name": "Guide line 1" }, { "id": "g2", "name": "Guideline 2" }]);
    const [functionList, setFunctionList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [roleList, setRoleList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [inboxList, setInboxList] = useState([{ "id": "i1", "name": "Inbox 1" }, { "id": "12", "name": "Inbox 2" }]);
    const [pluginList, setPluginList] = useState([{ "id": "i1", "name": "Plugin 1" }, { "id": "12", "name": "Plugin 2" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [formList, setFormList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Deals',
            dataIndex: 'name'
        },
        {
            title: 'Start',
            render: (record) => <a onClick={(e) => startProject(record)}><FontAwesomeIcon icon={faPlay} /></a>,
            width: 50
        },
        {
            title: 'Currency',
            dataIndex: 'currency_id',
            width: 50
        },
        {
            title: 'Value',
            dataIndex: 'value',
            width: 50
        },
        {
            title: 'Status',
            dataIndex: 'status_name',
            width: 200
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 20,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 20,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];

    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/crm/deals`);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/currencies` }, setCurrenciesList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-select-any-multi` }, { "doc_name": "roles", "select": ["id", "name"] }, setRoleList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-select-any-multi` }, { "doc_name": "guidelines", "select": ["id", "name"] }, setGuidelineList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "configurations", "filters": [{ "col_name": "genre", "data_type": "string", "operator": "=", "string_value": "function" }] }, setFunctionList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "configurations", "filters": [{ "col_name": "genre", "data_type": "string", "operator": "=", "string_value": "plugin" }] }, setPluginList);
        AxiosNetwork.axiosFetch({ "url": `api/emails/inboxes` }, setInboxList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "contacts" }, setNameList);
        const filter = { "col_name": "genre", "operator": "=", "data_type": "string", "string_value": "FORMS" };
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, { "doc_name": "forms", "filter": [filter] }, setFormList);
        if(queryParams["id"] !== undefined) AxiosNetwork.axiosGet({ "url": `/api/${tableName}/${queryParams["id"]}`, "id": queryParams["id"] }, theObject, setTheObject, fetchDealCallback);
        findAll();
    }, []);

    const fetchDealCallback = (project) => {
        console.log(project);
        setTheObject({ ...project });
        dealsForm.setFieldsValue({ ...project });
        dataMassage["workflow"] = { "id": project["workflow_id"] }
        showHideAllProps({ "deals-form": "none", "new-record": "none", "new-sub-record": "block", "edit-selected-record": "block", "records-list-table": "block" });
    }

    const startProject = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        dataMassage["workflowId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        formData["folder_id"] = dataMassage["workflowId"];
        AxiosNetwork.axiosPutMono({ "url": `/api/${tableName}/${selectedRow.id}/start`, "id": selectedRow.id }, theObject, setTheObject, fetchDealCallback);
    }

    const onChange = (newValue) => {
        setInputValue(newValue);
    };
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "new-record": "none", "records-list-table": "block" });

    }
    const editTableRow = (selectedRow) => {
        window.location.href = window.location.origin + "/apps/crm/deals?id=" + selectedRow.id;
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/deals/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "api/deals/" + theObject["id"] + "/upsert" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "button-controls-section": "none", "new-record": "none", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = () => {
        message.info('Your deals details has been saved.');
        findAll();
    }

    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `api/deals` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "deals-form": "block", "button-controls-section": "none", "new-record": "none", "records-list-table": "block" });
    }
    const dealsFormFailed = () => {

    }
    const newDeals = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "button-controls-section": "block", "deals-form": "none", "new-record": "block", "records-list-table": "none" });
        dealsForm.resetFields();
    }

    return (
        <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "deals-form": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>

            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                <section style={{ "display": presentObjects["deals-form"] }}>
                    <Button style={{ marginBottom: "20px", marginTop: "20px" }} type="primary" onClick={(e) => newDeals()}>New</Button>
                </section>
            </section>
            {(theObject.id !== undefined) &&
                <section style={{ "display": presentObjects["edit-selected-record"] }}>
                    <section style={{ "display": presentObjects["new-sub-record"] }}>
                        <Tabs defaultActiveKey={theObject.id}
                            items={[
                                { "label": "Info", "key": "deals-" + new Date().getTime(), children: <DealInfo appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Workflow", "key": "tasks-" + new Date().getTime(), children: <WorkflowTasks appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Todo", "key": "todo-" + new Date().getTime(), children: <MiniTodo appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Emails", "key": "email-" + new Date().getTime(), children: <MiniEmails appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Users", "key": "users-" + new Date().getTime(), children: <UserDocRoles appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Notes", "key": "notes-" + new Date().getTime(), children: <MiniNote appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Meetings", "key": "meeting-" + new Date().getTime(), children: <MiniCalendar appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Form", "key": "form-" + new Date().getTime(), children: <FormsDataCollection appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                { "label": "Query", "key": "query-" + new Date().getTime(), children: <MiniQuestion appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList} guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                            ]}
                        />
                    </section>
                </section>
            }
            <section className="thCard" style={{ "width": 700, "display": presentObjects["new-record"] }} >
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onFinish} form={dealsForm} onFinishFailed={dealsFormFailed}
                    autoComplete="off" initialValues={theObject}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label="Deals" name="name" rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Contact Name" name="contact_id" rules={[{ required: true, message: 'Type  your  contact name in this field' }]}>
                                <Select style={{ width: '100%' }} placeholder="Type  your  contact name in this field">
                                    {nameList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label="Value" name="value" rules={[{ required: false, message: 'Type  your  amount in this field' }]}>
                                <InputNumber  style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Currency" name="currency_id" rules={[{ required: false, message: 'Enter your currency' }]}>
                                <Select style={{ width: '100%' }} placeholder="Change Currencies">
                                    {currenciesList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Notes" name="notes"
                        rules={[{ required: false, message: 'Type the notes' }]}>
                        <CKEditor
                            editor={DecoupledEditor}
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                                editor.editing.view.change(writer => {
                                    writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                                });
                            }}
                            onError={(error, { willEditorRestart }) => {
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                console.log(ckEditorData);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </section>

            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>
        </Layout>
    )
}