import React, { useCallback, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon, faApple, faFacebook, faGithub, faGoogle, faInstagram, faLinkedin, faMicrosoft, faOrcid, faPinterest, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { findUrlPath, profile, footerLine, menuBarItems, socialAuthProps } from "./Config";
import './App.css';
import './style.css';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { AxiosNetwork } from "./axiosService";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

var REDIRECT_URI = "";

export default function Login() {
  const [oauths, setOauths] = useState([]);
  const [provider, setProvider] = useState('')
  const [userProfile, setUserProfile] = useState({})

  useEffect(() => {
    AxiosNetwork.onHello({}, { "url": "/api/public/common/hello" });
    AxiosNetwork.axiosFetch({ "url": "/api/public/user/login-oauths" }, setOauths);
  }, []);


  const onLoginStart = useCallback(() => {
    alert('login start')
  }, [])

  const onLogoutSuccess = useCallback(() => {
    setUserProfile(null)
    setProvider('')
    alert('logout success')
  }, [])


  const onFinish = (values) => {
    console.log('Success:', values);
    AxiosNetwork.onAuth(values, { "url": "/login" });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };



  return (
    <section style={{ position: "absolute", display: "table", width: "100%", height: "100%" }}>
      <div style={{ display: "table-cell", verticalAlign: "middle" }}>
          <center>
            <div className='login'>
              <img style={{ marginBottom: 20, width: 60 }} src="https://storage.googleapis.com/static.techiehug.com/icons/user.png" /><br /><strong>Login</strong>
              <div className='loginHeader' style={{ marginBottom: 20 }} ></div>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 5 }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" style={{ textAlign: 'left' }} >
                    <Row >
                      <Col span={12}>
                      <Checkbox>Remember me</Checkbox>
                      </Col>
                      <Col span={12}>
                      <a className="login-form-forgot" href="/forgot">Forgot password?</a>
                      </Col>
                    </Row>
                  </Form.Item>

                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    log in
                  </Button><br /><br />
                  OR <br /><br /><a href="/register">Register Now!</a>
                </Form.Item>
              </Form>
            </div>
            <p><small>Powered by <a href="https://techiehug.com">Techiehug Technologies Private Limited</a></small></p>
          </center>
        </div>
    </section>
  );
}