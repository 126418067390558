import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId } from "../../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit,faSave, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import '../../App.css';
import { Divider, Form, Input, DatePicker, Row, Button, Col, View, prefixSelector, suffixSelector, Card, message, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import moment from 'moment';
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-employee-basic";
const tableName = "human-resource";
var activeDataColumns = [];

const style = { padding: '8px 0' };
let ckEditorData = "";

const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function HumanResourceEmployeeBasic(props) {
    const [presentObjects, setPresentObjects] = useState({"employee-documents-details":"none","human-resource-holidays":"none","human-resource-employee-payroll":"none","human-resource-employer-documents": "none", "edit-selected-record": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourceadminForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [employmentTypeList, setEmploymentTypeList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [jobTitleList, setJobTitleList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [jobCategoryList, setJobCategoryList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theEmployee, setTheEmployee] = useState(props.employee); 
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("employees")
        setUiLabels({ "save": "Save" });

        findAll();
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "configurations", "range": [{ "col_name": "app_type", "operator": "=", "data_type": "integer", "integer_value": 88 }] }, dispatchConfigurations);
    }, [props.employee.id]);

    const dispatchConfigurations = (data) => {
        setEmploymentTypeList(data.filter(eachItem => eachItem["genre"] == "employee-employment-type"));
        setJobTitleList(data.filter(eachItem => eachItem["genre"] == "employee-job-title"));
        setJobCategoryList(data.filter(eachItem => eachItem["genre"] == "employee-job-category"));
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        humanresourceadminForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/admin/employees/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/admin/employees/` + theEmployee.id , "id": theEmployee.id  }, theEmployee, setTheEmployee, renderEmployeeData);//todo
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, {"doc_name":"addresses", "filters":[{"col_name":"doc_id", "operator":"=", "data_type":"string", "string_value":"doc_id"}]}, setRecordsList);

    }
    const renderEmployeeData = (data) =>
    {
        console.log("renderEmployeeData", data);
        setTheEmployee({...data});
        humanresourceadminForm.setFieldsValue({ ... data});
    }
    const humanresourcebasicFailed = () => {    
    }
    const onFinishFailed = () => {
    }
    const onFinish = (values) => {
        setUiLabels({ "save": "Save" });
        values["upsert"] = true;
        values["description"] = ckEditorData;
        values["user_uid"] = "000";
        values["job_category"] = "EMPLOYEE";
        values["blood_group"] = "A+";
        values["birthday"] = moment(values["birthday"]).format("yyyy-MM-DD");
        AxiosNetwork.axiosPut({ "url": `/api/human-resource/admin/employees/${theEmployee["id"]}/update` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }

    const afterNewRecordSubmit = () => {
        message.info('Your Basic details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": "addresses", "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );

    const humanresourceadminFailed = () => {
    }
    const columnSchema = [
        {
            title: 'Contact Type',
            dataIndex:'name',
        },
        {
            title: 'Address',
            dataIndex: 'address_line_1',
        },
        {
            title: 'City',
            dataIndex: 'city',
        },
        {
            title: 'State',
            dataIndex: 'state',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];
    return (
        <section>
            <Layout className='contentLayout'>
                    <section  className="thCard" style={{  marginBottom: "50px","width": 700, "display": presentObjects["personal-information"] }} >
                            <Form size="large" labelAlign="right" layout="vertical"
                            name="basic"
                            onFinish={onFinish}
                            form={humanresourceadminForm} initialValues={theObject}
                            onFinishFailed={humanresourceadminFailed} autoComplete="off">
                           <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Salutation" name="salutation" >
                                <Select  showSearch allowClear >
                                    <Select.Option value="Mr">Mr</Select.Option>
                                    <Select.Option value="Mrs">Mrs</Select.Option>
                                    <Select.Option value="Miss">Miss</Select.Option>
                                    </Select>
                                 </Form.Item>
                                 </Col>
                            <Col span ={12}>
                        <Form.Item label="First Name" name="name" rules={[{ required: true, message: 'Type  your  first name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Middle Name" name="middle_name" rules={[{ required: true, message: 'Type  your  second name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span ={12}>
                            <Form.Item label="Last Name" name="last_name" rules={[{ required: true, message: 'Type  your  second name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item
                                label="Date of Birth" name="birthday">
                                <DatePicker />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Gender" name="gender" 
                                rules={[{ required: true, message: 'Type  your  gender in this field' }]}>
                            <Select showSearch allowClear>
                                    <Select.Option value="Male">Male</Select.Option>
                                    <Select.Option value="Female">Female</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Blood Group" name="blood_group" >
                                <Select showSearch allowClear>
                                    <Select.Option value="A+">A+</Select.Option>
                                    <Select.Option value="A-">A-</Select.Option>
                                    <Select.Option value="O+">O+</Select.Option>
                                    <Select.Option value="O-">O-</Select.Option>
                                    <Select.Option value="AB-">AB-</Select.Option>
                                    <Select.Option value="AB+">AB+</Select.Option>
                                    <Select.Option value="B+">B+</Select.Option>
                                    <Select.Option value="B-">B-</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Married Status" name="married_status" >
                                <Select showSearch allowClear>
                                    <Select.Option value="Married">Married</Select.Option>
                                    <Select.Option value="Unmarried">Unmarried</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Employee Id" name="employee_id"
                                rules={[{ required: true, message: 'Type  your  employee id in this field' }]}>
                                <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>    
                            <Form.Item name="employment_type" label="Employment type">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Employment type">
                                    {employmentTypeList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item name="job_title" label="Job Title">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Job title">
                                    {jobTitleList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item name="job_category" label="Job Category">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Job category">
                                    {jobCategoryList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Official Email" name="email"
                                rules={[{ required: true, message: 'Type  your Official email' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item  label="Personal Email" name="personal_email"
                                rules={[{ message: 'Type  your personal email' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item name="mobile" label="Mobile Number"
                                rules={[{ required: true, message: 'Please input your mobile number!' }]} >
                                <Input addonBefore={prefixSelector} style={{width: '100%'}}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>
                        </Form>
                    </section>
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                    </section>
                </Layout>
        </section>
        )
}
