import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTrashCan, faMessage, faCloudUploadAlt, faPeace, faEdit, faSave, faPrint, faInfo, faCircleInfo, faInfoCircle, faBars, faCircleUser, faGrip, faPlus, faTag, faFolder, faEllipsisV, faStar, faGripLinesVertical, faClose, faUser, faAngleDown, faSortDown, faCloudArrowDown, faFileArrowDown, faUndoAlt, faStarAndCrescent, faStarHalfAlt, faTags, faFileDownload, faCheckSquare, faAddressCard, faCopy } from '@fortawesome/free-solid-svg-icons'
import { appProfile, dateTimeUtils, findSubstring, generateFuzzyId, fileUploadProps, menuBarItems, profileBarItems } from "../Config";
import '../App.css';
import Apps from '../Apps';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import moment from 'moment-timezone';
import MiniContact from "./MiniContact";
import { Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Space, Avatar, Select, Badge, Cascader, Tabs, TimePicker, Drawer, AutoComplete, Col, Checkbox, Grid } from 'antd';
import { AxiosNetwork } from "../axiosService";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const { TabPane } = Tabs;
const { Dragger } = Upload;
var formData = {};
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
const appId = "contacts";
const appPath = "contacts";
const tableName = "contacts";
const { Search } = Input;
var contactPayload = { "doc_name": tableName, offset: 0, limit: 10 };
const plainOptions = [];
const defaultCheckedList = [];
var activeDataColumns = [];
const dataMassage = { "emailAddress": {}, "associateId": "1", "refreshId":1};

export default function Contact() {
    const [tempList, setTempList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [importList, setImportList] = useState([]);
    const [exportList, setExportList] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "contact": "none", "button-controls-section": "none", "records-list-table": "block", "savedButton": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contact-form": "none"});
    const [appList, setAppList] = useState(false);
    const [newContactHeaderView, setContactHeaderView] = useState(false);
    const [folderEditShow, setFolderEditShow] = useState(false);
    const [selectedRowsLength, setSelectedRowsLength] = useState(0);
    const [labelEditShow, setLabelEditShow] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentSelectId, setCurrentSelectId] = useState('');
    const [theObject, setTheObject] = useState({ "id": generateFuzzyId("contact") });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [addressesRecordsList, setAddressesRecordsList] = useState([]);
    const [addressesTypes, setAddressesTypes] = useState([{}]);

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
        
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            render: (record) => <span><CopyToClipboard text={record["email"]}><span><a type="link"><FontAwesomeIcon icon={faCopy}/></a>&#8194;{findSubstring(record["email"], 3, "...")}</span></CopyToClipboard></span>
        },
        {
            key:"edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>
        },
        {
            title: 'Address',
            width: 50,
            render: (record) => <a onClick={(e) => editTableAddressRow(record)}><FontAwesomeIcon icon={faAddressCard} /></a>
        },
        {
            key:"trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>
        },
        {
            key:"info",
            title: 'Info',
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            key:"restore",
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const importTableColumn = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Request',
            dataIndex: 'created_at',
            render: (record) => <span>{moment(record).format("llll")}</span>
        },
        {
            title: 'Completed',
            dataIndex: 'completed_at',
            render: (record) => (record != null && <span>{moment(record).format("llll")}</span>)
        },
        {
            title: 'Download',
            render: (record) => <a onClick={(e) => AxiosNetwork.downloadFileByFileAssetId(record["file_id"], "contacts.csv")}><FontAwesomeIcon icon={faFileDownload} /></a>,
        }
    ];

    const downloadContacts = () => {
        AxiosNetwork.axiosPostMono({ "url": `/api/${appPath}/delayed_task/download` }, {}, setTempList);
        message.success("Bulk download request is raised. The file will be be available in a few seconds.");
        console.log("import contact clicked")
    }

    const infoTableRow = (selectedRow) => {
        showHideAllProps({"showInfo": true, "address-form": false, "contact-form":"none", "records-list-table": "block", "button-controls-section": "none" });
        setTheObject(selectedRow);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": `/api/${appPath}/restore/` + selectedRow.id, "id": selectedRow.id }, selectedRow, recordsList, setRecordsList);
    }

    const editTableAddressRow = (selectedRow) => {
        contactAddress(selectedRow);
    }

    const contactAddress = (selectedRow) => {
        dataMassage["address_id"] = generateFuzzyId("addresses");
        showHideAllProps({ "showInfo":false, "address-form": true, "records-list-table": "block", "address-list-table": "none", "button-controls-section": "none", "contact-form":"none" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "addresses", "filters": [{ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": selectedRow.id }] }, setAddressesRecordsList);
        setTheObject({ ...selectedRow });
    }

    const editTableRow = (selectedRow) => {
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id }, theObject, setTheObject, afterFetch);
    }

    const afterFetch = (data) => {
        if(data["birthday_at"] !== undefined) data["birthday_at"] = moment(data["birthday_at"]);
        showHideAllProps({"showInfo":false, "button-controls-section": "block", "showDownload": "none", "showUpload": "none", "contact-form": "block", "savedButton": "none", "records-list-table": "none", "address-form": false });
        setTheObject({ ...data });
    }

    
    const onSelectChange = (selectedRowIds) => {
        if (selectedRowIds.target.checked == true) {
            selectedRows.push(selectedRowIds.target.value);
            setSelectedRows(selectedRows);
            console.log(selectedRows);
            console.log(selectedRows.length);
            setCurrentSelectId(selectedRowIds.target.value);

        }
        else {
            selectedRows.splice(selectedRows.indexOf(selectedRowIds.target.value), 1);
            setSelectedRows(selectedRows);
            console.log(selectedRows);
            console.log(selectedRows.length);

        };
        if (selectedRows.length == 0) {
            setContactHeaderView(false);
            setSelectedRowsLength(0);
        }
        else {
            setContactHeaderView(true);
            setSelectedRowsLength(selectedRows.length);
        }
    }

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        console.log("useEffect from Contact - "+theObject.id);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetch({ "url": `/api/public/common/countries` }, setCountryList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name":"configurations","filters":[{"col_name":"genre", "string_value": "address-types", "operator":"=", "data_type":"string"}] }, setAddressesTypes);
        findAll();
    }, [  ]);

    const filterByFolder = (currentFolder) => {
        presentObjects.folderEdit = "block";
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folderId": currentFolder.id }, setRecordsList, afterContactCallBack);
    }

    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "labelId": currentLabel.id }, setRecordsList, afterContactCallBack);
    }
    
    const deleteTableRow = (selectedRow) => {
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findFromBin = (filter) => {
        activeDataColumns = columnSchema.filter(item => !["trash", "edit", "info"].includes(item.key));
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        console.log(activeDataColumns);
    }

    const findAll = () => {
        activeDataColumns = columnSchema.filter(item => !["restore"].includes(item.key));
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, contactPayload, setRecordsList, afterContactCallBack);
    }
    
    const afterContactCallBack = (data) => {
        console.log("afterContactCallBack");
        data.forEach(each => {
            if(each["birthday_at"] !== undefined) each["birthday_at"] = moment(each["birthday_at"]);
        });
        setRecordsList(data);
        showHideAllProps({ "contact": "none", "button-controls-section": "none", "showDownload": "none", "showUpload": "none", "contact-form": "none", "savedButton": "none", "records-list-table": "block" });
        setTheObject({"id": generateFuzzyId("contact")});
    }


    const newRecord = () => {
        showHideAllProps({ "contact": "block", "button-controls-section": "block", "showDownload": "none", "showUpload": "none", "contact-form": "block", "savedButton": "none", "records-list-table": "none" });
        setTheObject({ "id": generateFuzzyId("contact") });
    }

    const downloadRequestClicked = () => {
        AxiosNetwork.axiosGetMulti({ "url": `/api/${appPath}/delayed_task/download` }, exportList, setExportList);
        showHideAllProps({ "button-controls-section": "none", "showDownload": "block", "showUpload": "none", "contact-form": "none", "savedButton": "none", "records-list-table": "none" });
    }

    const uploadRequestClicked = () => {
        AxiosNetwork.axiosGetMulti({ "url": `/api/${appPath}/delayed_task/upload` }, importList, setImportList);
        showHideAllProps({ "button-controls-section": "none", "showDownload": "none", "showUpload": "block", "contact-form": "none", "savedButton": "none", "records-list-table": "none" });
    }


    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-contact-1',
                                label: (<a onClick={(e) => newRecord("deleted")} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            },
                            {
                                key: 'bulk-import',
                                label: (<a onClick={(e) => downloadRequestClicked()} rel="noopener noreferrer">Download</a>)
                            },
                            {
                                key: 'bulk-export',
                                label: (<a onClick={(e) => uploadRequestClicked()} rel="noopener noreferrer">Bulk Upload</a>)
                            }
                        ]} />
                </Sider>
                <Layout className='contentLayout'>
                    <Content>

                        <section>
                            <MiniContact key={"key-" + new Date().getTime()} appDoc={{...theObject}} presentObjects={presentObjects} recordsList={recordsList} columnSchema={[...activeDataColumns]} addressesRecordsList={addressesRecordsList} addressesTypes={addressesTypes} countryList={countryList} />
                        </section>

                        <div style={{ display: presentObjects.showUpload }}>
                            <center>
                                <Card style={{ width: 500, marginBottom: "20px" }}>
                                    <Dragger {...fileUploadProps(formData, theObject, setTheObject)}>
                                        <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
                                        <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
                                    </Dragger>
                                </Card>
                            </center>
                            <Table rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={importTableColumn} dataSource={importList} />
                        </div>

                        <div style={{ display: presentObjects.showDownload }}>
                            <Table style={{ marginBottom: "20px" }} pagination={false} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={importTableColumn} dataSource={exportList} />
                            <Button onClick={(e) => downloadContacts()}><FontAwesomeIcon icon={faFileDownload} /> &#x2002; Download Contacts</Button>
                        </div>

                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}