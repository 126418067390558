
import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId} from "../../Config";
import '../../App.css';
import { Divider, Form, Input, DatePicker,Card, Upload, Button, message, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import moment from  'moment';

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 85;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-leave-my-team";
const tableName = "human-resource";
let ckEditorData = "";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


export default function HumanResourceLeaveMyTeam() {
    const [presentObjects, setPresentObjects] = useState({"edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourceleaveForm] = Form.useForm();
    const [humanresourceleavemyteamForm] = Form.useForm();
    const [humanresourceleavemyteamList, setHumanResourceLeaveMyTeamList] = useState([{ "name": "LEAVE-MY-TEAM", "mime_assets": [], "description": "Type your content here" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);

    useEffect(() => {
        theObject["id"]= generateFuzzyId("leave-requests")
        findAll();
    }, []);
   
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanresourceleavemyteamForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/leaves` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "human-resource-leave-my-team": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `api/human-resource/leaves` }, setRecordsList);
        showHideAllProps({ "human-resource-leave-my -team": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const humanresourceleavemyteamFailed = () => {
    }
    
    const onFinish = (values) => {
        values["upsert"] = true;
        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["description"] = ckEditorData;
        values["duration_type"] = "DAY";
        values["name"]= " ";
        values["days"]= 10.0;
        values["type_id"]="sick-leave";
        values["reason_id"]="fever";
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/leaves/" + theObject["id"] + "/update"}, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your myteam details has been saved.');
        findAll();
    }
   
    const columnSchema = [
        {
            title: 'Employee name',
            dataIndex: 'name',
        },
        {
            title: 'Start at',
            dataIndex: 'start_at',
        },

    ];
   

    const dataSource = [
        {
            key: '1',
            leave_type: 'Earned Leave',
            annual_quota: 0,
            carried_forward: 0,
            availed_till_date: '2.5d',
            current_balance: 0,
        },
        {
            key: '2',
            leave_type: 'Optional Holiday',
            annual_quota: 0,
            carried_forward: 0,
            availed_till_date: '3d',
            current_balance: 0,
        },
    ];

    const columns = [
        {
            title: 'Leave Type',
            dataIndex: 'leave_type',
        },
        {
            title: 'Annual Quota',
            dataIndex: 'annual_quota',
        },
        {
            title: 'Carried Forward',
            dataIndex: 'carried_forward',
        },

        {
            title: 'Availed Till Date',
            dataIndex: 'availed_till_date',
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_balance',
        },
    ];

    
    return (
        <section>
           <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                    </section>
                    <section className="thCard" style={{ "width": 600, "display": presentObjects["human-resource-leave-my-team"] }} >
                        <section style={{ "display": presentObjects["records-list-table1"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columns} dataSource={dataSource} />
                        </section>
                       <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanresourceleavemyteamForm} initialValues={theObject}
                            onFinishFailed={humanresourceleavemyteamFailed} autoComplete="off">
                            <Form.Item
                                label="Comment"
                                name="approver_comments"
                                rules={[{ required: true, message: 'Type  your comment in this field' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}>
                                <Button type="primary" htmlType="submit">Reject  </Button> &#x2002; 
                                <Button type="primary" htmlType="submit"> Approve</Button>
                            </Form.Item>
                        </Form>
                       </section>
               
            </Layout>
        </section>
    )

}