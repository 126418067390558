import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Divider, Form, Input,  Button,  message,  Table,  Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { generateFuzzyId } from "../../Config";

const {  Content } = Layout;
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-payslip";
const tableName = "human-resource";
let ckEditorData = "";
var formData = {};

export default function HumanResourcePayslip() {
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourcePayslipForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);

    useEffect(() => {
      theObject["id"]= generateFuzzyId("payrolls")
    findAll();
    }, []);
   
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/human-resource/payrolls` },  setRecordsList);
    }
    const humanresourcepayslipFailed = () => {
    }
 
    const columnSchema = [
        {
            title: 'Employee Id',
            dataIndex: 'employee_id',
        },
        {
            title: 'Payroll Id',
            dataIndex: 'payroll_id',
        },
        {
            title: 'Status',
            dataIndex: 'status_id',
            render: (status_id) => <span>{((status_id==null) ? "Yes" : "No" )}</span>,
        },
      
    ];

    return (
        <section>
            <Layout className='contentLayout'>
                  <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                    </section>
            </Layout>
        </section>
    )

}