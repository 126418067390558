import React, { useState, useEffect } from 'react';
import './style.css';
import './App.css';
import store from 'store2';
import { AxiosNetwork } from "./axiosService";
import { Button, Card, Form, Input, Typography, Layout, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
const { Header, Footer, Content } = Layout;

function Register() {

  const params = useParams();
  const [tempList, setTempList] = useState([]);

  useEffect(() => {
    console.log(store.get("freelancer"));
    console.log(params);
  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
    values["url"] = window.location.origin;
    AxiosNetwork.axiosPost({ "url": "api/public/user/new-user-registration" }, values, [], setTempList, dataPostCallback);
  };

  const dataPostCallback = (data) => {
    console.log(data);
    message.info(data["ui_message"]);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <section style={{ position: "absolute", display: "table", width: "100%", height: "100%" }}>
      <div style={{ display: "table-cell", verticalAlign: "middle" }}>
        <center>
          <div className='login'>
          <img style={{ marginBottom: 20, width: 60 }} src="https://storage.googleapis.com/static.techiehug.com/icons/user.png" /><br /><strong>Register</strong>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input type="text" placeholder="First Name*" />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input type="text" placeholder="Last Name*" />
            </Form.Item>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input placeholder="Username*" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your Email!',
                },
              ]}
            >
              <Input type="email" placeholder="Email*" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input type='password' placeholder="Password*" />
            </Form.Item>

            <center><Form.Item wrapperCol={{ offset: 0 }}>
              <Button type="primary" htmlType="submit" className="register-form-button">register </Button>
            </Form.Item></center>

            <hr />

            <center>
              Already a user? <a href="/">LOG IN</a>
            </center>
          </Form>
          </div>
          <p><small>Powered by <a href="https://techiehug.com">Techiehug Technologies Private Limited</a></small></p>
        </center>
      </div>
    </section >
  );
}

export default Register;