import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTrashCan, faUndoAlt, faPen, faPeace, faEdit, faSave, faBars, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons'
import { appProfile, footerLine, menuBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { v4 as uuid } from 'uuid';
import DataTable from 'react-data-table-component';

import {
    Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form,
    Input, Card, Upload,
    Radio, message,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Mentions,
    Tag,
    TimePicker, Drawer, AutoComplete, Col, Checkbox
} from 'antd';
import { AxiosNetwork } from "../axiosService";

const { TextArea } = Input;
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
const appId = 44;
const appPath = "snippets";
const tableName = "snippets";
var activeDataColumns = [];

export default function Snippet() {
    const [tempList, setTempList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [newLabelDrawer, setNewLabelDrawer] = useState(false);
    const [newFolderDrawer, setNewFolderDrawer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "description": "Type your content here", "id": tableName + "-" + uuid() });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [appsList, setAppsList] = useState([{ "id": 1, "name": "Waiting to load" }]);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 60
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 60
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];


    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
    }


    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const saveTemplate = (value) => {
        value["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/update/` + theObject.id, "id": theObject.id }, value, recordsList, setRecordsList);
    }

    const infoTableRow = (selectedRow) => {
        setTheObject(selectedRow);
        showHideProps("showInfo", true);
    }

    const createNewTemplate = () => {
        window.location.href = window.location.href;
    }


    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
    }, []);

    const updateFolder = (e) => {
        console.log(theFolder);
        AxiosNetwork.axiosUpdate({ "url": "/api/common/update/folder/" + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
    }
    const updateLabel = (e) => {
        AxiosNetwork.axiosUpdate({ "url": "/api/common/update/label/" + theLabel.id, "id": theLabel.id }, theLabel, labelList, setTheLabel);
        console.log(theLabel);
    }

    const renameFolder = (e) => {
        theFolder["name"] = e.target.value;
        console.log(theFolder);
        setTheFolder(theFolder);
    }

    const renameLabel = (e) => {
        theLabel["name"] = e.target.value;
        console.log(theLabel);
        setTheLabel(theLabel);
    }

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        presentObjects.folderEdit = "block";
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}?folder=` + currentFolder.id }, setRecordsList);
    }




    const deleteFolder = (currentFolder) => {
        console.log(currentFolder);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/folder/` + currentFolder.id, "id": currentFolder.id }, folderList, setFolderList);
    }
    const deleteLabel = (currentLabel) => {
        console.log(currentLabel);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/label/` + currentLabel.id, "id": currentLabel.id }, labelList, setLabelList);
    }


    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}?label=` + currentLabel.id }, setRecordsList);
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": `/api/${appPath}/restore/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findFromBin = (filter) => {
        activeDataColumns = columnSchema;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        console.log(activeDataColumns);
    }


    const findAll = () => {
        console.log("findAll");
        activeDataColumns = columnSchema.slice(0, -1);
        console.log(activeDataColumns);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetch({ "url": "/api/common/apps" }, setAppsList)
    }


    const handleChange = (state) => {
        console.log(state.selectedRows);
    };


    let ckEditorData = "";

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Menu theme="dark" mode="horizontal" items={menuBarItems} />
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200} className="site-layout-background" collapsible collapsed={siderCollapsed} trigger={null}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}>
                        <Menu.Item key="all-records" onClick={(e) => findAll()}>All</Menu.Item>
                        <SubMenu key="sub-folders" title="Folders">
                            {folderList.map(eachItem => (
                                <Menu.Item key={eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</Menu.Item>
                            ))}
                        </SubMenu>
                        <SubMenu key="sub-labels" title="Labels">
                            {labelList.map(eachItem => (
                                <Menu.Item key={eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</Menu.Item>
                            ))}
                        </SubMenu>
                        <Menu.Item key="bin-records" onClick={(e) => findFromBin("deleted")}>BIN</Menu.Item>
                    </Menu>
                </Sider>
                <Layout className='contentLayout'>
                    <Content>

                        <Row gutter={[16, 16]}>
                            <Table style={{ width: "100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </Row>

                        <Form
                            name="basic"
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                span: 12,
                            }}
                            onFinish={saveTemplate}
                            initialValues={theObject}
                            autoComplete="off">

                            <Form.Item
                                label="Name" name="name">
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Application"
                                name="app_type">
                                <Select allowClear showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                    {appsList.map(eachItem => (
                                        <Option key={eachItem.type}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Type theDescription' }]}>
                                <TextArea rows={4} placeholder="maxLength is 6" maxLength={255} />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>

                    </Content>
                </Layout>
            </Layout>
            <Footer>{footerLine}</Footer>
        </Layout>
    )
}