import React, { useState, useEffect } from 'react';

import './App.css';
import store from 'store2';
import { AxiosNetwork } from "./axiosService";
import { Button, Card, Form, Input, Typography, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string';

function SocialAuthCallback() {
  const params = useParams();
  const [queryParams, setQueryParams] = useState({});
  const [theObject, setTheObject] = useState({ });
  const [tempList, setTempList] = useState({});
  

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    setQueryParams(queryParams);
    AxiosNetwork.axiosGet({ "url": "/api/users/email/google?code="+queryParams["code"] }, theObject, setTheObject, dataPostCallback);
  }, []);


  const dataPostCallback = (data) => {
    console.log(data);
    message.info(data["ui_message"] + "We will load your inbox shortly.... It may take while based your inbox data size");
    setTimeout(function(){
        window.location.href = window.location.origin + "/apps/emails";
    }, 5000);
  }


  return (
    <div>
      <div className="pageCenter" style={{ width: 800 }}>
          <center>
            <div>Please wait. We are accessing your inbox.</div>
          </center>
      </div>
    </div>
  );
}

export default SocialAuthCallback;