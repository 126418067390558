import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Divider, Form, Input,  Button,  message,  Table,Card, Row,Col,  Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { generateFuzzyId } from "../../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTrashCan,faSave} from '@fortawesome/free-solid-svg-icons'
const {  Content } = Layout;
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-declaration";
const tableName = "human-resource";
let ckEditorData = "";
var formData = {};

export default function HumanResourceDeclaration() {
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourcedeclarationForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
      theObject["id"]= generateFuzzyId("declaration")
      setUiLabels({ "save": "Save" });
      findAll();
    }, []);
   
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "employees" }, setRecordsList);
    }
    const humanresourcedeclarationFailed = () => {
    }
    const editTableRow = (selectedRow) => {
      console.log(selectedRow);
      selectedRow["mime_assets"] = [];
      setTheObject({ ...selectedRow });
      humanresourcedeclarationForm.setFieldsValue({ ...selectedRow });
      formData = {};
      formData["parent_id"] = selectedRow.id;
      AxiosNetwork.axiosFetch({ "url":`/api/human-resource/employees/salaries/`+ selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
    }
      const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/admin/employees/" +  theObject["id"] + "/salary/"+ "/update"}, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your declaration details has been saved.');
        findAll();
    }
  return (
        <section>
            <Layout className='contentLayout'>
                
                  <section className="thCard" style={{ "width": 400, "display": presentObjects["declaration-details"] }} >
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanresourcedeclarationForm} initialValues={theObject}
                            onFinishFailed={humanresourcedeclarationFailed} autoComplete="off">
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>                          
                        </Form>
                    </section>
                
            </Layout>
        </section>
    )

}