import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, DatePicker, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import PosBrand from "./PosBrand";
import PosProductType from "./PosProductType";
import PosProduct from "./PosProduct";
import PosConsignment from "./PosConsignment";
import PosSupplierProduct from './PosSupplierProduct';
import PosSupplier from './PosSupplier';
import PosCustomer from './PosCustomer';
import PosPriceBook from './PosPriceBook';
import PosCustomerAddress from './PosCustomerAddress';
import PosOutlet from './PosOutlet';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "pos";
const tableName = "pos";
var activeDataColumns = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function PosAdmin() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({"supplier-products":"none","supplier-product-details":"none","price-books":"none","customer-notifications":"none","customers": "none","consignment-product":"none","consignments":"none","suppliers":"none","variants":"none","product-variant":"none","supplier-product":"none","product":"none","brands":"none",  "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [posForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});

    console.log(queryParams);
    
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        console.log(selectedRow);

        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, assetCallback);
        showHideAllProps({ "edit-selected-record": "block", "new-record": "block", "records-list-table": "block" });
    }

    const assetCallback = (data) =>
    {
        if(data["start_at"]!=undefined) data["start_at"] = moment(data["start_at"]);
        if(data["end_at"]!=undefined) data["end_at"] = moment(data["end_at"]);
        if(data["expiry_at"]!=undefined) data["expiry_at"] = moment(data["expiry_at"]);
        if(data["depreciated_at"]!=undefined) data["depreciated_at"] = moment(data["depreciated_at"]);
        setTheObject({ ...data });

        posForm.setFieldsValue({...data});
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({"showInfo":true});
        console.log(selectedRow);
    }
    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
    }, []);

    const newRecord = () => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        setUiLabels({ "save": "Save" });
        showHideAllProps({ "new-record": "block", "records-list-table": "none" });
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none","records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/asset-management/assets/${selectedRow["id"]}`, "id": selectedRow.id}, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        showHideAllProps({"new-record": "none", "edit-selected-record": "none",  "records-list-table": "block" });
    }

    const onFinish = (values) => {
        values["value"] = "";
        values["description"] = ckEditorData;
        AxiosNetwork.axiosPut({ "url": "api/admin/pos/"+ theObject["id"] +"/update" }, values, recordsList, setRecordsList, newRecordCallback);
    }
    const newRecordCallback = (data) => {
        message.info('Asset Management has been created');
        posForm.resetFields();
        console.log(data);
    }
    const assetMangementFormFailed = () => {

    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id":currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id":currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const showOtherComponents = (sectionName) => {
        var toggleSections = {"supplier-products":"none","price-books": "none","customer-notifications": "none","customers": "none","consignment-product":"none","consignments":"none","suppliers":"none","supplier-product":"none","supplier-product-details": "none","product":"none","brands":"none","records-list-table": "none" };
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    const  newReport = () =>{
        
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['all-records']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll("all")} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },

                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin()} rel="noopener noreferrer">Bin</a>)
                            },
                            { label: (<a onClick={e => showOtherComponents("brands")} href="#">Brands</a>), "key": "brands" },
                            { label: (<a onClick={e => showOtherComponents("product")} href="#">Products</a>), "key": "product" },
                            { label: (<a onClick={e => showOtherComponents("product-type")} href="#">Product Types</a>), "key": "product-type" },
                            { label: (<a onClick={e => showOtherComponents("supplier-products")} href="#">Supplier Products</a>), "key": "supplier-products" },
                            { label: (<a onClick={e => showOtherComponents("suppliers")} href="#">Suppliers</a>), "key": "suppliers" },
                            { label: (<a onClick={e => showOtherComponents("consignments")} href="#">Consignments</a>), "key": "consignments" },
                            { label: (<a onClick={e => showOtherComponents("customers")} href="#">Customers</a>), "key": "customers" },
                            { label: (<a onClick={e => showOtherComponents("customer-notifications")} href="#">Customer Address</a>), "key": "customer-notifications" },
                            { label: (<a onClick={e => showOtherComponents("price-books")} href="#">Price Books</a>), "key": "price-books" },
                            { label: (<a onClick={e => window.location.href = window.location.origin + "/apps/pos" }>Outlets</a>), "key": "terminal" },
                            
                            { type: 'divider' },
                            {
                                key: 'report',
                                label: (<a onClick={(e) => newReport()} rel="noopener noreferrer">Report</a>)
                            },
                        ]} />

                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        {((presentObjects["brands"] == "block") &&
                            <section>
                                <PosBrand />
                            </section>
                        )}
                            {((presentObjects["product-type"] == "block") &&
                            <section>
                                <PosProductType />
                            </section>
                        )}
                           {((presentObjects["product"] == "block") &&
                            <section>
                                <PosProduct />
                            </section>
                        )}
                        {((presentObjects["supplier-products"] == "block") &&
                            <section>
                                <PosSupplierProduct/>
                            </section>
                        )}
                        {((presentObjects["suppliers"] == "block") &&
                            <section>
                                <PosSupplier/>
                            </section>
                        )}
                          {((presentObjects["customers"] == "block") &&
                            <section>
                                <PosCustomer/>
                            </section>
                        )}
                        {((presentObjects["customer-notifications"] == "block") &&
                            <section>
                                <PosCustomerAddress/>
                            </section>
                        )}
                      
                         {((presentObjects["consignments"] == "block") &&
                            <section>
                                <PosConsignment/>
                            </section>
                        )}
                      
                          {((presentObjects["price-books"] == "block") &&
                            <section>
                                <PosPriceBook/>
                            </section>
                        )}
                       
                        {((presentObjects["terminal"] == "block") &&
                            <section>
                                <PosOutlet/>
                            </section>
                        )}
                        
                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                        </section>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}