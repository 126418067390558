import { message, notification } from 'antd';
import axios, { Axios } from 'axios';
import { appProfile } from './Config';
const { JSONPath } = require('jsonpath-plus');


//axios.defaults.baseURL = "http://34.93.57.68:8086";
axios.defaults.baseURL = appProfile.serviceLocation;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Authorization'] = localStorage.getItem("rest-cloud-user-auth-token");
axios.defaults.headers.common['ngrok-skip-browser-warning'] = "any";
//axios.defaults.headers.common['Authorization'] = "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJyYXBoYS5pbyIsInN1YiI6InNhcmFuIiwiZXhwIjoxNjQ2Njk4MjEwLCJ1c2VyIjp7InBhc3N3b3JkIjoie2JjcnlwdH0kMmEkMTAkOFwvZWVncTFKQTJ4TS44V2VMZzJvTHVtaGhvOHR3NEsxb0loVjA2QXdWdDhqVDlyckhpQU1lIiwiY3JlZGVudGlhbHNOb25FeHBpcmVkIjp0cnVlLCJhY2NvdW50Tm9uRXhwaXJlZCI6dHJ1ZSwiYXV0aG9yaXRpZXMiOlt7fSx7fV0sImVuYWJsZWQiOnRydWUsInVzZXJuYW1lIjoic2FyYW4iLCJhY2NvdW50Tm9uTG9ja2VkIjp0cnVlfSwicm9sZXMiOiJST0xFX0FETUlOLFJPTEVfVVNFUiJ9.eGFWHxmwQ_XxPxOPDgnocOQNhrRhnd1EYyGwnk87UU4";
//axios.defaults.headers.common['Accept'] = 'application/json';
//axios.defaults.headers.common['Content-Type'] ='application/json';
//axios.defaults.withCredentials = true;
//axios.defaults.crossDomain = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';

console.log(appProfile)


export const AxiosNetwork = {

    onHello: function (values, networkProps) {
        console.log('Success:', values, networkProps);
        const instance = axios.create({ withCredentials: false })
        instance.get(networkProps.url, {})
            .then(function (response) {
                console.log(JSON.stringify(response));
            })
            .catch(function (error) {
                AxiosNetwork.handleError(error);
            });
    },


    onAuth: function (values, networkProps) {
        console.log('Success:', values, networkProps);
        const instance = axios.create({ withCredentials: false });
        instance.post(networkProps.url, {}, {
            "auth": {
                username: values["username"],
                password: values["password"]
            }
            })
            .then(function (response) {
                console.log(JSON.stringify(response));
                localStorage.setItem("rest-cloud-user-auth-token", response.headers["authorization"]);
                axios.defaults.headers.common['Authorization'] = localStorage.getItem("rest-cloud-user-auth-token");
                var recentApp = localStorage.getItem("rest-cloud-user-recent-app");
                window.location.href = ((recentApp == null) ? "/apps" : recentApp);
            })
            .catch(function (error) {
                //AxiosNetwork.handleError(error);
                //message.error("Sorry. Unable to login with the given credentails. Please try resetting your password.");
                notification.open({ message: 'Oops. Unable to login', description:'Sorry. Unable to login with the given credentails. Please try resetting your password.'}); });
    },

    onLogout: function (values, networkProps) {
        console.log('Success:', values, networkProps);
        const instance = axios.create({ withCredentials: false })
        instance.post(networkProps.url, {}, {
            "auth": {
                username: values["username"],
                password: values["password"]
            }
            })
            .then(function (response) {
                console.log(JSON.stringify(response));
                localStorage.setItem("rest-cloud-user-auth-token", response.headers["authorization"]);
                axios.defaults.headers.common['Authorization'] = localStorage.getItem("rest-cloud-user-auth-token");
                var recentApp = localStorage.getItem("rest-cloud-user-recent-app");
                window.location.href = ((recentApp == null) ? "/apps" : recentApp);
            })
            .catch(function (error) {
                console.log({...error});
                if(error.response==undefined) message.info("Unable to connect the server with the credentail.");
            });
    },

    axiosGetMulti: function (networkProps, currentData, setData, dataFetchCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                setData([...response.data]);
                if(dataFetchCallback != null) dataFetchCallback(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },


    axiosFetch: function (networkProps, setData, dataFetchCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                response.data.forEach(eachRow => eachRow["key"] = eachRow["id"]);
                setData([...response.data]);
                if(dataFetchCallback != null) dataFetchCallback(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosFetchCallback: function (networkProps, dataFetchCallback) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                response.data.forEach(eachRow => eachRow["key"] = eachRow["id"]);
                dataFetchCallback(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosFetchMono: function (networkProps, setData, dataFetchCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                setData(response.data);
                if(dataFetchCallback!=null) dataFetchCallback(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosFetchFields: function (networkProps, currentData, setData) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                currentData["customFields"] = response.data;
                console.log(currentData);
                setData(currentData);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosAppFetch: function (networkProps, setData) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                response.data.forEach(eachRow => eachRow["key"] = eachRow["id"]);
                for(var i=0; i<response.data.length; i++)
                {
                    localStorage.setItem("rest-cloud-apps-"+response.data[i]["type"], JSON.stringify(response.data[i]));
                    console.log(response.data[i]);
                }
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosGet: function (networkProps, currentData, setData, dataFetchCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                if(dataFetchCallback!=null)
                {
                    dataFetchCallback(response.data);
                }
                else
                {
                    setData({...response.data});
                }
            })
            .catch(function (error) {
                AxiosNetwork.handleError(error);
            });
    },

    axiosGetUpdate: function (networkProps, currentData, setData, dataList, setDataList) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(networkProps.url)
            .then(function (response) {
                dataList = dataList.filter((res) => res.id !== networkProps.id);
                dataList.push(response.data);
                setDataList(dataList);
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    

    axiosDelete: function (networkProps, dataList, setData, dataPostCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.delete(networkProps.url)
            .then(function(response) {
                console.log(networkProps.id);
                console.log(dataList);
                const deleteIndex = dataList.findIndex((obj => obj.id === networkProps.id));
                console.log(deleteIndex);
                if(deleteIndex!==-1) dataList.splice(deleteIndex, 1);
                setData([...dataList]);
                if(networkProps.refresh !== undefined) window.location.href = window.location.href;
                if(dataPostCallback!=null) dataPostCallback(dataList);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosPost: function (networkProps, values, currentData, setData, dataPostCallback=null) {
        this.addAdditionalParams(values);
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.post(networkProps.url, values)
            .then(function (response) {
                if(networkProps.unshift) currentData.unshift(response.data);
                else currentData.push(response.data);
                if(dataPostCallback!=null) dataPostCallback(response.data);
            })
            .catch(function (error) {
                AxiosNetwork.handleError(error);
            });
    },

    axiosFetchApprovedQuery: function (networkProps, values, setData) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.post(networkProps.url, values)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosFetchMulti: function(networkProps, values, setData, dataFetchCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.post(networkProps.url, values)
            .then(function(response) {
                if(dataFetchCallback!=null){
                    dataFetchCallback(response.data);
                }
                else
                {
                    setData([...response.data]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosFetchMultiProps: function(networkProps, values, field, dataFetchCallback) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.post(networkProps.url, values)
            .then(function(response) {
                dataFetchCallback(field, response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosUpdate: function (networkProps, currentData, dataList, setData) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.put(networkProps.url, currentData)
            .then(function (response) {
                const updateIndex = dataList.findIndex((obj => obj.id == networkProps.id));
                dataList[updateIndex] = response.data;
                setData([...dataList]);
            })
            .catch(function (error) {
                console.log(error);
            });
    },


    axiosRestore: function (networkProps, currentData, dataList, setData) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.put(networkProps.url, currentData)
            .then(function (response) {
                const updateIndex = dataList.findIndex((obj => obj.id == networkProps.id));
                dataList.splice(updateIndex, 1);
                setData([...dataList]);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosCommonRestore: function (networkProps, currentData, dataList, setData) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.put(networkProps.url, currentData)
            .then(function(response) {
                dataList = dataList.filter((res) => res.id !== currentData.id);
                setData([...dataList]);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    

    axiosPut: function (networkProps, currentData, dataList, setData, dataPostCallback=null) {
        this.addAdditionalParams(currentData);
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.put(networkProps.url, currentData)
            .then(function (response) {
                const updateIndex = dataList.findIndex((obj => obj.id == networkProps.id));
                if(updateIndex!=-1) { dataList[updateIndex] = response.data; } else { dataList.push(response.data); }
                setData([...dataList]);
                if(dataPostCallback!=null) dataPostCallback(response.data);
            })
            .catch(function (error) {
                AxiosNetwork.handleError(error);
            });
    },

    axiosPostMono: function (networkProps, values, setData, dataPostCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.post(networkProps.url, values)
            .then(function (response) {
                setData(response.data);
                if(dataPostCallback!=null) dataPostCallback(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    axiosPutMono: function (networkProps, payload, setData, dataPostCallback=null) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.put(networkProps.url, payload)
            .then(function (response) {
                setData(response.data);
                if(dataPostCallback!=null) dataPostCallback(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    downloadFileByFileAssetId: function (fileAssetId, fileName) {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = "api/file-assets/download/" + fileAssetId + "/" + fileName;
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.get(appProfile.serviceLocation + "api/file-assets/download/" + fileAssetId + "/" + fileName, {
            responseType: "blob",
        })
            .then((res) => {
                link.href = URL.createObjectURL(new Blob([res.data]));
                link.click();
            });
    },

    downloadMimeAsset: function(selectedRow) {
        console.log(selectedRow);
        var a = document.createElement('a');
        a.href = appProfile.serviceLocation + `api/file-assets/mime/download/${selectedRow.id}/${selectedRow.name}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },

    deleteMimeAsset: function(id, dataList, setData) {
        const instance = axios.create({
            headers: { "Authorization": localStorage.getItem("rest-cloud-user-auth-token") }
        })
        instance.delete(`api/file-assets/mime/delete/${id}`)
            .then(function(response) {
                const deleteIndex = dataList.findIndex((obj => obj.id == id));
                console.log(deleteIndex);
                if(deleteIndex!=-1) dataList.splice(deleteIndex, 1);
                setData([...dataList]);
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    addAdditionalParams: function(payload)
    {
        payload["domain"] = window.location.origin + "/";
        payload["source"] = appProfile.serviceLocation;
        delete payload["created_at"];
        delete payload["updated_at"];
        return payload;
    },

    handleError: function(error) {
        console.log(error.toJSON());
        let uiMessage = JSONPath({"path": "$..data..ui_message", "json": error});
        if(uiMessage.length == 0)  uiMessage = JSONPath({"path": "$..message", "json": error});
        message.info(uiMessage[0]);
        //notification.open({ message: 'Error Message', description: uiMessage[0], onClick: () => { console.log('Notification Clicked!'); } });
    }

}