import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Divider, Form, Input,Card, Row,Col,  Button,  message,  Table,  Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { generateFuzzyId } from "../../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave } from '@fortawesome/free-solid-svg-icons'

const {  Content } = Layout;
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-salary";
const tableName = "human-resource";
let ckEditorData = "";
var formData = {};

export default function HumanResourceSalary() {
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourcesalaryForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
      theObject["id"]= generateFuzzyId("salary")
      setUiLabels({ "save": "Save" });
      findAll();
    }, []);
   
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "employees" }, setRecordsList);
    }
    const humanresourcesalaryFailed = () => {
    }
    const editTableRow = (selectedRow) => {
      console.log(selectedRow);
      selectedRow["mime_assets"] = [];
      setTheObject({ ...selectedRow });
      humanresourcesalaryForm.setFieldsValue({ ...selectedRow });
      formData = {};
      formData["parent_id"] = selectedRow.id;
      AxiosNetwork.axiosFetch({ "url":`/api/human-resource/employees/salaries/`+ selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
    }
      const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/admin/employees/" +  theObject["id"] + "/salary/"+ "/update"}, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your salary details has been saved.');
        findAll();
    }
  
    const dataSource = [
        {
          key: '1',
          name: 'Gross salary',
          monthly: 0,
          yearly: 0,
        },
        {
          key: '2',
          name: 'Basic',
          monthly: 0,
          yearly: 0,
        },
        {
            key: '3',
            name: 'Driver salary',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '4',
            name: 'Newgen Pension Scheme',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '5',
            name: 'Food Coupons',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '6',
            name: 'LTA',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '7',
            name: 'Conveyance',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '8',
            name: 'Communication',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '9',
            name: 'Bonus',
            monthly: 0,
            yearly: 0,
          },  
          {
            key: '10',
            name: 'Books and Periodicals',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '11',
            name: 'Fuel',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '12',
            name: 'Special Allowance',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '13',
            name: 'HRA',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '14',
            name: 'Employee Pf',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '15',
            name: 'Total cost to company',
            monthly: 0,
            yearly: 0,
          },
          {
            key: '16',
            name: 'Total CTC',
            monthly: 0,
            yearly: 0,
          },
      ];

    const columnSchema = [
        {
            title: 'Pay component',
            dataIndex: 'name',
        },
        {
            title: 'Monthly',
            dataIndex: 'monthly',
        },
        {
            title: 'Yearly',
            dataIndex: 'yearly',
        },
        
    ];


    return (
        <section>
            <Layout className='contentLayout'>
               
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }}   columns={columnSchema} dataSource={dataSource} />
                    </section>
                    <section className="thCard" style={{ "width": 700, "display": presentObjects["salary-details"] }} >
                       <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanresourcesalaryForm} initialValues={theObject}
                            onFinishFailed={humanresourcesalaryFailed} autoComplete="off">
                            <Form.Item label="Employee Name" name="name"
                                rules={[{ required: true, message: 'Type  your employee name in this field' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Designation" name="designation"
                                rules={[{ required: true, message: 'Type  your designation in this field' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"> <FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                             </Form.Item>                          
                        </Form>
                     </section>
                </Layout>
        </section>
    )
  }