import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Divider, Form, Input, Space, Upload, Button, message, Card, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan,faSave, faEdit,faBackward} from '@fortawesome/free-solid-svg-icons'
import { generateFuzzyId } from "../../Config";
import moment from 'moment';

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-employee-payroll";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";
var nextTenYears = [];

export default function HumanResourceEmployeePayroll() {
    const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none","edit-selected-record":"none","personal-information": "none", "human-resource-holidays":"none","human-resource-employee-payroll":"none","human-resource-employer-documents": "none","edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourceEmployeePayrollForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [taxAssessmentsList, setTaxAssessmentsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("payrolls")
        setUiLabels({ "save": "Save" });
        var currentYear = new Date().getFullYear();
        for (var i = currentYear; i < currentYear + 10; i++) {
            nextTenYears.push(i);
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/shared-common/find-any-multi` }, { "doc_name": "tax_assessments" }, setTaxAssessmentsList);
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanResourceEmployeePayrollForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/admin/payrolls/`+ selectedRow.id, "id": selectedRow.id   }, theObject, setTheObject);
        showHideAllProps({"records-list-table":"none","button-controls-section":"block","employee-payroll-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
      }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/human-resource/admin/payrolls` }, setRecordsList);
        showHideAllProps({"records-list-table":"block","button-controls-section":"none","employee-payroll-details": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const humanResourceEmployeePayrollFailed = () => {
    }
    const onFinishFailed = () => {
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["name"]="";
        values["payroll_id"]="";
        AxiosNetwork.axiosPut({ "url": "api/human-resource/admin/payrolls/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your payroll details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const applyPayroll =  ( ) =>  {
        showHideAllProps({"records-list-table":"none","button-controls-section":"block","employee-payroll-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const columnSchema = [
        {
            title: 'Tax Assessment',
            dataIndex: 'tax_assessment_id',
        },
        {
            title: 'Year',
            dataIndex: 'year',
        },
        {
            title: 'Month',
            dataIndex: 'month',
        },
        {
            title: 'User',
            dataIndex: 'user_id',
        },
        {
            title: 'Status',
            dataIndex: 'status_code',
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];


    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "employee-payroll-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
           </section>
               <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyPayroll()}> New  Payroll  </Button>

                    </section>
                <section className="thCard" style={{ "width": 500, "display": presentObjects["employee-payroll-details"] }} >
                            <Form size="large" labelAlign="right" layout="vertical"
                            name="basic"
                            onFinish={onFinish}
                            form={humanResourceEmployeePayrollForm} initialValues={theObject}
                            onFinishFailed={humanResourceEmployeePayrollFailed} autoComplete="off">
                            <Form.Item label="Tax Assessments" name="tax_assessment_id">
                                <Select  showSearch  allowClear style={{ width: '100%' }} placeholder="Change  Tax Assessments">
                                    {taxAssessmentsList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Year" name="year" >
                                <Select  showSearch  allowClear>
                                    {nextTenYears.map(eachYear =>
                                        <Select.Option value={eachYear}>{eachYear}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Month of the Year" name="month" >
                                <Select  showSearch  allowClear>
                                    <Select.Option value={1}>January</Select.Option>
                                    <Select.Option value={2}>February</Select.Option>
                                    <Select.Option value={3}>March</Select.Option>
                                    <Select.Option value={4}>April</Select.Option>
                                    <Select.Option value={5}>May </Select.Option>
                                    <Select.Option value={6}>June</Select.Option>
                                    <Select.Option value={7}>July</Select.Option>
                                    <Select.Option value={8}>August </Select.Option>
                                    <Select.Option value={9}>September</Select.Option>
                                    <Select.Option value={10}>October</Select.Option>
                                    <Select.Option value={11}>November</Select.Option>
                                    <Select.Option value={12}>December</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                            </Form.Item>
                        </Form>
                    </section>
                </Layout>
        </section>
    )
}