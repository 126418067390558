import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faInfoCircle, faSave, faListSquares, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { appProfile, dateTimeUtils, generateFuzzyId, menuBarItems, profileBarItems } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment';
import { Layout, Menu, Typography, Space, Row, Button, Radio, Form, InputNumber, Input, Upload, message, Select, DatePicker, TimePicker, Drawer, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import { PlusOutlined } from '@ant-design/icons';
import * as EmailValidator from 'email-validator';
import queryString from 'query-string';


const queryParams = queryString.parse(window.location.search);
const { RangePicker } = TimePicker;
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 62;
const appPath = "meetings";
const tableName = "meetings";
var activeDataColumns = [];
var nextTenYears = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };
var scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment().startOf("day").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment().endOf("day").format(dateTimeUtils.dateTimeFormat) }];
var calendarView = { "view": { "type": "timeGridWeek" }, "calendarKey": "1" };

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export default function MiniCalendar(props) {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [guestList, setGuestList] = useState([]);
    const [inboxList, setInboxList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [timezonesList, setTimezonesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "setNewCalendarDrawer":false,"reminderRecurNo": true, "reminderRecurYes": true, "reminderRecurEndAfter": true, "reminderRecurEndDate": true, "new-schedules": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [newCalendarDrawer, setNewCalendarDrawer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [meetingForm] = Form.useForm();
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [keyboard, setKeyboard] = useState(true);
    

    if(props.appDoc!==undefined)
    {
        queryParams["doc_id"] = props.appDoc.id;
    }
    
    useEffect(() => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": "/api/common/folders/appid/" + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": "/api/common/labels/appid/" + appId }, setLabelList);
        AxiosNetwork.axiosFetch({ "url": `api/emails/inboxes` }, setInboxList, afterInboxFetchCallback);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/timezones` }, setTimezonesList);
        var currentYear = new Date().getFullYear();
        for (var i = currentYear; i < currentYear + 10; i++) {
            nextTenYears.push(i);
        }
        findAll();
        if (props.presentObjects !== undefined) showHideAllProps({ ...props.presentObjects })
    }, []);

    const afterInboxFetchCallback = (inboxes) => {
        if (inboxes.length == 0) return;
        meetingForm.setFieldsValue({ "inbox_id": inboxes[0]["name"], "guest_permission": "Modify event" });
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);

    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findAll = (filter) => {
        var filters=[];
        if(props.appDoc!==undefined)
        {
            filters.push({ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": props.appDoc.id });
            AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters":filters}, setRecordsList, afterReadingEvents);
        }
        if(props.appDoc === undefined)
        {
            AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters": scheduleFilter }, setRecordsList, afterReadingEvents);
        }
    }

    const afterReadingEvents = (data) => {
        var modifiedEvents = [];
        data.forEach(eachEventx => {
            var eachEvent = {};
            eachEvent["title"] = eachEventx["name"];
            //eachEvent["date"] = moment(eachEvent["created_at"]).format("YYYY-MM-DD");
            eachEvent["start"] = moment(eachEventx["start_at"]).format("YYYY-MM-DD HH:mm:ss");
            eachEvent["end"] = moment(eachEventx["end_at"]).format("YYYY-MM-DD HH:mm:ss");
            console.log(eachEvent);
            modifiedEvents.push(eachEvent);
        });
        setRecordsList(modifiedEvents);
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["doc_id"] = queryParams["doc_id"];
        console.log(values);
        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD HH:mm:ss");
        if (values["due_at"] !== undefined) values["due_at"] = moment(values["due_at"]).format("yyyy-MM-DD HH:mm:ss");
        AxiosNetwork.axiosPost({ "url": `api/meetings/`+ theObject.id +`/upsert` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }

    const afterNewRecordSubmit = () => {
        message.info('Your schedules has been saved.');
        findAll();
    }

    const dateAreaClicked = (dayClickEvent) => {
        setUiLabels({ "save": "Save" });
        console.log("dateAreaClicked", dayClickEvent);
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "setNewCalendarDrawer": true });
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
              <b>{eventInfo.timeText}</b>
              <i>{eventInfo.event.title}</i>
            </>
          )
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        setTheObject({ ...selectedRow });
        if (selectedRow["start_at"] != undefined) selectedRow["start_at"] = moment(selectedRow["start_at"]);
        if (selectedRow["end_at"] != undefined) selectedRow["end_at"] = moment(selectedRow["end_at"]);
        if (selectedRow["due_at"] != undefined) selectedRow["due_at"] = moment(selectedRow["due_at"]);
        meetingForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        showHideAllProps({ "setNewCalendarDrawer": true });
    }

    const onEventClicked = (eventInfo) => {
        console.log("onEventClicked", eventInfo);
        const eventId = eventInfo["event"]["_def"]["publicId"];
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + eventId, "id": eventId }, theObject, setTheObject, editTableRow);
    }


    const handleEvents = (eventInfo) => {
        console.log("handleEvents", eventInfo);
    }

    const meetingFormFailed = () => {

    }

    const recurOptionChanged = (recur) => {
        if (recur == true) {
            showHideAllProps({ "reminderRecurYes": false, "reminderRecurNo": true });
        }
        else {
            showHideAllProps({ "reminderRecurYes": true, "reminderRecurNo": false });
        }
    }

    const endsOptionChanged = (ends) => {
        ends = ends.toUpperCase();
        if (ends == "ENDS DATE") {
            showHideAllProps({ "reminderRecurEndAfter": true, "reminderRecurEndDate": false });
        }
        else if (ends == "ENDS AFTER") {
            showHideAllProps({ "reminderRecurEndAfter": false, "reminderRecurEndDate": true });
        }
        else {
            showHideAllProps({ "reminderRecurEndAfter": true, "reminderRecurEndDate": true });
        }
    }
    
    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    }
    

    function disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }

    let ckEditorData = "";

    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const findEmailAddressLike = (value) => {
        if (value === null || value === "") return;
        setGuestList([...[]]);
        var payload = { "select": ["id", "name","email"], "doc_name": "contacts", "col_name": "email", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi-like` }, payload, setGuestList, setEmailAddressListCallBack);
    }


  
    const setEmailAddressListCallBack = (data) => {
        setGuestList([...data]);
    }


    const fullCalendarViewRender = (event) => {
        console.log("fullCalendarViewRender", event);
    }

    const fcDateSetClicked = (event) => {
        console.log(event);
        if ((calendarView["startStr"] == event.startStr) && (calendarView["endStr"] == event.endStr)) return;
        calendarView["calendarKey"] = event.startStr + "" + calendarView["endStr"];
        console.log("fcDateSetClicked 1", event);
        calendarView = event;
        if (calendarView.view.type == "timeGridMonth") {
            scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment(event.start).startOf("month").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment(event.end).endOf("month").format(dateTimeUtils.dateTimeFormat) }];
        }
        else if (calendarView.view.type == "timeGridWeek") {
            scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment(event.start).startOf("week").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment(event.end).endOf("week").format(dateTimeUtils.dateTimeFormat) }];
        }
        else {
            scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment(event.start).startOf("day").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment(event.end).endOf("day").format(dateTimeUtils.dateTimeFormat) }];
        }
        if(queryParams["doc_id"]!==undefined)
        {
            scheduleFilter.push({ "col_name": "doc_id", "data_type": "string", "operator": "=", "string_value": queryParams["doc_id"] });
        }

        findAll();
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout className='contentLayout'>
             
                    <FullCalendar
                        key={calendarView.calendarKey}
                        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                        initialView={calendarView.view.type}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        dateClick={dateAreaClicked}
                        weekends={weekendsVisible}
                        customButtons={{
                            myCustomButton: {
                                text: 'custom!',
                                click: function () {
                                    alert('clicked the custom buttonxx!');
                                }
                            }
                        }}
                        headerToolbar={{
                            left: 'prev,next,today,myCustomButton',
                            center: 'title',
                            right: 'timeGridDay,timeGridWeek,dayGridMonth'
                        }}
                        datesSet={fcDateSetClicked}
                        events={recordsList} // alternatively, use the `events` setting to fetch from a feed
                        eventContent={renderEventContent} // custom render function
                        eventClick={onEventClicked}
                        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                        eventAdd={function () { }}
                        eventChange={function () { }}
                        eventRemove={function () { }}
                        viewRender={fullCalendarViewRender}
                    />
               

                <Drawer title={uiLabels.title} placement="right" width={720} onClose={(e) =>  showHideProps("setNewCalendarDrawer", false)} open={presentObjects["setNewCalendarDrawer"]}>
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                        onFinish={onFinish} form={meetingForm} onFinishFailed={meetingFormFailed}
                        autoComplete="off" initialValues={theObject}>
                        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Type the name for your events or tasks' }]}>
                            <Input />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Start at" name="start_at">
                                    <DatePicker style={{ width: '100%' }} format={dateTimeUtils.dateTimeFormat} showTime={true} onOk={onOk} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="End at" name="end_at">
                                    <DatePicker style={{ width: '100%' }} format={dateTimeUtils.dateTimeFormat} showTime={true} onOk={onOk} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Recur" name="recur">
                                    <Select showClear allowClear defaultValue={false} onChange={(e) => recurOptionChanged(e)}>
                                        <Select.Option value={true}>Yes</Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurNo"]} label="Due At" name="due_at">
                                    <DatePicker showTime={{ format: 'HH:mm' }} onChange={onChange} onOk={onOk} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]} label="Hour of the Day" name="hour">
                                    <Select showClear allowClear mode="tags">
                                        {[...Array(24)].map((eachValue, index) =>
                                            <Select.Option value={index + 1}>{(index + 1)}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]} label="Minute of the Hour" name="minute"  >
                                    <Select showClear allowClear mode="tags">
                                        {[...Array(60)].map((eachValue, index) =>
                                            <Select.Option value={index + 1}>{(index + 1)}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]} label="Day of the Month" name="date"  >
                                    <Select showClear allowClear mode="tags">
                                        {[...Array(31)].map((eachValue, index) =>
                                            <Select.Option value={index + 1}>{(index + 1)}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]} label="Day of the Week" name="day" >
                                    <Select showClear allowClear mode="tags">
                                        <Select.Option value={0}>Sunday</Select.Option>
                                        <Select.Option value={1}>Monday</Select.Option>
                                        <Select.Option value={2}>Tuesday</Select.Option>
                                        <Select.Option value={3}>Wednesday </Select.Option>
                                        <Select.Option value={4}>Thursday</Select.Option>
                                        <Select.Option value={5}>Friday</Select.Option>
                                        <Select.Option value={6}>Saturday</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]} label="Month of the Year" name="month" >
                                    <Select showClear allowClear mode="tags">
                                        <Select.Option value={1}>January</Select.Option>
                                        <Select.Option value={2}>February</Select.Option>
                                        <Select.Option value={3}>March</Select.Option>
                                        <Select.Option value={4}>April</Select.Option>
                                        <Select.Option value={5}>May </Select.Option>
                                        <Select.Option value={6}>June</Select.Option>
                                        <Select.Option value={7}>July</Select.Option>
                                        <Select.Option value={8}>August </Select.Option>
                                        <Select.Option value={9}>September</Select.Option>
                                        <Select.Option value={10}>October</Select.Option>
                                        <Select.Option value={11}>November</Select.Option>
                                        <Select.Option value={12}>December</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]} label="Year" name="year" >
                                    <Select showClear allowClear mode="tags">
                                        {nextTenYears.map(eachYear =>
                                            <Select.Option value={eachYear}>{eachYear}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]} label="Ends" name="end_based_on">
                                    <Select showClear allowClear onChange={(e) => endsOptionChanged(e)}>
                                        <Select.Option value="Never">Never</Select.Option>
                                        <Select.Option value="Ends Date">Ends Date</Select.Option>
                                        <Select.Option value="Ends After">Ends After</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item hidden={presentObjects["reminderRecurEndDate"]} label="Ends Date" name="end_date">
                                    <DatePicker style={{ width: '100%' }} format={dateTimeUtils.dateFormat} onOk={onOk} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item hidden={presentObjects["reminderRecurEndAfter"]}
                            label="Ends After" name="end_number">
                            <InputNumber min={1} max={20} keyboard={keyboard} defaultValue={1} />
                        </Form.Item>
                       
                        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Type theDescription' }]}>
                            <CKEditor
                                onReady={editor => {
                                    console.log('Editor is ready to use!', editor);
                                    // Insert the toolbar before the editable area.
                                    editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                }}
                                onError={(error, { willEditorRestart }) => {
                                    if (willEditorRestart) {
                                        this.editor.ui.view.toolbar.element.remove();
                                    }
                                }}
                                onChange={(event, editor) => {
                                    ckEditorData = editor.getData();
                                    console.log(ckEditorData);
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                                editor={DecoupledEditor}
                                data={((theObject["description"] != undefined) ? theObject["description"] : "")}

                            />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Guests" name="guests" rules={[{ required: false, message: 'Please select meeting guests' }]}>
                                    <Select showSearch allowClear mode="tags" onSearch={findEmailAddressLike} placeholder="Select Guests">
                                        {guestList.map(eachItem => (
                                            <Option key={eachItem.id} value={eachItem.email}>{eachItem.email}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Timezone" name="timezone_id" >
                                    <Select showSearch allowClear placeholder="Select Timezones">
                                        {timezonesList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Location" name="location" rules={[{ required: true, message: ' Choose your location by using the app ' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Uploader" name="uploader">
                                    <Upload {...fileUploadProps}>
                                        <Button className='uploadBtn' icon={<FontAwesomeIcon icon={faCloudUploadAlt} />}> &#160;&#160;File upload</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Guest Permission" name="guest_permission" >
                                    <Select showSearch allowClear defaultValue={"Modify event"}>
                                        <Select.Option value="Modify event">Modify Event</Select.Option>
                                        <Select.Option value="Invite others">Invite Others</Select.Option>
                                        <Select.Option value="See guest list">See Guest</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="inbox_id" label="Inbox" rules={[{ required: true, message: 'Select the inbox ' }]}>
                                    <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Inbox">
                                        {inboxList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>

                    </Form>
                </Drawer>
            
            </Layout>
        </Layout>
    )
}