import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faLock, faUnlock, faCloudArrowDown, faCopy,faSave, faUndoAlt, faBackward, faEdit, faInfoCircle, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { appProfile, generateFuzzyId, menuBarItems, profileBarItems } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Typography, Drawer,Row, Upload, Button, Table, Form, Input, message, Select, Space, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import moment from 'moment-timezone';
import copy from 'copy-to-clipboard';

const { Dragger } = Upload;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 82;
const appPath = "guidelines";
const tableName = "guidelines";
var activeDataColumns = [];
var applicationListGlobal = [];


//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const cdnFileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/cdn-file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};



export default function Guideline() {
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none", "new-guideline": "none", "edit-selected-record": "none", "records-list-table": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [guidelineForm] = Form.useForm();
    const [theObject, setTheObject] = useState({ "application": "EMAIL", "mime_assets": [], "body": "Type your content here" });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetch({ "url": `api/common/apps` }, setApplicationList, afterApplicationListLoaded);
        findAll();
    }, []);
    const afterApplicationListLoaded = (data) => {
        data.sort(function (a, b) { return ((a.name > b.name) ? 1 : -1); });
        applicationListGlobal = [...data];
        setApplicationList([...data]);
        findAll();
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Application',
            dataIndex: 'app_id',
            width:200
        },
        {
            title: 'Modified At',
            width: 150,
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            key:"edit",
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width:60
        },
        {
            key:"trash",
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width:60
        },
        {
            key: "info",
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width:60
        },
        {
            key: "restore",
            title: 'Restore',
            width: 60,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const mimeAssetDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Access',
            render: (record) => <span>{((record.access == 0) ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />)}</span>
        },
        {
            title: 'Uploaded At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Action',
            render: (record) => <a href={appProfile.serviceLocation + "api/file-assets/download/" + record.id + "/" + record.name}><FontAwesomeIcon icon={faCloudArrowDown} /></a>
        },
        {
            title: 'Copy',
            render: (record) => <a onClick={e => copy(appProfile.serviceLocation + "auth/file-assets/view/" + record.id + "/" + record.name)}><FontAwesomeIcon icon={faCopy} /></a>
        }
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const findAll = (filter) => {
        activeDataColumns = columnSchema.filter(item => !["restore"].includes(item.key));
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "button-controls-section":"none","new-guideline": "none", "records-list-table": "block" });
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        guidelineForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, afterReadingGuideline);
        showHideAllProps({ "button-controls-section":"block","new-guideline": "block", "edit-selected-record": "none", "records-list-table": "none" });
    }
    const afterReadingGuideline = (data) => {
        data["mime_assets"] = [...data["mime_assets"], ...data["cdn_file_assets"]];
        setTheObject({ ...data });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": `/api/${tableName}/update/` + theObject["id"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const guidelineFormFailed = () => {

    }
    const afterNewRecordSubmit = () => {
        message.info('Your guidelines has been saved.');
        findAll();
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-guideline": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-guideline": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
 
    const findFromBin = (filter) => {
        activeDataColumns = columnSchema.filter(item => !["trash", "edit", "info"].includes(item.key));
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "new-guideline": "none", "records-list-table": "block" });
    }
    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        const newRowId = generateFuzzyId(tableName);
        setTheObject({ "id": newRowId, "mime_assets": [] });
        formData = {};
        formData["parent_id"] = newRowId;
        showHideAllProps({ "edit-selected-record": "none", "new-guideline": "block", "records-list-table": "none" })
    }

    let ckEditorData = "";

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>
                        <section style={{ "display": presentObjects["button-controls-section"] }}>
                            <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                                <Button onClick={(e) => showHideAllProps({ "new-guideline": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                            </Space>
                        </section>
                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        </section>
                        <section className="thCard" style={{ "width": 700, "display": presentObjects["new-guideline"] }} >
                            <Form size="large" labelAlign="right" layout="vertical" name="basic"
                                onFinish={onFinish} form={guidelineForm} onFinishFailed={guidelineFormFailed}
                                autoComplete="off" initialValues={theObject}>
                                 <Row gutter={16}>
                                    <Col span={12}>
                                <Form.Item label="Username" name="name" rules={[{ required: true, message: 'Please input label name' }]}>
                                    <Input />
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="Application" name="app_id" rules={[{ required: true, message: 'Enter the application' }]}>
                                    <Select showSearch style={{ width: '100%' }} placeholder="Select Application" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {applicationList.map(eachItem => (
                                            <Select.Option key={eachItem["id"]}>{eachItem["name"]}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Type theDescription' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                        data={((theObject["description"] != undefined) ? theObject["description"] : "")}
                                    />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={12} style={{ marginBottom: "50px" }}>
                                        <Form.Item label="Uploader" name="uploader">
                                            <Dragger {...fileUploadProps}>
                                                <p className="ant-upload-drag-icon">
                                                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                                                </p>
                                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ marginBottom: "50px" }}>
                                        <Form.Item label="Upload Files" name="uploadFiles">
                                            <Dragger {...cdnFileUploadProps}>
                                                <p className="ant-upload-drag-icon">
                                                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                                                </p>
                                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                            
                            <Table size="small" rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={mimeAssetDataColumns} dataSource={theObject["mime_assets"]} />
                            
                            </section>
                            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} appType={appId} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>

                         
                     
                </Layout>
            </Layout>
        </Layout>
    )
}