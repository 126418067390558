import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEdit, faUndoAlt, faInfoCircle, faSave, faLock, faBackward, faPlus, faUnlock, faCloudArrowDown, faCopy, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { appProfile, generateFuzzyId, profileBarItems, menuBarItems } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { Layout, Menu, Typography, Row, Button, Table, Form, Input, Space, Upload, message, Select, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';
import copy from 'copy-to-clipboard';

const { Dragger } = Upload;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = "notes";
const appPath = "notes";
const tableName = "notes";
var activeDataColumns = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };
var applicationListGlobal = [];
let ckEditorData = "";

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


export default function Note(props) {
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "description": "", "mime_assets": [] });
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "new-record": "none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "composeNote": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [noteForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/` + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/` + appId }, setLabelList);
        AxiosNetwork.axiosFetch({ "url": `api/common/apps` }, setApplicationList, afterApplicationListLoaded);
        composeNote();
        findAll();
        if (props.presentObjects !== undefined) showHideAllProps({ ...props.presentObjects })
    }, []);
    const afterApplicationListLoaded = (data) => {
        data.sort(function (a, b) { return ((a.name > b.name) ? 1 : -1); });
        applicationListGlobal = [...data];
        setApplicationList([...data]);
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Application',
            dataIndex: 'app_id',
            width: 200
        },
        {
            title: 'Modified At',
            width: 150,
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];
    const mimeAssetDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Access',
            render: (record) => <span>{((record.access == 0) ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />)}</span>
        },
        {
            title: 'Uploaded At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Action',
            render: (record) => <a href={appProfile.serviceLocation + "api/file-assets/download/" + record.id + "/" + record.name}><FontAwesomeIcon icon={faCloudArrowDown} /></a>
        },
        {
            title: 'Copy',
            render: (record) => <a onClick={e => copy(appProfile.serviceLocation + "auth/file-assets/view/" + record.id + "/" + record.name)}><FontAwesomeIcon icon={faCopy} /></a>
        }
    ];


    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, afterReadingNote);
    }
    const afterReadingNote = (data) => {
        data["mime_assets"] = [...data["mime_assets"], ...data["cdn_file_assets"]];
        setTheObject({ ...data });
        noteForm.setFieldsValue({ ...data });
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "edit-selected-record": "block", "new-record": "block" });

    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "button-controls-section": "block", "edit-selected-record": "none", "new-record": "block", "records-list-table": "none", });
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }


    const deleteFolder = (currentFolder) => {
        console.log(currentFolder);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/folder/` + currentFolder.id, "id": currentFolder.id }, folderList, setFolderList);
    }
    const deleteLabel = (currentLabel) => {
        console.log(currentLabel);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/label/` + currentLabel.id, "id": currentLabel.id }, labelList, setLabelList);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const findFromBin = (filter) => {
        activeDataColumns = columnSchema.filter(item => !["trash", "edit", "info"].includes(item.key));
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.filter(item => !["restore"].includes(item.key));
        var filters=[];
        if(props.appDoc!==undefined)
        {
            filters.push({ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": props.appDoc.id });
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters":filters}, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "new-record": "none", "records-list-table": "block" });
    }
    const composeNote = () => {
        showHideProps("composeNote", "block");
        theObject["emailAddress"] = [];
        formData = {};
        dataMassage["noteId"] = "note-" + uuid();
        formData["parent_id"] = dataMassage["noteId"]
        formData["folder_id"] = dataMassage["noteId"];
    };
    const onFinish = (values) => {
        console.log(values);
        values["description"] = ckEditorData;
        if(props.appDoc!==undefined) values["doc_id"] = props.appDoc.id;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": `/api/${tableName}/update/` + theObject["id"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values)
    }
    const afterNewRecordSubmit = () => {
        message.info('Your note has been saved.');
        findAll();
    }
    const noteFormFailed = () => {
    }

    return (
        <Layout>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>

            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" pagination={false} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                <div style={{ marginTop: 10 }}>
                    <Space size={10}>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => newRecord()}><FontAwesomeIcon icon={faPlus} />Notes</Button>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => findFromBin()}> Bin </Button>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => findAll()}> All </Button>

                    </Space>
                </div>
            </section>
            <section style={{ "display": presentObjects["edit-selected-record"] }}>
            </section>
            <section className="thCard" style={{ "width": 700, "display": presentObjects["new-record"] }} >
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onFinish} form={noteForm} onFinishFailed={noteFormFailed}
                    autoComplete="off" initialValues={theObject}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Enter your task' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Type" name="app_id" rules={[{ required: true, message: 'Enter the application' }]}>
                                <Select showSearch style={{ width: '100%' }} placeholder="Select Application" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                    {applicationList.map(eachItem => (
                                        <Select.Option key={eachItem["id"]}>{eachItem["name"]}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Description" name="description"
                        rules={[{ required: true, message: 'Type theDescription' }]}>
                        <CKEditor
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                                editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                            }}
                            onError={(error, { willEditorRestart }) => {
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                console.log(ckEditorData);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                            editor={DecoupledEditor}
                            data={((theObject["description"] != undefined) ? theObject["description"] : "")}

                        />
                    </Form.Item>
                    <Row>
                        <Col style={{ marginBottom: "50px" }}>
                            <Form.Item label="Uploader" name="uploader">
                                <Dragger {...fileUploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
                <Table size="small" rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={mimeAssetDataColumns} dataSource={theObject["mime_assets"]} />
                </section>
            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>
        </Layout>
    )
}
