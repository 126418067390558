import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faSave, faBackward, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, profileBarItems, generateFuzzyId } from "../../Config";
import '../../App.css';
import moment from 'moment';
import { Layout, Menu, Typography, Tabs, Table, Button, Space,Row, Form, Input, Card, message, Select, DatePicker, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import CustomFieldEmbedEditor from "../../custom-field/CustomFieldEmbedEditor";
import HumanResourceEmployerDocuments from "./HumanResourceEmployerDocuments";
import HumanResourceEmployeeDocuments from "./HumanResourceEmployeeDocuments";
import HumanResourceEmployeeSalary from "./HumanResourceEmployeeSalary";
import HumanResourceEmployeeBasic from "./HumanResourceEmployeeBasic";
import HumanResourceEmployeePayroll from "./HumanResourceEmployeePayroll";
import HumanResourceHolidays from "./HumanResourceHolidays";
import HumanResourceRelation from "./HumanResourceRelation";

const { TextArea } = Input;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 88;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-admin";
const tableName = "human-resource";
var activeDataColumns = [];
const style = { padding: '8px 0' };
let ckEditorData = "";
var selectedEmployee = null;
const infiniteLoop = [];


export default function HumanResourceAdmin() {
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [], "upsert": true });
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "human-resource-holidays": "none", "human-resource-employee-payroll": "none", "human-resource-employer-documents": "none", "records-list-table": "block", "personal-information": "none", "composeHumanResource": "none", "human-resource-employee": "none", "human-resource-leave-mine": "none", "human-resource-leave-my-team": "none", "human-resource-leave": "none", "human-resource-dashboard": "block", "human-resource-document": "none", "human-resource-salary": "none", "human-resource-kye": "none", "human-resource-bank": "none", "human-resource-previous-employment": "none", "human-resource-nomination": "none", "human-resource-basic": "none", "human-resource-contact": "none", "human-resource-position": "none", "human-resource-dependent": "none", "edit-selected-record": "none", "new-ticket": "block", "newStatusDrawer": false, "newLabelDrawer": false, "newFolderDrawer": false, "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [employmentTypeList, setEmploymentTypeList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [jobTitleList, setJobTitleList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [jobCategoryList, setJobCategoryList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [humanresourceadminForm] = Form.useForm();
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "configurations", "range": [{ "col_name": "app_type", "operator": "=", "data_type": "integer", "integer_value": 88 }] }, dispatchConfigurations);
        findAll();
    }, []);

    const dispatchConfigurations = (data) => {
        setEmploymentTypeList(data.filter(eachItem => eachItem["genre"] == "employee-employment-type"));
        setJobTitleList(data.filter(eachItem => eachItem["genre"] == "employee-job-title"));
        setJobCategoryList(data.filter(eachItem => eachItem["genre"] == "employee-job-category"));
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Employee Id',
            dataIndex: 'employee_id',
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "employees", "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "records-list-table": "block", "personal-information": "none", "human-resource-dashboard": "none" });
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `api/human-resource/admin/employees` }, {}, setRecordsList);
        showHideAllProps({"button-controls-section":"none", "edit-selected-record": "none", "human-resource-holidays": "none", "human-resource-employee-payroll": "none", "human-resource-employer-documents": "none", "personal-information": "none", "records-list-table": "block", "human-resource-dashboard": "none" });
    }
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        humanresourceadminForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/admin/employees/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, loadEmployeeDetails);
    }
    const loadEmployeeDetails = (data) => {
        selectedEmployee = { ...data };
        setTheObject({ ...data });
        showHideAllProps({"button-controls-section":"none", "personal-information": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }   
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const onFinish = (values) => {
        console.log(values);
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["user_uid"] = "000";
        values["job_category"] = "EMPLOYEE";
        values["blood_group"] = "A+";
        values["birth_at"] = moment(values["birth_at"]).format("yyyy-MM-dd");
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/admin/employees/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your human resource has been saved.');
        findAll();
    }
    const humanresourceadminFailed = () => {

    }
    const onFinishFailed = () => {

    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "employees", "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "records-list-table": "block", "human-resource-dashboard": "none" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "employees", "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "records-list-table": "block", "human-resource-dashboard": "none" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": "employees", "id": selectedRow.id }, recordsList, setRecordsList);
        showHideAllProps({ "records-list-table": "block", "human-resource-dashboard": "none" });
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": "employees", "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );

    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId("employees"), "mime_assets": [] });
        showHideAllProps({ "button-controls-section":"block","human-resource-dashboard": "none", "personal-information": "block", "id-information": "block", "records-list-table": "none" })
    }
    const showOtherComponents = (sectionName) => {
        var toggleSections = { "human-resource-relation":"none","edit-selected-record": "none", "human-resource-holidays": "none", "human-resource-employee-payroll": "none", "records-list-table": "none", "human-resource-employer-documents": "none" };
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }
    const  newReport = () =>{
        
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                label: "Employees", "key": "human-resource-menu", children: [
                                    {
                                        key: 'New',
                                        label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                                    },
                                    {
                                        key: 'all-records',
                                        label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                                    },
                                    {
                                        key: 'sub-folders',
                                        label: (<a onClick={(e) => findAll()}>Folders</a>),
                                        children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                                    },
                                    {
                                        key: 'sub-labels',
                                        label: (<a onClick={(e) => findAll()}>Labels</a>),
                                        children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                                    },
                                    {
                                        key: 'bin-records',
                                        label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                                    }
                                ]
                            },
                            {
                                key: "human-resource-employer-documents",
                                label: (<a onClick={e => showOtherComponents("human-resource-employer-documents")} href="#"> Employer Documents </a>)
                            },
                            {
                                key: "human-resource-employee-payroll",
                                label: (<a onClick={e => showOtherComponents("human-resource-employee-payroll")} href="#"> Employee Payroll</a>)
                            },
                            {
                                key: "human-resource-holidays",
                                label: (<a onClick={e => showOtherComponents("human-resource-holidays")} href="#"> Holidays</a>)
                            },
                            { type: 'divider' },
                            {
                                key: "human-resource-mine",
                                label: (<a onClick={e => window.location.href = window.location.origin + "/apps/human-resource"} href="#">My Details</a>)
                            },
                            { type: 'divider' },
                            {
                                key: 'report',
                                label: (<a onClick={(e) => newReport()} rel="noopener noreferrer">Report</a>)
                            },
                        ]} />
                </Sider>
                    <Layout className='contentLayout'>
                        <section style={{ "display": presentObjects["human-resource-dashboard"] }}>
                            <div className="site-card-wrapper">
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Card title="Announcement" bordered={false}>

                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Holiday" bordered={false}>

                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Leave Requests" bordered={false}>

                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Exit Approval" bordered={false}>

                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </section>

                        {(presentObjects["human-resource-employer-documents"] == "block") &&
                            <section>
                                <HumanResourceEmployerDocuments />
                            </section>
                        }
                        {(presentObjects["human-resource-employee-payroll"] == "block") &&
                            <section>
                                <HumanResourceEmployeePayroll />
                            </section>
                        }
                        {(presentObjects["human-resource-holidays"] == "block") &&
                            <section>
                                <HumanResourceHolidays />
                            </section>
                        }
                           <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "personal-information": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        </section>

                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                            <div>
                                <Row>
                                    <Col>{theObject.name}</Col> &#x2002;
                                    <Col>{theObject.last_name}</Col>&#x2002;
                                    <Col>{theObject.personal_email}</Col>&#x2002;
                                </Row>
                            </div>

                            {(selectedEmployee != null) && (
                                <Tabs defaultActiveKey="1"
                                    items={[
                                        { "label": "Info", "key": "basic-info-" + new Date().getTime(), children: <HumanResourceEmployeeBasic employee={theObject} /> },
                                        { "label": "Salary", "key": "salary-" + new Date().getTime(), children: <HumanResourceEmployeeSalary employee={theObject} /> },
                                        { "label": "Documents", "key": "documents-" + new Date().getTime(), children: <HumanResourceEmployeeDocuments employee={theObject} /> },
                                        { "label": "Relation", "key": "relations-" + new Date().getTime(), children: <HumanResourceRelation employee={theObject} /> },

                                    ]}
                                />
                            )}
                        </section>
                        <section className="thCard" style={{ "width": 700, "display": presentObjects["personal-information"] }} >
                            <Form size="large" labelAlign="right" layout="vertical"
                                name="basic"
                                onFinish={onFinish}
                                form={humanresourceadminForm} initialValues={theObject}
                                onFinishFailed={humanresourceadminFailed} autoComplete="off">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Salutation" name="salutation" >
                                            <Select>
                                                <Select.Option value="Mr">Mr</Select.Option>
                                                <Select.Option value="Mrs">Mrs</Select.Option>
                                                <Select.Option value="Miss">Miss</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="First Name" name="name"
                                            rules={[{ required: true, message: 'Type  your  first name in this field' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Middle Name" name="middle_name"
                                            rules={[{ required: true, message: 'Type  your  second name in this field' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Last Name" name="last_name"
                                            rules={[{ required: true, message: 'Type  your  second name in this field' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Date of Birth" name="birthday">
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>
                                    <Col sapn={12}>
                                        <Form.Item label="Gender" name="gender"
                                            rules={[{ required: true, message: 'Type  your  gender in this field' }]}>
                                            <Select>
                                                <Select.Option value="Male">Male</Select.Option>
                                                <Select.Option value="Female">Female</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Blood Group" name="blood_group" >
                                            <Select>
                                                <Select.Option value="A+">A+</Select.Option>
                                                <Select.Option value="A-">A-</Select.Option>
                                                <Select.Option value="O+">O+</Select.Option>
                                                <Select.Option value="O-">O-</Select.Option>
                                                <Select.Option value="AB-">AB-</Select.Option>
                                                <Select.Option value="AB+">AB+</Select.Option>
                                                <Select.Option value="B+">B+</Select.Option>
                                                <Select.Option value="B-">B-</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Married Status" name="married_status" >
                                            <Select>
                                                <Select.Option value="Married">Married</Select.Option>
                                                <Select.Option value="Unmarried">Unmarried</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Employee Id" name="employee_id"
                                            rules={[{ required: true, message: 'Type  your  employee id in this field' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="employment_type" label="Employment type">
                                            <Select style={{ width: '100%' }} placeholder="Select Employment type">
                                                {employmentTypeList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item name="job_title" label="Job Title">
                                            <Select style={{ width: '100%' }} placeholder="Select Job title">
                                                {jobTitleList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="job_category" label="Job Category">
                                            <Select style={{ width: '100%' }} placeholder="Select Job category">
                                                {jobCategoryList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Personal Email" name="personal_email"
                                            rules={[{ required: true, message: 'Type  your personal email' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="mobile"
                                            label="Mobile Number"
                                            rules={[{ required: true, message: 'Please input your mobile number!' }]} >
                                            <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                        </section>
                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={"employees"} labels={labelList} folders={folderList} id={theObject.id} appType={appId} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                </Layout>
            </Layout>
        </Layout>
    )
}
