import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faBackward, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, dateTimeUtils } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, DatePicker, Space, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "services";
const tableName = "services";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function Services() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [supplierList, setSupplierList] = useState([]);
    const [assetList, setAssetList] = useState([]);
    const [amcsList, setAmcsList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "asset-users": "none", "user-roles-list-table": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [servicesForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Supplier',
            dataIndex: 'supplier_id'
        },
        {
            title: 'Services Manager',
            dataIndex: 'manager'
        },
        {
            title: 'Services Engineer',
            dataIndex: 'engineer'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            widht: 50
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }

    ];

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        theObject["id"] = generateFuzzyId("services")
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setSupplierList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/assets` }, { "doc_name": "assets" }, setAssetList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/amcs` }, { "doc_name": "amcs" }, setAmcsList);
        findAll();
    }, []);


    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "records-list-table": "block" });

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/asset-management/services/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD");
        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD");
        AxiosNetwork.axiosPut({ "url": "api/admin/asset-management/services/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);

    }
    const afterNewRecordSubmit = () => {
        message.info('Your services details has been saved.');
        servicesForm.resetFields();
        showHideAllProps({ "button-controls-section": "none", "services-details": "none", "records-list-table": "block" });
    }

    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/asset-management/services` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "edit-selected-record": "none", "services-details": "none", "records-list-table": "block" });
    }

    const servicesFormFailed = () => {

    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/asset-management/services/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject,servicesCallback);
    }
    const servicesCallback = (data) => {
        if (data["start_at"] != undefined) data["start_at"] = moment(data["start_at"]);
        if (data["end_at"] != undefined) data["end_at"] = moment(data["end_at"]);
        setTheObject({ ...data });
        servicesForm.setFieldsValue({ ...data });
        showHideAllProps({ "button-controls-section": "block", "services-details": "block", "edit-selected-record": "block", "records-list-table": "none" });
    }

    const applyServices = () => {
        showHideAllProps({ "button-controls-section": "block", "services-details": "block", "edit-selected-record": "block", "records-list-table": "none" });
        servicesForm.resetFields();
    }
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }


    return (

        <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "services-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                <div style={{ marginTop: 10 }}>
                    <Space size={10}>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => applyServices()}> Apply Services  </Button>
                    </Space>
                </div>
            </section>
            <section className="thCard" style={{ "width": 700, "display": presentObjects["services-details"] }} >
                <Form form={servicesForm} labelAlign="right" layout="vertical" size={"large"} name="basic"
                    onFinish={onFinish} onFinishFailed={servicesFormFailed} initialValues={theObject} autoComplete="off">

                    <Form.Item label="Name" name="name">
                        <Input />
                    </Form.Item>
                    
                    <Form.Item label="Supplier" name="supplier_id" rules={[{ required: true, message: 'Enter your Supplier', }]}>
                        <Select allowClear showSearch placeholder="Select supplier">
                            {supplierList.map(eachItem => (
                                <Option key={eachItem.name}>{eachItem.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                        <Form.Item label="Start At" name="start_at">
                        <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item label="End At" name="end_at">
                        <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                    </Form.Item>
                    </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                    <Form.Item label="Services engineer" name="engineer">
                        <Input />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item label="Services Manager" name="manager">
                        <Input />
                    </Form.Item>
                    </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                    <Form.Item label="Asset" name="asset_id" rules={[{ required: true, message: 'Enter your Asset', }]}>
                        <Select allowClear showSearch placeholder="Select Asset">
                            {assetList.map(eachItem => (
                                <Option key={eachItem.name}>{eachItem.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item label="Repair" name="repair" >
                        <Select allowClear showSearch >
                            <Select.Option key="yes">Yes</Select.Option>
                            <Select.Option key="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                    </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                    <Form.Item label="Invoice" name="invoice">
                        <Input />
                    </Form.Item>
                    </Col>
                    <Col span={8}>
                    <Form.Item label="AMC" name="amc_id" rules={[{ required: true, message: 'Enter your AMC', }]}>
                        <Select allowClear showSearch placeholder="Select AMC">
                            {amcsList.map(eachItem => (
                                <Option key={eachItem.name}>{eachItem.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col span={8}>
                    <Form.Item label="Deceased" name="deceased" rules={[{ required: true, message: 'Enter your deceased', }]}>
                        <Input />
                    </Form.Item>
                    </Col>
                    </Row>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Type the Description' }]}>
                        <CKEditor
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                                editor.editing.view.change(writer => {
                                    writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                                });
                            }}
                            onError={(error, { willEditorRestart }) => {
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                console.log(ckEditorData);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                            editor={DecoupledEditor}
                            data={((theObject.description == null) ? "" : theObject.description)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </section>
        </Layout>
    )
}