import React, { useRef, useEffect, useState } from 'react';
//http://fabricjs.com/events
import { fabric } from 'fabric';
import { Layout, Menu, Dropdown, Modal, Row, Button, Table, Form, Input, Card, Upload, message, Select, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
const { JSONPath } = require('jsonpath-plus');

var selectedCanvasObject = "";
const dataMassage = { "id": 0, "taskLinks":[] };
const nonTasks = ["START", "END"];
const tatUnit = "HOUR";
const gridWidth = 15;
const taskLinkPlugWidth = 5;
const swimelaneGap = gridWidth*2;
const swimelaneWidth = (gridWidth * gridWidth);
const swimelaneHeight = (swimelaneWidth / 2);
const task = {
    width: swimelaneWidth,
    height: (swimelaneHeight / 3),
    current: null,
    previous: null
}
var taskLinkMap = {};
var taskMap = {};

var laneGeometry = {};

var userActions = {
    current: "none",
    startLink: null
}
var taskLinkDrawing, isMouseDown = false;


const WorkflowSwimlaneFabric = props => {
    console.log(props);
    const canvasRef = useRef(null);
    const [presentObjects, setPresentObjects] = useState({ isTaskContextOpen: false, "swimlaneDiagram":"none", "refreshId":0 });
    const taskList = props.taskList;
    const [totalHeightBlocks, setTotalHeightBlocks] = useState(props.roles.length);
    const [totalWidthBlocks, setTotalWidthBlocks] = useState(props.totalTatUnits);
    const [theWorkflow, setTheWorkflow] = useState(props.workflow);
    const [theTasklink, setTheTasklink] = useState({});
    const [taskLinks, setTasklinks] = useState([]);
    const [laneNames, setLaneNames] = useState(props.roles);
    var canvasWidth = (totalWidthBlocks * swimelaneWidth) + (totalWidthBlocks * swimelaneGap) - swimelaneGap;
    var canvasHeight = (totalHeightBlocks * swimelaneHeight) + (totalHeightBlocks * swimelaneGap) - swimelaneGap;
    console.log("totalHeightBlocks", totalHeightBlocks);
    console.log("totalWidthBlocks", totalWidthBlocks);
    console.log("winWidth,winHeight", window.innerWidth + "," + window.innerHeight);
    var fabricCanvas = null;

    const validateTaskLinks = (taskLinks) =>
    {
        var taskLinkErrors = [];
        var sourceIds = [], targetIds = [];
        taskLinks.forEach(eachLink => {
            sourceIds.push(eachLink.source_id);
            targetIds.push(eachLink.target_id);
            if(eachLink["source_id"] === eachLink["target_id"])
            {
                taskLinkErrors.push({"message":"Task must not link with the same task."});
            }
         });

         taskList.forEach(eachTask => {
            if((eachTask["genre"]!="END") && (eachTask["genre"]!="START") && (!targetIds.includes(eachTask.id)))
            {
                taskLinkErrors.push({"message":eachTask["name"] + " must have one linked with any previous task"});
            }
        });

        const startTask = taskList.filter(eachTask => eachTask.genre=="START");
        if(startTask.length!==1) taskLinkErrors.push({"message":"Start task must be 1"});
        const endTask = taskList.filter(eachTask => eachTask.genre=="END");
        if(endTask.length!==1) taskLinkErrors.push({"message":"End task must be 1"});
        if(startTask.length===1)
        {
            if(!sourceIds.includes(startTask[0]["id"]))
            {
                taskLinkErrors.push({"message":"Start task must have at least a next task"});
            }
        }
        if(endTask.length==1)
        {
            if(!targetIds.includes(endTask[0]["id"]))
            {
                taskLinkErrors.push({"message":"End task id must connected with any tasks"});
            }
            if(sourceIds.includes(endTask[0]["id"]))
            {
                taskLinkErrors.push({"message":"End task can't have next task"});
            }
        }
        
        if((startTask.length===1) && (endTask.length===1))
        {
            const startEnd = taskLinks.filter(eachLink => (eachLink["source_id"]===startTask[0]["id"]) && (eachLink["target_id"]===endTask[0]["id"]));
            if(startEnd.length!==0) taskLinkErrors.push({"message":"Start task can't link to end task directly"});
        }
        //alert(JSON.stringify(taskLinkErrors));
        console.log(taskLinkErrors);
    }


    const afterReadingTasklinks = (data) => {
        validateTaskLinks(data);
        setTasklinks([...data]);
        dataMassage["taskLinks"] = data;
        init();
        showHideAllProps({"swimlaneDiagram":"block"});
    }

    useEffect(() => {
        console.log("totalHeightBlocks", totalHeightBlocks);
        console.log("totalWidthBlocks", totalWidthBlocks);
        console.log("laneNames", laneNames);
        AxiosNetwork.axiosFetch({ "url": `/api/workflows/${theWorkflow.id}/task-links` }, setTasklinks, afterReadingTasklinks);
    }, [ theWorkflow.id ]);



    function init()
    {
        console.log("Init is being called", canvasRef.current);
        fabricCanvas = new fabric.Canvas(canvasRef.current, {
            fireRightClick: true,  // <-- enable firing of right click events
            fireMiddleClick: true, // <-- enable firing of middle click events
            stopContextMenu: true, // <--  prevent context menu from showing
        });
        fabricCanvas.setWidth(canvasWidth);
        fabricCanvas.setHeight(canvasHeight);
        var i = 0; 
        //vertical lines
        for (i = 0; i < ((totalWidthBlocks * gridWidth)); i++)
        {
            fabricCanvas.add(new fabric.Line([i * gridWidth, 0, i * gridWidth, canvasHeight], { type: 'line', stroke: '#E8E8E8', selectable: false }));
        }
        //horizontal lines
        for (i = 0; i < ((totalHeightBlocks * gridWidth)); i++)
        {
            fabricCanvas.add(new fabric.Line([0, i * gridWidth, canvasWidth, i * gridWidth], { type: 'line', stroke: '#E8E8E8', selectable: false }))
        }

        //vertical lines
        var blockCounter = 0;
        for (i = 0; i < (totalWidthBlocks+1); i++)
        {
            fabricCanvas.add(new fabric.Line([(i * swimelaneWidth), 0, (i * swimelaneWidth), canvasHeight], { type: 'line', strokeWidth: 1, stroke: '#A8A8A8', selectable: false }));
            fabricCanvas.add(new fabric.Line([((i * swimelaneWidth)+swimelaneGap), 0, ((i * swimelaneWidth)+swimelaneGap), canvasHeight], { type: 'line', strokeWidth: 1, stroke: '#A8A8A8', selectable: false }));

            var taskName = new fabric.Text((tatUnit +" "+blockCounter), {  left: ((i * swimelaneWidth)+swimelaneGap), top: 0,  fontSize: 10, fill: 'red' });
            fabricCanvas.add(taskName);

            laneGeometry[laneNames[blockCounter]] = { "x": ((i * swimelaneWidth)+swimelaneGap) };
            blockCounter++;
        }

        //horizontal lines
        blockCounter = 0;
        for (i = 0; i < (totalHeightBlocks+1); i++)
        {
            fabricCanvas.add(new fabric.Line([0, (i * swimelaneHeight), (canvasWidth-swimelaneWidth), (i * swimelaneHeight)], { type: 'line', strokeWidth: 1, stroke: '#A8A8A8', selectable: false }))
            fabricCanvas.add(new fabric.Line([0, ((i * swimelaneHeight)+swimelaneGap), (canvasWidth-swimelaneWidth), ((i * swimelaneHeight)+swimelaneGap)], { type: 'line', strokeWidth: 1, stroke: '#A8A8A8', selectable: false }))

            var actorName = new fabric.Text((laneNames[i]+"").substring(0,12), {  left: 0, top: (i * swimelaneHeight)+swimelaneHeight,  angle:270, fontSize: 15, fill: 'red' });
            fabricCanvas.add(actorName);

            laneGeometry[laneNames[blockCounter]]["y"] = ((i * swimelaneHeight)+swimelaneGap);
            blockCounter++;
        }
    
        //fabricCanvas.renderAll();
        //start and end task positions
        taskMap["start-" + theWorkflow.id] = { "x": 0, "y": 0 };
        taskMap["end-" + theWorkflow.id] = { "x": canvasWidth, "y": canvasHeight };

        blockCounter = 0;
        for (i = 0; i < taskList.length; i++) {
            var eachTask = taskList[i];

            var leftConnector = new fabric.Circle({
                radius: taskLinkPlugWidth,
                stroke: "red",
                strokeWidth: 1,
                selectable:false,
                left: ((blockCounter*swimelaneWidth)+swimelaneGap-(taskLinkPlugWidth/2)),
                top: (laneGeometry[eachTask["role_id"]]["y"]-taskLinkPlugWidth),
                id: eachTask["id"]
            });

            var rectangle = new fabric.Rect({
                width: task.width-swimelaneGap,
                height: task.height,
                left: (blockCounter*swimelaneWidth)+swimelaneGap,
                top: laneGeometry[eachTask["role_id"]]["y"],
                fill: '',
                stroke: 'red',
                strokeWidth: 3,
                id: eachTask["id"]
            });
            fabricCanvas.add(rectangle);
            rectangle.on("mouseup", function (e) {
                props.callback(e.target.id);
            });
            

            var rightConnector = new fabric.Circle({
                radius: taskLinkPlugWidth,
                stroke: "red",
                strokeWidth: 1,
                selectable:false,
                left: ((blockCounter*swimelaneWidth)+task.width-taskLinkPlugWidth),
                top: (laneGeometry[eachTask["role_id"]]["y"]+task.height-taskLinkPlugWidth),
                "id":eachTask["id"]
            });

            fabricCanvas.add(leftConnector);            
            fabricCanvas.add(rightConnector);

            leftConnector.on("mouseup", function (e) {
                console.log("mouseup", e.e.clientX, e.e.clientY, e.target);
                linkTasks(e.target);
                onTaskLinkDrawing(fabricCanvas.getPointer(e.e));
            });

            rightConnector.on("mouseup", function (e) {
                console.log("mouseup", e.e.clientX, e.e.clientY, e.target);
                linkTasks(e.target);
                onTaskLinkDrawing(fabricCanvas.getPointer(e.e));
            });

            var text = new fabric.Text(eachTask["name"], { 
                left: rectangle.left, //Take the block's position
                top: (rectangle.top+task.height)+5, 
                fontSize: 10,
                fill: 'red'
            });
            fabricCanvas.add(text);

            taskMap[eachTask.id] = {"x": rectangle.left, "y": rectangle.top };
            blockCounter++;
        }

        //draw task links
        console.log("taskLinks.length", dataMassage["taskLinks"].length);
        for (i = 0; i < dataMassage["taskLinks"].length; i++) {
            var eachLink = dataMassage["taskLinks"][i];
            var sourceTaskId = eachLink["source_id"];
            var targetTaskId = eachLink["target_id"];
            
            //avoid duplicate links
            if(taskLinkMap[`${sourceTaskId}-${targetTaskId}`]!=undefined) continue;
            taskLinkMap[`${sourceTaskId}-${targetTaskId}`]=1;

            var taskLinkRed = new fabric.Polyline(
                [
                    {"x":(taskMap[sourceTaskId]["x"]+task.width-swimelaneGap+(taskLinkPlugWidth/2)), "y":(taskMap[sourceTaskId]["y"]+task.height)},
                    {"x":(taskMap[sourceTaskId]["x"]+task.width-swimelaneGap+(taskLinkPlugWidth*2)), "y":(taskMap[sourceTaskId]["y"]+task.height)},
                    {"x":(taskMap[targetTaskId]["x"]-(taskLinkPlugWidth)-15), "y": (taskMap[targetTaskId]["y"])},
                    {"x":(taskMap[targetTaskId]["x"]+(taskLinkPlugWidth/2)), "y": (taskMap[targetTaskId]["y"])}
                ],
                {
                    stroke: "red",
                    id: eachLink.id,
                    fill: null,
                    strokeWidth: 1
                }
            );

            var taskLinkBlack = new fabric.Polyline(
                [
                    {"x":(taskMap[sourceTaskId]["x"]+task.width-swimelaneGap+(taskLinkPlugWidth/2)), "y":(taskMap[sourceTaskId]["y"]+task.height)},
                    {"x":(taskMap[sourceTaskId]["x"]+task.width-swimelaneGap+(taskLinkPlugWidth*2)), "y":(taskMap[sourceTaskId]["y"]+task.height)},
                    {"x":(taskMap[targetTaskId]["x"]-(taskLinkPlugWidth)-15), "y": (taskMap[targetTaskId]["y"])},
                    {"x":(taskMap[targetTaskId]["x"]+(taskLinkPlugWidth/2)), "y": (taskMap[targetTaskId]["y"])}
                ],
                {
                    stroke: "black",
                    id: eachLink.id,
                    strokeDashArray: [6, 3],
                    fill: null,
                    strokeWidth: 1
                }
            );

            var taskLinkArrow = new fabric.Triangle({
                width: 10, 
                height: 15, 
                fill: 'red', 
                left: (taskMap[targetTaskId]["x"]+(taskLinkPlugWidth/2)), 
                top: (taskMap[targetTaskId]["y"]-5),
                angle: 90
            });

            var linkGroup = new fabric.Group([taskLinkRed, taskLinkBlack, taskLinkArrow]);
            linkGroup.id = ("task-link~"+eachLink.id);
            fabricCanvas.on('mouse:down', function(e) { selectedCanvasObject = e.target.id; });
            fabricCanvas.add(linkGroup);
            fabricCanvas.renderAll();
        }
        
        fabricCanvas.on("dragover", function (e) {
            console.log("dragover", e.e.clientX, e.e.clientY, e.target);
            //userActions.current = "assignNextTask";
        });

        fabricCanvas.on("dragleave", function (e) {
            console.log("dragleave", e.e.clientX, e.e.clientY, e.target);
            //userActions.current = "assignNextTask";
        });
        

        fabricCanvas.on('mouse:move', function(o){
            if (!isMouseDown) return;
            var pointer = fabricCanvas.getPointer(o.e);
            taskLinkDrawing.set({ x2: pointer.x, y2: pointer.y });
            fabricCanvas.renderAll();
        });

        fabricCanvas.on('mouse:up', function(o){
            //isMouseDown = false;
        });
     
        fabricCanvas.on({"selection:created": function (e) {
            console.log(e);
            //selectedCanvasObject = e.target.id;
        }});

        actorName = new fabric.Text("Sa", {  left: 0, top: (0 * swimelaneHeight),  fontSize: 10, fill: 'red' });
        fabricCanvas.add(actorName);
        fabricCanvas.renderAll();
    }

    const linkTasks = (fabTask) =>
    {
        if(userActions.startLink===fabTask.id)
        {
            return;
        }
        if(userActions.startLink==null)
        {
            userActions.startLink = fabTask.id;
            return;
        }
        if(userActions.startLink!=null)
        {
            AxiosNetwork.axiosPost({ "url": `/api/workflows/${theWorkflow.id}/task-links/create` }, { "workflow_id": theWorkflow.id, "source_id": userActions.startLink, "target_id": fabTask.id }, taskLinks, setTasklinks);
            userActions.startLink = null;
            init();
            fabricCanvas.renderAll();
        }
    }

    const onTaskLinkDrawing = (pointer) =>
    {
        console.log("onTaskLinkDrawing called", isMouseDown);
        if(isMouseDown==true)
        {
            isMouseDown = false;
            return;
        }
        var points = [ pointer.x, pointer.y, pointer.x, pointer.y ];
        taskLinkDrawing = new fabric.Line(points, {
            strokeWidth: 5,
            fill: 'red',
            stroke: 'red',
            originX: 'center',
            originY: 'center'
        });
        fabricCanvas.add(taskLinkDrawing);
        isMouseDown = true;
    }

    document.onkeydown = function(e) {
        console.log(selectedCanvasObject, e);
        if(e.key === "Delete")
        {
            if(selectedCanvasObject.startsWith("task-link~"))
            {
                selectedCanvasObject = selectedCanvasObject.replace("task-link~", "");
                AxiosNetwork.axiosDelete({ "url": `/api/workflows/${theWorkflow.id}/task-links/${selectedCanvasObject}` }, taskLinks, setTasklinks);
                fabricCanvas.current.dispose();
                init();
            }
        }
    }

    const taskContextSubmit = () => {
        showHideAllProps({ isTaskContextOpen: false });
    }

    const taskContextCancel = () => {
        showHideAllProps({ isTaskContextOpen: false });
    }

    const duplicateCurrentTask = () => {
        console.log("duplicateCurrentTask");
        userActions.current = null;
    }
    const assignNextTask = () => {
        console.log("assignNextTask");
        userActions.current = "assignNextTask";
    }

    const assignPreviousTask = () => {
        console.log("assignPreviousTask");
        userActions.current = "assignPreviousTask";
    }

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }


    
    return (
        <section style={{"display":presentObjects["swimlaneDiagram"], marginTop:20}}>
            <canvas key={theWorkflow.id} style={{ display: "block" }} ref={canvasRef}></canvas>
            <div style={{marginTop:10}}>
                <Button type="primary" onClick={(e) => validateTaskLinks(taskLinks)}>Validate</Button>
            </div>
            <Modal title="Task Options" open={presentObjects.isTaskContextOpen} onOk={taskContextSubmit} onCancel={taskContextCancel}>
                <Button onClick={duplicateCurrentTask} type="link">Duplicate</Button>
                <Button onClick={assignNextTask} type="link">Assign Next Task</Button>
                <Button onClick={assignPreviousTask} type="link">Assign Previous Task</Button>
            </Modal>
        </section>
    )
}

export default WorkflowSwimlaneFabric;