import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
const dataMassage = { "emailAddress": {}, "associateId": "1" };

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "price-books";
const tableName = "price-books";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function PosPriceBook() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [organizationRecords, setOrganizationRecords] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [priceBookProductRecords, setPriceBookProductRecords] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [priceBookProductTaxes, setPriceBookProductTaxes] = useState([]);
    const [productList, setProductList] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [outletList, setOutletList] = useState([]);
    const [taxesList, setTaxesList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "price-book-product-tax-details": "none", "product-records-list-table": "none", "price-book-products-details": "none", "organization-records-list-table": "none", "price-books": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [theProductObject, setTheProductObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [priceBooksForm] = Form.useForm();
    const [priceBookProductForm] = Form.useForm();
    const [priceBookProductTaxesForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [thePriceBookProduct, setThePriceBookProduct] = useState({});
    console.log(queryParams);


    useEffect(() => {
        theObject["id"] = generateFuzzyId("price-book")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/shared-common/find-any-multi` }, { "doc_name": "taxes", "select": ["id", "name"] }, setTaxesList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi` }, { "doc_name": "organizations", "select": ["id", "name"] }, setOutletList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/products` }, { "doc_name": "products" }, setProductList);
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        findAll();
    }, []);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "view",
            title: 'View',
            width: 50,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faEye} /></a>,
        },
        {
            key: "Doc",
            title: 'Doc',
            width: 50,
            render: (record) => <a onClick={(e) => listPriceBookProducts(record)}><FontAwesomeIcon icon={faUserGroup} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }
    ];
    const columns = [
        {
            title: 'Outlet',
            dataIndex: 'organization_name'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteOutletRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];
    const priceBookProductsColumn = [
        {
            title: 'Product',
            dataIndex: 'product_name'
        },
        {
            title: 'Price',
            dataIndex: 'price'
        },
        {
            title: 'Markup',
            dataIndex: 'markup'
        },
        {
            title: 'Discount',
            dataIndex: 'discount'
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editProductTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "trash",
            title: 'Trash',
          
            width: 50,
            render: (record) => <a onClick={(e) => deletePriceBookProduct(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            key: "tax",
            title: 'Tax',
            width: 50,
            render: (record) => <a onClick={(e) => listPriceBookProductTaxes(record)}><FontAwesomeIcon icon={faUserGroup} /></a>,
        },
    ];
    const priceBookPrtoductTaxesColumn = [
        {
            title: 'Taxes',
            dataIndex: 'tax_id'
        },

        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deletePriceBookProduct(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }

    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/pos/price-books/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const deleteOutletRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/pos/organization-price-books/${selectedRow["id"]}`, "id": selectedRow.id }, organizationRecords, setOrganizationRecords);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const onFinish = (values) => {
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/price-books/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "tax-records-list-table": "none", "price-book-product-tax-details": "none", "product-records-list-table": "none", "price-book-products-details": "none", "organization-records-list-table": "none", "price-books-details": "none", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your price books details has been saved.');
        priceBooksForm.resetFields();
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/price-books` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "tax-records-list-table": "none", "price-book-product-tax-details": "none", "product-records-list-table": "none", "price-book-products-details": "none", "organization-records-list-table": "none", "edit-selected-record": "block", "price-books-details": "none", "records-list-table": "block" });
    }

    const priceBooksFormFailed = () => {

    }
    const showFindAll = () => {
        showHideAllProps({ "price-book-product-tax-details": "none", "tax-records-list-table": "none", "product-records-list-table": "none", "price-book-products-details": "none", "organization-records-list-table": "none", "edit-selected-record": "block", "price-books-details": "none", "records-list-table": "block" });
    }
    const editTableRow = (selectedRow) => {
        console.log("editTableRow", selectedRow);
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        dataMassage["priceBookId"] = selectedRow.id;
        setTheObject({ ...selectedRow });
        priceBooksForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/price-books/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "tax-records-list-table": "none", "price-book-product-tax-details": "none", "product-records-list-table": "none", "price-book-products-details": "none", "organization-records-list-table": "none", "price-books-details": "block", "edit-selected-record": "block" });
    }
    const editProductTableRow = (selectedRow) => {
        console.log("editProductTableRow", selectedRow);
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        dataMassage["priceBookProductId"] = selectedRow.id;
        setTheObject({ ...selectedRow });
        priceBookProductForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/price-books/${selectedRow["priceBookId"]}/price-book-products/`+ selectedRow.id, "id": selectedRow.id}, theObject, setTheObject);
        showHideAllProps({ "tax-records-list-table": "none", "price-book-product-tax-details": "none", "product-records-list-table": "block", "price-book-products-details": "block", "organization-records-list-table": "none", "price-books-details": "none", "edit-selected-record": "block" });
    }
    const viewTableRow = (selectedRow) => {
        dataMassage["priceBookId"] = selectedRow.id;
        dataMassage["priceBookProductId"] = generateFuzzyId("price-book");
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/organization-price-books` }, { "filters": [{ "col_name": "price_book_id", "operator": "=", "data_type": "string", "string_value": selectedRow.id }] }, setOrganizationRecords);
        showHideAllProps({ "tax-records-list-table": "none", "price-book-product-tax-details": "none", "product-records-list-table": "none", "price-book-products-details": "none", "organization-records-list-table": "block", "price-books-details": "none", "edit-selected-record": "block" });
    }

    const listPriceBookProducts = (selectedRow) => {
        console.log("listPriceBookProducts", selectedRow);
        setUiLabels({ "save": "Save" });
        dataMassage["priceBookId"] = selectedRow.id;
        dataMassage["priceBookProductId"] = generateFuzzyId("price-book-product");
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/price-books/${selectedRow.id}/price-book-products` }, { "doc_name": tableName }, setPriceBookProductRecords, afterPriceBookProductRecordsCallback);
        showHideAllProps({ "tax-records-list-table": "none", "price-book-product-tax-details": "none", "product-records-list-table": "block", "price-book-products-details": "block", "organization-records-list-table": "none", "price-books-details": "none", "edit-selected-record": "block" });
    }

    const afterPriceBookProductRecordsCallback = (data) => {
        console.log(data);
        setPriceBookProductRecords([...data]);
    }

    const onPriceBookProductFinish = (values) => {
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/price-books/" + dataMassage["priceBookId"] + "/price-book-products/" + dataMassage["priceBookProductId"] + "/update" }, values, priceBookProductRecords, setPriceBookProductRecords, afterPriceBookProductRecordSubmit);
        showHideAllProps({ "tax-records-list-table": "none", "price-book-product-tax-details": "none", "product-records-list-table": "block", "price-book-products-details": "block", "records-list-table": "none" });
    }

    const afterPriceBookProductRecordSubmit = (values) => {
        message.info('Your price book products details has been saved.');
        priceBookProductForm.resetFields();
        console.log("afterPriceBookProductRecordSubmit", values);
    }

    const priceBookProductFormFailed = () => {

    }
    const priceBookProductTaxesFormFailed = () => {

    }

    const onPriceBookProductTaxFinish = (values) => {
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/price-book-product/" + dataMassage["priceBookProductId"] + "/taxes/" + dataMassage["priceBooksProductTaxId"] + "/update" }, values, priceBookProductTaxes, setPriceBookProductTaxes, afterPriceBookProductTaxesRecordSubmit);
    }

    const afterPriceBookProductTaxesRecordSubmit = (values) => {
        message.info('Your price book product taxes details has been saved.');
        priceBookProductTaxesForm.resetFields();
        listPriceBookProductTaxes(thePriceBookProduct);
        showHideAllProps({ "price-book-product-tax-details": "none" });
    }

    const listPriceBookProductTaxes = (selectedRow) => {
        console.log("listPriceBookProductTaxes", selectedRow);
        selectedRow["mime_assets"] = [];
        setThePriceBookProduct({ ...selectedRow });
        dataMassage["priceBookProductId"] = selectedRow.id;
        dataMassage["priceBooksProductTaxId"] = generateFuzzyId("price-book-product-tax-id");
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGetMulti({ "url": `/api/admin/pos/price-book-product/${selectedRow.id}/taxes` }, priceBookProductTaxes, setPriceBookProductTaxes, priceBookProductTaxesCallback);
        console.log(selectedRow);
        showHideAllProps({ "tax-records-list-table": "block", "price-book-product-tax-details": "block", "product-records-list-table": "block", "price-book-products-details": "none", "organization-records-list-table": "none", "price-books-details": "none", "edit-selected-record": "block" });
    }

    const priceBookProductTaxesCallback = (data) => {
        console.log(data);
        //setPriceBookProductTaxes([...data]);
    }
    //todo
    const deletePriceBookProduct = (selectedRow) => {
        console.log(selectedRow);
    }
    const newPriceBook = () => {
        setTheObject({ "id": generateFuzzyId("price-book"), "mime_assets": [] });
        showHideAllProps({ "price-books-details": "block" });
    }

    return (
        <section>
            <Layout className='contentLayout'>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                    <Button style={{ "marginBottom": "20px" }} onClick={(e) => newPriceBook()}>New</Button>
                </section>

                <section style={{ "display": presentObjects["organization-records-list-table"] }}>
                    <Button style={{ "marginBottom": "20px" }} onClick={(e) => showFindAll()}>Back</Button>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columns} dataSource={organizationRecords} pagination={{ pageSize: 50 }} />
                </section>

                <section style={{ "display": presentObjects["product-records-list-table"] }}>
                    <Button style={{ "marginBottom": "20px" }} onClick={(e) => showFindAll()}>Back</Button>
                    <Table style={{ "marginBottom": "20px" }} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={priceBookProductsColumn} dataSource={priceBookProductRecords} pagination={{ pageSize: 50 }} />
                </section>

                <section style={{ "display": presentObjects["tax-records-list-table"] }}>
                    <Button style={{ "marginBottom": "20px" }} onClick={(e) => showFindAll()}>Back</Button>
                    <Table style={{ "marginBottom": "20px" }} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={priceBookPrtoductTaxesColumn} dataSource={priceBookProductTaxes} pagination={{ pageSize: 50 }} />
                </section>

                <section className="thCard" style={{ "width": 750, "display": presentObjects["price-books-details"] }}>
                    <Form size="large" labelAlign="right" layout="vertical"
                        onFinish={onFinish}
                        form={priceBooksForm} initialValues={theObject}
                        onFinishFailed={priceBooksFormFailed} autoComplete="off">
                        <Form.Item label="Name" name="name"
                            rules={[{ required: true, message: 'Enter your name', }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Outlet(s)" name="organization_ids"
                            rules={[{ required: true, message: 'Enter your Outlet', }]}>
                            <Select mode="tags" placeholder="Select Outlet">
                                {outletList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>

                </section>
                <section className="thCard" style={{ "width": 750, "display": presentObjects["price-book-products-details"] }}>

                    <Form size="large" labelAlign="right" layout="vertical"
                        onFinish={onPriceBookProductFinish}
                        form={priceBookProductForm} initialValues={theObject}
                        onFinishFailed={priceBookProductFormFailed} autoComplete="off">
                        <Form.Item label="Product" name="product_id"
                            rules={[{ required: true, message: 'Enter the Product', }]}>
                            <Select placeholder="Select Product ">
                                {productList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem["brand_name"] + " - " + eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Price" name="price">
                                    <InputNumber style={{ width: 300 }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Discount" name="discount">
                                    <InputNumber style={{ width: 300 }} min={0} max={100} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Max Units" name="max_units">
                                    <InputNumber style={{ width: 300 }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Min Units"
                                    name="min_units">
                                    <InputNumber style={{ width: 300 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>

                </section>
                <section className="thCard" style={{ "width": 750, "display": presentObjects["price-book-product-tax-details"] }}>

                    <Form size="large" labelAlign="right" layout="vertical"
                        onFinish={onPriceBookProductTaxFinish}
                        form={priceBookProductTaxesForm} initialValues={theObject}
                        onFinishFailed={priceBookProductTaxesFormFailed} autoComplete="off">
                        <Form.Item label="Taxes" name="tax_id"
                            rules={[{ required: true, message: 'Enter the Taxes', }]}>
                            <Select placeholder="Select Taxes ">
                                {taxesList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem["bound"] + " - " + eachItem.id}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Percentage" name="percentage">
                            <InputNumber style={{ width: 300 }} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>

                </section>

            </Layout>
        </section>
    )
}