import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faBackward,  faPlus,  faClock, faUser, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { appProfile, searchTextLength, menuBarItems, profileBarItems, dateTimeUtils, generateFuzzyId } from "../Config";
import '../App.css';
import { v4 as uuid } from 'uuid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Avatar, Space, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";

const { Dragger } = Upload;
const { SubMenu } = Menu;
const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
var activeDataColumns = [];
let ckEditorData = "";

const appId = "todo";
const appPath = "todo";
const tableName = "todos";
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const dateTimeFormat = "yyyy-MM-DD HH:mm:ss";

const infiniteLoop = [];

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export default function MiniTodos(props) {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [reminderList, setReminderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [priorityList, setPriorityList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none","create-new-record-section": "none", "records-list-table": "block", "edit-selected-record": "none", "composeTodo": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "new-todo": "none" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theTodo, setTheTodo] = useState({ "application": "EMAIL", "fileAssets": [], "body": "Type your content here" });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [todoForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", "/apps/todos");
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/priorities` }, { "doc_name": "priorities" }, setPriorityList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi` }, { "doc_name": "reminders", "select": ["id", "name"] }, setReminderList);
        composeTodo();
        findAll();
        if (props.presentObjects !== undefined) showHideAllProps({ ...props.presentObjects })
    }, infiniteLoop);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    function disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    const { RangePicker } = DatePicker;
    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    };
    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'genre',
        },
        {
            title: 'Due At',
            render: (record) => <span>{(record.dueAt !== null && moment(record.dueAt).format("lll"))}</span>,
            width: 200
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 60
        },
        {
            key: "trash",
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        },
        {
            key: "done",
            title: 'Done',
            render: (record) => <a onClick={(e) => markAsDone(record)}><FontAwesomeIcon icon={faCircleCheck} /></a>,
            width: 60
        },
        {
            key: "info",
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 60
        },
        {
            key: "restore",
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>,
            width: 60
        }
    ];


    const todoFormFailed = () => {

    }
    const onFinishFailed = () => {

    }
   
    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        dataMassage["todoId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": "/api/todos/" + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject,theCallback);
    }
    const theCallback = (data) => {
        if (data["due_at"] != undefined) data["due_at"] = moment(data["due_at"]);
        setTheObject({ ...data });
        todoForm.setFieldsValue({ ...data });
        showHideAllProps({ "records-list-table": "none","button-controls-section":"block","create-new-record-section": "block", "edit-selected-record": "block" });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const markAsDone = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/mark-as-done/` + selectedRow.id, "id": selectedRow.id }, theTodo, recordsList, setRecordsList);
    }

    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "button-controls-section":"block","edit-selected-record": "none", "create-new-record-section": "block", "records-list-table": "none" })
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        if(props.appDoc!==undefined) values["doc_id"] = props.appDoc.id;
        values["due_at"] = moment(values["due_at"]).format("yyyy-MM-DD HH:mm:ss");
        AxiosNetwork.axiosPut({ "url": "/api/todos/update/" + dataMassage["todoId"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your todo has been saved.');
        showHideAllProps({ "button-controls-section":"none","edit-selected-record": "none", "create-new-record-section": "none", "records-list-table": "none" })
        findAll();
    }
    const updateFolder = (e) => {
        console.log(theFolder);
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/folder/` + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
    }
    const updateLabel = (e) => {
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/label/` + theLabel.id, "id": theLabel.id }, theLabel, labelList, setTheLabel);
        console.log(theLabel);
    }
    const deleteFolder = (currentFolder) => {
        console.log(currentFolder);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/folder/` + currentFolder.id, "id": currentFolder.id }, folderList, setFolderList);
    }
    const deleteLabel = (currentLabel) => {
        console.log(currentLabel);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/label/` + currentLabel.id, "id": currentLabel.id }, labelList, setLabelList);
    }
   


    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none","create-new-record-section": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none","create-new-record-section": "none", "records-list-table": "block" });
    }
   
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const changeDueAt = (e) => {
        theTodo["dueAt"] = e.format(dateTimeFormat);
    }
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "create-new-record-section": "none", "records-list-table": "block", });
    }
    const findTaskBy = (filter) => {
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/${filter}` }, recordsList, setRecordsList);
        showHideProps("active", "block");
    }

    const findByDateRange = (afterBy, beforeBy, timeZone) => {
        console.log(afterBy, beforeBy);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters": [{ "col_name": "due_at", "data_type": "date", "operator": ">", "date_value": afterBy }, { "col_name": "due_at", "data_type": "date", "operator": "<", "date_value": beforeBy }] }, setRecordsList);
        showHideProps("active", "block");
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        var filters=[];
        if(props.appDoc!==undefined)
        {
            filters.push({ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": props.appDoc.id });
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters":filters}, setRecordsList);
        showHideAllProps({"edit-selected-record":"none","button-controls-section":"none", "create-new-record-section": "none", "records-list-table": "block" });
    }
    const composeTodo = () => {
        showHideProps("composeTodo", "block");
        theObject["emailAddress"] = [];
        formData = {};
        dataMassage["todoId"] = "todo-" + uuid();
        formData["parent_id"] = dataMassage["todoId"]
        formData["folder_id"] = dataMassage["todoId"];
    };

    return (
        <Layout>
             <section style={{ "display": presentObjects["button-controls-section"] }}>
                            <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                                <Button onClick={(e) => showHideAllProps({ "edit-selected-record":"none","create-new-record-section": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                            </Space>
            </section>
          
            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                <div style={{ marginTop: 10 }}>
                    <Space size={10}>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => newRecord()}><FontAwesomeIcon icon={faPlus} />Todos</Button>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => findFromBin()}> Bin </Button>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => findAll()}> All </Button>
                    </Space>
                </div>
            </section>
           
            <section style={{ "display": presentObjects["edit-selected-record"] }}>
               
            </section>

            <section className="thCard" style={{ marginTop: "20", "width": 700, "display": presentObjects["create-new-record-section"] }} >
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onFinish} form={todoForm} onFinishFailed={todoFormFailed}
                    autoComplete="off" initialValues={theObject}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Name" name="name">
                                <Input maxLength={25} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Due Date" name="due_at">
                                <DatePicker style={{ width: '100%' }} format={dateTimeUtils.dateTimeFormat} showTime={true} onOk={onOk} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="reminder_id" label=" Send Reminder">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Reminder">
                                    {reminderList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Type" name="genre" >
                                <Select showSearch allowClear placeholder="Enter your task" >
                                    <Select.Option value="To-do">To-do</Select.Option>
                                    <Select.Option value="Email">Email</Select.Option>
                                    <Select.Option value="SMS">SMS </Select.Option>
                                    <Select.Option value="Telegram">Telegram </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="priority_id" label="Priority">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Priority">
                                    {priorityList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Assign to" name="assignTo" >
                                <Select showSearch allowClear placeholder="Select Assign to">
                                    <Select.Option value="Automatic Ticket Assignment">Automatic Ticket Assignment</Select.Option>
                                    <Select.Option value="Round Robin">Round Robin</Select.Option>
                                    <Select.Option value="Load balance">Load Balancec</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Description" name="description">
                        <CKEditor
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                                editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                            }}
                            onError={(error, { willEditorRestart }) => {
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                console.log(ckEditorData);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                            editor={DecoupledEditor}
                            data={((theObject.description == null) ? "" : theObject.description)}
                            />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </section>
            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>
        </Layout>
    )
}