import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faTimeline, faClock, faUser, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faCircleCheck, faBridgeLock } from '@fortawesome/free-solid-svg-icons'
import { appProfile, searchTextLength, menuBarItems, profileBarItems, generateFuzzyId } from "../Config";
import '../App.css';
import { v4 as uuid } from 'uuid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Avatar, Space, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";


const { Dragger } = Upload;
const { SubMenu } = Menu;
const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;

var formData = {};
var activeDataColumns = [];

const appId = 61;
const appPath = "reminder";
const tableName = "reminders";
const dataMassage = { "emailAddress": {}, "associateId": "1" };
var nextTenYears = [];


//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const infiniteLoop = [];


function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}


export default function Reminder() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [tempList, setTempList] = useState([]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [templateList, setTemplateList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "reminderRecurNo": true, "reminderRecurYes": true, "reminderRecurEndAfter": true, "reminderRecurEndDate": true, "create-new-record-section": "none", "records-list-table": "block", "edit-selected-record": "none", "composeremainder": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "new-remainder": "none" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [thereminder, setTheReminder] = useState({ "application": "EMAIL", "fileAssets": [], "body": "Type your content here" });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [remindersForm] = Form.useForm();



    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", "/apps/reminders");
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi` }, {"doc_name":"templates", "select":["id","name"]}, setTemplateList);
        composeReminder();
        findAll();

        var currentYear = new Date().getFullYear();
        for (var i = currentYear; i < currentYear + 10; i++) {
            nextTenYears.push(i);
        }
        findAll();
    }, infiniteLoop);


    const recurOptionChanged = (recur) => {
    if (recur == true) {
            showHideAllProps({ "reminderRecurYes": false, "reminderRecurNo": true });
        }
        else {
            showHideAllProps({ "reminderRecurYes": true, "reminderRecurNo": false });
        }
    }

    const endsOptionChanged = (ends) => {
        ends = ends.toUpperCase();
        if (ends == "ENDS DATE") {
            showHideAllProps({ "reminderRecurEndAfter": true, "reminderRecurEndDate": false });
        }
        else if (ends == "ENDS AFTER") {
            showHideAllProps({ "reminderRecurEndAfter": false, "reminderRecurEndDate": true });
        }
        else {
            showHideAllProps({ "reminderRecurEndAfter": true, "reminderRecurEndDate": true });
        }
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    function disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }

    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    };
    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    const [keyboard, setKeyboard] = useState(true);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Recur',
            dataIndex: 'recur',
            render: (record) => <span>{((record) ? "Yes" : "No")}</span>,
        },
        {
            title: 'Due At',
            render: (record) => <span>{(record.dueAt !== null && moment(record.dueAt).format("lll"))}</span>,
        },
        {
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },

        {
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Done',
            width: 50,  
            render: (record) => <a onClick={(e) => markAsDone(record)}><FontAwesomeIcon icon={faCircleCheck} /></a>,
        },

        {
            title: 'Info',
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];


    const remindersFormFailed = () => {

    }
   
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": "/api/reminder/" + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, readTheObject);
        showHideAllProps({ "create-new-record-section": "block" });
    }

    const readTheObject = (data) => {
        console.log(data);
        setTheObject({ ...data });
        recurOptionChanged(data["recur"]);
        remindersForm.setFieldsValue({ ...data });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const markAsDone = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/mark-as-done/` + selectedRow.id, "id": selectedRow.id }, thereminder, recordsList, setRecordsList);
    }

    const saveReminder = () => {
        if (dataMassage["newRecordId"] == undefined) {
            AxiosNetwork.axiosPut({ "url": `/api/${appPath}/update/` + thereminder.id, "id": thereminder.id }, thereminder, recordsList, setRecordsList);
            return;
        }
        dataMassage["newRecordId"] = "remainder-" + uuid();
        thereminder["id"] = dataMassage["newRecordId"];
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/create` }, thereminder, recordsList, setRecordsList);
    }

    const onLabelFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/create/label` }, values, labelList, setLabelList);
        console.log('Success:', values);
        showHideProps("newLabelDrawer", false);
    };

    const onLabelFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const form = Form.useFormInstance();

    const onFolderFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "/api/${appPath}/create/folder" }, values, folderList, setFolderList);
        console.log('Success:', values);
        showHideProps("newFolderDrawer", false);;
    };

    const onFolderFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const newRecord = () => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "edit-selected-record": "none", "create-new-record-section": "block", "records-list-table": "none" })
    }
    const onFinish = (values) => {
        console.log(values);
        values["description"] = ckEditorData;
        values["upsert"] = true;
        if(values["due_at"]!=undefined)
        {
            values["due_at"] = values['due_at'].format("yyyy-MM-DD HH:mm:ss");
        }
        if(values["end_date"]!=undefined)
        {
            values["end_date"] = values['end_date'].format("yyyy-MM-DD HH:mm:ss");
        }
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/update/` + dataMassage["reminderId"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your reminder has been saved.');
        findAll();
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "create-new-record-section": "none", "records-list-table": "block" });
    }
   const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "create-new-record-section": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "create-new-record-section": "none", "records-list-table": "block", });
    }
    const findTaskBy = (filter) => {
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/${filter}` }, recordsList, setRecordsList);
        showHideProps("active", "block");
    }
    const findByDateRange = (afterBy, beforeBy, timeZone) => {
        console.log(afterBy, beforeBy);
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/time-range?from=${afterBy}&to=${beforeBy}&timezone=${timeZone}` }, recordsList, setRecordsList);
        showHideProps("active", "block");
    }

    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({  "edit-selected-record": "none", "create-new-record-section": "none", "records-list-table": "block" });
    }

    const composeReminder = () => {
        showHideProps("composeReminder", "block");
        theObject["emailAddress"] = [];
        formData = {};
        dataMassage["reminderId"] = "reminder-" + uuid();
        formData["parent_id"] = dataMassage["reminderId"]
        formData["folder_id"] = dataMassage["reminderId"];
    };
    let ckEditorData = "";

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Sider width={200}>
                        <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                            items={[
                                {
                                    key: 'create-new-item',
                                    label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                                },
                                {
                                    key: 'all-records',
                                    label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                                },
                                {
                                    key: 'sub-folders',
                                    label: (<a onClick={(e) => findAll()}>Folders</a>),
                                    children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                                },
                                {
                                    key: 'sub-labels',
                                    label: (<a onClick={(e) => findAll()}>Labels</a>),
                                    children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                                },
                                {
                                    key: 'bin-records',
                                    label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                                }
                            ]} />
                    </Sider>

                </Sider>
                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Title level={5} style={{ marginTop: 40, marginBottom: 10 }}>Reminder</Title>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </section>


                        <section style={{ "display": presentObjects["create-new-record-section"] }}>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                onFinish={onFinish}
                                form={remindersForm} initialValues={theObject}
                                onFinishFailed={remindersFormFailed} autoComplete="off">

                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the Reminder name',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Recur" name="recur">
                                    <Select onChange={(e) => recurOptionChanged(e)}>
                                        <Select.Option value={true}>Yes</Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item hidden={presentObjects["reminderRecurNo"]}
                                    label="Due At" name="due_at">
                                    <DatePicker format={"yyyy-MM-DD HH:mm:ss"} showTime={{ format: 'HH:mm' }} onChange={onChange}/>
                                </Form.Item>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]}
                                    label="Hour of the Day" name="hour">
                                    <Select  mode="tags">
                                        {[...Array(24)].map((eachValue, index)  =>
                                            <Select.Option value={index+1}>{(index + 1)}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]}
                                    label="Minute of the Hour" name="minute"  >
                                    <Select mode="tags">
                                        {[...Array(60)].map((eachValue, index) =>
                                            <Select.Option value={index+1}>{(index + 1)}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]}
                                    label="Day of the Month" name="date"  >
                                    <Select mode="tags">
                                        {[...Array(31)].map((eachValue, index) =>
                                            <Select.Option value={index+1}>{(index + 1)}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]}
                                    label="Day of the Week" name="day" >
                                    <Select mode="tags">
                                        <Select.Option value={0}>Sunday</Select.Option>
                                        <Select.Option value={1}>Monday</Select.Option>
                                        <Select.Option value={2}>Tuesday</Select.Option>
                                        <Select.Option value={3}>Wednesday </Select.Option>
                                        <Select.Option value={4}>Thursday</Select.Option>
                                        <Select.Option value={5}>Friday</Select.Option>
                                        <Select.Option value={6}>Saturday</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]}
                                    label="Month of the Year" name="month" >
                                    <Select mode="tags">
                                        <Select.Option value={1}>January</Select.Option>
                                        <Select.Option value={2}>February</Select.Option>
                                        <Select.Option value={3}>March</Select.Option>
                                        <Select.Option value={4}>April</Select.Option>
                                        <Select.Option value={5}>May </Select.Option>
                                        <Select.Option value={6}>June</Select.Option>
                                        <Select.Option value={7}>July</Select.Option>
                                        <Select.Option value={8}>August </Select.Option>
                                        <Select.Option value={9}>September</Select.Option>
                                        <Select.Option value={10}>October</Select.Option>
                                        <Select.Option value={11}>November</Select.Option>
                                        <Select.Option value={12}>December</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item hidden={presentObjects["reminderRecurYes"]}
                                    label="Year" name="year" >
                                    <Select mode="tags">
                                        {nextTenYears.map(eachYear =>
                                            <Select.Option value={eachYear}>{eachYear}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item hidden={presentObjects["reminderRecurYes"]}
                                    label="Ends"
                                    name="end_based_on">
                                    <Select onChange={(e) => endsOptionChanged(e)}>
                                        <Select.Option value="Never">Never</Select.Option>
                                        <Select.Option value="Ends Date">Ends Date</Select.Option>
                                        <Select.Option value="Ends After">Ends After</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item hidden={presentObjects["reminderRecurEndDate"]}
                                    label="Ends Date" name="end_date">
                                    <DatePicker format={"yyyy-MM-DD HH:mm:ss"} showTime={{ format: 'HH:mm' }} onChange={onChange}/>
                                </Form.Item>
                                <Form.Item hidden={presentObjects["reminderRecurEndAfter"]}
                                    label="Ends After" name="end_number">
                                        <InputNumber min={1} max={20} keyboard={keyboard} defaultValue={1} />
                                </Form.Item>
                                <Form.Item name="template_id" label="Template">
                                    <Select style={{ width: '100%' }} placeholder="Select Template">
                                        {templateList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ required: true, message: 'Type theDescription' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                    />
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button type="primary" htmlType="Save">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}
