import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faUndoAlt, faUser, faClock, faTrashCan, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faPlay } from '@fortawesome/free-solid-svg-icons'
import { appProfile, menuBarItems, profileBarItems, generateFuzzyId } from "../Config";
import '../App.css';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Tabs, Row, Button, Table, Form, Input, Card, Upload, message, Select, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import WorkflowTasks from "../workflows/WorkflowTasks";
import MiniNote from "../note/MiniNote";
import MiniTodo from "../todo/MiniTodos";
import MiniEmails from "../emails/MiniEmails";
import MiniQuestion from "../question/MiniQuestion";
import ProjectInfo from "./ProjectInfo";
import FormsDataCollection from "../forms/FormsDataCollection"
import MiniCalendar from "../calendar/MiniCalendar"
import queryString from 'query-string';
import UserDocRoles from '../commons/UserDocRole';


const queryParams = queryString.parse(window.location.search);
const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;
const dataMassage = { "emailAddress": {}, "associateId": "1", "workflow": {} };

var formData = {};
const appId = 11;
const appPath = "projects";
const tableName = "projects";
var activeDataColumns = [];

let ckEditorData = "";
var tatUnit = "HOUR";

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function Project() {
    const [theObject, setTheObject] = useState({ "name": "Type the project name here", "description": "", "mime_assets": [] });
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [projectForm] = Form.useForm();
    const [workflowList, setWorkflowList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [searchedRecords, setSearchedRecords] = useState({ "workflows": [] });
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "create-new-record": "block", "new-sub-record": "none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [checklistList, setChecklistList] = useState([{ "id": 1, "name": "Waiting to load" }])
    const [guidelineList, setGuidelineList] = useState([{ "id": "g1", "name": "Guide line 1" }, { "id": "g2", "name": "Guideline 2" }]);
    const [functionList, setFunctionList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [roleList, setRoleList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [inboxList, setInboxList] = useState([{ "id": "i1", "name": "Inbox 1" }, { "id": "12", "name": "Inbox 2" }]);
    const [pluginList, setPluginList] = useState([{ "id": "i1", "name": "Plugin 1" }, { "id": "12", "name": "Plugin 2" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [formList, setFormList] = useState([{ "id": 1, "name": "Waiting to load" }]);

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
        AxiosNetwork.axiosFetch({ "url": "/api/common/folders/appid/" + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": "/api/common/labels/appid/" + appId }, setLabelList);
        AxiosNetwork.axiosFetch({ "url": `api/common/apps` }, setApplicationList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-select-any-multi` }, { "doc_name": "roles", "select": ["id", "name"] }, setRoleList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-select-any-multi` }, { "doc_name": "guidelines", "select": ["id", "name"] }, setGuidelineList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "function" }, setFunctionList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "plugin" }, setPluginList);
        AxiosNetwork.axiosFetch({ "url": `api/emails/inboxes` }, setInboxList);
        const filter = { "col_name": "genre", "operator": "=", "data_type": "string", "string_value": "FORMS" };
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, { "doc_name": "forms", "filter": [filter] }, setFormList);
        if (queryParams["id"] !== undefined) AxiosNetwork.axiosGet({ "url": `/api/${tableName}/${queryParams["id"]}`, "id": queryParams["id"] }, theObject, setTheObject, loadProject);
    }, []);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Start',
            render: (record) => <a onClick={(e) => startProject(record)}><FontAwesomeIcon icon={faPlay} /></a>,
            width: 50
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 50
        },
        {
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 50
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 50
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];


    const startProject = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        dataMassage["workflowId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        formData["folder_id"] = dataMassage["workflowId"];
        AxiosNetwork.axiosPutMono({ "url": `/api/${tableName}/${selectedRow.id}/start`, "id": selectedRow.id }, theObject, setTheObject, loadProject);
    }

    const editTableRowX = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        dataMassage["workflowId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        formData["folder_id"] = dataMassage["workflowId"];
        AxiosNetwork.axiosGet({ "url": `/api/${tableName}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, loadProject);
    }

    const editTableRow = (selectedRow) => {
        window.location.href = window.location.origin + "/apps/projects?id=" + selectedRow.id;
    }


    const loadProject = (project) => {
        console.log(project);
        setTheObject({ ...project });
        projectForm.setFieldsValue({ ...project });
        dataMassage["workflow"] = { "id": project["workflow_id"] }
        showHideAllProps({ "create-new-record": "none", "new-sub-record": "block", "edit-selected-record": "block" });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    }

    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "create-new-record": "none", "new-sub-record": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "create-new-record": "none", "new-sub-record": "none", "records-list-table": "block" });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "create-new-record": "none", "new-sub-record": "none", "records-list-table": "block" });
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "create-new-record": "none", "new-sub-record": "none", "records-list-table": "block" });
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        AxiosNetwork.axiosPost({ "url": `/api/${tableName}/` + theObject.id + "/upsert" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your workflow has been saved.');
        findAll();
    }
    const projectFormFailed = () => {
    }
    const searchRecordsLike = (value, table) => {
        if (value === null || value === "") return;
        var payload = { "select": ["id", "name"], "doc_name": table, "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMultiProps({ "url": `/api/common/find-select-any-multi-like` }, payload, table, searchedRecordsCallback);
    }
    const searchedRecordsCallback = (field, value) => {
        setSearchedRecords({ ...searchedRecords, [field]: value });
    }

    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "create-new-record": "block", "new-sub-record": "none", "records-list-table": "none", "edit-selected-record": "none" });
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            },
                            {
                                key: 'all-reports',
                                label: (<a onClick={(e) => window.location.href = window.location.origin + '/apps/reports'} rel="noopener noreferrer">Reports</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                    </section>

                    {(theObject.id !== undefined) &&
                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                            <section style={{ "display": presentObjects["new-sub-record"] }}>
                                <Tabs defaultActiveKey={theObject.id}
                                    items={[
                                        { "label": "Info", "key": "info-" + new Date().getTime(), children: <ProjectInfo appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Workflow", "key": "tasks-" + new Date().getTime(), children: <WorkflowTasks appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Todo", "key": "todo-" + new Date().getTime(), children: <MiniTodo appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Emails", "key": "email-" + new Date().getTime(), children: <MiniEmails appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Users", "key": "users-" + new Date().getTime(), children: <UserDocRoles appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Notes", "key": "notes-" + new Date().getTime(), children: <MiniNote appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Meetings", "key": "meeting-" + new Date().getTime(), children: <MiniCalendar appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Form", "key": "form-" + new Date().getTime(), children: <FormsDataCollection appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                        { "label": "Query", "key": "query-" + new Date().getTime(), children: <MiniQuestion appDoc={theObject} roleList={roleList} workflow={dataMassage["workflow"]} functionList={functionList} formList={formList}guidelineList={guidelineList} pluginList={pluginList} inboxList={inboxList} /> },
                                    ]}
                                />
                            </section>
                        </section>
                    }

                    <section className="thCard" style={{ "width": 800, "display": presentObjects["create-new-record"] }}>
                        <Form size="large" name="basic" layout="vertical"
                            onFinish={onFinish} form={projectForm} initialValues={theObject}
                            onFinishFailed={projectFormFailed} autoComplete="off">
                            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Type the name' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Workflow" name="workflow_id"
                                rules={[{ message: 'Type the workflow' }]}>
                                <Select notFoundContent={null} showSearch onSearch={(e) => searchRecordsLike(e, "workflows")} placeholder="Select Wokflow">
                                    {searchedRecords["workflows"].map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>
                        </Form>
                    </section>
                    <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                        <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                            {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                        </div>
                    </Drawer>
                </Layout>
            </Layout>
        </Layout>
    )
}