import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId, dateTimeUtils } from "../../Config";
import '../../App.css';
import { Divider, Form, Input, DatePicker, Card, Row, Col, Upload, Space, Button, message, Select, Table, Layout, InputNumber } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave, faEdit,faBackward } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-previous-employment";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


export default function HumanResourcePreviousEmployment() {
    const [presentObjects, setPresentObjects] = useState({"button-controls-section": "none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourcePreviousEmploymentForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [employmentTypeList, setEmploymentTypeList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [jobTitleList, setJobTitleList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [jobCategoryList, setJobCategoryList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("employment-histories")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "configurations", "range": [{ "col_name": "app_type", "operator": "=", "data_type": "integer", "integer_value": 88 }] }, dispatchConfigurations);
        findAll();
    }, []);

    const dispatchConfigurations = (data) => {
        setEmploymentTypeList(data.filter(eachItem => eachItem["genre"] == "employee-employment-type"));
        setJobTitleList(data.filter(eachItem => eachItem["genre"] == "employee-job-title"));
        setJobCategoryList(data.filter(eachItem => eachItem["genre"] == "employee-job-category"));
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/employment-histories/`+ selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, previousEmploymentCallback);
    }
    const previousEmploymentCallback = (data) => {
        if (data["joined_at"] != undefined) data["joined_at"] = moment(data["joined_at"]);
        if (data["offer_confirmed_at"] != undefined) data["offer_confirmed_at"] = moment(data["offer_confirmed_at"]);
        if (data["resigned_at"] != undefined) data["resigned_at"] = moment(data["resigned_at"]);
        setTheObject({ ...data });
        humanResourcePreviousEmploymentForm.setFieldsValue({ ...data });
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "previous-employment-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/human-resource/employment-histories` }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "records-list-table": "block", "previous-employment-details": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const humanResourcePreviousEmploymentFailed = () => {
    }
    const onFinishFailed = () => {
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["joined_at"] = moment(values["joined_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["offer_confirmed_at"] = moment(values["offer_confirmed_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["resigned_at"] = moment(values["resigned_at"]).format("yyyy-MM-DD HH:mm:ss");
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/employment-histories/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }

    const afterNewRecordSubmit = () => {
        message.info('Your previous employment details has been saved.');
        findAll();
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/human-resource/employment-histories", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const applyPreviousEmployement = () => {
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "previous-employment-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
        humanResourcePreviousEmploymentForm.resetFields();
    }
    const columnSchema = [
        {
            title: 'Company Name',
            dataIndex: 'name',
        },
        {
            title: 'Employee Code',
            dataIndex: 'employee_code',
        },
        {
            title: 'Served Years',
            dataIndex: 'served_years',
        },
        {
            title: 'Served Months',
            dataIndex: 'served_months',
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 60
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 50
        },
    ];


    return (
        <section>
            <Layout className='contentLayout'>
                <section style={{ "display": presentObjects["button-controls-section"] }}>
                    <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                        <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "previous-employment-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                    </Space>
                </section>

                <section style={{ "display": presentObjects["records-list-table"] }}>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                    <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyPreviousEmployement()}>   Previous Employement </Button>

                </section>
                <section className="thCard" style={{ "width": 700, "display": presentObjects["previous-employment-details"] }} >
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                        onFinish={onFinish}
                        form={humanResourcePreviousEmploymentForm} initialValues={theObject}
                        onFinishFailed={humanResourcePreviousEmploymentFailed} autoComplete="off">
                        <Form.Item label="Company name" name="name"
                            rules={[{ required: true, message: 'Type  your  company name in this field' }]}>
                            <Input />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Employee code" name="employee_code"
                                    rules={[{ required: true, message: 'Type  your  employee code in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Nick Name" name="nick_name"
                                    rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Served Years" name="served_years">
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Served months" name="served_months">
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Notice Period" name="notice_period">
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Joined At" name="joined_at">
                                    <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Offer Confirmed At" name="offer_confirmed_at">
                                    <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Resigned At" name="resigned_at">
                                    <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item name="employment_type" label="Employment type">
                                    <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Employment type">
                                        {employmentTypeList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="job_title" label="Job Title"
                                    rules={[{ required: true, message: 'Type  your  job title in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="job_category" label="Job Category">
                                    <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Job category">
                                        {jobCategoryList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Address Line 1" name="address_line_1"
                                    rules={[{ required: true, message: 'Type  your contact address in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Address Line 2" name="address_line_2">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Address Line 3" name="address_line_3">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                        </Form.Item>
                    </Form>
                </section>
            </Layout>
        </section>
    )
}