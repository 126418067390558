import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faUsers, faEdit, faClose, faCircleInfo, faPlusCircle, faCheckCircle, faUserPen, faTrash, faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { appProfile, generateFuzzyId, footerLine, menuBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { v4 as uuid } from 'uuid';
import { Layout, Menu, Typography, Button, Table, Form, Input, Upload, Select, Tree, prefixSelector, Space, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
const appId = "companies";
const appPath = "organizations";
const tableName = "organizations";
var activeDataColumns = [];
var currentBranch = {};
var treeData = [];
let ckEditorData = "";
var formData = {};
const dataMassage = { "parent_id": null };

export default function Organization() {
    const [tempList, setTempList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [subRecordsList, setSubRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "address-list-table": "none", "address-form": false, "show-tree-content-menu": "none", "user-roles-list-table": "none", "user-form": false, "organization-details": "none", "tree-context-menu": false, "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [newLabelDrawer, setNewLabelDrawer] = useState(false);
    const [newFolderDrawer, setNewFolderDrawer] = useState(false);
    const [showNewDrawer, setShowNewDrawer] = useState(false);
    const [userRecordsList, setUserRecordsList] = useState([{}]);
    const [addressesRecordsList, setAddressesRecordsList] = useState([{}]);
    const [addressesTypes, setAddressesTypes] = useState([{}]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "description": "Type your content here", "id": tableName + "-" + uuid() });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [organizationTypesList, setOrganizationTypesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [organizationBranchTypeList, setOrganizationBranchTypeList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [organizationList, setOrganizationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userList, setUserList] = useState([]);
    const [showLine, setShowLine] = useState(true);
    const [showIcon, setShowIcon] = useState(false);
    const [showLeafIcon, setShowLeafIcon] = useState(false);
    const [organizationForm] = Form.useForm();
    const [addressForm] = Form.useForm();
    const [usersForm] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [selectedTree, setSelectedTree] = useState({ "event": { "node": { "screenX": 0, "screenY": 0 } }, "node": { "name": "" } });

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
        currentBranch = { ...info["node"]["props"]["data"] };
        setTheObject({ ...currentBranch });
        console.log(theObject);
        AxiosNetwork.axiosGetMulti({ "url": `/api/organizations/${currentBranch["id"]}/branches` }, setSubRecordsList, afterOrganizationBranchLoad);
    }

    const eachTreeChildren = (children, newBranch) => {
        for (var i = 0; i < children.length; i++) {
            var eachBranch = children[i];
            if (eachBranch["key"] == currentBranch["id"]) {
                eachBranch["children"] = newBranch;
                break;
            }
            if (Array.isArray(eachBranch["children"])) {
                eachTreeChildren(eachBranch["children"], newBranch);
            }
        }
        return children;
    }

    const afterOrganizationBranchLoad = (newBranch) => {
        console.log("afterOrganizationBranchLoad", currentBranch["id"]);
        console.log(newBranch);

        newBranch.forEach(organization => {
            organization["title"] = organization["name"];
            organization["key"] = currentBranch["id"] + "-" + organization["id"];
            organization["icon"] = <FontAwesomeIcon icon={faPlusCircle} />;
        });

        var newRecordList = [...treeData];
        for (var i = 0; i < newRecordList.length; i++) {
            var eachBranch = newRecordList[i];
            if (eachBranch["key"] == currentBranch["id"]) {
                eachBranch["children"] = newBranch;
                break;
            }
            if (Array.isArray(eachBranch["children"])) {
                eachBranch["children"] = eachTreeChildren(eachBranch["children"], newBranch);
            }
        }
        console.log(newRecordList);
        treeData = [...newRecordList];
        setShowLeafIcon(false);
    }
    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "organization-type" }, setOrganizationTypesList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "organization-branch-type" }, setOrganizationBranchTypeList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "address-types" }, setAddressesTypes);
        findAll();
    }, []);


    const info = (e) => {
        currentBranch["mime_assets"] = [];
        setTheObject({ ...currentBranch });
        showHideAllProps({ "showInfo": true });
        console.log(currentBranch);
        e = [...currentBranch];
        showHideAllProps({ "address-list-table": "none", "show-tree-content-menu": "none", "user-form": false, "user-roles-list-table": "none", "address-form": false })

    }
    const edit = (e) => {
        console.log(currentBranch);
        currentBranch["mime_assets"] = [];
        setTheObject({ ...currentBranch });
        dataMassage["id"] = currentBranch["id"];
        dataMassage["parent_id"] = theObject["id"];
        organizationForm.setFieldsValue({ ...currentBranch });
        formData = {};
        dataMassage["id"] = currentBranch.id;
        formData["parent_id"] = currentBranch.id;
        AxiosNetwork.axiosGet({ "url": `/api/organizations/` + currentBranch.id, "id": currentBranch.id }, theObject, setTheObject);
        setShowNewDrawer(true);
        showHideAllProps({ "show-tree-content-menu": "none" })

    }

    const newBranchRecord = (e) => {
        dataMassage["parent_id"] = theObject["id"];
        dataMassage["id"] = generateFuzzyId(tableName);
        setShowNewDrawer(true);
        showHideAllProps({ "show-tree-content-menu": "none" })
    }

    const newRecord = (e) => {
        dataMassage["parent_id"] = null;
        dataMassage["id"] = generateFuzzyId(tableName);
        setShowNewDrawer(true);
    }
    
    const onDelete = (e) => {
        console.log(e);
        AxiosNetwork.axiosDelete({ "url": `api/organizations/${theObject.id}` }, setRecordsList);
        showHideAllProps({ "records-list-table": "block", "show-tree-content-menu": "none" })
    }

    const organizationUsers = (e) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/organizations/${theObject.id}/users` }, {}, setUserRecordsList, userRecordsListCallback);
    }

    const userRecordsListCallback = (data) => {
        setUserRecordsList([...data]);
        showHideAllProps({ "address-list-table": "none", "show-tree-content-menu": "none", "user-form": true, "user-roles-list-table": "block" });
    }
    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        console.log(selectedRow);
        dataMassage["address_id"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/addresses/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, addressCallback);
        showHideAllProps({ "address-list-table": "block", "show-tree-content-menu": "none", "address-form": true });
    }
    const addressCallback = (data) => {
        setTheObject({ ...data });
        addressForm.setFieldsValue({ ...data });
    }

    const onAddressFinish = (values) => {
        if(dataMassage["address_id"] == undefined) dataMassage["address_id"]=generateFuzzyId("addresses");
        values["description"] = ckEditorData;
        values["name"] = theObject["name"];
        values["upsert"] = true;
        values["app_type"] = "52";
        values["doc_id"] = theObject["id"];
        AxiosNetwork.axiosPut({ "url": `/api/addresses/${dataMassage["address_id"]}/update` }, values, addressesRecordsList, setAddressesRecordsList, afterOrganizationAddressAddedCallback);
        showHideAllProps({ "address-list-table": "block", "show-tree-content-menu": "none", "address-form": true });
    }

    const afterOrganizationAddressAddedCallback = (data) => {
        organizationAddress({ ...theObject });
        showHideAllProps({ "address-form": true});
    }
    const organizationAddress = (values) => {
        dataMassage["address_id"] = generateFuzzyId("addresses");
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "addresses", "filters": [{ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": theObject["id"] }] }, setAddressesRecordsList);
        showHideAllProps({ "address-list-table": "block", "show-tree-content-menu": "none", "address-form": true });
    }
    const addressRecordsListCallback = (data) => {
        setAddressesRecordsList([...theObject]);
        showHideAllProps({ "address-list-table": "block", "show-tree-content-menu": "none", "address-form": true });
    }
    const deleteRoleFromUser = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/organizations/${theObject.id}/users/${selectedRow["organization_user_id"]}`, "id": selectedRow.id }, userRecordsList, setUserRecordsList);
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["parent_id"] = dataMassage["parent_id"];
        AxiosNetwork.axiosPut({ "url": "/api/organizations/" + dataMassage["id"] + "/update" }, values, recordsList, setRecordsList);
        console.log('Success:', values);
        setShowNewDrawer(false);
        findAll();
    }

    const treeRightClicked = (selectedKeys, info) => {
        console.log(selectedKeys);
        currentBranch = { ...selectedKeys["node"]["props"]["data"] };
        setTheObject({ ...currentBranch });
        showHideAllProps({ "show-tree-content-menu": "block" })
        setSelectedTree({ ...selectedKeys });
    }

    const organizationsAddressColumn = [
        {
            title: 'Type',
            dataIndex: 'genre',
            width: 50,
        },
        {
            title: 'Address',
            render: (record) => <span>{record.address_line_1}, {record.address_line_2}, {record.address_line_3}, {record.city}, {record.state}, {record.country}</span>,
            width: 50,
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        }
    ];

    const userRolesColumn = [
        {
            title: 'User',
            dataIndex: 'name'
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteRoleFromUser(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];

    const handleLeafIconChange = (value) => {
        if (value === 'custom') {
            return setShowLeafIcon(<FontAwesomeIcon icon={faCheckCircle} />);
        }
        if (value === 'true') {
            return setShowLeafIcon(true);
        }
        return setShowLeafIcon(false);
    };

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const updateFolder = (e) => {
        console.log(theFolder);
        AxiosNetwork.axiosUpdate({ "url": "/api/common/update/folder/" + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "organizations", "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "address-list-table": "none", "show-tree-content-menu": "none", "user-form": false, "user-roles-list-table": "none", "address-form": false })
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "organizations", "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "address-list-table": "none", "show-tree-content-menu": "none", "user-form": false, "user-roles-list-table": "none", "address-form": false })
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": `/api/${appPath}/restore/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        console.log(activeDataColumns);
    }
    const findAll = () => {
        console.log("findAll");
        console.log(activeDataColumns);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setRecordsList, afterOrganizationLoad);
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}>
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    
    const afterOrganizationLoad = (data) => {
        data.forEach(organization => {
            organization["title"] = organization["name"];
            organization["key"] = organization["id"];
            organization["icon"] = <FontAwesomeIcon icon={faPlusCircle} />;
        });
        setRecordsList([...data]);
        setOrganizationList([...data]);
        treeData = [...data];
        setShowLeafIcon(true);
    }

    const organizationFailed = () => {

    }
    const addressFailed = () => {

    }
    const onUserFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": `api/organizations/${theObject.id}/users/${values["user_id"]}/create` }, values, userRecordsList, setUserRecordsList, afterUserRoleAddedCallback);
        showHideAllProps({ "address-list-table": "none", "user-form": true, "records-list-table": "none", "user-roles-list-table": "block" });
    }
    const afterUserRoleAddedCallback = (data) => {
        organizationUsers({ ...theObject });
        showHideAllProps({ "user-form": true });
    }
    const usersFormFailed = () => {

    }
    const findUserLike = (value) => {
        var payload = { "doc_name": "users", "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/find-any-multi-like` }, payload, setUserList, setUserListCallBack);
    }
    const setUserListCallBack = (data) => {
        setUserList([...data]);
        showHideAllProps({ "address-list-table": "none", "user-form": true, "user-roles-list-table": "block" });
    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Menu theme="dark" mode="horizontal" items={menuBarItems} />
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200} className="site-layout-background" collapsible collapsed={siderCollapsed} trigger={null}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}>
                        <Menu.Item key="all-records" onClick={(e) => findAll()}>All</Menu.Item>
                        <SubMenu key="sub-folders" title="Folders">
                            {folderList.map(eachItem => (
                                <Menu.Item key={'folder-' + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</Menu.Item>
                            ))}
                        </SubMenu>
                        <SubMenu key="sub-labels" title="Labels">
                            {labelList.map(eachItem => (
                                <Menu.Item key={'label-' + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</Menu.Item>
                            ))}
                        </SubMenu>
                        <Menu.Item key="bin-records" onClick={(e) => findFromBin("deleted")}>BIN</Menu.Item>
                    </Menu>
                </Sider>


                <Layout className='contentLayout'>
                    <Content>
                        <Tree
                            showLine={showLine ? showLeafIcon : false}
                            showIcon={showIcon}
                            defaultExpandedKeys={['0-0-0']}
                            onSelect={onSelect}
                            treeData={[...treeData]}
                            onRightClick={treeRightClicked}
                        />
                        <Space>
                            <Button type="primary" onClick={(e) => newRecord()} icon={<PlusOutlined />}>NEW</Button>
                        </Space>

                        <Drawer
                            width={720}
                            open={showNewDrawer}
                            onClose={(e) => setShowNewDrawer(false)}
                            bodyStyle={{
                                paddingBottom: 80,
                            }}>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                onFinish={onFinish}
                                form={organizationForm} initialValues={theObject}
                                onFinishFailed={organizationFailed} autoComplete="off">

                                <Form.Item
                                    label="Name" name="name">
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Organization Type"
                                    name="type_id" rules={[
                                        {
                                            required: true,
                                            message: 'Select the  Organization Type',
                                        },
                                    ]}>
                                    <Select style={{ width: '100%' }} placeholder="Change Organization Type">
                                        {organizationTypesList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Organization Branch Type"
                                    name="branch_type_id" rules={[
                                        {
                                            required: true,
                                            message: 'Select the  Organization Types ',
                                        },
                                    ]}>
                                    <Select style={{ width: '100%' }} placeholder="Change Organization Types ">
                                        {organizationBranchTypeList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Genre"
                                    name="genre" rules={[
                                        {
                                            message: 'Select the Organization Genre',
                                        }
                                    ]}>
                                    <Input />
                                </Form.Item>

                                <Form.Item label="Branch of" name="parent_organization_id"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Select the parent organization',
                                        },
                                    ]}>
                                    <Select style={{ width: '100%' }} placeholder="Select the parent organization">
                                        {organizationList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Email" name="email"
                                    rules={[{ required: false, message: 'Type  your  email' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="URL" name="url"
                                    rules={[{ required: false, message: 'Type  your  url' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description">
                                    <TextArea rows={4} placeholder="maxLength is 6" maxLength={255} />
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Drawer>
                        <Drawer title={uiLabels.title}  width={720} placement="right" onClose={(e) => showHideProps("address-form", false)} open={presentObjects["address-form"]}>
                        <section>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                onFinish={onAddressFinish}
                                form={addressForm} initialValues={theObject}
                                onFinishFailed={addressFailed} autoComplete="off">
                                <Form.Item
                                    name="genre" label="Type"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Type your address location such as office, godown etc.',
                                        }
                                    ]}>
                                    <Select style={{ width: '100%' }} showSearch placeholder="Select the address location">
                                        {addressesTypes.map(eachItem => (
                                            <Option key={eachItem.name}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Address Line 1"
                                    name="address_line_1"
                                    rules={[{ required: true, message: 'Type  your  address in this field' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Address Line 2"
                                    name="address_line_2">
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Address Line 3"
                                    name="address_line_3">
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Landmark"
                                    name="landmark"
                                    rules={[{ required: false, message: 'Type  your landmark in this field' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="City"
                                    name="city"
                                    rules={[{ required: true, message: 'Type  your city in this field' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="State"
                                    name="state"
                                    rules={[{ required: true, message: 'Type  your state in this field' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Country"
                                    name="country"
                                    rules={[{ required: true, message: 'Type  your country in this field' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="mobile"
                                    label="Mobile Number"
                                    rules={[{ required: true, message: 'Please input your Mobile number!' }]} >
                                    <Input
                                        addonBefore={prefixSelector}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>

                        <section style={{ "display": presentObjects["address-list-table"] }}>
                            <h3>Organization Addresses</h3>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={organizationsAddressColumn} dataSource={addressesRecordsList} pagination={{ pageSize: 50 }} />
                        </section>

                        </Drawer>
                        
                        <Drawer title={uiLabels.title}  width={720} placement="right" onClose={(e) => showHideProps("user-form", false)} open={presentObjects["user-form"]}>
                        <section>
                            <Form layout="horizontal" name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                onFinish={onUserFinish}
                                form={usersForm} initialValues={theObject}
                                onFinishFailed={usersFormFailed} autoComplete="off">
                                <Form.Item
                                    label="User"
                                    name="user_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter your User',
                                        }
                                    ]}
                                >
                                <Select showSearch onSearch={findUserLike} placeholder="Select User">
                                        {userList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                </Select>
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>
                        <section style={{ "display": presentObjects["user-roles-list-table"] }}>
                            <h3>Organization Users</h3>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={userRolesColumn} dataSource={userRecordsList} pagination={{ pageSize: 50 }} />
                        </section>
                        </Drawer>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                        <section style={{ "display": presentObjects["show-tree-content-menu"] }}>
                            <ul className="context-menu" style={{ left: selectedTree["event"]["pageX"], top: selectedTree["event"]["pageY"] }}>
                                <li onClick={(e) => info(e)}><FontAwesomeIcon icon={faCircleInfo} /> Info</li>
                                <li onClick={(e) => edit(e)}><FontAwesomeIcon icon={faUserPen} />  Edit</li>
                                <li onClick={(e) => newBranchRecord(e)}><FontAwesomeIcon icon={faUserPen} /> New </li>
                                <li onClick={(e) => onDelete(e)}><FontAwesomeIcon icon={faTrash} /> Delete </li>
                                <li onClick={(e) => organizationUsers(e)}><FontAwesomeIcon icon={faUsers} /> Add Users</li>
                                <li onClick={(e) => organizationAddress(e)}><FontAwesomeIcon icon={faAddressCard} /> Address</li>
                                <li onClick={(e) => showHideAllProps({ "show-tree-content-menu": "none" })}><FontAwesomeIcon icon={faClose} /> Close</li>
                            </ul>
                        </section>
                    </Content>
                </Layout>
            </Layout>
            <Footer>{footerLine}</Footer>
        </Layout>
    )

}
