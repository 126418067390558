//https://www.npmjs.com/package/storagedb2
import StorageDB from 'storagedb2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHomeLg, faSignOut, faSignOutAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid';
import { Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox, Space } from 'antd';

const dateTimeFormat = "yyyy-MM-DD HH:mm:ss";
const dateFormat = "yyyy-MM-DD";

export const dateTimeUtils = {
    "dateTimeFormat" : "YYYY-MM-DD HH:mm:ss",
    "dateFormat" : "YYYY-MM-DD"
}

export const tableRenderUtils = {
    "pageSize":10
}

const urlPaths = {
    "56": "cdn-file-assets",
    "28": "file-assets"
}

const envFile = ((process.env.REACT_APP_ENV==undefined) ? "frontend" : process.env.REACT_APP_ENV);
console.log("envFile", );
//export const appProfile = require("./env/"+envFile+".json")
export const appProfile = require("./env/"+envFile+".json")

export const searchTextLength = 3;

//export const offlineData = new StorageDB({storage:window.localStorage,database:'techiehug', primaryKey:'id'});

export const replaceWindow = (location) => {
    window.location.href = appProfile.websiteLocation + location;
}


export const openWindow = (location) => {
    window.open(appProfile.websiteLocation + location, "_blank");
}

export const openBlogWindow = (location) => {
    window.open(appProfile.blogLocation + location, "_blank");
}


export const staticColors = {
    primary: "#5f637c",
    secondary: "#f56a00",
    territory: "#5f637c"
}

export const findUrlPath = (record) => {
    return urlPaths[record.type];
}

export const footerLine = "©"+new Date().getFullYear()+" Techiehug Technologies Private Limited";


export const menuBarItems = [
    {
        key: 'home',
        icon: <img height={30} style={{marginBottom:-10}} src={appProfile.staticAssets + "icons/logo-wide.png"} />
    },
    {
        key: 'apps',
        icon: <FontAwesomeIcon icon={faHomeLg} style={{marginLeft:50}}/>,
        label: (<a href={window.location.origin + "/apps"} target="_parent">Apps</a>)
    }
];

export const profileBarItems = [
    {
        key: 'profile',
        icon: <FontAwesomeIcon icon={faUserAlt} />,
        label: (<a href={window.location.origin + "/apps"} target="_parent">Profile</a>)
    },
    {
        key: 'logout',
        icon: <FontAwesomeIcon icon={faSignOutAlt}  style={{marginLeft:450}}/>,
        label: (<a href={window.location.origin + "/apps"} target="_parent">Logout</a>)
    }
];

export const socialAuthProps = {
    google : {
        clientId: "518996613404-du0pktorlsp178ti10al051g6cjagoj0.apps.googleusercontent.com"
    },
    facebook : {
        clientId: "636231528029440"
    }
}

export const generateFuzzyId = (prefix) => {
    return prefix + "-" + new Date().getTime() + "-" + uuid();
}



export const configMenus = [
    {
        key: 'status',
        label: (<a href={window.location.origin + "/apps/configurations/statuses"}>Status</a>), "key": "status" 

    },
    {
        key: 'priority',
        label: (<a href={window.location.origin + "/apps/configurations/priorities"}>Priority</a>), "key": "priority" 

    },
    {
        key: 'severity',
        label: (<a href={window.location.origin + "/apps/configurations/severities"}>Severity</a>), "key": "severity" 

    },
    {
        key: 'roles',
        label: (<a href={window.location.origin + "/apps/configurations/roles"}>Roles</a>), "key": "roles" 

    },
    {
        key: 'units',
        label: (<a href={window.location.origin + "/apps/configurations/units"}>Units</a>), "key": "units" 

    },
    {
        key: 'skills',
        label: (<a href={window.location.origin + "/apps/configurations/skills"}>Skills</a>), "key": "skills" 
    },
    {
        key: 'configurations',
        label: (<a onClick={e => window.location.href = window.location.origin + "/apps/configurations"} href="#">Configurations</a>), "key":"configurtions"
    },
    {
        key: 'reports',
        label: (<a onClick={e => window.location.href = window.location.origin + "/apps/configurations/reports"} href="#">Reports</a>), "key":"reports"
    }
]



//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
export const fileUploadProps = (formData, theObject, setTheObject) => {
    return {
        name: "file",
        multiple: true,
        showUploadList: false,
        action: appProfile.serviceLocation + 'api/file-assets/upload/single',
        data: function (file) {
            console.log("Data being triggered");
            return formData;
        },
        headers: {
            Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                theObject["file_assets"].push(info.file.response);
                setTheObject({...theObject});
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    }
}


//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
export const cdnFileUploadProps = (formData, theObject, setTheObject) => {
    return {
        name: 'file',
        showUploadList: false,
        multiple: true,
        action: appProfile.serviceLocation + 'api/cdn-file-assets/upload/single',
        data: function (file) {
            console.log("Data being triggered");
            return formData;
        },
        headers: {
            Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                theObject["file_assets"].push(info.file.response);
                setTheObject({...theObject});
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    }
}

export const findSubstring = (value, length, suffix) =>
{
    if(value===undefined) return "...";
    return value.substring(0,length) + suffix;
} 

export const tablePagination = (dataMassage, event) =>
{
    dataMassage.pagination.pageSize = event.pageSize;
    dataMassage.pagination.current = event.current;
    dataMassage.pagination.offset = ((event.current-1) * event.pageSize);
    dataMassage.pagination.limit = (event.current * event.pageSize);
}