import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId } from "../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faSave, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import '../App.css';
import { Divider, Form, Input, DatePicker, Row, Button, Slider, Col, View, prefixSelector, suffixSelector, Card, message, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../axiosService";
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "deals";
const tableName = "deals";
var activeDataColumns = [];
const style = { padding: '8px 0' };
let ckEditorData = "";

export default function DealsInfo(props) {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "deals-form": "none", "button-controls-section": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState(props.appDoc);
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [searchedRecords, setSearchedRecords] = useState({ "workflows": [] });
    const [formList, setFormList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [dealsForm] = Form.useForm();
    const [nameList, setNameList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [currenciesList, setCurrenciesList] = useState([]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [inputValue, setInputValue] = useState(1);
    console.log(props.appDoc);

    useEffect(() => {
        setUiLabels({ "save": "Update" });
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/crm/deals`);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/currencies` }, setCurrenciesList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "contacts" }, setNameList);
        const filter = { "col_name": "genre", "operator": "=", "data_type": "string", "string_value": "FORM" };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "forms", "filter": [filter] }, setFormList);
     
        findAll();
    }, []);

    const onChange = (newValue) => {
        setInputValue(newValue);
    };
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        setUiLabels({ "save": "Update" });
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        dealsForm.setFieldsValue({ ...selectedRow });
        dataMassage["dealsId"] = selectedRow.id;
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/deals/` + selectedRow.id, "id": selectedRow.id }, setTheObject);
        showHideAllProps({ "deals-form": "none", "button-controls-section": "none", "new-record": "none", "records-list-table": "none" });
    }

    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `api/deals` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "deals-form": "none", "button-controls-section": "none", "new-record": "block", "records-list-table": "none" });
    }

    const onFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "api/deals/" + theObject["id"] + "/upsert" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "button-controls-section": "none", "new-record": "none", "records-list-table": "block" });

    }
    const afterNewRecordSubmit = () => {
        message.info('Your deals details has been saved.');
        findAll();
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/deals/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const dealsFormFailed = () => {

    }
    const searchRecordsLike = (value, table) => {
        if (value === null || value === "") return;
        var payload = { "select": ["id", "name"], "doc_name": table, "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMultiProps({ "url": `/api/common/find-select-any-multi-like` }, payload, table, searchedRecordsCallback);
    }

    const searchedRecordsCallback = (field, value) => {
        setSearchedRecords({ ...searchedRecords, [field]: value });
    }
    const columnSchema = [
        {
            title: 'Deals',
            dataIndex: 'name'
        },
        {
            title: 'Currency',
            dataIndex: 'currency_id',
            width: 50
        },
        {
            title: 'Value',
            dataIndex: 'value',
            width: 50
        },
        {
            title: 'Status',
            dataIndex: 'status_id',
            width: 50
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 20,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 20,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];


    return (
        <section>
            <Layout className='contentLayout'>
                <section className="thCard" style={{ "width": 700, "display": presentObjects["new-record"] }} >
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                        onFinish={onFinish} form={dealsForm} onFinishFailed={dealsFormFailed}
                        autoComplete="off" initialValues={theObject}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Deals" name="name" rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Contact Name" name="contact_id" rules={[{ required: true, message: 'Type  your  contact name in this field' }]}>
                                    <Select style={{ width: '100%' }} placeholder="Type  your  contact name in this field">
                                        {nameList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Value" name="value" rules={[{ required: false, message: 'Type  your  amount in this field' }]}>
                                    <InputNumber  style={{ width: '100%' }}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Currency" name="currency_id" rules={[{ required: false, message: 'Enter your currency' }]}>
                                    <Select style={{ width: '100%' }} placeholder="Change Currencies">
                                        {currenciesList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Workflow"
                                name="workflow_id"
                                rules={[{ message: 'Type the workflow' }]}>
                                <Select notFoundContent={null} showSearch onSearch={(e) => searchRecordsLike(e, "workflows")} placeholder="Select Wokflow">
                                    {searchedRecords["workflows"].map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="form_id" label="Forms" rules={[{ required: true, message: 'Enter the Forms', }]}>
                                <Select style={{ width: '100%' }} placeholder="Select Forms">
                                    {formList.map(eachItem => (
                                        <Option key={eachItem["id"]}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                   
                       <Form.Item label="Notes" name="notes"
                            rules={[{ required: false, message: 'Type the notes' }]}>
                            <CKEditor
                                editor={DecoupledEditor}
                                onReady={editor => {
                                    console.log('Editor is ready to use!', editor);
                                    // Insert the toolbar before the editable area.
                                    editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    editor.editing.view.change(writer => {
                                        writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                                    });
                                }}
                                onError={(error, { willEditorRestart }) => {
                                    if (willEditorRestart) {
                                        this.editor.ui.view.toolbar.element.remove();
                                    }
                                }}
                                onChange={(event, editor) => {
                                    ckEditorData = editor.getData();
                                    console.log(ckEditorData);
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>
                </section>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                </section>
            </Layout>
        </section>
    )
}
