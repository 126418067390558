import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "supplier-products";
const tableName = "supplier-products";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function PosSupplierProduct() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [productList, setProductList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({"supplier-products":"none", "supplier-product-details": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [posSupplierProductForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    console.log(queryParams);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("supplier-product")
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/pos-admin/${tableName}`);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/products` }, { "doc_name": "products" }, setProductList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/suppliers` }, { "doc_name": "suppliers" }, setSupplierList);
        findAll();
    }, []);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_name'
        },
        {
            title: 'Product ',
            dataIndex: 'product_name'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        }
    ];
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
   
    const onFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "/api/admin/pos/supplier-products/" + theObject["id"] + "/upsert" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "supplier-product-details": "block", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your suppliers product details has been saved.');
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": "/api/admin/pos/supplier-products/" }, {}, setRecordsList,setProductsAfter);
        showHideAllProps({ "edit-selected-record": "block", "supplier-product-details": "block", "records-list-table": "block" });
    } 

    const  setProductsAfter = (data) =>{
        setRecordsList([...data]);
        console.log("setProductsAfter", data);
    }
    const posSupplierProductFormFailed = () => {
    }
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        setUiLabels({ "save": "Update" });
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        posSupplierProductForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/suppliers/by/products/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "edit-selected-record": "block", "supplier-product-details": "block", "records-list-table": "block" });
    }
 
    return (
        <section>
            <Layout className='contentLayout'>
                   <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                    </section>
                    <section className="thCard" style={{ "width": 750, "display": presentObjects["supplier-product-details"] }}>
                        <Form size="large" labelAlign="right" layout="vertical"
                            onFinish={onFinish}
                            form={posSupplierProductForm} initialValues={theObject}
                            onFinishFailed={posSupplierProductFormFailed} autoComplete="off">
                            <Form.Item label="Name" name="name"
                                rules={[{required: true,message: 'Enter your name',}]}>
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                            <Col span={8}>
                            <Form.Item label="Product" name="product_id"
                                rules={[{required: true,message: 'Enter your Product ',}]}>
                                <Select showSearch allowClear  placeholder="Select Product ">
                                    {productList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Supplier" name="supplier_id"
                                rules={[{required: true,message: 'Enter your Supplier ',}]}>
                                <Select showSearch allowClear placeholder="Select  Supplier">
                                    {supplierList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Price"name="price">
                                <InputNumber style={{width: 200}}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>
                        </Form>
                       </section>
                </Layout>
        </section>
    )
}