import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId,dateTimeUtils} from "../../Config";
import '../../App.css';
import { Divider, Form, Input, Space,DatePicker, Upload,Card,Row,Col, Button, message, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faSave, faEdit, faBackward, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
const appId = 85;
var formData = {};
let ckEditorData = "";

const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-leave-mine";
const tableName = "human-resource";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function HumanResourceLeaveMine() {
    const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none", "human-resource-leave-mine": "none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [leavetypesList, setLeaveTypesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [leaveReasonList, setLeaveReasonList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [addressesList, setAddressesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [humanresourceleavemineForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [humanresourceleavemineList, setHumanResourceLeaveMineList] = useState([{ "name": "LEAVE-MINE", "mime_assets": [], "description": "Type your content here" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    
    useEffect(() => {
        theObject["id"]= generateFuzzyId("leaves")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "leave-types" }, setLeaveTypesList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "leave-reasons" }, setLeaveReasonList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, {"doc_name" : "addresses"},  setAddressesList );
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `api/human-resource/leaves` }, setRecordsList);
        showHideAllProps({ "button-controls-section":"none","records-list-table":"block", "human-resource-leave-mine": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const humanresourceleavemineFailed = () => {
    }
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/leaves/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject,LeaveCallBack);
    }
    const LeaveCallBack = (data) => {
        if (data["start_at"] != undefined) data["start_at"] = moment(data["start_at"]);
        if (data["end_at"] != undefined) data["end_at"] = moment(data["end_at"]);
        setTheObject({ ...data });
        humanresourceleavemineForm.setFieldsValue({ ...data });
        showHideAllProps({"button-controls-section":"block","records-list-table":"none", "human-resource-leave-mine": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }  
    const onFinish = (values) => {
        values["upsert"] = true;
        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["description"] = ckEditorData;
        values["duration_type"] = "DAY";
        values["name"]= " ";
        values["days"]= 10.0;
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/leaves/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your Leave mine details has been saved.');
        findAll();
    }
    const applyLeave = () => {
        showHideAllProps({"button-controls-section":"block","records-list-table":"none", "human-resource-leave-mine": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
        humanresourceleavemineForm.resetFields();
    }
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    
    const leaveTypeChanged = (e) => 
    {
        console.log(e);
        var selectedLeaveTypeIndex = leavetypesList.findIndex((obj => obj.id == e));
        if(selectedLeaveTypeIndex==-1) return;
        var selectedLeaveType = leavetypesList[selectedLeaveTypeIndex];
        var selectedLeaveTypeName = "leave-type-"+selectedLeaveType.name.toLocaleLowerCase().trim().replaceAll(" ", "-");
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": selectedLeaveTypeName }, setLeaveReasonList);        
    }

    const columnSchema = [
        {
            title: 'Leave types',
            dataIndex: 'reason_id',
        },
        {
            title: 'Leave reason',
            dataIndex: 'reason_id',
        },
        {
            title: 'Start date',
            dataIndex: 'start_at',
        },
        {
            title: 'End date',
            dataIndex: 'end_at',
        },
        {
            title: 'Days',
            dataIndex: 'days',
        },
        {
            title: 'Status',
            dataIndex: 'status_id'
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },

    ];

    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                    <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                        <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "human-resource-leave-mine": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                    </Space>
                </section>
               
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyLeave()}>Apply Leave</Button>
                    </section>

                    <section className="thCard" style={{ "width": 800, "display": presentObjects["human-resource-leave-mine"] }} >
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanresourceleavemineForm} initialValues={theObject}
                            onFinishFailed={humanresourceleavemineFailed} autoComplete="off">
                            <Form.Item name="type_id" label="Leave types">
                                <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Leave-types" onChange={leaveTypeChanged}>
                                    {leavetypesList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Leave reason" name="reason_id">
                                <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Leave Reason" onChange={leaveTypeChanged}>
                                    {leaveReasonList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Row gutter={16}>
                            <Col span={8}>
                            <Form.Item label="Start Date" name="start_at">
                            <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk} />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="End Date" name="end_at">
                            <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk} />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                           <Form.Item label="Address" name="addressId">
                                <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Addresses">
                                {addressesList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Form.Item label="Description" name="description" rules={[{ required: false, message: 'Type the Description' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change(writer => {
                                                writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                                            });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                        data={((theObject.description == null) ? "" : theObject.description)}
                                    />
                                    </Form.Item>
                                <Form.Item label="Attachement" name="attachement">
                                <Dragger {...fileUploadProps}>
                                    <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
                                    <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>
                        </Form>
                    </section>
            </Layout>
        </section>
    )

}
