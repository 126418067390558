import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faSave, faCopy, faEdit, faTrashCan, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { appProfile, profileBarItems, generateFuzzyId, menuBarItems } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Card, Row, Tabs, Button, Table, Form, Input, Upload, Radio, message, Select, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { v4 as uuid } from 'uuid';
import { Droppable } from './Droppable';
import { Draggable } from './Draggable';
import { SortableItem } from './SortableItem';
import { SortableItemTest } from './SortableItemTest';
import { FormElement } from './FormElement';
import FormsDataResponses from './FormsDataResponses';
import FormsDataCollection from './FormsDataCollection';
import { faFirefox, faWpforms } from '@fortawesome/free-brands-svg-icons';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
var activeDataColumns = [];
const appId = 55;
const appPath = "forms";
const tableName = "forms";
const { TabPane } = Tabs;
const dataMassage = { "emailAddress": {}, "associateId": "1" };

let ckEditorData = "";

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};
const formItemTemplate = {
    "input": {
        "type": "input",
        "label": "Input",
        "required": false
    },
    "input-number": {
        "type": "input-number",
        "label": "Number",
        "min": 0,
        "max": 100,
        "required": false
    },
    "textarea": {
        "type": "textarea",
        "label": "Paragraph",
        "required": false
    },
    "select": {
        "type": "select",
        "label": "Select",
        "options": [],
        "required": false
    },
    "multi-select": {
        "type": "multi-select",
        "label": "Multi Select",
        "options": [],
        "required": false
    },
    "tags": {
        "type": "tags-select",
        "label": "Tags Select",
        "options": [],
        "required": false
    },
    "upload": {
        "type": "upload",
        "label": "Upload",
        "required": false
    },
    "radio": {
        "type": "radio",
        "label": "Radio",
        "options": [],
        "required": false
    },
    "checkbox": {
        "type": "checkbox",
        "label": "Checkbox",
        "options": [],
        "required": false
    },
    "divider": {
        "type": "divider",
        "label": "Divider",
        "required": false
    },
    "date-picker": {
        "type": "date-picker",
        "label": "Date Picker",
        "required": false
    },
    "date-range-picker": {
        "type": "date-range-picker",
        "label": "Date Range Picker",
        "required": false
    },
    "slider": {
        "type": "slider",
        "label": "Slider",
        "min": 0,
        "max": 100,
        "required": false
    },
    "title": {
        "type": "title",
        "label": "Title",
        "required": false
    }
};

export default function Forms() {
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "fields": [], "mime_assets": [] });
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "new-form": "none", "form": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [isDropped, setIsDropped] = useState(false);
    const [items, setItems] = useState(['1', '2', '3']);
    const [formItems, setFormItems] = useState([]);
    const [formItemIds, setFormItemIds] = useState([]);
    const [newformForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    const addNewFormItem = (e) => {
        const newFormItem = { ...formItemTemplate[e] };
        newFormItem["label"] = "Undefined";
        newFormItem["id"] = "form-" + uuid();
        newFormItem["order"] = (formItems.length + 1);
        formItemIds.push(newFormItem["id"]);
        formItems.push(newFormItem);
        console.log(newFormItem);
        setFormItems(formItems);
        setFormItemIds(formItemIds);
        saveFormItem(newFormItem);
        showHideProps("form", "block");
    }
    const formItemActions = (action, e) => {
        if (action === "update") updateFormItem(e);
        if (action === "sort") sortFormItem(e);
        if (action === "delete") deleteFormItemById(e);
        if (action === "duplicate") copyFormItemById(e);
    }
    const copyFormItemById = (e) => {
        console.log("copyFormItemsById", e);
        const copyIndex = formItems.findIndex((obj => obj.id == e.id));
        if (copyIndex != -1) {
            e["id"] = "form-" + uuid();
            formItems.splice(copyIndex, 0, e);
        }
        console.log(formItems);
        setFormItems([...formItems]);
        showHideProps("form", "block");
    }
    const deleteFormItemById = (e) => {
        console.log("deleteFormItemsById", e);
        const deleteIndex = formItems.findIndex((obj => obj.id == e.id));
        if (deleteIndex != -1) {
            formItems.splice(deleteIndex, 1);
        }
        console.log(formItems);
        setFormItems([...formItems]);
        showHideProps("form", "block");
    }
    const updateFormItem = (e) => {
        console.log("updateFormItem", e);
        const replaceIndex = formItems.findIndex((obj => obj.id == e.id));
        if (replaceIndex != -1) {
            formItems[replaceIndex] = e;
        }
        console.log({ ...formItems });
        setFormItems([...formItems]);
        showHideProps("form", "block");
    }
    const newformFailed = () => {
    }
    const sortFormItem = (e) => {
        var sortedFormItems = formItems.sort((a, b) => a.order - b.order);
        setFormItems([...sortedFormItems]);
        showHideProps("form", "block");
    }
    const saveFormItem = (e) => {
        theObject["fields"] = formItems;
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/update/` + theObject.id, "id": theObject.id }, theObject, setRecordsList, setTheObject, null);
        console.log(e);
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const activeDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 150,
        },
        {
            title: 'Type',
            dataIndex: 'genre',
            width: 150,
        },
        {
            title: 'Application',
            dataIndex: 'application',
            width: 150,
        },
        {
            title: 'Modified At',
            width: 150,
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            title: 'Preview',
            width: 150,
            render: (record) => <a onClick={(e) => window.location.href = window.location.origin + "/apps/forms/form-data-collection?id=" + record.id}><FontAwesomeIcon icon={faFirefox} /></a>,
        },
        {
            title: 'Duplicate',
            width: 150,
            render: (record) => <a onClick={(e) => duplicate(record)}><FontAwesomeIcon icon={faCopy} /></a>,
        },

        {
            title: 'Responses',
            width: 150,
            render: (record) => <a onClick={(e) => window.location.href = window.location.origin + "/apps/forms/form-responses?id=" + record.id}><FontAwesomeIcon icon={faWpforms} /></a>,
        },
        {
            title: 'Edit',
            width: 150,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Info',
            width: 150,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        selectedRow["fields"] = [];
        setTheObject({ ...selectedRow });
        newformForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, afterReadingForm);
        showHideAllProps({ "edit-selected-record": "block", "new-form": "block", "records-list-table": "block" });
    }
    const afterReadingForm = (data) => {
        console.log("After reading form", data);
        if ((data["fields"] == undefined) || (!Array.isArray(data["fields"]))) data["fields"] = [];
        setTheObject({ ...data });
        setFormItems([...data["fields"]]);
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const createNewForm = (application) => {
        AxiosNetwork.axiosPostMono({ "url": `/api/${appPath}/create` }, { "tabs": 1, "application": application, "name": "Untitled " + moment().format("llll") }, recordsList, setRecordsList);
    };
    const containers = ['A', 'B', 'C'];
    const [parent, setParent] = useState(null);
    const draggableMarkup = (
        <Draggable id="draggable">Drag me</Draggable>
    );

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": "/api/common/folders/appid/" + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": "/api/common/labels/appid/" + appId }, setLabelList);
        findAll();
    }, []);

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-form": "none", "records-list-table": "block" });
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-form": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const duplicate = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosGet({ "url": "/api/forms/duplicate", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = () => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "new-form": "none", "records-list-table": "block" });
    }
    function handleDragEnd(event) {
        console.log(event);
        const { active, over } = event;
        console.log(items);
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-form": "none", "records-list-table": "block" });
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["message_after_submit"] = "";
        values["upsert"] = true;
        values["fields"] = formItems;
        AxiosNetwork.axiosPut({ "url": "/api/forms/update/" + theObject["id"] }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    };
    const afterNewRecordSubmit = () => {
        message.info('Your forms has been saved.');
        findAll();
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "new-form": "block", "records-list-table": "none" });
    }
    const updateClicked = () => {
        saveFormItem(updateFormItem);
        setFormItems(formItems);
    }
    const onRenderFormFinish = (values) => {
        console.log(values);
    }
    const onRenderFormFinishFailed = (values) => {
        console.log(values);
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </section>
                        <section className="thCard" style={{ marginBottom: "50px","width": 600, "display": presentObjects["new-form"] }}>

                            <Card>
                                <Form size="large" labelAlign="right" layout="vertical"
                                    name="basic"
                                    onFinish={onFinish}
                                    form={newformForm} initialValues={theObject}
                                    onFinishFailed={newformFailed} autoComplete="off">

                                    <Form.Item label="Name" name="name"
                                        rules={[{ required: true, message: 'Type  your   name in this field' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Description"name="description"
                                        rules={[{ required: true, message: 'Type theDescription' }]}>
                                        <CKEditor
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement()
                                                );
                                                editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                            }}
                                            onError={(error, { willEditorRestart }) => {
                                                if (willEditorRestart) {
                                                    this.editor.ui.view.toolbar.element.remove();
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                ckEditorData = editor.getData();
                                                console.log(ckEditorData);
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                            editor={DecoupledEditor}
                                            data={((theObject["description"] != undefined) ? theObject["description"] : "")}

                                        />
                                    </Form.Item>
                                    <Form.Item label="Message after submit" name="message_after_submit"
                                        rules={[{ required: true, message: 'Type the message after submit' }]}>
                                        <CKEditor
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement()
                                                );
                                                editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                            }}
                                            onError={(error, { willEditorRestart }) => {
                                                if (willEditorRestart) {
                                                    this.editor.ui.view.toolbar.element.remove();
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                ckEditorData = editor.getData();
                                                console.log(ckEditorData);
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                            editor={DecoupledEditor}
                                        />
                                    </Form.Item>

                                    <Form.Item label="Access"name="access"  >
                                        <Radio.Group name="radiogroup" value={0} initialValues={0}>
                                            <Radio value={0}>Public</Radio>
                                            <Radio value={1}>Private</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="Type" name="genre" >
                                        <Select>
                                            <Select.Option value="FORM">Form</Select.Option>
                                            <Select.Option value="CHECKLIST">Checklist</Select.Option>
                                            <Select.Option value="SURVEY">Survey</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                        </Form.Item>

                                </Form>
                                </Card>
                        </section>

                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                            <div className="thCard" style={{ marginBottom: "50px","width": 900, border: "1px solid gray", display: presentObjects["form"] }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12} >

                                        <SortableContext items={formItemIds} strategy={verticalListSortingStrategy} >
                                            {formItems.map(item => <SortableItem key={item.id} id={item.id} form={item} callback={formItemActions} />)}
                                        </SortableContext>

                                        <DndContext onDragEnd={handleDragEnd}>
                                            {parent === null ? draggableMarkup : null}

                                            <Select style={{ width: '100%' }} placeholder="New Item" onChange={(e) => addNewFormItem(e)}>
                                                {Object.keys(formItemTemplate).map(eachItem => (
                                                    <Option key={eachItem}>{formItemTemplate[eachItem]["label"]}</Option>
                                                ))}
                                            </Select>

                                            {containers.map((id) => (
                                                // We updated the Droppable component so it would accept an `id`
                                                // prop and pass it to `useDroppable`
                                                <Droppable key={id} id={id}>
                                                    {parent === id ? draggableMarkup : 'Drop here'}
                                                </Droppable>
                                            ))}
                                        </DndContext>
                                    </Col>
                                    <Col span={12} >
                                        <Form style={{ border: "2px solid gray"}} layout="vertical"
                                            name="basic"
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                            initialValues={{ remember: true }}
                                            onFinish={onRenderFormFinish}
                                            onFinishFailed={onRenderFormFinishFailed}
                                            autoComplete="off"
                                        >
                                            {formItems.map(item => <FormElement key={item.id} id={item.id} form={item} />)}
                                            <Form.Item {...tailFormItemLayout}>
                                                <Button type="primary" htmlType="submit">Save</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <Button type="primary" onClick={(e) => updateClicked(e)}>Update</Button>
                        </section>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}