import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter,faCloudUploadAlt, faEraser, faRefresh, faSave, faEye } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems,appProfile, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Dragger } = Upload;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "products";
const tableName = "products";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function PosProduct() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [brandList, setBrandList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    const [variantTypeList, setVariantTypeList] = useState([]);
    const [taxList, setTaxList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({"products": "none",  "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [posProductForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);
    
    useEffect(() => {
        theObject["id"]= generateFuzzyId("products")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/brands` }, { "doc_name": "brands" }, setBrandList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/product-types` }, { "doc_name": "product-types" }, setProductTypeList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/shared-common/find-any-multi` }, { "doc_name": "tax_assessments" }, setTaxList);
        AxiosNetwork.axiosFetch({ "url": `api/public/pos/variants` }, setVariantTypeList);
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/pos-admin/${tableName}`);
        findAll();
    }, []);

const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Brand',
            dataIndex: 'brand_name'
        },
        {
            title: 'Product Type',
            dataIndex: 'product_type_name'
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }
    ];
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/pos/products/${selectedRow["id"]}`, "id": selectedRow.id }, userRecordsList, setUserRecordsList);
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/products/"+ theObject["id"] +"/update" },values, recordsList, setRecordsList, afterNewRecordSubmit );
        showHideAllProps({ "product-details": "block","records-list-table": "block" });
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your product details has been saved.');
        posProductForm.resetFields();
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/products` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "block", "product-details": "block", "records-list-table": "block" });
    }
 
    const posProductFormFailed = () => {
    }

    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        setTheObject({ ...selectedRow });
        posProductForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/products/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "product-details": "block", "edit-selected-record": "block","records-list-table": "block" });
    }
 
    return (
                <section>
                <Layout className='contentLayout'>
                        <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                        </section>
                        <section className="thCard" style={{ "width": 750, "display": presentObjects["product-details"] }}>
                            <Form size="large" labelAlign="right" layout="vertical"
                                onFinish={onFinish}
                                form={posProductForm} initialValues={theObject}
                                onFinishFailed={posProductFormFailed} autoComplete="off">
                                <Form.Item label="Name"name="name"
                                        rules={[{required: true,message: 'Enter your name',}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Description" name="description"
                                    rules={[{ required: true, message: 'Type theDescription' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                        data={((theObject["description"]!=undefined) ? theObject["description"] : "") }
                                        />
                                </Form.Item>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Brand" name="brand_id"
                                        rules={[{required: true,message: 'Enter your Brand',}]}>
                                        <Select showSearch allowClear  placeholder="Select Brand">
                                            {brandList.map(eachItem => (
                                                <Option key={eachItem.id}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="Product type" name="product_type_id"
                                        rules={[{required: true, message: 'Enter your Product type',}]}>
                                        <Select showSearch allowClear  placeholder="Select Product type">
                                            {productTypeList.map(eachItem => (
                                                <Option key={eachItem.id}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Form.Item label="Upload Files" name="uploadFiles">
                                    <Dragger {...fileUploadProps}>
                                        <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
                                        <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
                                    </Dragger>
                                </Form.Item>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Code" name="code"
                                        rules={[{required: false,message: 'Enter your Code',}]}>
                                    <Input/>
                                </Form.Item> 
                                </Col>
                                <Col span={12}>  
                                <Form.Item label="Code Type"name="code_type"
                                    rules={[{required: false,message: 'Enter your Code Type',}]}>
                                <Input/>
                                </Form.Item>  
                                </Col>
                                </Row>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Ready" name="ready" >
                                <Select showSearch allowClear >
                                        <Select.Option key={true}>Yes</Select.Option>
                                        <Select.Option key={false}>No</Select.Option>
                                </Select>
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="Inventory Tracking" name="has_inventory" >
                                <Select showSearch allowClear >
                                        <Select.Option key={true}>Yes</Select.Option>
                                        <Select.Option key={false}>No</Select.Option>
                                </Select>
                                </Form.Item>
                            
                            <Form.Item label="Trade Type" name="trade_type" >
                                    <Select>
                                        <Select.Option key="Buy">Buy</Select.Option>
                                        <Select.Option key="Sell">Sell</Select.Option>
                                        <Select.Option key="Both">Both</Select.Option>
                                </Select>
                                </Form.Item>
                                </Col>
                                </Row>
                                 <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Variant type"name="variant_id"
                                        rules={[{required: true,message: 'Enter your "Variant type',}]}>
                                        <Select showSearch allowClear  placeholder="Select Variant type">
                                            {variantTypeList.map(eachItem => (
                                                <Option key={eachItem.id}>{eachItem.id}</Option>
                                            ))}
                                        </Select>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                        </section>
                    </Layout>
            </section>
        )
}