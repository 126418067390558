import React, { useState, useEffect } from 'react';
import '../App.css';
import moment from 'moment-timezone';
import { Layout, Typography,  Table,   } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
const { Content } = Layout;
var columnSchema = [];
const appId = 55;
const appPath = "forms";
const tableName = "forms";

export default function FormsDataResponses() {
    const [theObject, setTheObject] = useState({ "fields": [], "name": "Type your summary here", "mime_assets": [] });
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [formResponseList, setFormResponseList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "after-form-submission": "none", "form-submission": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const queryParams = queryString.parse(window.location.search);
    console.log(queryParams);

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    useEffect(() => {
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + queryParams.id, "id": queryParams.id }, theObject, setTheObject, afterReadingFormTemplate);
        showHideAllProps({ "records-list-table": "block" });
    }, []);
    const renderFormResponses = (data) => {
        var prepareResponseList = [];
        data["upsert"] = true;
        data.forEach(eachResponse => {
            if(typeof(eachResponse["responses"]) != "object") return;
            eachResponse["responses"]["th_created_at"] = eachResponse["created_at"];
            eachResponse["responses"]["id"] = eachResponse["id"];
            prepareResponseList.push({...eachResponse, ...eachResponse["responses"]});
        });
        setFormResponseList(prepareResponseList);
    }
    const afterReadingFormTemplate = (data) => {
        console.log("afterReadingFormTemplate", data);
        if((data["fields"] == undefined) || (!Array.isArray(data["fields"]))) data["fields"] = [];
        setTheObject({ ...data });

        //construct table header
        columnSchema = [];
        data["fields"].forEach(eachFormItem => {
            columnSchema.push({"key":eachFormItem["name"], "title":eachFormItem["label"], "dataIndex":eachFormItem["name"]});
        });
        columnSchema.push({title: 'Created At', render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>});
        AxiosNetwork.axiosFetchMulti({ "url": "/api/form-responses/list/"+queryParams["id"] }, {}, setRecordsList, renderFormResponses);
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>

            <Layout className='contentLayout'>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}>
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                       <Typography.Title level={1} style={{ margin: 0 }}>{theObject.name}</Typography.Title>
                       <div dangerouslySetInnerHTML={{ __html: theObject["description"] }} />
                       <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={formResponseList} />
                    </section>
                </Content>
            </Layout>
        </Layout>
    )
}