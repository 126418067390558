import React, { useState, useEffect } from 'react';

import './App.css';
import store from 'store2';
import { AxiosNetwork } from "./axiosService";
import { Button, Card, Form, Input, Typography, Layout, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
const { Header, Footer, Content } = Layout;

function Logout() {

    const params = useParams();
    const [tempList, setTempList] = useState([]);
    const [theObject, setTheObject] = useState({ "body": "" });

    useEffect(() => {
        localStorage.removeItem("rest-cloud-user-auth-token");
        AxiosNetwork.axiosGet({ "url": "api/public/common/templates/user-logout-message" }, theObject, setTheObject);
    }, []);


    return (
        <div>
            <div className="pageCenter" style={{ width: 800 }}>
                <div
                    dangerouslySetInnerHTML={{ __html: theObject.body }}
                />
            </div>
        </div>
    );
}

export default Logout;