import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "suppliers";
const tableName = "suppliers";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function PosSupplierProduct() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [addressList, setAddressList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({"suppliers":"none", "suppliers-details": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [posSupplierForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("suppliers")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, { "doc_name": "addresses" }, setAddressList);
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/pos-admin/${tableName}`);
        findAll();
    }, []);
    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Default markup ',
            dataIndex: 'supply_price'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        }
    ];


    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "records-list-table": "block" });
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/suppliers/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "supplier-details": "block", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your supplier details has been saved.');
        posSupplierForm.resetFields();
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/suppliers` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "block", "supplier-details": "block", "records-list-table": "block" });
    }
    const posSupplierFormFailed = () => {

    }
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        setUiLabels({ "save": "Update" });
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        posSupplierForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/suppliers/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "supplier-details": "block", "edit-selected-record": "block", "records-list-table": "block" });
    }
    
    return (
        <section>
            <Layout className='contentLayout'>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                    </section>
                    <section className="thCard" style={{ "width": 750, "display": presentObjects["supplier-details"] }}>
                        <Form size="large" labelAlign="right" layout="vertical"
                                onFinish={onFinish}
                                form={posSupplierForm} initialValues={theObject}
                                onFinishFailed={posSupplierFormFailed} autoComplete="off">
                                <Form.Item label="Name" name="name"
                                    rules={[{ required: true, message: 'Enter your name', }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Description" name="description"
                                    rules={[{ required: true, message: 'Type theDescription' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                        data={((theObject["description"] != undefined) ? theObject["description"] : "")}

                                    />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Default markup " name="supply_price">
                                            <InputNumber style={{ width: 300 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Address " name="address_id"
                                            rules={[{ required: true, message: 'Enter your Address ', }]}>
                                            <Select showSearch allowClear  placeholder="Select Address ">
                                                {addressList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                            </section>
                        </Layout>
        </section>
    )
}