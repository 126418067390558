import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faBackward, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faPeopleGroup, faLaptopHouse } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, dateTimeUtils } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, DatePicker, Button, Table, Space,Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "amcs";
const tableName = "amcs";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function AMC() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [supplierList, setSupplierList] = useState([]);
    const [purchasesList, setPurchasesList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section":"none","amcs": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [amcsForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_id',
            width: 100
        },
        {
            title: 'Purchases',
            dataIndex: 'purchase_id',
            width: 100
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            widht: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }
    ];

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        theObject["id"] = generateFuzzyId("amcs")
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setSupplierList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/purchases` }, { "doc_name": "purchases" }, setPurchasesList);
        findAll();
    }, []);


    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "records-list-table": "block" });

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/asset-management/amcs/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD");
        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD");
        AxiosNetwork.axiosPut({ "url": "api/admin/asset-management/amcs/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "button-controls-section":"none","amcs-details": "block", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = () => {
        message.info('Your amcs details has been saved.');
        amcsForm.resetFields();
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/asset-management/amcs` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "button-controls-section":"none","edit-selected-record": "none", "amcs-details": "none", "records-list-table": "block" });
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/asset-management/amcs/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, amcCallback);
    }
    const amcCallback = (data) => {
        if (data["start_at"] != undefined) data["start_at"] = moment(data["start_at"]);
        if (data["end_at"] != undefined) data["end_at"] = moment(data["end_at"]);
        setTheObject({ ...data });
        amcsForm.setFieldsValue({ ...data });
        showHideAllProps({"button-controls-section":"block", "amcs-details": "block", "records-list-table": "none", "edit-selected-record": "block" });
    }

    const amcsFormFailed = () => {

    }

    const applyAmc = () => {
        showHideAllProps({ "button-controls-section":"block","amcs-details": "block", "records-list-table": "none", "edit-selected-record": "block" });
        amcsForm.resetFields();
    }

    return (
        <Layout className='contentLayout'>
             <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "amcs-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table style={{ marginBottom: "20px" }} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                <div style={{ marginTop: 10 }}>
                    <Space size={10}>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => applyAmc()}>  New Amc  </Button>
                    </Space>
                </div>
            </section>
            <section className="thCard" style={{ "width": 700, "display": presentObjects["amcs-details"] }} >
                <Form form={amcsForm} labelAlign="right" layout="vertical" size={"large"} name="basic"
                    onFinish={onFinish} onFinishFailed={amcsFormFailed} initialValues={theObject} autoComplete="off">
                    <Form.Item label="Name" name="name" rules={[{ required: false, message: 'Enter your Name' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Purchases" name="purchase_id" rules={[{ required: false, message: 'Enter your Purchases' }]}>
                        <Select showSearch allowClear placeholder="Select Purchases">
                            {purchasesList.map(eachItem => (
                                <Option key={eachItem.id}>{eachItem.id}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Supplier" name="supplier_id" rules={[{ required: true, message: 'Enter your Supplier' }]}>
                        <Select showSearch allowClear placeholder="Select supplier">
                            {supplierList.map(eachItem => (
                                <Option key={eachItem.name}>{eachItem.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Start At" name="start_at">
                        <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                    </Form.Item>
                    <Form.Item label="End At" name="end_at">
                        <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                    </Form.Item>
                    <Form.Item label="Mime Asset" name="mime_asset_id" rules={[{ required: true, message: 'Enter your Mime Asset', }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </section>
        </Layout>
    )
}