import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTrashCan, faEdit, faUndoAlt,  faSave,  faInfoCircle, faCloudUploadAlt, faDownload } from '@fortawesome/free-solid-svg-icons'
import { findUrlPath, appProfile, generateFuzzyId, profileBarItems, footerLine, menuBarItems, socialAuthProps } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import { v4 as uuid } from 'uuid';

const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 21;
const appPath = "projects";
const tableName = "projects";
var activeDataColumns = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };
let ckEditorData = "";

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const cdnFileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/cdn-file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};
export default function ProjectInfo(props) {
    const [theObject, setTheObject] = useState(props.appDoc);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "new-record": " block", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "composeNote": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [noteForm] = Form.useForm();
    const [searchedRecords, setSearchedRecords] = useState({ "workflows": [] });
    const [formList, setFormList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(props.appDoc);

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/` + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/` + appId }, setLabelList);
        const filter = { "col_name": "genre", "operator": "=", "data_type": "string", "string_value": "FORM" };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "forms", "filter": [filter] }, setFormList);
        composeNote();
    }, []);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 150,
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },

        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        noteForm.setFieldsValue({ ...selectedRow });
        formData = {};
        dataMassage["noteId"] = selectedRow.id;
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "new-record": "block" });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const newRecord = () => {
        dataMassage["noteId"] = generateFuzzyId(tableName);
        formData = {};
        formData["parent_id"] = dataMassage["noteId"];
        formData["folder_id"] = dataMassage["noteId"];
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "new-record": "block", "records-list-table": "none", });
    }

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "new-record": "block", "records-list-table": "block" });
    }

    const composeNote = () => {
        showHideProps("composeNote", "block");
        theObject["emailAddress"] = [];
        formData = {};
        formData["parent_id"] = dataMassage["noteId"]
        formData["folder_id"] = dataMassage["noteId"];
    };

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPostMono({ "url": `/api/${appPath}/${theObject["id"]}/upsert` }, values, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values)
    }

    const afterNewRecordSubmit = () => {
        message.info('Your project info has been saved.');
        findAll();
    }

    const searchRecordsLike = (value, table) => {
        if (value === null || value === "") return;
        var payload = { "select": ["id", "name"], "doc_name": table, "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMultiProps({ "url": `/api/common/find-select-any-multi-like` }, payload, table, searchedRecordsCallback);
    }

    const searchedRecordsCallback = (field, value) => {
        setSearchedRecords({ ...searchedRecords, [field]: value });
    }

    const noteFormFailed = () => {
    }

return (
        <Layout style={{ minHeight: '100vh' }}>
            <section className="thCard" style={{ "width": 700, "display": presentObjects["new-record"] }} >
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onFinish} form={noteForm} onFinishFailed={noteFormFailed}
                    autoComplete="off" initialValues={theObject}>
                    <Form.Item label="Name" name="name"
                        rules={[{ required: true, message: 'Enter your task' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Type theDescription' }]}>
                        <CKEditor
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                                editor.editing.view.change((writer) => { writer.setStyle("height", "100px", editor.editing.view.document.getRoot()); });
                            }}
                            onError={(error, { willEditorRestart }) => {
                                if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                }
                            }}
                            onChange={(event, editor) => {
                                ckEditorData = editor.getData();
                                console.log(ckEditorData);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                            editor={DecoupledEditor} data={theObject["description"]}
                        />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Workflow"
                                name="workflow_id"
                                rules={[{ message: 'Type the workflow' }]}>
                                <Select notFoundContent={null} showSearch onSearch={(e) => searchRecordsLike(e, "workflows")} placeholder="Select Wokflow">
                                    {searchedRecords["workflows"].map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="form_id" label="Forms" rules={[{ required: true, message: 'Enter the Forms', }]}>
                                <Select style={{ width: '100%' }} placeholder="Select Forms">
                                    {formList.map(eachItem => (
                                        <Option key={eachItem["id"]}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
                <div style={{ marginTop: "20px" }}>
                    {theObject["mime_assets"].map(eachFile => (
                        <p key={eachFile.id}><a href={appProfile.serviceLocation + "api/file-assets/mime/download/" + eachFile.id + "/" + eachFile.name}><FontAwesomeIcon icon={faDownload} /></a> &#x2002; <span>{eachFile.name}</span></p>
                    ))}
                </div>
                <Row gutter={16}>
                    <Col>
                        <Dragger {...fileUploadProps}>
                            <p className="ant-upload-drag-icon">
                                <FontAwesomeIcon icon={faCloudUploadAlt} />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Col>
                </Row>
            </section>
        </Layout>
    )
}
