import React, { useState, useEffect } from 'react';
import '../../App.css';
import { generateFuzzyId } from "../../Config";
import { Divider, Form, Input,   Upload, Button, message, Space,Card,Row,Col, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTrashCan,faSave, faEdit, faBackward} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone';

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-bank";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";

export default function HumanResourceBank() {
    const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourceBankForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("banks")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetch({ "url": `/api/public/common/countries` }, setCountryList);
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanResourceBankForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/banks/user-banks/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({"button-controls-section":"block","records-list-table": "none", "bank-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
      }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/banks/user-banks` },  setRecordsList);
        showHideAllProps({"button-controls-section":"none","records-list-table": "block", "bank-details": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const humanResourceBankFailed = () => {
    }
    const onFinishFailed = () => {
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/banks/user-banks/" +theObject["id"]+ "/update"  }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your bank details has been saved.');
        humanResourceBankForm.resetFields();
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": "/api/organizations", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
      
    const applyBank = ( ) => {
        showHideAllProps({"button-controls-section":"block","records-list-table": "none", "bank-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
        humanResourceBankForm.resetFields();
    }

    const columnSchema = [
        {
            title: 'Beneficiary Name',
            dataIndex: 'name',
        },
        {
            title: 'Ifsc code',
            dataIndex: 'ifsc',
        },
        {
            title: 'Account type',
            dataIndex: 'account_type',
        },
        {
            title: 'Account number',
            dataIndex: 'account_number',
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 60
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Action',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];


    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "bank-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyBank ()}>  Bank  </Button>

                   </ section>
                    <section className="thCard" style={{ "width": 800, "display": presentObjects["bank-details"] }} >
                            <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanResourceBankForm} initialValues={theObject}
                            onFinishFailed={humanResourceBankFailed} autoComplete="off">
                            <Form.Item label="Bank Name" name="bank_name"
                                rules={[{ required: true, message: 'Type  your bank name in this field' }]}>
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Branch Name" name="branch_name"
                                rules={[{ required: true, message: 'Type  your branch name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Account Number" name="account_number"
                                rules={[{ required: true, message: 'Type  your account number in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                           <Row gutter={16}>
                            <Col span={12}> 
                            <Form.Item label="Ifsc code" name="ifsc"
                                rules={[{ required: true, message: 'Type  your  Ifsc code in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Account type" name="account_type"
                                rules={[{ required: true, message: 'Type  your  Account type in this field' }]}>
                                       <Select showSearch  allowClear>
                                        <Select.Option value="Saving">Saving</Select.Option>
                                        <Select.Option value="Current">Current</Select.Option>
                                    </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={8}>
                            <Form.Item label="Address Line 1" name="address_line_1"
                                    rules={[{ required: true, message: 'Type  your  address in this field' }]}>
                                    <Input />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Address Line 2" name="address_line_2">
                                    <Input />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Address Line 3" name="address_line_3">
                                    <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={8}>
                            <Form.Item label="City" name="city"
                                    rules={[{ required: true, message: 'Type  your city in this field' }]}>
                                    <Input />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="State" name="state"
                                    rules={[{ required: true, message: 'Type  your state in this field' }]}>
                                    <Input />
                            </Form.Item>
                            </Col>
                            <Col span ={8}>
                            <Form.Item name="country" label="Country" rules={[{ message: 'Please input Country', required:true }]}>
                                <Select showSearch optionFilterProp="children" placeholder="select your Country" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {countryList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item name="mobile" label="Mobile Number"
                                    rules={[{ required: true, message: 'Please input your Mobile number!' }]} >
                                    <Input addonBefore={prefixSelector} style={{width: '100%',}}/>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Beneficiary Name"name="name"
                                rules={[{ required: true, message: 'Type  your beneficiary name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                        </section>
            </Layout>
        </section>
    )
}