import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId } from "../../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan,faSave,faEdit, faBackward} from '@fortawesome/free-solid-svg-icons'
import { Divider, Form, Input, prefixSelector, DatePicker, Card,Space, Row,Col, message, Select,Button, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import moment from 'moment';
import { View } from 'paper/dist/paper-core';
import FormItem from 'antd/es/form/FormItem';
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-emergency-contacts";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";


export default function HumanResourceEmergencyContacts() {
    const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourceEmergencyContactsForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [relationList, setRelationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"]= generateFuzzyId("human-resource")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employee-emergency-relation" }, setRelationList);
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanResourceEmergencyContactsForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url":`/api/emergency-contacts/`+ selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "button-controls-section":"block","records-list-table":"none","emergency-contacts": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/emergency-contacts` }, setRecordsList);
        showHideAllProps({"button-controls-section":"none","records-list-table":"block","emergency-contacts": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const humanResourceEmergencyContactsFailed = () => {
    }
   
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/emergency-contacts/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your Emergency Contacts details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
  
    const  applyContact = ( )  => {
        showHideAllProps({"button-controls-section":"block","records-list-table":"none","emergency-contacts": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
        humanResourceEmergencyContactsForm.resetFields();
    }
    const columnSchema = [
        {
            title: 'Contact Name',
            dataIndex: 'name',
        },
        {
            title: 'Address_Line_1',
            width: 100,
            dataIndex: 'address_id',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
        },
        {
            title: 'Contact Relationship',
            dataIndex: 'relation_type_id',
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];

    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "emergency-contacts": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
          
               <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyContact ()}>  Contact  </Button>

                    </section>
                    <section className="thCard" style={{ "width": 700, "display": presentObjects["emergency-contacts"] }} >
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanResourceEmergencyContactsForm} initialValues={theObject}
                            onFinishFailed={humanResourceEmergencyContactsFailed} autoComplete="off">
                            <Form.Item label="Contact Name"name="name"
                                rules={[{ required: true, message: 'Type  your contact name in this field' }]}>
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Contact Relationship" name="relation_type_id" >
                                <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Relation">
                                    {relationList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Gender" name="gender" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="Male">Male</Select.Option>
                                    <Select.Option value="Female">Female</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Address Line 1" name="address_id"
                                rules={[{ required: true, message: 'Type  your contact address in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Address Line 2"name="address_line_2">
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Address Line 3" name="address_line_3">
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item name="mobile" label="Mobile Number"
                                rules={[{ required: true, message: 'Please input your mobile number!' }]} >
                                <Input addonBefore={prefixSelector} style={{width: '100%'}}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                           </Form>
                           </section>
              </Layout>
        </section>
    )

}