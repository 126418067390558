import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faBackward, faFilter, faEraser, faRefresh, faSave } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, dateTimeUtils } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, DatePicker, Menu, Card, Row, Button, Space, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import AssetUsers from "./AssetUsers";
import Purchases from "./Purchases";
import Warrently from "./Warrently";
import AMC from "./AMC";
import Manual from "./Manual";
import Services from "./Services";
import Category from "./Category";
import Insurance from "./Insurance";
import Movements from "./Movements";


const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "asset-management";
const tableName = "asset-management";
var activeDataColumns = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function AssetManagement() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "new-record": "none", "category": "none", "movements": "none", "insurances": "none", "services": "none", "manuals": "none", "amc": "none", "warrently": "none", "purchases": "none", "asset-users": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [categoryList, setCategoryList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [purchasesList, setPurchasesList] = useState([]);
    const [costCentreList, setCostCentreList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [amcsList, setAmcsList] = useState([]);
    const [warrantyList, setWarrantyList] = useState([]);
    const [manualsList, setManualsList] = useState([]);
    const [insurancesList, setInsurancesList] = useState([]);
    const [assetManagementForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Code',
            dataIndex: 'identifier'
        },
        {
            title: 'Type',
            dataIndex: 'type'
        },
        {
            title: 'Cost Centre',
            dataIndex: 'cost_centre_id'
        },
        {
            title: 'Branch',
            dataIndex: 'branch'
        },
        {
            title: 'Usage',
            dataIndex: 'usage'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            widht: 50
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        selectedRow["mime_assets"] = [];
        console.log(selectedRow);
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/asset-management/assets/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, assetCallback);
    }

    const assetCallback = (data) => {
        if (data["start_at"] != undefined) data["start_at"] = moment(data["start_at"]);
        if (data["end_at"] != undefined) data["end_at"] = moment(data["end_at"]);
        if (data["expiry_at"] != undefined) data["expiry_at"] = moment(data["expiry_at"]);
        if (data["depreciated_at"] != undefined) data["depreciated_at"] = moment(data["depreciated_at"]);
        setTheObject({ ...data });
        assetManagementForm.setFieldsValue({ ...data });
        showHideAllProps({ "button-controls-section": "block", "edit-selected-record": "block", "new-record": "block", "records-list-table": "none" });

    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/categories` }, { "doc_name": "category" }, setCategoryList);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setSupplierList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/purchases` }, { "doc_name": "purchases" }, setPurchasesList);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setCostCentreList);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setBranchList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/amcs` }, { "doc_name": "amcs" }, setAmcsList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/warranties` }, { "doc_name": "warranties" }, setWarrantyList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/manuals` }, { "doc_name": "manuals" }, setManualsList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/insurances` }, { "doc_name": "insurances" }, setInsurancesList);

    }, []);

    const newRecord = () => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        setUiLabels({ "save": "Save" });
        showHideAllProps({ "button-controls-section": "block", "new-record": "block", "records-list-table": "none" });
    }
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/asset-management/assets/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/asset-management/assets` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "new-record": "none", "edit-selected-record": "none", "records-list-table": "block" });
    }

    const onFinish = (values) => {
        values["value"] = "";
        values["description"] = ckEditorData;
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD");
        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD");
        values["expiry_at"] = moment(values["expiry_at"]).format("yyyy-MM-DD");
        values["depreciated_at"] = moment(values["depreciated_at"]).format("yyyy-MM-DD");
        AxiosNetwork.axiosPut({ "url": "api/admin/asset-management/assets/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, newRecordCallback);
    }
    const newRecordCallback = (data) => {
        message.info('Asset Management has been created');
        assetManagementForm.resetFields();
        console.log(data);
        showHideAllProps({ "button-controls-section": "none", "new-record": "none", "edit-selected-record": "none", "records-list-table": "block" });

    }
    const assetMangementFormFailed = () => {

    }

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }

    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }

    const showOtherComponents = (sectionName) => {
        var toggleSections = { "new-record": "none", "category": "none", "movements": "none", "insurances": "none", "services": "none", "manuals": "none", "amcs": "none", "asset-users": "none", "warrently": "none", "purchases": "none", "records-list-table": "none" };
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['all-records']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll("all")} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin()} rel="noopener noreferrer">Bin</a>)
                            },
                            { label: (<a onClick={e => showOtherComponents("asset-users")} href="#">Asset User </a>), "key": "asset-users" },
                            { label: (<a onClick={e => showOtherComponents("purchases")} href="#">Purchases</a>), "key": "purchases" },
                            { label: (<a onClick={e => showOtherComponents("warrently")} href="#">Warrently</a>), "key": "warrently" },
                            { label: (<a onClick={e => showOtherComponents("amcs")} href="#">AMC</a>), "key": "amcs" },
                            { label: (<a onClick={e => showOtherComponents("manuals")} href="#">Manual</a>), "key": "manuals" },
                            { label: (<a onClick={e => showOtherComponents("services")} href="#">Services</a>), "key": "services" },
                            { label: (<a onClick={e => showOtherComponents("category")} href="#">Category</a>), "key": "category" },
                            { label: (<a onClick={e => showOtherComponents("insurances")} href="#">Insurance</a>), "key": "insurances" },
                            { label: (<a onClick={e => showOtherComponents("movements")} href="#">Movements</a>), "key": "movements" },

                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>
                        {(presentObjects["asset-users"] == "block") &&
                            <section>
                                <AssetUsers />
                            </section>
                        }
                        {(presentObjects["purchases"] == "block") &&
                            <section>
                                <Purchases />
                            </section>
                        }
                        {(presentObjects["warrently"] == "block") &&
                            <section>
                                <Warrently />
                            </section>
                        }
                        {(presentObjects["amcs"] == "block") &&
                            <section>
                                <AMC />
                            </section>
                        }
                        {(presentObjects["manuals"] == "block") &&
                            <section>
                                <Manual />
                            </section>
                        }
                        {(presentObjects["services"] == "block") &&
                            <section>
                                <Services />
                            </section>
                        }
                        {(presentObjects["category"] == "block") &&
                            <section>
                                <Category />
                            </section>
                        }
                        {(presentObjects["insurances"] == "block") &&
                            <section>
                                <Insurance />
                            </section>
                        }
                        {(presentObjects["movements"] == "block") &&
                            <section>
                                <Movements />
                            </section>
                        }


                        <section style={{ "display": presentObjects["button-controls-section"] }}>
                            <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                                <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                            </Space>
                        </section>
                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                        </section>

                        <section style={{ "display": presentObjects["edit-selected-record"] }}>
                        </section>
                        <section className="thCard" style={{ "width": 700, "display": presentObjects["new-record"] }} >
                            <Form form={assetManagementForm} labelAlign="right" layout="vertical" size={"large"} name="basic"
                                onFinish={onFinish} onFinishFailed={assetMangementFormFailed} initialValues={theObject} autoComplete="off">
                                <Form.Item label="Name" name="name" rules={[{ required: false, message: 'Enter your name' }]}>
                                    <Input />
                                </Form.Item>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Identifier" name="identifier" rules={[{ required: true, message: 'Enter your identifier' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Category" name="category_id" rules={[{ required: true, message: 'Enter your category' }]}>
                                            <Select allowClear showSearch placeholder="Select Category">
                                                {categoryList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Supplier" name="supplier_id" rules={[{ required: true, message: 'Enter your supplier' }]}>
                                            <Select allowClear showSearch placeholder="Select Supplier">
                                                {supplierList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Purchase" name="purchase_id" rules={[{ required: false, message: 'Enter your purchase' }]}>
                                            <Select allowClear showSearch placeholder="Select Purchase">
                                                {purchasesList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Ownership" name="ownership" >
                                            <Select allowClear showSearch placeholder="Select Ownership">
                                                <Select.Option key="rental">Rental</Select.Option>
                                                <Select.Option key="Owned">owned</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Cost centre" name="cost_centre_id" rules={[{ required: true, message: 'Enter your cost centre' }]}>
                                            <Select allowClear showSearch placeholder="Select Cost Centre">
                                                {costCentreList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Branch" name="branch" rules={[{ required: true, message: 'Enter your branch' }]}>
                                            <Select allowClear showSearch placeholder="Select Branch">
                                                {branchList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Usage" name="usage" >
                                            <Select allowClear showSearch >
                                                <Select.Option key="1 Shifts">1 Shifts</Select.Option>
                                                <Select.Option key="2 Shifts">2 Shifts</Select.Option>
                                                <Select.Option key="3 Shifts">3 Shifts</Select.Option>
                                                <Select.Option key="4 Shifts">4 Shifts</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Start At" name="start_at">
                                            <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="End At" name="end_at">
                                            <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Location" name="location" rules={[{ required: true, message: 'Enter your Location' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="AMC" name="amc_id" rules={[{ required: true, message: 'Enter your AMC' }]}>
                                            <Select allowClear showSearch placeholder="Select AMC">
                                                {amcsList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Warranty" name="warranty_id" rules={[{ required: true, message: 'Enter your warranty' }]}>
                                            <Select allowClear showSearch placeholder="Select  Warranty">
                                                {warrantyList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Manual" name="manual_id" rules={[{ required: true, message: 'Enter your manual' }]}>
                                            <Select allowClear showSearch placeholder="Select Manual">
                                                {manualsList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Insurance" name="insurance_id" rules={[{ required: true, message: 'Enter your insurance' }]}>
                                            <Select allowClear showSearch placeholder="Select Insurance">
                                                {insurancesList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Current Cost" name="current_cost">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Form.Item label="Purchase cost" name="purchase_cost" >
                                            <InputNumber style={{ width: '100%', }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Expiry At" name="expiry_at">
                                            <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Depreciated At" name="depreciated_at">
                                            <DatePicker style={{ width: '100%', }} format={dateTimeUtils["dateFormat"]} showTime={false} onOk={onOk} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Currency" name="currency" rules={[{ required: false, message: 'Enter your Currency' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Depreciation" name="deprication_id" rules={[{ required: true, message: 'Enter your Deprication' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Type the Description' }]}>
                                    <CKEditor
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                            // Insert the toolbar before the editable area.
                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                            editor.editing.view.change(writer => {
                                                writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                                            });
                                        }}
                                        onError={(error, { willEditorRestart }) => {
                                            if (willEditorRestart) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            ckEditorData = editor.getData();
                                            console.log(ckEditorData);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                        editor={DecoupledEditor}
                                        data={((theObject.description == null) ? "" : theObject.description)}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                        </section>
                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}