import React, { Component, useState, useEffect } from 'react';
import './App.css';
import { Form, Anchor, Typography, Avatar, Divider, List, Card, Col } from 'antd';
import { AxiosNetwork } from "./axiosService";
const { Meta } = Card;
const { Title } = Typography;


const suiteApps = {};

export default function AppSuite() {
    const [appsList, setAppsList] = useState([]);
    const [appsSuiteList, setAppsSuiteList] = useState({});

    useEffect(() => {
        AxiosNetwork.axiosFetchCallback({ "url": "/api/common/apps-suite" }, setAppsSuiteListCallback)
        AxiosNetwork.axiosFetch({ "url": "/api/common/apps" }, setAppsList, setAppsListCallback)
    }, []);

    const setAppsListCallback = (data) =>
    {
        data = data.filter(item => item.id !== "all");
        setAppsList(data);
    }

    const setAppsSuiteListCallback = (data) =>
    {
        data = data.filter(item => item.id !== "all");
        var appGroup = {};
        for(var i=0; i<data.length; i++)
        {
            const suiteId = data[i]["suite_id"];
            if(appGroup[suiteId]===undefined) appGroup[suiteId] = [];
            suiteApps[suiteId] = data[i];
            appGroup[suiteId].push(data[i]);
        }
        setAppsSuiteList(appGroup);
        console.log(appGroup);
    }

    return (
        <section>
            {Object.keys(appsSuiteList).map(appItem => (
            <div className='appCategory' key={'div-'+appItem}>
                <Divider orientation="center">{suiteApps[appItem]["suite_name"]} Suite</Divider>
                {appsSuiteList[appItem].filter(eachItem => eachItem.genre === "APP").map(eachItem => (
                    <div key={"div-"+appItem+"-"+eachItem.id} className='appCard boxes zoom-in'>
                        <a href={eachItem.href} key={'link-' + eachItem.id}>
                            <img className='appIcon' src={eachItem.icon} /> <div className='appName'>{eachItem.name}</div>
                        </a>
                    </div>
                ))}
            </div>
            ))}

            <div className='appCategory'>
                <Divider orientation="center">Utilities</Divider>
                {appsList.filter(eachItem => eachItem.genre === "UTILS").map(eachItem => (
                    <div key={"div-"+eachItem.id} className='appCard boxes zoom-in'>
                        <a href={eachItem.href} key={'link-' + eachItem.id}>
                            <img className='utilsIcon' src={eachItem.icon} /><div className='appName'>{eachItem.name}</div>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
}