import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTrashCan, faBackward, faCloudUploadAlt, faPeace, faEdit, faSave, faPrint, faInfo, faCircleInfo, faInfoCircle, faBars, faCircleUser, faGrip, faPlus, faTag, faFolder, faEllipsisV, faStar, faGripLinesVertical, faClose, faUser, faAngleDown, faSortDown, faCloudArrowDown, faFileArrowDown, faUndoAlt, faStarAndCrescent, faStarHalfAlt, faTags, faFileDownload, faCheckSquare, faAddressCard, faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { appProfile, dateTimeUtils, tableRenderUtils, generateFuzzyId, tablePagination, profileBarItems } from "../Config";
import '../App.css';
import Apps from '../Apps';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Pagination, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Space, Avatar, Select, Badge, Cascader, DatePicker, Tabs, TimePicker, Drawer, AutoComplete, Col, Checkbox, Grid, InputNumber } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { DoubleSide } from 'three';
const { TabPane } = Tabs;
const { Dragger } = Upload;
var formData = {};
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
const appId = 1;
const appPath = "contacts";
const tableName = "contacts";
const { Search } = Input;
let ckEditorData = "";
var contactPayload = { "doc_name": tableName, offset: 0, limit: 100 };
const dataMassage = { "emailAddress": {}, "associateId": "1", "refreshId":1, "pagination":{"total":1000} };
const plainOptions = [];
const defaultCheckedList = [];
var activeDataColumns = [];


export default function MiniContact(props) {
    const [countryList, setCountryList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "contact-form":"none" });
    const [newContactView, setContactView] = useState(true);
    const [addressesTypes, setAddressesTypes] = useState([{}]);
    const [addressesRecordsList, setAddressesRecordsList] = useState([]);
    const [appList, setAppList] = useState(false);
    const [addressForm] = Form.useForm();
    const [newContactHeaderView, setContactHeaderView] = useState(false);
    const [folderEditShow, setFolderEditShow] = useState(false);
    const [selectedRowsLength, setSelectedRowsLength] = useState(0);
    const [labelEditShow, setLabelEditShow] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [newContactForm] = Form.useForm();
    const [currentSelectId, setCurrentSelectId] = useState('');
    const [theObject, setTheObject] = useState({ "id": new Date().getTime() });
    const [theAddressObject, setTheAddressObject] = useState({ "id": generateFuzzyId("contact") });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);


    useEffect(() => {
        console.log("useEffect from MiniContact - "+theObject.id);
        setUiLabels({ "save": "Save" });
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
    }, [ ]);

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            render: (record) => <span>{record.email}</span>
        },
        {
            key:"edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>
        },
        {
            title: 'Address',
            width: 50,
            render: (record) => <a onClick={(e) => editTableAddressRow(record)}><FontAwesomeIcon icon={faAddressCard} /></a>
        },
        {
            key:"trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>
        },
        {
            key:"info",
            title: 'Info',
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            key:"restore",
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const contactAddressColumn = [
        {
            title: 'Type',
            dataIndex: 'genre',
            width: 50,
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50,
        },
        {
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editAddressTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteAddressTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }
    ];

    activeDataColumns = ((props.columnSchema!==undefined) ? props.columnSchema : columnSchema.filter(item => !["restore"].includes(item.key)));
    
    const onSelectChange = (selectedRowIds) => {
        if (selectedRowIds.target.checked === true) {
            selectedRows.push(selectedRowIds.target.value);
            setSelectedRows(selectedRows);
            console.log(selectedRows);
            console.log(selectedRows.length);
            setCurrentSelectId(selectedRowIds.target.value);

        }
        else {
            selectedRows.splice(selectedRows.indexOf(selectedRowIds.target.value), 1);
            setSelectedRows(selectedRows);
            console.log(selectedRows);
            console.log(selectedRows.length);

        };
        if (selectedRows.length == 0) {
            setContactHeaderView(false);
            setSelectedRowsLength(0);
        }
        else {
            setContactHeaderView(true);
            setSelectedRowsLength(selectedRows.length);
        }
    }

    const newRecord = () => {
        newContactForm.resetFields();
        showHideAllProps({ "contact": "block", "button-controls-section": "block", "showDownload": "none", "showUpload": "none", "contact-form": "block", "savedButton": "none", "records-list-table": "none" });
        setTheObject({ "id": generateFuzzyId("contact") });
    }

    const onCreateNewContact = (values) => {
        setPresentObjects({ ...presentObjects });
        console.log('Received values of form: ', values);
        message.success("Saved");
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/${theObject.id}/upsert` }, values, recordsList, setRecordsList, afterContactAdded);
    }

    const afterContactAdded = (data) => {
        console.log(data);
        const updateIndex = recordsList.findIndex((obj => obj.id == data.id));
        if (updateIndex != -1) { recordsList[updateIndex] = data; } else { recordsList.push(data); }
        setRecordsList([...recordsList]);
        showHideAllProps({ "contact": "none", "contact-form":"none", "address-list-table": "none", "address-form": false, "button-controls-section": "block", "savedButton": "none", "records-list-table": "none" });
        findAll();
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: 70 }}>
                {countryList.map(eachItem => (
                    <Option key={eachItem.isd}>{eachItem.isd}</Option>
                ))}
            </Select>
        </Form.Item>
    );

    const updateFolder = (e) => {
        AxiosNetwork.axiosUpdate({ "url": `/api/common/folder/update/` + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
        setFolderEditShow(false);
    }
    const updateLabel = (e) => {
        AxiosNetwork.axiosUpdate({ "url": `/api/common/label/update/` + theLabel.id, "id": theLabel.id }, theLabel, labelList, setTheLabel);
        setLabelEditShow(false);
    }
    const renameFolder = (e) => {
        theFolder["name"] = e.target.value;
        setTheFolder(theFolder);
    }
    const renameLabel = (e) => {
        theLabel["name"] = e.target.value;
        setTheLabel(theLabel);
    }

    const deleteAddressTableRow = (selectedRow) => {
        AxiosNetwork.axiosDelete({ "url": `/api/addresses/` + selectedRow.id, "id": selectedRow.id }, addressesRecordsList, setAddressesRecordsList);
    }

    const findAll = () => {
        console.log(props.presentObjects);
        if((props!==undefined) && (props.appDoc !== undefined))
        {
            console.log("Updating");
            setAddressesTypes([...props.addressesTypes]);
            setCountryList([...props.countryList]);
            setRecordsList([...props.recordsList]);
            setTheObject({...props.appDoc});
            setAddressesRecordsList([...props.addressesRecordsList]);
            showHideAllProps({ ...props.presentObjects });
            return;
        }

        AxiosNetwork.axiosFetch({ "url": `/api/public/common/countries` }, setCountryList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name":"configurations","filters":[{"col_name":"genre", "string_value": "address-types", "operator":"=", "data_type":"string"}] }, setAddressesTypes);
        contactPayload["filters"] = [];
        if(props.latest!==undefined) contactPayload["filters"].push({"col_name":"latest", "integer_value": ((props.latest===true) ? 1 : 0), "operator":"=", "data_type":"integer"});
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, contactPayload, setRecordsList, afterContactCallBack);
        showHideAllProps({ "address-list-table": "none", "address-form": false, "button-controls-section": "none", "contact":"none", "contact-form": "none", "savedButton": "none", "records-list-table": "block" });
    }
    

    const afterContactCallBack = (data) => {
        console.log("afterContactCallBack", recordsList.length);
        data.forEach(each => {
            if(each["birthday_at"] !== undefined) each["birthday_at"] = moment(each["birthday_at"]);
        });
        if(data.length===0) dataMassage.pagination.total = (dataMassage.pagination.current*dataMassage.pagination.pageSize);
        setRecordsList(data);
        showHideAllProps({ "contact": "none", "button-controls-section": "none", "showDownload": "none", "showUpload": "none", "contact-form": "none", "savedButton": "none", "records-list-table": "block" });
    }

    const recordListPagination = (event) =>
    {
        tablePagination(dataMassage, event);
        contactPayload = Object.assign(contactPayload, dataMassage.pagination);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, contactPayload, setRecordsList, afterContactCallBack);
    }

    const onAddressFinish = (values) => {
        if (dataMassage["address_id"] === undefined) dataMassage["address_id"] = generateFuzzyId("addresses");
        values["description"] = ckEditorData;
        values["name"] = theObject["name"];
        values["upsert"] = true;
        values["app_type"] = "52";
        values["doc_id"] = theObject["id"];
        AxiosNetwork.axiosPut({ "url": `/api/addresses/${dataMassage["address_id"]}/update` }, values, addressesRecordsList, setAddressesRecordsList, afterContactAddressAddedCallback);
    }

    const afterContactAddressAddedCallback = (data) => {
        //showHideAllProps({ "contact": "none", "address-list-table": "block", "address-form": true });
    }

    const editAddressTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        console.log(selectedRow);
        dataMassage["address_id"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/addresses/` + selectedRow.id, "id": selectedRow.id }, theAddressObject, setTheAddressObject, addressCallback);
    }

    const addressCallback = (data) => {
        setTheAddressObject({ ...data });
        addressForm.setFieldsValue({ ...data });
        showHideAllProps({ "address-list-table": "block", "address-form": true });
    }

    const addressFailed = () => {

    }

    const infoTableRow = (selectedRow) => {
        showHideAllProps({"showInfo": true, "address-form": false, "contact-form":"none", "records-list-table": "block", "button-controls-section": "none" });
        setTheObject(selectedRow);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": `/api/${appPath}/restore/` + selectedRow.id, "id": selectedRow.id }, selectedRow, recordsList, setRecordsList);
    }

    const deleteTableRow = (selectedRow) => {
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const editTableAddressRow = (selectedRow) => {
        contactAddress(selectedRow);
    }

    const contactAddress = (selectedRow) => {
        dataMassage["address_id"] = generateFuzzyId("addresses");
        showHideAllProps({ "showInfo":false, "address-form": true, "records-list-table": "block", "address-list-table": "none", "button-controls-section": "none", "contact-form":"none" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "addresses", "filters": [{ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": selectedRow.id }] }, setAddressesRecordsList);
        setTheObject({ ...selectedRow });
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id }, theObject, setTheObject, afterFetch);
    }

    const afterFetch = (data) => {
        if(data["birthday_at"] !== undefined) data["birthday_at"] = moment(data["birthday_at"]);
        showHideAllProps({"showInfo":false, "button-controls-section": "block", "showDownload": "none", "showUpload": "none", "contact-form": "block", "savedButton": "none", "records-list-table": "none", "address-form": false });
        setTheObject({ ...data });
    }

    return (
        <section key={"id-"+new Date().getTime()}>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "address-list-table": "none", "contact-form": "none", "contact": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>

            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table pagination={dataMassage.pagination} onChange={(e) => recordListPagination(e)} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
            </section>

            <section className="thCard" style={{ "width": 700, "display": presentObjects["contact-form"] }} >
                <Form key={"form-"+new Date().getTime()} form={newContactForm} labelAlign="right" layout="vertical" size={"large"} name="register" onFinish={onCreateNewContact} initialValues={{...theObject}} autoComplete="off">
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="name" label="Name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="nick_name" label="Nick Name" rules={[{ message: 'Please input your nickname!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="middle_name" label="Middle Name"rules={[{ message: 'Please input your middlename!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="last_name" label="Last Name" rules={[{ message: 'Please input your lastname!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="gender" label="Gender" rules={[{ message: 'Please select gender!' }]}>
                                <Select placeholder="select your gender">
                                    <Option key="male">Male</Option>
                                    <Option key="female">Female</Option>
                                    <Option key="other">Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="birthday_at" label="Birthday">
                                <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateFormat"]} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="phone" label="Phone Number" rules={[{ message: 'Please input your phone number!' }]}>
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="mobile" label="Mobile Number" rules={[{ message: 'Please input your mobile number!' }]}>
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="fax" label="Fax Number" rules={[{ message: 'Please input your fax number!' }]}>
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        {(props.latest!==undefined) && <Col span={12}>
                            <Form.Item name="latest" label="Under lead?">
                                <Select>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </Col>}
                    </Row>
                    <Form.Item name="job_title" label="Job Title" rules={[{ message: 'Please input address!', },]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="address_line_1" label="Address Line 1" rules={[{ message: 'Address Line 1' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="address_line_2" label="Address Line 2" rules={[{ message: 'Address Line 2' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="address_line_3" label="Address Line 3" rules={[{ message: 'Address Line 3' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="landmark" label="Landmark" rules={[{ message: 'Landmark' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="City" name="city" rules={[{ required: true, message: 'Type  your city in this field' }]}>
                        <Input />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="state" label="State" rules={[{ message: 'Please input state!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="country_id" label="Country" rules={[{ message: 'Please input Country', required:true }]}>
                                <Select showSearch optionFilterProp="children" placeholder="select your Country" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {countryList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="email" label="Email">
                        <Input />
                    </Form.Item>
                    <Form.Item name="website" label="Website" rules={[{ message: 'Please input website!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="facebook" label="Facebook">
                        <Input />
                    </Form.Item>
                    <Form.Item name="twitter" label="Twitter">
                        <Input />
                    </Form.Item>
                    <Form.Item name="linkedin" label="LinkedIn">
                        <Input />
                    </Form.Item>
                    <Form.Item name="instagam" label="Instagram">
                        <Input />
                    </Form.Item>
                    <Form.Item name="orcid" label="ORCID">
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ message: 'Please describe' }]}>
                        <Input.TextArea showCount maxLength={100} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
                
                <section style={{ "display": presentObjects["contact"] }}>
                    <div style={{ marginTop: 10 }}>
                        <Space size={10}>
                            <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => contactAddress({ ...theObject })}><FontAwesomeIcon icon={faPlus} />Address</Button>
                        </Space>
                    </div>
                </section>
            </section>

            <Drawer key={"time-"+new Date().getTime()} title={uiLabels.title} width={720} placement="right" onClose={(e) => showHideProps("address-form", false)} open={presentObjects["address-form"]}>
                <section>
                    <Form size="large" labelAlign="right" layout="vertical" name="basic"
                        onFinish={onAddressFinish} form={addressForm} initialValues={theAddressObject}
                        onFinishFailed={addressFailed} autoComplete="off">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item name="genre" label="Type" rules={[{ required: false, message: 'Type your address location such as office, godown etc.' }]}>
                                    <Select style={{ width: '100%' }} showSearch placeholder="Select the address location">
                                        {addressesTypes.map(eachItem => (
                                            <Option key={eachItem.name}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Address Line 1" name="address_line_1" rules={[{ required: true, message: 'Type  your  address in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Address Line 2" name="address_line_2">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Address Line 3" name="address_line_3">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Landmark" name="landmark" rules={[{ required: false, message: 'Type  your landmark in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="City" name="city" rules={[{ required: true, message: 'Type  your city in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="State" name="state" rules={[{ required: true, message: 'Type  your state in this field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="country_id" label="Country" rules={[{ message: 'Please input Country', required:true }]}>
                                    <Select showSearch optionFilterProp="children" placeholder="select your Country" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {countryList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item name="phone" label="Phone Number" rules={[{ message: 'Please input your phone number!' }]}>
                                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="mobile" label="Mobile Number" rules={[{ required: false, message: 'Please input your Mobile number!' }]} >
                                    <Input addonBefore={prefixSelector} style={{ width: '100%', }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="fax" label="Fax Number" rules={[{ message: 'Please input your fax number!' }]}>
                                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>
                </section>
                <section>
                    <Table size="small" rowKey="id" columns={contactAddressColumn} dataSource={addressesRecordsList} pagination={false} />
                </section>
            </Drawer>

            <Drawer placement="right" onClose={() => setAppList(false)} visible={appList}>
                <div style={{ "marginTop": "-20px" }}><Apps /></div>
            </Drawer>

            <Modal title="Edit folder" width={400} onCancel={() => setFolderEditShow(false)} visible={folderEditShow}>
                <input className='inbxClass' style={{ "width": "100%", "marginBottom": "10px" }} onChange={(e) => renameFolder(e)} defaultValue={theFolder.name} />
                <br /><button className="modleBtn1" onClick={(e) => updateFolder(theFolder)}>Save</button> <br />
            </Modal>

            <Modal title="Edit label" width={400} onCancel={() => setLabelEditShow(false)} visible={labelEditShow}>
                <input className='inbxClass' style={{ "width": "100%", "marginBottom": "10px" }} onChange={(e) => renameLabel(e)} defaultValue={theLabel.name} />
                <br /><button className="modleBtn1" onClick={(e) => updateLabel(theFolder)}>Save</button> <br />
            </Modal>


            <Drawer title="Info Label" placement="right" onClose={() => showHideAllProps({"showInfo": false})} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>


        </section>
    )
}