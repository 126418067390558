import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave, faEdit, faBackward } from '@fortawesome/free-solid-svg-icons'
import { Form, Input, prefixSelector, Card, Row, Col, message, Select, Space, Button, Table, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { generateFuzzyId } from '../../Config';
import moment from 'moment-timezone';

const { Option } = Select;
const { Content } = Layout;
var formData = {};
const appId = 83;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-address";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";

export default function HumanResourceAddress() {
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "contact-details": "none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourceaddressForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("addresses")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetch({ "url": `/api/public/common/countries` }, setCountryList);

        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "addresses" }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "contact-details": "none", "records-list-table": "block", "human-resource-dashboard": "none" });

    }
    const humanresourceaddressFailed = () => {
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanresourceaddressForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/addresses/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "contact-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["user_uid"] = "000";
        values["state"] = "Puducherry";
        values["country_code"] = "IN";
        values["doc_id"] = theObject["id"];
        values["app_type"] = "52";
        AxiosNetwork.axiosPut({ "url": "/api/addresses/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your Address details has been saved.');
        humanresourceaddressForm.resetFields();
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": "addresses", "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    const applyAddress = () => {
        showHideAllProps({ "records-list-table": "none", "button-controls-section": "block", "contact-details": "block" });
        humanresourceaddressForm.resetFields();
    }
    const columnSchema = [
        {
            title: 'Contact Type',
            dataIndex: 'name',
            width: 60
        },
        {
            title: 'Address',
            dataIndex: 'address_line_1',
            width: 60
        },
        {
            title: 'City',
            dataIndex: 'city',
            width: 60
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            width: 60
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 60
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Action',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];
    return (
        <section>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "contact-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>

            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyAddress()}>  New Address  </Button>
            </section>
            <section className="thCard" style={{ "width": 800, "display": presentObjects["contact-details"] }} >
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onFinish}
                    form={humanresourceaddressForm} initialValues={theObject}
                    onFinishFailed={humanresourceaddressFailed} autoComplete="off">
                    <Form.Item label="Contact Type" name="name"
                        rules={[{ required: true, message: 'Type  your contact type in this field' }]}>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Address Line 1" name="address_line_1"
                                rules={[{ required: true, message: 'Type  your contact address in this field' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Address Line 2" name="address_line_2">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Address Line 3" name="address_line_3">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="City" name="city"
                                rules={[{ required: true, message: 'Type  your city in this field' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="State" name="state"
                                rules={[{ required: true, message: 'Type  your state in this field' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="country" label="Country" rules={[{ message: 'Please input Country', required: true }]}>
                                <Select showSearch optionFilterProp="children" placeholder="select your Country" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {countryList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Zip code" name="zip"
                                rules={[{ required: true, message: 'Type  your zip in this field' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="phone" label="Phone Number"
                                rules={[{ required: true, message: 'Please input your Phone number!' }]} >
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </section>

        </section>
    )
}