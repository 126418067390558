import React, { Component, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faEdit,
  faUndoAlt,
  faSave,
  faCircleCheck,
  faInfoCircle,
  faCloudUploadAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  findUrlPath,
  dateTimeUtils,
  appProfile,
  generateFuzzyId,
  profileBarItems,
  footerLine,
  menuBarItems,
  socialAuthProps,
} from "../Config";
import "../App.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import moment from "moment-timezone";
import {
  Layout,
  Menu,
  Typography,
  Breadcrumb,
  Row,
  Modal,
  Dropdown,
  Button,
  Table,
  Form,
  Input,
  Card,
  Upload,
  Radio,
  message,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Mentions,
  Tag,
  TimePicker,
  Drawer,
  AutoComplete,
  Col,
  Checkbox,
} from "antd";
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import { v4 as uuid } from "uuid";
import queryString from 'query-string';

const queryParams = queryString.parse(window.location.search);
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 21;
const appPath = "projects";
const tableName = "projects";
var activeDataColumns = [];
const dataMassage = { emailAddress: {}, associateId: "1" };
let ckEditorData = "";

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
  name: "file",
  multiple: true,
  action: appProfile.serviceLocation + "api/file-assets/mime/upload/single",
  data: function (file) {
    console.log("Data being triggered");
    return formData;
  },
  headers: {
    Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const cdnFileUploadProps = {
  name: "file",
  multiple: true,
  action: appProfile.serviceLocation + "api/cdn-file-assets/mime/upload/single",
  data: function (file) {
    console.log("Data being triggered");
    return formData;
  },
  headers: {
    Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};
export default function TaskInfo(props) {
  const [theObject, setTheObject] = useState(props.appDoc);
  const [folderList, setFolderList] = useState([
    { id: 1, name: "Waiting to load" },
  ]);
  const [labelList, setLabelList] = useState([
    { id: 1, name: "Waiting to load" },
  ]);
  const [recordsList, setRecordsList] = useState([
    { id: 1, name: "Waiting to load" },
  ]);
  const [presentObjects, setPresentObjects] = useState({
    "create-new-record-section": " none",
    "edit-selected-record": "none",
    "records-list-table": "block",
    active: "block",
    bin: "none",
    showDownload: "none",
    showUpload: "none",
    folderEdit: "none",
    labelEdit: "none",
    showInfo: false,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [taskForm] = Form.useForm();
  const [searchedRecords, setSearchedRecords] = useState({ workflows: [] });
  const [formList, setFormList] = useState([
    { id: 1, name: "Waiting to load" },
  ]);
  const [uiLabels, setUiLabels] = useState([{ save: "Save" }]);
  const [theFolder, setTheFolder] = useState({});
  const [theLabel, setTheLabel] = useState({});
  const [theTodo, setTheTodo] = useState({
    application: "EMAIL",
    fileAssets: [],
    body: "Type your content here",
  });
  const [reminderList, setReminderList] = useState([
    { id: 1, name: "Waiting to load" },
  ]);
  const [priorityList, setPriorityList] = useState([
    { id: 1, name: "Waiting to load" },
  ]);

  console.log(props.appDoc);
  useEffect(() => {
    setUiLabels({ save: "Save" });
    localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
    findAll();
    AxiosNetwork.axiosFetch(
      { url: `/api/common/folders/appid/` + appId },
      setFolderList
    );
    AxiosNetwork.axiosFetch(
      { url: `/api/common/labels/appid/` + appId },
      setLabelList
    );
    const filter = {
      col_name: "genre",
      operator: "=",
      data_type: "string",
      string_value: "FORM",
    };
    AxiosNetwork.axiosFetchMulti(
      { url: `/api/common/find-any-multi` },
      { doc_name: "forms", filter: [filter] },
      setFormList
    );
  }, []);

  const showHideProps = (prop, show) => {
    presentObjects[prop] = show;
    setPresentObjects({ ...presentObjects });
  };
  const showHideAllProps = (props) => {
    for (var prop in props) {
      presentObjects[prop] = props[prop];
    }
    setPresentObjects({ ...presentObjects });
  };
  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  const columnSchema = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "genre",
    },
    {
      title: "Due At",
      render: (record) => (
        <span>
          {record.dueAt !== null && moment(record.dueAt).format("lll")}
        </span>
      ),
    },
    {
      title: "Edit",
      render: (record) => (
        <a onClick={(e) => editTableRow(record)}>
          <FontAwesomeIcon icon={faEdit} />
        </a>
      ),
    },

    {
      title: "Trash",
      render: (record) => (
        <a onClick={(e) => deleteTableRow(record)}>
          <FontAwesomeIcon icon={faTrashCan} />
        </a>
      ),
    },
    {
      title: "Done",
      render: (record) => (
        <a onClick={(e) => markAsDone(record)}>
          <FontAwesomeIcon icon={faCircleCheck} />
        </a>
      ),
    },

    {
      title: "Info",
      render: (record) => (
        <a onClick={(e) => infoTableRow(record)}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </a>
      ),
    },
    {
      title: "Restore",
      render: (record) => (
        <a onClick={(e) => restoreTableRow(record)}>
          <FontAwesomeIcon icon={faUndoAlt} />
        </a>
      ),
    },
  ];

  const taskFormFailed = () => {};

  const editTableRow = (selectedRow) => {
    console.log(selectedRow);
    selectedRow["mime_assets"] = [];
    setTheObject({ ...selectedRow });
    taskForm.setFieldsValue({ ...selectedRow });
    formData = {};
    dataMassage["taskId"] = selectedRow.id;
    formData["parent_id"] = selectedRow.id;
    AxiosNetwork.axiosGet(
      { url: "/api/tasks/" + selectedRow.id, id: selectedRow.id },
      theObject,
      setTheObject
    );
    showHideAllProps({
      "create-new-record-section": "none",
      "edit-selected-record": "block",
    });
  };

  const onSelectChange = (selectedRowIds) => {
    console.log("selectedRowKeys changed: ", selectedRowIds);
    setSelectedRows(selectedRowIds);
  };

  const markAsDone = (selectedRow) => {
    console.log(selectedRow);
    AxiosNetwork.axiosPut(
      {
        url: `/api/${appPath}/mark-as-done/` + selectedRow.id,
        id: selectedRow.id,
      },
      theTodo,
      recordsList,
      setRecordsList
    );
  };

  const onLabelFinish = (values) => {
    AxiosNetwork.axiosPost(
      { url: `/api/${appPath}/create/label` },
      values,
      labelList,
      setLabelList
    );
    console.log("Success:", values);
    showHideProps("newLabelDrawer", false);
  };

  const onLabelFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFolderFinish = (values) => {
    AxiosNetwork.axiosPost(
      { url: "/api/${appPath}/create/folder" },
      values,
      folderList,
      setFolderList
    );
    console.log("Success:", values);
    showHideProps("newFolderDrawer", false);
  };

  const newRecord = () => {
    setUiLabels({ save: "Save" });
    setTheObject({ id: generateFuzzyId(tableName), mime_assets: [] });
    showHideAllProps({
      "edit-selected-record": "none",
      "create-new-record-section": "block",
      "records-list-table": "none",
    });
  };

  const onFinish = (values) => {
    values["description"] = ckEditorData;
    values["upsert"] = true;
    AxiosNetwork.axiosPut(
      { url: "/api/tasks/" + theObject["id"] + "/update" },
      values,
      recordsList,
      setRecordsList,
      afterNewRecordSubmit
    );
    console.log("Success:", values);
  };
  const afterNewRecordSubmit = () => {
    message.info("Your tasks has been saved.");
    findAll();
  };
  const updateFolder = (e) => {
    console.log(theFolder);
    AxiosNetwork.axiosUpdate(
      { url: "/api/common/folder/update/" + theFolder.id, id: theFolder.id },
      theFolder,
      folderList,
      setTheFolder
    );
  };
  const updateLabel = (e) => {
    AxiosNetwork.axiosUpdate(
      { url: "/api/common/label/update/" + theLabel.id, id: theLabel.id },
      theLabel,
      labelList,
      setTheLabel
    );
    console.log(theLabel);
  };
  const renameFolder = (e) => {
    theFolder["name"] = e.target.value;
    console.log(theFolder);
    setTheFolder(theFolder);
  };
  const renameLabel = (e) => {
    theLabel["name"] = e.target.value;
    console.log(theLabel);
    setTheLabel(theLabel);
  };

  const deleteTableRow = (selectedRow) => {
    console.log(selectedRow);
    AxiosNetwork.axiosPost(
      { url: "/api/common/delete-any-by-owner", id: selectedRow.id },
      { doc_name: tableName, id: selectedRow.id },
      recordsList,
      setRecordsList
    );
  };
  const restoreTableRow = (selectedRow) => {
    console.log(selectedRow);
    AxiosNetwork.axiosRestore(
      { url: "/api/common/restore-any-record", id: selectedRow.id },
      { doc_name: tableName, id: selectedRow.id },
      recordsList,
      setRecordsList
    );
  };

  const infoTableRow = (selectedRow) => {
    selectedRow["mime_assets"] = [];
    setTheObject({ ...selectedRow });
    showHideAllProps({ showInfo: true });
    console.log(selectedRow);
  };
  const removeBinHeaders = (header) => {
    return header.filter(
      (item) => !["trash", "edit", "info"].includes(item.key)
    );
  };

  const findTaskBy = (filter) => {
    AxiosNetwork.axiosGet(
      { url: `/api/${appPath}/${filter}` },
      recordsList,
      setRecordsList
    );
    showHideProps("active", "block");
  };

  const findByDateRange = (afterBy, beforeBy, timeZone) => {
    console.log(afterBy, beforeBy);
    AxiosNetwork.axiosFetchMulti(
      { url: `/api/tasks` },
      {
        doc_name: tableName,
        filters: [
          {
            col_name: "start_planned_at",
            data_type: "date",
            operator: ">",
            date_value: afterBy,
          },
          {
            col_name: "start_planned_at",
            data_type: "date",
            operator: "<",
            date_value: beforeBy,
          },
          { col_name: "ready", operator: "=", integer_value: 1 },
        ],
      },
      setRecordsList
    );
    showHideProps("active", "block");
  };

  const findAll = (filter) => {
    activeDataColumns = columnSchema.slice(0, -1);
    AxiosNetwork.axiosFetchMulti(
      { url: `/api/tasks` },
      { doc_name: tableName },
      setRecordsList
    );

    showHideAllProps({
      "create-new-record-section": "none",
      "records-list-table": "block",
    });
  };

  const renderTaskInfo = (data) => {
    console.log("renderEmployeeData", data);
    taskForm.setFieldsValue({ ...data });
  };

  return (
    <Layout>
      <section
        className="thCard"
        style={{
          width: 800,
        }}
      >
        <Form
          size="large"
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          form={taskForm}
          initialValues={theObject}
          onFinishFailed={taskFormFailed}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Enter your task" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Due Date">
                <DatePicker
                  style={{ width: "100%" }}
                  format={dateTimeUtils.dateFormat}
                  showTime={true}
                  onOk={onOk}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="reminder_id" label=" Send Reminder">
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select Reminder"
                >
                  {reminderList.map((eachItem) => (
                    <Option key={eachItem.id}>{eachItem.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Type" name="genre">
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select Type"
                >
                  <Select.Option value="To-do">To-do</Select.Option>
                  <Select.Option value="Email">Email</Select.Option>
                  <Select.Option value="SMS">SMS </Select.Option>
                  <Select.Option value="Telegram">Telegram </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="priority_id" label="Priority">
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select Priority"
                >
                  {priorityList.map((eachItem) => (
                    <Option key={eachItem.id}>{eachItem.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Assign to" name="assignTo">
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select Assign to"
                >
                  <Select.Option value="Automatic Ticket Assignment">
                    Automatic Ticket Assignment
                  </Select.Option>
                  <Select.Option value="Round Robin">Round Robin</Select.Option>
                  <Select.Option value="Load balance">
                    Load Balancec
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Type theDescription" }]}
          >
            <CKEditor
              onReady={(editor) => {
                console.log("Editor is ready to use!", editor);
                // Insert the toolbar before the editable area.
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "100px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onError={(error, { willEditorRestart }) => {
                if (willEditorRestart) {
                  this.editor.ui.view.toolbar.element.remove();
                }
              }}
              onChange={(event, editor) => {
                ckEditorData = editor.getData();
                console.log(ckEditorData);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
              editor={DecoupledEditor}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}
            </Button>
          </Form.Item>
        </Form>
      </section>
    </Layout>
  );
}
