import React, {  useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Divider, Slider, Space, Typography,  Row, Modal,  Button,  Form, Input, Tabs,  Radio, Select,  DatePicker, InputNumber,  Col, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCopy, faTrash, faGear } from '@fortawesome/free-solid-svg-icons';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;
const { Option } = Select;

const inputFieldTypes = ["input", "input-number", "upload"];
const inputSelectTypes = ["select", "multi-select", "tags-select", "checkbox", "radio"];
const inputRangeTypes = ["input-number", "slider"];
function onChange(value, dateString) {
  console.log('Selected Time: ', value);
  console.log('Formatted Selected Time: ', dateString);
}
function onOk(value) {
  console.log('onOk: ', value);
}
function changeTab(key) {
  console.log(key);
}
const Thinput = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Input />
  </Form.Item>
}
const Thinputnumber = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required:props.form.required}]}>
  <InputNumber />
  </Form.Item>
}
const Thtextarea = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <TextArea rows={4} placeholder="maxLength is 100" maxLength={100} />
  </Form.Item>
}
const Thselect = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Select style={{ width: '100%' }} placeholder={props.form.label}>
      {props.form.options.map(eachItem => (<Option value={'unique-' + eachItem}>{eachItem}</Option>))}
    </Select>
  </Form.Item>
}
const Thmultiselect = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Select mode="multiple" style={{ width: '100%' }} placeholder={props.form.label}>
      {props.form.options.map(eachItem => (<Option value={'unique-' + eachItem}>{eachItem}</Option>))}
    </Select>
  </Form.Item>
}
const Thtagsselect = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Select mode="tags" style={{ width: '100%' }} placeholder={props.form.label} >
      {props.form.options.map(eachItem => (<Option value={'unique-' + eachItem}>{eachItem}</Option>))}
    </Select>
  </Form.Item>
}
const Thradio = (props) => {
    return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Radio.Group onChange={onChange}>
      {props.form.options.map(eachItem => (<Radio key ={'unique-' + eachItem}>{eachItem}</Radio>))}
    </Radio.Group>
  </Form.Item>
}
const Thcheckbox = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name}  valuePropName="checked" rules={[{ required: props.form.required }]}>
   <Checkbox style={{ width: '100%' }}  placeholder={props.form.label} onChange={onChange}>
  {props.form.options.map(eachItem => (<Checkbox key={'unique-' + eachItem}>{eachItem}</Checkbox>))}
  </Checkbox>
  </Form.Item>
}
const Thupload = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
  </Form.Item>
}
const Thslider = (props) => {
  let marks = {}; marks[props.form.min] = props.form.min; marks[props.form.max] = props.form.max;
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <Slider style={{ width: '100%' }} marks={marks} min={props.form.min}  max={props.form.max} disabled={false} />
  </Form.Item>
}
const Thtitle = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
   <Title>{props.form.name}  </Title> 
   </Form.Item>
}
const Thdivider = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
<Divider plain>{props.form.name}</Divider>
</Form.Item>
}
const Thdatepicker = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required }]}>
    <DatePicker showTime onChange={onChange} onOk={onOk} />
  </Form.Item>
}
const Thdaterangepicker = (props) => {
  return <Form.Item label={props.form.label} name={props.form.name} rules={[{ required: props.form.required}]}>
    <RangePicker
      showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      onChange={onChange}
      onOk={onOk}
    />
  </Form.Item>
}
const findElement = (form) => {
  switch (form.type) {
    case "input":
      return <Thinput form={form} />;
    case "input-number":
      return <Thinputnumber form={form} />;
    case "textarea":
      return <Thtextarea form={form} />;
    case "select":
      return <Thselect form={form} />;
    case "multi-select":
      return <Thmultiselect form={form} />;
    case "tags-select":
      return <Thtagsselect form={form} />;
    case "divider":
      return <Thdivider form={form} />;
    case "slider":
      return <Thslider form={form} />;
    case "title":
      return <Thtitle form={form} />;
    case "date-picker":
      return <Thdatepicker form={form} />;
    case "checkbox":
      return <Thcheckbox form={form} />;
    case "radio":
      return <Thradio form={form} />;
    case "date-range-picker":
      return <Thdaterangepicker form={form} />;
    default:
      break;
  }
}

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition } = useSortable({ id: props.id });

  const [editModal, setEditModal] = useState(false);
  const [editSelectModal, setEditSelectModal] = useState(false);
  const [editDividerModal, setEditDividerModal] = useState(false);
  const [editHeadingModal, setEditHeadingModal] = useState(false);
  const [editRangeModal, setEditRangeModal] = useState(false);
  const [theItem, setTheItem] = useState(props.form);
  const onCreateSelect = (values) => {
    console.log('Received values of form: ', values);
    const newFormItems = {...theItem, ...values};
     setTheItem({ ...newFormItems });
     console.log(newFormItems);
     props.callback("update", newFormItems);
     console.log("update")
     setEditSelectModal(false);
   }
 const onCreateInput = (values) => {
     console.log('Received values of form: ', values);
     const newFormItems = {...theItem, ...values};
     setTheItem({ ...newFormItems });
     console.log(newFormItems);
     props.callback("update", newFormItems);
     setEditModal(false);
   }
  const onCreateRangeHeading = (values) => {
    console.log('Received values of onCreateRangeHeading: ', values);
    const newFormItems = {...theItem, ...values};
    setTheItem({ ...newFormItems });
    console.log(newFormItems);
    props.callback("update", newFormItems);
    console.log("update");
    setEditRangeModal(false);
  }
 const onCreateDivider = (values) => {
     console.log('Received values of form: ', values);
     const newFormItems = {...theItem, ...values};
     setTheItem({ ...newFormItems });
     console.log(newFormItems);
     props.callback("update", newFormItems);
     console.log("update");
     setEditDividerModal(false);
  }
   const onCreateHeading = (values) => {
     console.log('Received values of form: ', values);
     const newFormItems = {...theItem, ...values};
     setTheItem({ ...newFormItems });
     console.log(newFormItems);
     props.callback("update", newFormItems);
    console.log("update");
    setEditHeadingModal(false);
  }
  const style = { transform: CSS.Transform.toString(transform), transition };
  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    display: "block",
    paddingLeft: 10,
    border: "1px solid gray",
    borderRadius: 20,
    marginBottom: 20,
    userSelect: "none",
    cursor: "grab",
    minHeight:"100px",
    boxSizing: "20px border-box"
  };
  const editProperties = (form) => {
    if (inputSelectTypes.indexOf(form.type) != -1) {
      setEditSelectModal(true);
      return;
    }
    else if (form.type === "onCreateDivider ") {
      setEditDividerModal(true);
      return;
    }
    else if (form.type === "title") {
      setEditHeadingModal(true);
      return;
    }
    if (inputRangeTypes.indexOf(form.type) != -1) {
      setEditRangeModal(true);
      return;
    }
    setEditModal(true);
    console.log(form);
  }
  const moveUp = (form) => {
    form["order"] = form["order"] - 1.1;
    props.callback("sort", theItem);
  }
  const moveDown = (form) => {
    form["order"] = form["order"] + 1.1;
    props.callback("sort", theItem);
  }
  const saveData = (e) => {
    setEditModal(false);
    console.log(e);
  }
  const onCancel = (e) => {
    setEditModal(false);
    console.log(e);
  }
  const deleteTableForm = (formItem) => {
    props.callback("delete", formItem);
  }
  const duplicateFormItem = (formItem) => {
  console.log("duplicate", formItem);
  props.callback("duplicate", formItem);
}
  const InputFormEditor = ({ open, onCreateInput, onCancel,  }) => {
  const [form] = Form.useForm();
    return (
      <Modal
        open={open}
        title="Edit Form Attributes"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreateInput(values);
            })
            .catch((info) => {
              console.log('...Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'optional' }}>
          <Form.Item
            name="label"
            label="User Display Name"
            rules={[
              {
                required:  props.form.required,
                message: 'Please input the name',
              },
            ]}
          >
            <Input initialValues={theItem.label}/>
          </Form.Item>
          <Form.Item
            name="name"
            label="Database Column"
            rules={[
              {
                required:  props.form.required,
                message: 'Please input the name',
              },
            ]}
          >
            <Input initialValues={theItem.name}/>
          </Form.Item>

          <Form.Item name="required" className="collection-create-form_last-form-item">
            <Radio.Group>
              <Radio value={true}>Required</Radio>
              <Radio value={false}>Optional</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const DividerFormEditor = ({ open, onCreateDivider, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={open}
        title="Edit Form Attributes"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreateDivider(values);
            })
            .catch((info) => {
              console.log('...Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'optional' }}>
          <Form.Item
            name="name"
            label="Database Column"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
            <Input initialValues={theItem.name}/>
          </Form.Item>
          <Form.Item name="required" className="collection-create-form_last-form-item">
            <Radio.Group>
              <Radio value={true}>Required</Radio>
              <Radio value={false}>Optional</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        </Modal>
    );
  };
    const RangeFormEditor = ({ open,onCreateRangeHeading, onCancel }) => {
    const [form] = Form.useForm();
    console.log(onCreateRangeHeading);
    return (
      <Modal
        open={open}
        title="Edit Form Attributes"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
            form.resetFields();
             onCreateRangeHeading(values);
            })
            .catch((info) => {
              console.log('...Validate Failed:', info);
            });
        }}
        >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'optional' }}>
          <Form.Item
            name="label"
            label="User Display Name"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
            <Input  initialValues={theItem.label}/>
          </Form.Item>
          <Form.Item
            name="name"
            label="Database Column"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
            <Input  initialValues={theItem.name}/>
          </Form.Item>

          <Form.Item
            name="min"
            label="Minimum"
            rules={[{ required: true }]}>
            <InputNumber />

          </Form.Item>
          <Form.Item
            name="max"
            label="Maximum"
            rules={[{ required: true }]}>
            <InputNumber />

          </Form.Item>
          <Form.Item name="required" className="collection-create-form_last-form-item">
            <Radio.Group>
              <Radio value={true}>Required</Radio>
              <Radio value={false}>Optional</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        </Modal>
    );
  };
    const HeadingFormEditor = ({ open, onCreateHeading, onCancel}) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={open}
        title="Edit Form Attributes"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreateHeading(values);
            })
            .catch((info) => {
              console.log('...Validate Failed:', info);
            });
        }}
      >  
      <Form.Item
      name="label"
      label="User Display Name"
      rules={[
        {
          required: true,
          message: 'Please input the name',
        },
      ]}
    >
      <Input  initialValues={theItem.label}/>
    </Form.Item>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'optional' }}>
          <Form.Item
            name="name"
            label="Database Column"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
            <Input initialValues={theItem.name}/>
          </Form.Item>
          <Form.Item name="required" className="collection-create-form_last-form-item">
            <Radio.Group>
              <Radio value={true}>Required</Radio>
              <Radio value={false}>Optional</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
    const SelectFormEditor = ({ open, onCreateSelect, onCancel}) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={open}
        title="Edit Form Attributes"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreateSelect(values);
            })
            .catch((info) => {
              console.log('...Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'optional' }}>
          <Form.Item
            name="label"
            label="User Display Name"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
            <Input initialValues={theItem.label}/>
          </Form.Item>
          <Form.Item
            name="name"
            label="Database Column"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
          <Input initialValues={theItem.name}/>
          </Form.Item>

          <Form.Item
            name="options"
            label="Drop Down Options"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
          <Select mode="tags"></Select>
          </Form.Item>

          <Form.Item
            name="extra"
            label="Additional User Information"
            rules={[
              {
                required:  false,
                message: 'User Information',
              },
            ]}
          >
          <Input initialValues={theItem.extra}/>
          </Form.Item>

          <Form.Item name="required" className="collection-create-form_last-form-item">
            <Radio.Group>
              <Radio value={true}>Required</Radio>
              <Radio value={false}>Optional</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  
  return (
    <div style={itemStyle} ref={setNodeRef} {...attributes} {...listeners}>
      <section>
      <Row gutter={[16, 16]}>
          <Col span={14}>
          {findElement(props.form)}
          </Col>
          <Col span={10}>
          <Space size={10}>
            <Button icon={<FontAwesomeIcon icon={faGear} />} onClick={(e) => editProperties(props.form)} />
            <Button icon={<FontAwesomeIcon icon={faArrowUp} />} onClick={(e) => moveUp(props.form)} />
            <Button icon={<FontAwesomeIcon icon={faArrowDown} />} onClick={(e) => moveDown(props.form)} />
            <Button icon={<FontAwesomeIcon icon={faTrash} />} onClick={(e) => deleteTableForm(props.form)} />
            <Button icon={<FontAwesomeIcon icon={faCopy} />} onClick={(e)=> duplicateFormItem(props.form)} />
          </Space>
          </Col>
        </Row>
      </section>

      <InputFormEditor
        open={editModal}
        onCreateInput ={onCreateInput}
        onCancel={() => {
          setEditModal(false);
        }}
      />

      <SelectFormEditor
        open={editSelectModal}
        onCreateSelect={onCreateSelect}
        onCancel={() => {
        setEditSelectModal(false);
        }}
      />

      <DividerFormEditor
        open={editDividerModal}
        onCreateDivider ={onCreateDivider}
        onCancel={() => {
        setEditDividerModal(false);
        }}
      />

      <HeadingFormEditor
        open={editHeadingModal}
        onCreateHeading={onCreateHeading}
        onCancel={() => {
        setEditHeadingModal(false);
        }}
      />

      <RangeFormEditor
      open={editRangeModal}
      onCreateRangeHeading={onCreateRangeHeading}
      onCancel={() => {
      setEditRangeModal(false);
      }}
      />
    </div>
  );
}