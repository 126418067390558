import React, { useRef, useState, useEffect } from 'react';
import '../App.css';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { Layout, Select, Typography, Breadcrumb, Row, Form, Dropdown, Button } from 'antd';
import { AxiosNetwork } from "../axiosService";

const { Option } = Select;

//https://www.npmjs.com/package/jsoneditor-react
export default function CustomFieldEmbedEditor(props) {
    console.log(props);

    const [jsonDoc, setJsonDoc] = useState({});
    const [theObjectList, setTheObjectList] = useState([]);
    const [labelList, setLabelList] = useState([]);
    const [folderList, setFolderList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": "none" });

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }


    useEffect(() => {
        AxiosNetwork.axiosFetchMono({ "url": `api/common/common-fields/${props.table}/${props.id}` }, setJsonDoc);
    }, []);


    const handleChange = (formData) => {
        console.log(formData);
        setJsonDoc(formData);
    }

    const saveCustomFields = () => {
        AxiosNetwork.axiosPut({ "url": `api/common/common-fields/update/${props.table}/${props.id}`, "id": props.id }, jsonDoc, theObjectList, setTheObjectList);
        console.log(jsonDoc);
        showHideAllProps({"showInfo": false});
        props.present(presentObjects);
    }



    const applyFolderToObject = (folderId) => {
        let payload = { "folder_id": folderId, "id": props.id, "doc_name": props.table };
        AxiosNetwork.axiosPut({ "url": `api/common/assign/folder-to-object`, "id": props.id }, payload, folderList, setFolderList);
    }

    const applyLabelsToObject = (labelIds) => {
        let payload = { "label_ids": labelIds, "id": props.id, "doc_name": props.table, "app_type": props.appType };
        AxiosNetwork.axiosPut({ "url": `api/common/assign/labels-to-object`, "id": props.id }, payload, labelList, setLabelList);
    }

    return (
        <div>
            <div>
                <Form>
                    <Form.Item
                        label="Folder"
                        name="activity">
                        <Select
                            showSearch
                            style={{ width: 150, border: "1px solid gray" }}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onChange={(e) => applyFolderToObject(e)}>
                            {props.folders.map((d) => <Option key={d.id}>{d.name}</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </div>

            <div>
                <Form>
                    <Form.Item
                        label="Label"
                        name="label">
                        <Select mode="tags"
                            showSearch
                            style={{ width: 150, border: "1px solid gray" }}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onChange={(e) => applyLabelsToObject(e)}>
                            {props.labels.map((d) => <Option key={d.id}>{d.name}</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </div>

            <Editor
                key={new Date().getTime()}
                value={jsonDoc}
                onChange={handleChange}
            />
            <Button type="primary" onClick={(e) => saveCustomFields()}>Save</Button>

        </div>
    )
}