import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt, faTrashCan, faMessage, faEye, faPeace, faTimeline, faClock, faUser, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faCircleCheck, faPaperclip, faComment, faComments, faGripLines } from '@fortawesome/free-solid-svg-icons'
import { findUrlPath, appProfile, footerLine, menuBarItems, socialAuthProps, generateFuzzyId } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment';
import { Layout, Menu, Typography, Breadcrumb, Row, List, notification, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox, Space } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { v4 as uuid } from 'uuid';
import sanitize from 'sanitize-html';
import queryString from 'query-string';

const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
const appId = 10;
var formData = { "parent_type": appId };
const appPath = "emails";
const tableName = "emails";
var activeDataColumns = [];
let ckEditorData = "";
var ckEditorDataReply = "";
const dataMassage = { "emailAddress": {}, "associateId": "1" };
var mailBoxGenre = "inbox";
const queryParams = queryString.parse(window.location.search);
var emailAccountProvider = "gmail";
const menuBarItemsCustom = [...menuBarItems];
menuBarItemsCustom.push({key: 'calendar', label: (<a href={window.location.origin + "/apps/calendar"} target="_parent">Calendar</a>), icon: <img height={40} src={appProfile.staticAssets + "icons/calendar.webp"} />});

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const cdnFileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/cdn-file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};



export default function MiniEmails(props) {
    const [tempList, setTempList] = useState([]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "email-cc":"none", "email-bcc":"none", "email-connect-section-non-oauth": "none", "no-inbox-selected": "block", "email-connect-section-display": "none", "back-to-home-controls": "none", "email-received-list": "block", "email-body-display": "none", "email-body-compose-display": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [emailComposeForm] = Form.useForm();
    const [theObject, setTheObject] = useState({ "email_to": [], "email_cc": [], "application": "EMAIL", "mime_assets": [], "cdnFileAssets": [], "fileAssets": [], "description": "Type your content here", "id": tableName + "-" + uuid() });
    const [theInbox, setTheInbox] = useState({ "email_to": [], "email_cc": [], "application": "EMAIL", "mime_assets": [], "cdnFileAssets": [], "fileAssets": [], "description": "Type your content here", "id": tableName + "-" + uuid() });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [theFilesList, setTheFilesList] = useState([]);
    const [theCdnFilesList, setTheCdnFilesList] = useState([]);
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [emailAddressList, setEmailAddressList] = useState([]);
    const [inboxList, setInboxList] = useState([]);

    if(props.appDoc!==undefined)
    {
        queryParams["doc_id"] = props.appDoc.id
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'From',
            render: (record) => <a onClick={(e) => editTableRow(record)}>{record.sender_name}</a>,
        },
        {
            title: 'Name',
            render: (record) => <span>{((record["in_reply_to"]!=undefined) ? <FontAwesomeIcon icon={faGripLines} /> : "")} <a onClick={(e) => editTableRow(record)}>{record.name}</a></span>,
        },
        {
            title: <FontAwesomeIcon icon={faPaperclip} />,
            render: (record) => <a onClick={(e) => editTableRow(record)}>{((record.attachment==0) ? "" : record.attachment )}</a>,
            width: 60
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
            width: 60
        },
        {
            title: 'Received At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>,
            width: 60
        }
    ];

    const editDraftEmailRow = (selectedRow) => {
        console.log(selectedRow);
        formData = { "parent_type": appId };
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, fetchEmailDraftCallback);
    }

    const fetchEmailDraftCallback = (data) => {
        setTheCdnFilesList(data["cdnFileAssets"]);
        setTheFilesList(data["fileAssets"]);
        setTheObject({ ...data });
        emailComposeForm.setFieldsValue({ ...data });
        ckEditorDataReply = data["description"];
        showHideAllProps({ "email-body-display": "none", "email-received-list": "none", "back-to-home-controls": "block", "email-body-compose-display": "block" });
    }


    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        formData = { "parent_type": appId };
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, dataFetchCallback);
        showHideAllProps({ "email-body-display": "block", "email-received-list": "none", "back-to-home-controls": "block" });
    }

    const dataFetchCallback = (data) => {
        setTheCdnFilesList(data["cdnFileAssets"]);
        setTheFilesList(data["fileAssets"]);
        setTheObject({ ...data });
        emailComposeForm.setFieldsValue({ ...data });
        ckEditorDataReply = "<div><div> <br/> </div><div>Subject: " + data["name"] + "<br/>Date: " + moment(data["created_at"]).format("lll") + "</div><div>~ ~ ~</div><div>" + sanitize(data["description"]) + "</div></div>";
        ckEditorDataReply = ckEditorDataReply.replace("<table", "<table border='0'");
        //ckEditorDataReply = data["description"];
        console.log(ckEditorDataReply);
    }



    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    
    const onEmailAccountRegisterFails = () =>
    {

    }

    const onEmailAccountRegister = (values) =>
    {
        values["trash"] = "Trash";
        values["provider"] = emailAccountProvider;
        AxiosNetwork.axiosPostMono({ "url": `/api/${appPath}/inboxes/create`, "id": theObject.id }, values, setTheInbox, addingNewMailBoxCallback);
    }

    const addingNewMailBoxCallback = (data) =>
    {
        if(data["status_code"]==200)
        {
            notification.open({ message: 'Hurray!', description:'Sorry. Your mail box box is linked. You can manage your inbox shortly....'});
        }
        else
        {
            notification.open({ message: 'Oops. Unable to connect', description:'Sorry. We are unable to link your mailbox. Please contact your IT administrator.'});
        }
    }

    const saveEmail = (values) => {
        if (queryParams["inbox-id"] === undefined) return;
        values["upsert"] = true;
        values["inbox_id"] = queryParams["inbox-id"];
        values["description"] = ckEditorData;
        values["doc_id"] = queryParams["doc_id"];
        if (dataMassage["in_reply_to"] !== undefined) values["in_reply_to"] = dataMassage["in_reply_to"];

        if (dataMassage["mailComposeFolder"] == "sent")
        {
            AxiosNetwork.axiosPut({ "url": `/api/${appPath}/update/` + theObject.id, "id": theObject.id }, values, recordsList, setRecordsList, afterEmailSentCatch);
        }
        else
        {
            AxiosNetwork.axiosPut({ "url": `/api/${appPath}/draft/` + theObject.id, "id": theObject.id }, values, recordsList, setRecordsList);
        }
    }

    const afterEmailSentCatch = (data) =>
    {
        findAll("inbox");
    }


    const infoTableRow = (selectedRow) => {
        setTheObject(selectedRow);
        showHideProps("showInfo", true);
    }


    useEffect(() => {
        document.title = "Email";
        if (queryParams["inbox-id"] !== undefined) {
            showHideAllProps({ "no-inbox-selected": "none" });
        }
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": `/api/emails/inboxes` }, setInboxList, inboxListCallback);
        findAll();
    }, []);

    const inboxListCallback = (data) => {
        if(data.length>0) switchMailBox(data[0]["id"]);
    }

    const updateFolder = (e) => {
        console.log(theFolder);
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/folder/` + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
    }
    const updateLabel = (e) => {
        AxiosNetwork.axiosUpdate({ "url": `/api/${appPath}/update/label/` + theLabel.id, "id": theLabel.id }, theLabel, labelList, setTheLabel);
        console.log(theLabel);
    }

    const renameFolder = (e) => {
        theFolder["name"] = e.target.value;
        console.log(theFolder);
        setTheFolder(theFolder);
    }

    const renameLabel = (e) => {
        theLabel["name"] = e.target.value;
        console.log(theLabel);
        setTheLabel(theLabel);
    }

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        presentObjects.folderEdit = "block";
        setTheFolder({ ...currentFolder });
        const filters = [{ "col_name": "direction", "data_type": "number", "operator": "=", "integer_value": 0 }];
        AxiosNetwork.axiosFetchMulti({ "url": `/api/emails/find-new-emails/${queryParams["inbox-id"]}` }, { "folder_id":currentFolder.id, "doc_name": tableName, "filters": filters }, setRecordsList);
    }


    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        const filters = [{ "col_name": "direction", "data_type": "number", "operator": "=", "integer_value": 0 }];
        AxiosNetwork.axiosFetchMulti({ "url": `/api/emails/find-new-emails/${queryParams["inbox-id"]}` }, { "label_id":currentLabel.id, "doc_name": tableName, "filters": filters }, setRecordsList);
    }

    const switchMailBoxByMenu = (selectedAccount) => {
        switchMailBox(selectedAccount["key"]);
    }

    const switchMailBox = (mailBox) => {
        console.log(mailBox);
        mailBox = mailBox.replace("mail-box-", "");
        queryParams["inbox-id"] = mailBox;
        findAll();
    }



    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const deleteFileAssetRow = (selectedRow) => {
        console.log(selectedRow);
        if (selectedRow.type == 56) AxiosNetwork.axiosDelete({ "url": `/api/cdn-file-assets/mime/delete/` + selectedRow.id, "id": selectedRow.id }, theCdnFilesList, setTheCdnFilesList);
        if (selectedRow.type == 28) AxiosNetwork.axiosDelete({ "url": `/api/file-assets/mime/delete/` + selectedRow.id, "id": selectedRow.id }, theFilesList, setTheFilesList);
    }


    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": `/api/${appPath}/restore/` + selectedRow.id, "id": selectedRow.id }, recordsList, setRecordsList);
    }


    const findFromBin = (filter) => {
        activeDataColumns = columnSchema;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        console.log(activeDataColumns);
    }

    const findEmailAddressLike = (value) => {
        var payload = { "doc_name": "users", "col_name": "email", "col_value": value };
        AxiosNetwork.axiosPost({ "url": `/api/common/find-any-multi-like` }, payload, emailAddressList, setEmailAddressList, setEmailAddressListCallBack);
    }

    const setEmailAddressListCallBack = (data) => {
        setEmailAddressList([...data])
    }



    const findAll = (genre = "inbox") => {
        console.log("findAll");
        mailBoxGenre = genre;
        activeDataColumns = columnSchema.slice(0, -1);
        var filters = [{ "col_name": "direction", "data_type": "number", "operator": "=", "integer_value": 0 }];
        if (genre == "sent") {
            filters = [{ "col_name": "direction", "data_type": "number", "operator": "=", "integer_value": 1 }];
        }
        if (genre == "draft") {
            activeDataColumns[1] = { title: 'Open', render: (record) => <a onClick={(e) => editDraftEmailRow(record)}><FontAwesomeIcon icon={faEye} /></a>, width: 60 };
            filters = [{ "col_name": "direction", "data_type": "number", "operator": "=", "integer_value": 3 }];
        }
        if(queryParams["doc_id"]!==undefined)
        {
            filters.push({ "col_name": "doc_id", "data_type": "string", "operator": "=", "string_value": queryParams["doc_id"] });
        }
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/${appId}` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/${appId}` }, setLabelList);
        if (queryParams["inbox-id"] !== undefined) {
            AxiosNetwork.axiosFetchMulti({ "url": `/api/emails/find-new-emails/${queryParams["inbox-id"]}` }, { "doc_name": tableName, "filters": filters }, setRecordsList);
            showHideAllProps({"email-connect-section-non-oauth": "none", "no-inbox-selected": "none", "email-body-display": "none", "email-received-list": "block", "back-to-home-controls": "none", "email-body-compose-display": "none" });
        }
        else {
            showHideAllProps({ "email-connect-section-non-oauth": "none", "no-inbox-selected": "block", "email-body-display": "none", "email-received-list": "none", "back-to-home-controls": "none", "email-body-compose-display": "none" });
        }
    }



    const showReply = (type) => {
        dataMassage["in_reply_to"] = theObject["id"];
        theObject["id"] = generateFuzzyId("email");
        theObject["name"] = "Re: " + theObject["name"];
        emailComposeForm.setFieldsValue({ ...theObject });
        formData["parent_id"] = theObject["id"];
        formData["folder_id"] = theObject["id"];
        showHideAllProps({ "email-connect-section-display": "none", "email-body-compose-display": "block", "email-body-display": "none", "email-received-list": "none" });
        window.scrollTo(0, 0);
    }

    const showForward = (type) => {
        dataMassage["in_reply_to"] = theObject["id"];
        theObject["id"] = generateFuzzyId("email");
        theObject["name"] = "Fwd: " + theObject["name"];
        emailComposeForm.setFieldsValue({ ...theObject });
        formData["parent_id"] = theObject["id"];
        formData["folder_id"] = theObject["id"];
        showHideAllProps({ "email-connect-section-display": "none", "email-body-compose-display": "block", "email-body-display": "none", "email-received-list": "none" });
        window.scrollTo(0, 0);
    }


    const showComposeEmail = () => {
        if (queryParams["inbox-id"] == undefined) return;
        delete dataMassage["in_reply_to"];
        theObject["id"] = generateFuzzyId("email");
        formData["parent_id"] = theObject["id"];
        formData["folder_id"] = theObject["id"];
        showHideAllProps({ "email-connect-section-display": "none", "email-body-compose-display": "block", "email-body-display": "none", "email-received-list": "none" });
    }

    const showEmailHomeControls = () => {
        showHideAllProps({ "email-connect-section-display": "none", "email-received-list": "block", "email-body-display": "none", "back-to-home-controls": "none", "email-body-compose-display": "none" });
    }

    const downloadFile = (selectedRow) => {
        console.log(selectedRow);
        var a = document.createElement('a');
        a.href = appProfile.serviceLocation + `api/file-assets/mime/download/` + selectedRow.id + "/" + selectedRow.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const connectEmailAccount = (emailAccount) => {
        emailAccountProvider = emailAccount;
        if (emailAccountProvider == "gmail") {
            window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${window.location.origin}/public/social/auth/email/google&prompt=consent&response_type=code&client_id=${socialAuthProps["google"]["clientId"]}&scope=https://mail.google.com/mail/feed/atom+https://www.googleapis.com/auth/userinfo.email&access_type=offline`;
        }
        else if (emailAccountProvider == "icloud") {
            showHideAllProps({ "email-connect-section-non-oauth": "block" });
        }
        else if (emailAccountProvider == "yahoo") {
            showHideAllProps({ "email-connect-section-non-oauth": "block" });
        }
        else if (emailAccountProvider == "microsoft-exchange") {
            showHideAllProps({ "email-connect-section-non-oauth": "block" });
        }
        else if (emailAccountProvider == "aol") {
            showHideAllProps({ "email-connect-section-non-oauth": "block" });
        }
        else if (emailAccountProvider == "other") {
            showHideAllProps({ "email-connect-section-non-oauth": "block" });
        }
    }

    return (
        <Layout>
            <Row gutter={[16,16]}>
                
                <Col span={4}>
                    <Select style={{ width: '100%' }} defaultValue={((queryParams["inbox-id"] !== undefined) ? queryParams["inbox-id"] : "")} placeholder="Select Account" onChange={(e) => switchMailBox(e)}>
                            {inboxList.map(eachItem => (
                                <Menu.Item key={"mail-box-" + eachItem.id}>{eachItem.name}</Menu.Item>
                            ))}
                        </Select>
                        <a onClick={(e) => window.location.href=window.location.origin + "/apps/emails"}>Connect</a>

                    {(queryParams["inbox-id"] !== undefined) && (
                        <Menu mode="inline" defaultOpenKeys={['sub1']} style={{ borderRight: 0 }}>
                            <Menu.Item key="compose-records" onClick={(e) => showComposeEmail()}>Compose</Menu.Item>
                            <Menu.Item key="inbox-all-records" onClick={(e) => findAll("inbox")}>Inbox</Menu.Item>
                            <Menu.Item key="draft-all-records" onClick={(e) => findAll("draft")}>Draft</Menu.Item>
                            <Menu.Item key="sent-all-records" onClick={(e) => findAll("sent")}>Sent</Menu.Item>
                            <SubMenu key="sub-folders" title="Folders">
                                {folderList.map(eachItem => (
                                    <Menu.Item key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</Menu.Item>
                                ))}
                            </SubMenu>
                            <SubMenu key="sub-labels" title="Labels">
                                {labelList.map(eachItem => (
                                    <Menu.Item key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</Menu.Item>
                                ))}
                            </SubMenu>
                            <Menu.Item key="bin-records" onClick={(e) => findFromBin("deleted")}>BIN</Menu.Item>
                        </Menu>
                    )}

                </Col>
            <Col>
            
                        <section style={{ display: presentObjects["back-to-home-controls"] }}>
                            <Button onClick={(e) => showEmailHomeControls()}>Back</Button>
                        </section>

        

                        <section style={{ display: presentObjects["email-received-list"] }}>
                            <Table style={{ width: "100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} />
                        </section>

                        <section style={{ width: "800px", paddingBottom: "50px", display: presentObjects["email-body-display"] }}>
                            <div>
                                <Typography.Title level={5} style={{ margin: 0 }}>Subject: {theObject.name}</Typography.Title>
                                <p>From: {((theObject["sender_name"] == undefined) ? theObject["sender"] : theObject["sender_name"])}<br />
                                    Received: {moment(theObject["created_at"]).format("lll")}<br />
                                    To: {theObject["email_to"].join(", ")}<br />
                                    CC: {theObject["email_cc"].join(", ")}
                                </p>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: theObject.description }} />
                            <div>
                                {theObject["mime_assets"].map(eachFile =>
                                    <p>Download: <a onClick={(e) => downloadFile(eachFile)}>{eachFile.name}</a></p>
                                )}
                            </div>
                            {(mailBoxGenre == "inbox") && (
                                <div><Space size={10}><Button onClick={(e) => showReply("reply")}>Reply</Button>
                                    <Button onClick={(e) => showReply("reply-all")}>Reply All</Button>
                                    <Button onClick={(e) => showForward("forward")}>Forward</Button>
                                    <Button onClick={(e) => showEmailHomeControls()}>Back</Button></Space></div>
                            )}
                            {(mailBoxGenre == "sent") && (
                                <div><Button onClick={(e) => showForward("reply")}>Forward</Button></div>
                            )}
                        </section>

                        <section className="thCard" style={{ maxWidth: "1024px", display: presentObjects["email-body-compose-display"] }}>
                            <Form size={"large"} form={emailComposeForm} name="basic" onFinish={saveEmail} autoComplete="off" layout="vertical"
                                labelCol={{
                                    span: 4,
                                }}
                                wrapperCol={{
                                    span: 20,
                                }}>
                                <Form.Item label="Subject" name="name" rules={[{ required: true, message: 'Enter the subject' }]}>
                                    <Input onChange={(e) => { theObject.name = e.target.value }} />
                                </Form.Item>

                                <Form.Item label="To" name="email_to">
                                    <Select onSearch={findEmailAddressLike} mode="tags" style={{ width: '100%' }} placeholder="Email Ids">
                                        {emailAddressList.map(eachItem => (
                                            <Option key={eachItem.email}>{eachItem.email}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                        
                                <Button type="link" onClick={(e) => showHideProps("email-cc", true)}>CC</Button>
                                <Button type="link" onClick={(e) => showHideProps("email-bcc", true)}>BCC</Button>
                                

                                <Form.Item label="CC" name="email_cc" style={{"display":presentObjects["email-cc"] }}>
                                    <Select onSearch={findEmailAddressLike} mode="multiple" style={{ width: '100%' }} placeholder="Email Ids">
                                        {emailAddressList.map(eachItem => (
                                            <Option key={eachItem.email}>{eachItem.email}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item label="BCC" name="email_bcc" style={{"display":presentObjects["email-bcc"] }}>
                                    <Select onSearch={findEmailAddressLike} mode="multiple" style={{ width: '100%' }} placeholder="Email Ids">
                                        {emailAddressList.map(eachItem => (
                                            <Option key={eachItem.email}>{eachItem.email}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Body" name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter your task',
                                        },
                                    ]}>
                                    <div style={{ border: "1px solid gray" }}>
                                        <CKEditor
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement()
                                                );
                                                editor.editing.view.change((writer) => { writer.setStyle("height", "200px", editor.editing.view.document.getRoot()); });
                                            }}

                                            onError={(error, { willEditorRestart }) => {
                                                // If the editor is restarted, the toolbar element will be created once again.
                                                // The `onReady` callback will be called again and the new toolbar will be added.
                                                // This is why you need to remove the older toolbar.
                                                if (willEditorRestart) {
                                                    this.editor.ui.view.toolbar.element.remove();
                                                }
                                            }}

                                            onChange={(event, editor) => {
                                                ckEditorData = editor.getData();
                                                theObject["description"] = ckEditorData;
                                                console.log({ event, editor, ckEditorData });
                                            }}

                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}

                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}

                                            editor={DecoupledEditor} data={ckEditorDataReply} />
                                    </div>
                                </Form.Item>
                                <Row gutter={12} style={{marginTop:"30px"}}>
                                    <Col span={10}>
                                        <Dragger {...fileUploadProps}>
                                            <p className="ant-upload-drag-icon"><FontAwesomeIcon /></p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        </Dragger>
                                    </Col>
                                    <Col span={10}>
                                        <Dragger {...fileUploadProps}>
                                            <p className="ant-upload-drag-icon"><FontAwesomeIcon /></p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        </Dragger>
                                    </Col>
                                </Row>
                                <Form.Item style={{marginTop:"10px"}}>
                                    <Button type="primary" size={12} onClick={(e) => dataMassage["mailComposeFolder"] = "sent"} htmlType="submit">Send</Button> &#x2002;
                                    <Button type="primary" size={12} onClick={(e) => dataMassage["mailComposeFolder"] = "draft"} htmlType="submit">Save</Button>
                                </Form.Item>
                                </Form>
                        </section>


                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                        </Col></Row>
            
        </Layout>
    )
}