import React, { Component, useState, useEffect } from 'react';
import './App.css';
import { Form, Anchor, Typography, Avatar, Divider, List, Card, Col } from 'antd';
import { AxiosNetwork } from "./axiosService";
const { Meta } = Card;
const { Title } = Typography;


export default function Apps() {
    const [appsList, setAppsList] = useState([{ "id": 1, "name": "Waiting to load" }]);

    useEffect(() => {
        AxiosNetwork.axiosFetch({ "url": "/api/common/apps" }, setAppsList, setAppsListCallback)
    }, []);

    const setAppsListCallback = (data) =>
    {
        //remove all apps icons
        data = data.filter(item => item.id !== "all");
        setAppsList(data);
    }

    return (
        <div>
            <div className='appCategory'>
                <Divider orientation="center">Applications</Divider>
                {appsList.filter(eachItem => eachItem.genre === "APP").map(eachItem => (
                    <div key={"div-"+eachItem.id} className='appCard boxes zoom-in'>
                        <a href={eachItem.href} key={'link-' + eachItem.id}>
                            <img className='appIcon' src={eachItem.icon} /> <div className='appName'>{eachItem.name}</div>
                        </a>
                    </div>
                ))}
            </div>


            <div className='appCategory'>
                <Divider orientation="center">Utilities</Divider>
                {appsList.filter(eachItem => eachItem.genre === "UTILS").map(eachItem => (
                    <div key={"div-"+eachItem.id} className='appCard boxes zoom-in'>
                        <a href={eachItem.href} key={'link-' + eachItem.id}>
                            <img className='utilsIcon' src={eachItem.icon} /><div className='appName'>{eachItem.name}</div>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}