import React, { useRef, useState, useEffect } from 'react';
import Form from "react-jsonschema-form";
import '../App.css';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import ReactJson from 'react-json-view'


//https://www.npmjs.com/package/jsoneditor-react
export default function CustomFieldEditor(props) {

    const [jsonDoc, setJsonDoc] = useState({});
    const [theObject, setTheObject] = useState({});
    const [queryParams, setQueryParams] = useState({});
    const [theObjectList, setTheObjectList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "active":"block", "bin":"none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": "none" });

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }


    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);
        setQueryParams(queryParams);
        AxiosNetwork.axiosFetchMono({ "url": `api/common/common-fields/${queryParams.table}/${queryParams.id}`}, setJsonDoc);
        showHideProps("showInfo", "block");
    },[]);

    const handleChange = ( formData ) => {
        console.log(formData);
        setJsonDoc(formData);
    }

    const saveCustomFields = () => 
    {
        AxiosNetwork.axiosPut({ "url": `api/common/common-fields/update/${queryParams.table}/${queryParams.id}`, "id":queryParams.id}, jsonDoc, theObjectList, setTheObjectList);
        console.log(jsonDoc);
    }

    return (
        <div>
            <div>
            <ReactJson src={jsonDoc} 
                onEdit={e => {
                    console.log("edit callback", e)
                    if (e.new_value == "error") {
                        return false
                    }
                }}
                onDelete={e => {
                    console.log("delete callback", e)
                }}
                onAdd={e => {
                    console.log("add callback", e)
                    if (e.new_value == "error") {
                        return false
                    }
                }}
                onSelect={e => {
                    console.log("select callback", e)
                    console.log(e.namespace)
                }}
                />
            </div>
            <div style={{marginTop:20}}>
            <Editor
                key={new Date().getTime()}
                value={jsonDoc}
                onChange={handleChange}
            />
            </div>
            <Button style={{marginTop:20}} type="primary" onClick={(e) => saveCustomFields()}>Save</Button>
        </div>
    )
}