import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
import { AudioOutlined } from '@ant-design/icons';

//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, List, Avatar, Menu, Card, Row, Button, Table, Form, Modal, Upload, message, Select, InputNumber, Collapse, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import PosBrand from "./PosBrand";
import PosProductType from "./PosProductType";
import PosProduct from "./PosProduct";
import PosConsignment from "./PosConsignment";
import PosSupplierProduct from './PosSupplierProduct';
import PosSupplier from './PosSupplier';
import PosCustomer from './PosCustomer';
import PosPriceBook from './PosPriceBook';
import PosCustomerAddress from './PosCustomerAddress';
import PosPreferences from './PosPreferences';
import Iframe from '../Iframe';

const { Panel } = Collapse;
const dataMassage = { "emailAddress": {}, "associateId": "1", "sale_id":undefined };
const { Header, Content, Sider } = Layout;
const { Option } = Select;
const { Search } = Select;

var formData = {};
const appId = 130;
const appPath = "terminal";
const tableName = "pos";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function PosOutlet() {
    const [productBrands, setProductBrands] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [outletList, setOutletList] = useState([]);
    const [salesList, setSalesList] = useState([]);
    const [paymentPartner, setPaymentPartner] = useState([]);
    const [theOutlet, setTheOutlet] = useState([]);
    const [productList, setProductList] = useState([]);
    const [frequentProductList, setFrequentProductList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [scaleStep, setScaleStep] = useState(0.5);
    const [presentObjects, setPresentObjects] = useState({"payment-process-iframe":"none", "show-sales-modal":false, "payment-methods":"none", "pos-preferences":"none", "outlet-lists-table": "none", "price-books": "none", "customer-notifications": "none", "customers": "none", "consignment-product": "none", "consignments": "none", "suppliers": "none", "variants": "none", "product-variant": "none", "supplier-product": "none", "product": "none", "brands": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block", "customers": "none", "terminals": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [theCustomer, setTheCustomer] = useState({});
    const [theSale, setTheSale] = useState({"total":0, "discount":0, "line_items":[], "payments":[], "total_price_incl":0});
    const [organizationPayment, setOrganizationPayment] = useState({});
    const [thePayment, setThePayment] = useState([]);
    const [thePricing, setThePricing] = useState({ "total": 0, "tax": 0, "discount": 0, "loyalty": 0 });
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [posTerminalForm] = Form.useForm();
    const [posTerminalBillForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("terminal")
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/pos/${tableName}`);
        findAll();
    }, []);

    const terminalsTable = [
        {
            title: 'Outlet',
            dataIndex: 'name'
        },
        {
            key: "view",
            title: 'Open',
            width: 50,
            render: (record) => <a onClick={(e) => window.location.href = window.location.origin + "/apps/pos?outlet=" + record.id}><FontAwesomeIcon icon={faEye} /></a>,
        }
    ];


    const columnSchema = [
        {
            title: 'Outlet',
            dataIndex: 'organization_name'
        },
        {
            key: "view",
            title: 'View',
            width: 50,
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },

    ];


    const showOtherComponents = (sectionName) => {
        var toggleSections = { "price-books": "none", "customer-notifications": "none", "customers": "none", "consignment-product": "none", "consignments": "none", "suppliers": "none", "supplier-product": "none", "product": "none", "brands": "none", "records-list-table": "none" };
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/pos/brands/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your terminals details has been saved.');
        posTerminalBillForm.resetFields();
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/pos/terminal/organizations` }, {}, setOutletList, afterOrgnizationFetchCallback);//todod
    }
    const afterOrgnizationFetchCallback = (data) => {
        setOutletList([...data]);
        var sections = { "customers-details": "none", "edit-selected-record": "block", "terminals-sales-window": "none", "records-list-table": "none" }
        if (queryParams.outlet == undefined) {
            sections["outlet-lists-table"] = "block";
            showHideAllProps(sections);
            return;
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/pos/terminal/organizations/${queryParams.outlet}/products` }, { "filters": [{ "col_name": "frequent", "data_type": "number", "integer_value": 1, "operator": "=" }] }, setFrequentProductList, afterFrequentProductsLoaded);
    }

    const afterFrequentProductsLoaded = (data) => {
        setFrequentProductList([...data]);
        showHideAllProps({ "terminals-sales-window": "block", "customers-details": "none", "edit-selected-record": "block", "records-list-table": "none" });
    }

    const posTerminalFormFailed = () => {

    }
    const posTerminalBillFormFailed = () => {

    }
    const viewTableRow = (selectedRow) => {
        dataMassage["terminalId"] = selectedRow.id;
        dataMassage["terminalProductId"] = generateFuzzyId("terminal");
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/products` }, { "doc_name": "products" }, setProductList);
        showHideAllProps({ "customers-details": "block", "terminals-sales-window": "block", "records-list-table": "none", "edit-selected-record": "none" })
    }

    const findProductLike = (value) => {
        var payload = { "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/pos/terminal/organizations/${value["organization_id"]}/products` }, payload, setProductList, setProductListCallBack);
    }

    const setProductListCallBack = (data) => {
        setProductList([...data]);
    }

    const findCustomerLike = (value) => {
        var payload = { "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/pos/terminal/organizations/${value["organization_id"]}/customers` }, payload, setCustomerList, setCustomerListCallBack);
    }
    const setCustomerListCallBack = (data) => {
        setCustomerList([...data]);
    }

    const findCustomerDone = (data) => {
        console.log(data);
        //setTheCustomer(...customer);
        showHideAllProps({"payment-methods":"block"});
    }
    
    const showFindAll = () => {
        showHideAllProps({ "customers-details": "none", "terminals-sales-window": "none", "records-list-table": "block", "edit-selected-record": "none" })
    }
    const selectedProduct = (e, product) => {
        addToCart(e, product);
    }   
    const calculateCartTotal = () =>
    {
        var cartLineItems = [...cartItems];
        let total = 0;
        let discount = 0;
        let totalTax = 0;
        cartLineItems.forEach(eachItem => {
            total = total + eachItem.total;
            discount = discount + eachItem.discount;
            totalTax = totalTax + eachItem["tax"];
        });
        setTheSale({"id": "sale-"+new Date().getTime(), "total_price_incl": (total+totalTax), "total":total, "discount":discount, "tax":totalTax, "line_items":[], "payments":[]});
        showHideAllProps({"terminals-sales-window":"block"});
    }
    const calculateCartItemTotal = (product) =>
    {
        const total = (product["quantity"] * product["price"]);
        const discount = (total * (product["discount"]/100));
        product["total"] = (total - discount);
        product["total_discount"] = discount;
        product["tax"] = 0;
        product["taxes"].forEach(eachTax => {
            //todo change price to percentage
            product["tax"] = product["tax"] + (product["total"] * (eachTax["price"]/100));
        });
        return product;
    }
    const addToCart = (e, product) => {
        product["quantity"] = 1;
        product["discount"] = 0.0;
        product["product_id"] = product["id"];
        product["tax"] = 0.0;
        if(!Array.isArray(product["taxes"])) product["taxes"] = [];
        product = calculateCartItemTotal(product);
        const cartIndex = cartItems.findIndex((obj => obj.id == product.id));
        if(cartIndex!=-1){ cartItems[cartIndex] = product;}else{cartItems.push(product);}
        setCartItems([...cartItems]);
        calculateCartTotal();
    }
    const changeCartDiscount = (e, cartItem) =>
    {
        console.log(e, cartItem);
        cartItem["discount"] = e;
        const cartIndex = cartItems.findIndex((obj => obj.id == cartItem.id));
        if(cartIndex==-1) return;
        cartItem = calculateCartItemTotal(cartItem);
        cartItems[cartIndex] = cartItem;
        setCartItems([...cartItems]);
        calculateCartTotal();
    } 
    const changeCartPrice = (e, cartItem) =>
    {
        console.log(e, cartItem);
        cartItem["price"] = e;
        const cartIndex = cartItems.findIndex((obj => obj.id == cartItem.id));
        if(cartIndex==-1) return;
        cartItem = calculateCartItemTotal(cartItem);
        cartItems[cartIndex] = cartItem;
        setCartItems([...cartItems]);
        calculateCartTotal();
    } 
    const changeCartQuantity = (e, cartItem) =>
    {
      console.log(e, cartItem);
      cartItem["quantity"] = e;
      const cartIndex = cartItems.findIndex((obj => obj.id == cartItem.id));
      if(cartIndex==-1) return;
      cartItem = calculateCartItemTotal(cartItem);
      cartItems[cartIndex] = cartItem;
      setCartItems([...cartItems]);
      calculateCartTotal();
    } 
    const proceedPayment = () =>
    {
        if(dataMassage["sale_id"]==undefined) dataMassage["sale_id"] = generateFuzzyId("sale");
        let salePayload = {...theSale};
        salePayload["line_items"] = cartItems;
        AxiosNetwork.axiosPutMono({ "url": `/api/pos/terminal/organizations/${queryParams.outlet}/sales/${dataMassage["sale_id"]}/update` }, salePayload, setTheSale, afterSaleSavedCallback);
    }
    const afterSaleSavedCallback = (data) =>
    {
        if(theCustomer["id"]==undefined) return;
        showHideAllProps({"payment-methods":"block"});
    }
    const electronicPayment = () =>
    {
        if(dataMassage["cash-payment-id"]==undefined) dataMassage["cash-payment-id"] = generateFuzzyId("sale-payment");
        let salePaymentPayload = {"sale_id": dataMassage["sale_id"], "payment_id":"root-payment-ccavenue", "test":false };
        AxiosNetwork.axiosPostMono({ "url": `/api/pos/terminal/organizations/${queryParams.outlet}/sales/${dataMassage["sale_id"]}/sale-payments/${dataMassage["cash-payment-id"]}/upsert` }, salePaymentPayload, setTheSale, afterSalePaymentSavedCallback);
    }
    const cashPayment = () =>
    {
        if(dataMassage["electronic-payment-id"]==undefined) dataMassage["electronic-payment-id"] = generateFuzzyId("sale-payment");
        let salePaymentPayload = {"sale_id": dataMassage["sale_id"], "payment_id":"root-payment-cash", "test":false };
        AxiosNetwork.axiosPostMono({ "url": `/api/pos/terminal/organizations/${queryParams.outlet}/sales/${dataMassage["sale_id"]}/sale-payments/${dataMassage["electronic-payment-id"]}/upsert` }, salePaymentPayload, setTheSale, afterSalePaymentSavedCallback);
    }
    const afterSalePaymentSavedCallback = (data) =>
    {
        const paymentPartnerPayload = {
            order_id: 8765432,
            currency: 'INR',
            amount:1
        }
        //todo organization paymentid
        console.log("afterSalePaymentSavedCallback", data);
        AxiosNetwork.axiosPostMono({ "url": `/api/common/payments/ccavenue/generate-payload/abc123` }, paymentPartnerPayload, setOrganizationPayment, setOrganizationPaymentCollectionForward);
    }
    const setOrganizationPaymentCollectionForward = (data) =>
    {
        console.log(data);
        setPaymentPartner({...data});
        showHideAllProps({"payment-process-iframe":"block"});
    }
    const listRecentSales = (data) =>
    {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/pos/terminal/organizations/${queryParams.outlet}/sales` }, {}, setSalesList, setSalesListCallback);
    }
    const setSalesListCallback = (data) =>
    {
        setSalesList([...data]);
        showHideAllProps({"show-sales-modal":true});
    }
    const openSaleById = (saleId) =>
    {
        console.log(saleId);
    }
    
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['all-records']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll("all")} rel="noopener noreferrer">Outlets</a>),
                                children: outletList.map(eachItem => (
                                    {
                                        key: 'outlet-' + eachItem.id,
                                        label: (<a onClick={(e) => window.location.href = window.location.origin + "/apps/pos?outlet=" + eachItem.id}>{eachItem.name}</a>)
                                    }
                                ))
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin()} rel="noopener noreferrer">Bin</a>)
                            },
                            { label: (<a onClick={e => showOtherComponents("brands")} href="#">Brands</a>), "key": "brands" },
                            { label: (<a onClick={e => showOtherComponents("product")} href="#">Products</a>), "key": "product" },
                            { label: (<a onClick={e => showOtherComponents("product-type")} href="#">Product Types</a>), "key": "product-type" },
                            { label: (<a onClick={e => showOtherComponents("supplier-product")} href="#">Supplier Products</a>), "key": "supplier-product" },
                            { label: (<a onClick={e => showOtherComponents("suppliers")} href="#">Suppliers</a>), "key": "suppliers" },
                            { label: (<a onClick={e => showOtherComponents("consignments")} href="#">Consignments</a>), "key": "consignments" },
                            { label: (<a onClick={e => showOtherComponents("customers")} href="#">Customers</a>), "key": "customers" },
                            { label: (<a onClick={e => showOtherComponents("customer-notifications")} href="#">Customer Address</a>), "key": "customer-notifications" },
                            { label: (<a onClick={e => showOtherComponents("price-books")} href="#">Price Books</a>), "key": "price-books" },
                            { label: (<a onClick={e => showOtherComponents("pos-preferences")} href="#">Preference</a>), "key": "pos-preferences" },
                            { label: (<a onClick={e => window.location.href = window.location.origin + "/apps/pos-admin" }>Outlets</a>), "key": "pos-admin" },
                            
                        ]} />

                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>
                        {((presentObjects["brands"] == "block") &&
                            <section>
                                <PosBrand />
                            </section>
                        )}
                        {((presentObjects["product-type"] == "block") &&
                            <section>
                                <PosProductType />
                            </section>
                        )}
                        {((presentObjects["product"] == "block") &&
                            <section>
                                <PosProduct />
                            </section>
                        )}
                        {((presentObjects["supplier-product"] == "block") &&
                            <section>
                                <PosSupplierProduct />
                            </section>
                        )}
                        {((presentObjects["suppliers"] == "block") &&
                            <section>
                                <PosSupplier />
                            </section>
                        )}
                        {((presentObjects["customers"] == "block") &&
                            <section>
                                <PosCustomer />
                            </section>
                        )}
                        {((presentObjects["customer-notifications"] == "block") &&
                            <section>
                                <PosCustomerAddress />
                            </section>
                        )}

                        {((presentObjects["consignments"] == "block") &&
                            <section>
                                <PosConsignment />
                            </section>
                        )}
                        {((presentObjects["price-books"] == "block") &&
                            <section>
                                <PosPriceBook />
                            </section>
                        )}
                        {((presentObjects["pos-preferences"] == "block") &&
                            <section>
                                <PosPreferences />
                            </section>
                        )}
                        {((presentObjects["terminal"] == "block") &&
                            <section>
                                <PosOutlet />
                            </section>
                        )}


                        <section style={{ "display": presentObjects["outlet-lists-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={terminalsTable} dataSource={outletList} pagination={{ pageSize: 50 }} />
                        </section>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                        </section>

                        <section style={{ "display": presentObjects["terminals-sales-window"]  }}>
                            <Card style= {{ marginBottom: "50px"}}>
                            <Row gutter={[16, 16]}>
                                <Button style= {{ marginLeft: "20px"}} onClick={(e) => window.location.reload()}>New</Button>
                                <Button onClick={(e) => listRecentSales()}>Open Sale</Button>

                                <Modal  title="Open Sales" open={presentObjects["show-sales-modal"]} onOk={(e)=>showHideAllProps({"show-sales-modal":false})} onCancel={(e)=>showHideAllProps({"show-sales-modal":false})}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={salesList}
                                        renderItem={(item) => (
                                        <List.Item
                                            actions={[<a onClick={(e)=>openSaleById(item.id)}onkey="list-loadmore-edit"><FontAwesomeIcon icon={faEdit}/></a>]}>
                                            <List.Item.Meta
                                            avatar={<FontAwesomeIcon icon={faCartShopping}/>}
                                            title={<a href="https://ant.design">{item.name}</a>}
                                            description={item.created_at}
                                            />
                                        </List.Item>
                                        )}
                                    />
                                </Modal>

                            </Row>
                            </Card>
                            
                            <Card style= {{ marginBottom: "50px"}}>
                                <Row gutter={[16, 16]}>
                                <Col span={12} >
                                    <Select style= {{ marginBottom: "50px"}} showSearch onSearch={findProductLike}
                                        placeholder="Select Product ">
                                        {productList.map(eachItem => (
                                            <Option key={eachItem.name}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                    {frequentProductList.map(eachProduct =>
                                        <Card style= {{ marginBottom: "50px"}}
                                            key={'product-' + eachProduct.id}
                                            hoverable
                                            cover={<img src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png" />}
                                            onClick={(e) => addToCart(e, eachProduct)}>
                                            {eachProduct.name}
                                            {eachProduct.price}
                                        </Card>
                                    )}
                                </Col>
                                <Col span={12} >
                                    <Select style= {{ marginBottom: "50px"}} showSearch onSelect={(e) => findCustomerDone(e)} onSearch={findCustomerLike}
                                        placeholder="Enter your Customer">
                                        {customerList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
            <Collapse accordion>
                                            {cartItems.map(eachProduct =>
                                                <Panel header={eachProduct.quantity + ", "+ eachProduct.name + ", " + eachProduct.total} key={eachProduct.id}>
                                                    <div>Qty:<InputNumber onChange={(e) => changeCartQuantity(e,eachProduct)} defaultValue={eachProduct.quantity}/> Price: <InputNumber onChange={(e) => changeCartPrice(e,eachProduct)} defaultValue={eachProduct.price}/> Discount: <InputNumber onChange={(e) => changeCartDiscount(e,eachProduct)} defaultValue={eachProduct.discount}/></div>
                                                </Panel>
                                            )}
                                    </Collapse>
                                    <section id={theSale.id} style= {{ marginBottom: "50px"}}>
                                        <div>Total: {theSale.total}, Tax: {theSale.tax}, Discount: {theSale.discount}</div>
                                        <div><Button onClick={(e) => proceedPayment()}>Pay {cartItems.length} items, Total:{theSale.total_incl}</Button></div>
                                    </section>
                                    <section style={{"display":presentObjects["payment-methods"], marginBottom: "50px"}}>
                                        <div>
                                            <Button onClick={(e) => cashPayment()}>Cash</Button>
                                            <Button onClick={(e) => electronicPayment()}>Payment Partner</Button>
                                        </div>
                                    </section>
                                    <section key={"refresh-"+new Date().getTime} style={{"display":presentObjects["payment-process-iframe"]}}>
                                        <div>
                                            <Iframe src={paymentPartner["forward_url"]} height={500} width={500}/>
                                        </div>
                                    </section>
                                </Col>
                            </Row>
                            </Card>
                        </section>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}