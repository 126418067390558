import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faInfoCircle, faSave, faListSquares, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { appProfile, dateTimeUtils, generateFuzzyId, menuBarItems, profileBarItems } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment';
import { Layout, Menu, Typography, Space, Row, Button, Radio, Form, InputNumber, Input, Upload, message, Select, DatePicker, TimePicker, Drawer, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import { PlusOutlined } from '@ant-design/icons';
import * as EmailValidator from 'email-validator';
import MiniCalendar from './MiniCalendar';


const { RangePicker } = TimePicker;
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 62;
const appPath = "meetings";
const tableName = "meetings";
var activeDataColumns = [];
var nextTenYears = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };
var scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment().startOf("day").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment().endOf("day").format(dateTimeUtils.dateTimeFormat) }];
var calendarView = { "view": { "type": "timeGridWeek" }, "calendarKey": "1" };

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export default function Calendar() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [guestList, setGuestList] = useState([]);
    const [inboxList, setInboxList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [timezonesList, setTimezonesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [templateList, setTemplateList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({  "setNewCalendarDrawer":false,"reminderRecurNo": true, "reminderRecurYes": true, "reminderRecurEndAfter": true, "reminderRecurEndDate": true, "new-schedules": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [newCalendarDrawer, setNewCalendarDrawer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [meetingForm] = Form.useForm();
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            title: 'Starts At',
            render: (record) => <span>{record["start_at"] && moment(record["start_at"]).format("lll")}</span>
        },
        {
            title: 'Ends At',
            render: (record) => <span>{record["end_at"] && moment(record["end_at"]).format("lll")}</span>
        },
        {
            title: 'Total',
            render: (record) => <span>{(record["total"] != null) && Math.round(record["total"] / 60000)} Min.</span>
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        AxiosNetwork.axiosFetch({ "url": "/api/common/folders/appid/" + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": "/api/common/labels/appid/" + appId }, setLabelList);
        AxiosNetwork.axiosFetch({ "url": `api/emails/inboxes` }, setInboxList, afterInboxFetchCallback);
        AxiosNetwork.axiosFetch({ "url": `api/public/common/timezones` }, setTimezonesList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi` }, { "doc_name": "templates", "select": ["id", "name"] }, setTemplateList);
        var currentYear = new Date().getFullYear();
        for (var i = currentYear; i < currentYear + 10; i++) {
            nextTenYears.push(i);
        }
        findAll();
    }, []);


    const afterInboxFetchCallback = (inboxes) => {
        if (inboxes.length == 0) return;
        meetingForm.setFieldsValue({ "inbox_id": inboxes[0]["name"], "guest_permission": "Modify event" });
    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);

    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findAll = (filter) => {
        //AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "filters": scheduleFilter }, setRecordsList, afterReadingEvents);
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        console.log(values);
        if ((!Array.isArray(values["guests"])) || (values["guests"].length == 0)) {
            message.error("Please assign meeting guests");
        }

        for (var i = 0; i < values["guests"].length; i++) {
            if (EmailValidator.validate(values["guests"][i]) == false) {
                message.error(values["guests"][i] + " is not a valid email address");
                return;
            }
        }

        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD HH:mm:ss");
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD HH:mm:ss");
        if (values["due_at"] != undefined) values["due_at"] = moment(values["due_at"]).format("yyyy-MM-DD HH:mm:ss");
        AxiosNetwork.axiosPut({ "url": `/api/${appPath}/${theObject["id"]}/update` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    };

    const afterNewRecordSubmit = () => {
        message.info('Your schedules has been saved.');
        findAll();
    }

    const dateAreaClicked = (dayClickEvent) => {
        setUiLabels({ "save": "Save" });
        console.log("dateAreaClicked", dayClickEvent);
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        setNewCalendarDrawer(true);
    }

    const renderEventContent = (eventInfo) => {
        console.log("renderEventContent", eventInfo);
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        setTheObject({ ...selectedRow });
        if (selectedRow["start_at"] != undefined) selectedRow["start_at"] = moment(selectedRow["start_at"]);
        if (selectedRow["end_at"] != undefined) selectedRow["end_at"] = moment(selectedRow["end_at"]);
        if (selectedRow["due_at"] != undefined) selectedRow["due_at"] = moment(selectedRow["due_at"]);
        meetingForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        setNewCalendarDrawer(true);
    }

    const onEventClicked = (eventInfo) => {
        console.log("onEventClicked", eventInfo);
        const eventId = eventInfo["event"]["_def"]["publicId"];
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + eventId, "id": eventId }, theObject, setTheObject, editTableRow);
    }

    const handleEvents = (eventInfo) => {
        console.log("handleEvents", eventInfo);

    }

    const meetingFormFailed = () => {

    }
    const recurOptionChanged = (recur) => {
        if (recur == true) {
            showHideAllProps({ "reminderRecurYes": false, "reminderRecurNo": true });
        }
        else {
            showHideAllProps({ "reminderRecurYes": true, "reminderRecurNo": false });
        }
    }

    const endsOptionChanged = (ends) => {
        ends = ends.toUpperCase();
        if (ends == "ENDS DATE") {
            showHideAllProps({ "reminderRecurEndAfter": true, "reminderRecurEndDate": false });
        }
        else if (ends == "ENDS AFTER") {
            showHideAllProps({ "reminderRecurEndAfter": false, "reminderRecurEndDate": true });
        }
        else {
            showHideAllProps({ "reminderRecurEndAfter": true, "reminderRecurEndDate": true });
        }
    }
    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    };
    const [keyboard, setKeyboard] = useState(true);
    function disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }

    let ckEditorData = "";
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const findEmailAddressLike = (value) => {
        var payload = { "doc_name": "contacts", "col_name": "email", "col_value": value };
        AxiosNetwork.axiosPost({ "url": `/api/common/find-any-multi-like` }, payload, guestList, setGuestList, setEmailAddressListCallBack);
    }

    const setEmailAddressListCallBack = (data) => {
        setGuestList([...data]);
    }


    const fullCalendarViewRender = (event) => {
        console.log("fullCalendarViewRender", event);
    }

    const fcDateSetClicked = (event) => {
        console.log(event);
        if ((calendarView["startStr"] == event.startStr) && (calendarView["endStr"] == event.endStr)) return;
        calendarView["calendarKey"] = event.startStr + "" + calendarView["endStr"];
        console.log("fcDateSetClicked 1", event);
        calendarView = event;
        if (calendarView.view.type == "timeGridMonth") {
            scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment(event.start).startOf("month").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment(event.end).endOf("month").format(dateTimeUtils.dateTimeFormat) }];
        }
        else if (calendarView.view.type == "timeGridWeek") {
            scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment(event.start).startOf("week").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment(event.end).endOf("week").format(dateTimeUtils.dateTimeFormat) }];
        }
        else {
            scheduleFilter = [{ "col_name": "start_at", "data_type": "date", "operator": ">=", "date_value": moment(event.start).startOf("day").format(dateTimeUtils.dateTimeFormat) }, { "col_name": "end_at", "data_type": "date", "operator": "<=", "date_value": moment(event.end).endOf("day").format(dateTimeUtils.dateTimeFormat) }];
        }
        findAll();
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
            <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>
                        
                        <MiniCalendar key={"key"+new Date().getTime()} presentObjects={presentObjects}/>

                    </Content>

                </Layout>
            </Layout>
        </Layout>
    )
}