import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 130;
const appPath = "customer-notifications";
const tableName = "customer-notifications";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);
let ckEditorData = "";

export default function PosCustomerAddress() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [customerList, setCustomerList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "customer-notifications": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [posCustomerAddressForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("customer-notifications")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/customers` }, { "doc_name": "customers" }, setCustomerList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, { "doc_name": "addresses" }, setAddressList);
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/pos-admin/${tableName}`);
        findAll();
    }, []);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Customer',
            dataIndex: 'customer_id'
        },
        {
            title: 'Address',
            dataIndex: 'address_id'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },

    ];
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/pos/customer-notifications/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
   
    const onFinish = (values) => {
        values["upsert"] = true;
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/customer-notifications/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "customers-notifications-details": "block", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your customer-notifications details has been saved.');
        posCustomerAddressForm.resetFields();
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/customer-notifications` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "block", "customers-notifications-details": "block", "records-list-table": "block" });
    }
    const posCustomerAddressFormFailed = () => {

    }
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        setUiLabels({ "save": "Update" });
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        posCustomerAddressForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/customers-notifications/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "edit-selected-record": "block", "customers-notifications-details": "block", "records-list-table": "block" });
    }

    return (
        <section>
            <Layout className='contentLayout'>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                    <Table style={{ marginBottom: "50px" }} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                </section>
                <section className="thCard" style={{ "width": 750, "display": presentObjects["customers-notifications-details"] }}>
                    <Form size="large" labelAlign="right" layout="vertical"
                        onFinish={onFinish}
                        form={posCustomerAddressForm} initialValues={theObject}
                        onFinishFailed={posCustomerAddressFormFailed} autoComplete="off">
                        <Form.Item label="Name" name="name">
                            <Input />
                        </Form.Item>
                        <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Customer" name="customer_id" rules={[{ required: true, message: 'Enter your Customer', }]}>
                            <Select showSearch allowClear placeholder="Select Customer ">
                                {customerList.map(eachItem => (
                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item label="Address" name="address_id">
                            <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Addresses">
                                {addressList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>
                </section>
            </Layout>
        </section>
    )
}