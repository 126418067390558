import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId, dateTimeUtils } from "../../Config";
import '../../App.css';
import { Divider, Form, Input, DatePicker, Card, Row, Col,Space, Upload, Button, message, Checkbox, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faEdit, faBackward,faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHourglassStart, faHourglassEnd, faHourglassHalf, faUserClock, faShareAltSquare, faUser, faClock, faShareNodes, faFolder, faSatellite, faTimeline, faFastForward, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { View } from 'paper/dist/paper-core';
import moment from 'moment';

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-nominee";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


export default function HumanResourceNominee(props) {
    const [theEmployee, setTheEmployee] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section":"none","edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourcenomineeForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [nomineeBenefitList, setNomineeBenefitList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [dependantsList, setDependantsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [statusList, setStatusList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [addressesList, setAddressesList] = useState([{ "id": 1, "name": "Waiting to load" }]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("nominees")
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employee-nominee-benefits" }, setNomineeBenefitList);
        AxiosNetwork.axiosFetch({ "url": `/api/human-resource/employees/dependants` }, setDependantsList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employer-status" }, setStatusList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, { "doc_name": "addresses" }, setAddressesList);
        setUiLabels({ "save": "Save" });
        setTheEmployee(props["employee"]);
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/employees/nominees/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject,nomineeCallback);
    }
    const nomineeCallback = (data) => {
        if (data["effective_from"] != undefined) data["effective_from"] = moment(data["effective_from"]);
        setTheObject({ ...data });
        humanresourcenomineeForm.setFieldsValue({ ...data });
        showHideAllProps({"button-controls-section":"block","records-list-table": "none",  "nominee-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/human-resource/employees/nominees` }, setRecordsList);
        showHideAllProps({"button-controls-section":"none", "nominee-details": "none", "records-list-table": "block", "human-resource-dashboard": "none" });
    }
    const humanresourcenomineeFailed = () => {
    }
   
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["effective_from"] = moment(values["effective_from"]).format("yyyy-MM-DD HH:mm:ss");
        values["name"] = "abc";
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/employees/nominees/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your nomination details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": "/api/human-resource/employees/nominees", "id": selectedRow.id }, { "doc_name": "employees", "id": selectedRow.id }, recordsList, setRecordsList);
    }
    
    const  applyNominee  = ( )  => {
        showHideAllProps({"button-controls-section":"block", "nominee-details": "block", "records-list-table": "none", "human-resource-dashboard": "none" });
        humanresourcenomineeForm.resetFields();
    }
    const columnSchema = [
        {
            title: 'Employee Dependant',
            dataIndex: 'employee_dependant_id'
        },
        {
            title: 'Percentage',
            dataIndex: 'percentage',
        },
        {
            title: 'Nominee Benefit',
            dataIndex: 'nominee_benefit_id',
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 60
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 50,
        },
       
    ];

return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "nominee-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
          
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyNominee ()}>  Nominee  </Button>

                    </section>
                    <section className="thCard" style={{ "width": 700, "display": presentObjects["nominee-details"] }} >
                     <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanresourcenomineeForm} initialValues={theObject}
                            onFinishFailed={humanresourcenomineeFailed} autoComplete="off">
                        <Form.Item label="Employee Dependant" name="employee_dependant_id" >
                                <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Dependant Types">
                                    {dependantsList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                        </Form.Item>
                        <Row gutter={16}>
                        <Col span={12}>
                       <Form.Item label="Effective From" name="effective_from">
                       <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk}/>
                        </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item label="Percentage" name="percentage"
                                rules={[{ required: true, message: 'Type  your percentage this field' }]}>
                                <InputNumber />
                        </Form.Item>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col span={12}>
                        <Form.Item label="Address" name="address_id">
                                <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Addresses">
                                    {addressesList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                        <Col span={12}>
                        <Form.Item label="Nominee Benefit" name="nominee_benefit_id" >
                            <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Nominee Benefit">
                                    {nomineeBenefitList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        </Col>
                        </Row>
                        <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                            </Form.Item>
                        </Form>
                   </section>
               </Layout>
        </section>
    )
}