import React, { useState, useEffect } from 'react';
import '../../App.css';
import { appProfile,generateFuzzyId} from "../../Config";
import { Divider, Form, Input, Upload, Space, message, Select,Button, Card, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faBackward,faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHourglassStart, faHourglassEnd, faHourglassHalf, faUserClock, faShareAltSquare, faUser, faClock, faShareNodes, faFolder, faSatellite, faTimeline, faFastForward, faLock, faUnlock, faCancel } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

const {Content} = Layout;
const {Dragger} = Upload;
const {Option} = Select;
var formData = {};
const appId = 106;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-employee-documents";
const tableName = "human-resource";
let ckEditorData = "";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function HumanResourceEmployeeDocuments(props) {
    const [presentObjects, setPresentObjects] = useState({"button-controls-section":"none","edit-selected-record":"none","personal-information": "none", "human-resource-holidays":"none","human-resource-employee-payroll":"none","human-resource-employer-documents": "none","edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourceEmployeeDocumentsForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [employeeDocumentsList, setEmployeeDocumentsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [statusList, setStatusList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [nameList, setNameList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theEmployee, setTheEmployee] = useState(props.employee);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"]= generateFuzzyId("employee-documents");
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employee-documents" }, setEmployeeDocumentsList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employer-status" }, setStatusList);
        findAll();
    }, [props.employee.id]);


    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanResourceEmployeeDocumentsForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/admin/employees/${theEmployee["user_id"]}/documents/`+ selectedRow.id, "id": selectedRow.id}, theObject, setTheObject);
        showHideAllProps({"button-controls-section":"block","records-list-table": "none",  "employee-documents-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `api/human-resource/admin/employees/${theEmployee["user_id"]}/documents` }, setRecordsList);
        showHideAllProps({"button-controls-section":"none", "employee-documents-details": "none","records-list-table": "block", "human-resource-dashboard": "none" });
    }
    const humanResourceEmployeeDocumentsFailed = () => {

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": "employees", "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        const selectDocument = employeeDocumentsList.filter(each => each.id = values["document_type_id"]);
        if(selectDocument.length > 0)
        {
            values["name"] = selectDocument[0]["name"];
        }
        AxiosNetwork.axiosPut({ "url": `api/human-resource/admin/employees/${theEmployee["user_id"]}/documents/` + theObject["id"] + `/update`  }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const afterNewRecordSubmit = () => {
        message.info('Your Employee Documents details has been saved.');
        findAll();
    }
    const applyEmployeeDocuments = () => {
        showHideAllProps({ "button-controls-section":"block","employee-documents-details": "block","records-list-table": "none", "human-resource-dashboard": "none" });

        humanResourceEmployeeDocumentsForm.resetFields();
    }
    const columnSchema = [
        {
            title: 'Namer',
            dataIndex: 'name',
        },
        {
            title: 'Attachement',
            dataIndex: 'attachement',
        },
        {
            title: 'Employee Documents',
            dataIndex: 'document_type_id',
        },
        {
            title: 'Updated At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];


    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "employee-documents-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table style={{marginBottom:"20px"}} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button  style={{marginBottom:"20px"}} type="primary" onClick={(e) => applyEmployeeDocuments()}>  New Documents  </Button>
                    </section>
            <section className="thCard" style={{ "width": 600, "display": presentObjects["employee-documents-details"] }} >
                                <Form size="large" labelAlign="right" layout="vertical"
                            name="basic"
                            onFinish={onFinish}
                            form={humanResourceEmployeeDocumentsForm} initialValues={theObject}
                            onFinishFailed={humanResourceEmployeeDocumentsFailed} autoComplete="off">
                            <Form.Item label="Employee Documents" name="document_type_id"
                                    rules={[{required: true,message: 'Select the employee documents '}]}>
                                    <Select  showSearch  allowClear style={{ width: '100%' }} placeholder="Change Employee Documents">
                                        {employeeDocumentsList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Status" name="status_id">
                                    <Select  showSearch  allowClear style={{ width: '100%' }} placeholder="Change  Status">
                                        {statusList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Attachement" name="attachement">
                                <Dragger {...fileUploadProps}>
                                    <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
                                    <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
                                </Dragger>
                            </Form.Item>
                                <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                            </Form.Item>
                        </Form>
                        </section>
               </Layout>
        </section>
    )
}