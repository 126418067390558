import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faBackward, faFilter, faEraser, faRefresh, faSave, faEye, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Space, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "asset-users";
const tableName = "asset-users";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function AssetUsers() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [assetList, setAssetList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "asset-users": "none", "user-roles-list-table": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [assetUsersForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Asset',
            dataIndex: 'name'
        },
        {
            title: 'User',
            dataIndex: 'user_id'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            widht: 50
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }

    ];

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        theObject["id"] = generateFuzzyId("asset-users")
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/assets` }, { "doc_name": "assets" }, setAssetList);
        findAll();
    }, []);


    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": "none", "records-list-table": "block" });
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/asset-management/assets/${selectedRow["id"]}`, "id": selectedRow.id }, userRecordsList, setUserRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        values["name"] = "";
        AxiosNetwork.axiosPut({ "url": "api/admin/asset-management/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "button-controls-section": "none", "asset-users-details": "none", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your asset users details has been saved.');
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/asset-management/assets` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "asset-users-details": "none", "records-list-table": "block" });
    }
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        setTheObject({ ...selectedRow });
        assetUsersForm.setFieldsValue({ ...selectedRow });
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/asset-management/assets` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "button-controls-section": "block", "asset-users-details": "block", "records-list-table": "none" });
    }


    const assetUsersFormFailed = () => {

    }
    const findUserLike = (value) => {
        if (value === null || value === "") return;
        var payload = { "doc_name": "employees", "col_name": "name", "col_value": value };
        AxiosNetwork.axiosPost({ "url": `/api/common/find-any-multi-like` }, payload, userList, setUserList, setUserListCallBack);
    }

    const setUserListCallBack = (data) => {
        setUserList([...data]);
    }

    const assignAssetUsers = () => {
        showHideAllProps({ "button-controls-section": "block", "asset-users-details": "block", "records-list-table": "none" });

    }


    return (
        <section>
            <Layout className='contentLayout'>
                <section style={{ "display": presentObjects["button-controls-section"] }}>
                    <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                        <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "asset-users-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                    </Space>
                </section>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                    <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                    <div style={{ marginTop: 10 }}>
                        <Space size={10}>
                            <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => assignAssetUsers()}>  Asset  Users  </Button>
                        </Space>
                    </div>
                </section>

                <section className="thCard" style={{ "width": 700, "display": presentObjects["asset-users-details"] }} >
                    <Form form={assetUsersForm} labelAlign="right" layout="vertical" size={"large"} name="basic"
                        onFinish={onFinish} onFinishFailed={assetUsersFormFailed} initialValues={theObject} autoComplete="off">
                        <Form.Item label="Asset" name="asset_id" rules={[{ required: true, message: 'Enter your Asset' }]}>
                            <Select allowClear showSearch placeholder="Select Asset">
                                {assetList.map(eachItem => (
                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="User" name="user_id" rules={[{ required: true, message: 'Enter your User' }]}>
                            <Select showSearch onSearch={findUserLike} placeholder="Select User">
                                {userList.map(eachItem => (
                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>
                </section>

            </Layout>
        </section >
    )
}