import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Divider, Form, Input,Row,Col,Space, Upload, Button, message, Card, Select, Table, DatePicker, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan,faSave,faBackward } from '@fortawesome/free-solid-svg-icons'
import { generateFuzzyId, dateTimeUtils } from "../../Config";
import moment from 'moment';

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-relation";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";
var nextTenYears = [];

export default function HumanResourceRelation(props) {
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section":"none","personal-information": "none","human-resource-holidays":"none","human-resource-employee-payroll":"none","human-resource-employer-documents": "none","edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanResourceRelationForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [relationList, setRelationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [docIsList, setDocIsList] = useState([]);
    const [theEmployee, setTheEmployee] = useState(props.employee); 

    useEffect(() => {
        theObject["id"] = generateFuzzyId("relations")
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employee-manager-relation" },setRelationList);
        setUiLabels({ "save": "Save" });
        findAll();
    }, [props.employee.id]);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        humanResourceRelationForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/common/holidays` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({"records-list-table":"none", "button-controls-section":"block","relation-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/relations/filter` }, {  "filters":[{"col_name":"of_doc", "string_value":theEmployee["user_id"], "operator":"=", "data_type":"string"}]}, setRecordsList);
        showHideAllProps({"records-list-table":"block", "button-controls-section":"none","relation-details": "none", "edit-selected-record": "block", "human-resource-dashboard": "none" });

    }

    const humanResourceRelationFailed = () => {
    }

    const onFinishFailed = () => {
    }

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values ["app_type"]=0;
        values["app_id"]="human-resource";
        values["of_doc"]=theEmployee["user_id"];
        AxiosNetwork.axiosPost({ "url": "/api/common/relations/" + theObject["id"] + "/upsert" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your relations details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `/api/common/relations/${selectedRow["id"]}`, "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }    
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    const columnSchema = [
        {
            title: 'User',
            dataIndex: 'doc_is',
        },
        {
            title: 'Relation',
            dataIndex: 'relation_id',
        },
        {
            title: 'User',
            dataIndex: 'of_doc',
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];
    const findUserLike = (value) => {
        if (value === null || value === "") return;
        setDocIsList([...[]]);
        var payload = { "select":["id", "name"], "doc_name": "users", "col_name": "name", "col_value": value };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-select-any-multi-like` }, payload, setDocIsList, setReviewListCallBack);
    }

    const setReviewListCallBack = (data) => {
        setDocIsList([...data]);
    }
   const applyRelations = ( ) => {
    showHideAllProps({"records-list-table":"none", "button-controls-section":"block","relation-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    humanResourceRelationForm.resetFields();
   }
    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "relation-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
                <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table style={{ marginBottom: "50px" }} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button  style={{marginBottom:"20px"}} type="primary" onClick={(e) => applyRelations()}>  Relation  </Button>

                    </section>
                    <section className="thCard" style={{ "width": 700, "display": presentObjects["relation-details"] }} >
                            <Form size="large" labelAlign="right" layout="vertical" name="basic"
                                onFinish={onFinish}
                                form={humanResourceRelationForm} initialValues={theObject}
                                onFinishFailed={humanResourceRelationFailed} autoComplete="off">
                                <Form.Item label="User"name="doc_is"
                                            rules={[{ required: true, message: 'Select User', }]}>
                                            <Select notFoundContent={null} showSearch onSearch={findUserLike} placeholder="Select User">
                                                {docIsList.map(eachItem => (
                                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                <Form.Item label="Relation" name="relation_id">
                                    <Select autoComplete="none" showSearch allowClear style={{ width: '100%' }} placeholder="Change  Relation">
                                        {relationList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save} </Button>
                                </Form.Item>
                            </Form>
                    </section>
                </Layout>
        </section>
    )
}