import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, configMenus } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
import Status from "./Status";
import Priority from "./Priority";
import Severity from "./Severity";
import Roles from "./Roles";
import Units from "./Units";
import Skills  from "./Skills";

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "config";
const tableName = "configurations";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function Configuration() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "new-record": false, "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "id": generateFuzzyId(tableName), "name": "Type your summary here", "mime_assets": [] });
    const [genreList, setGenreList] = useState([]);
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [theForm] = Form.useForm();
    const [filterForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save", "title":"Create Config" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Genre',
            dataIndex: 'genre'
        },
        {
            title: 'Application',
            render: (record) => <span>{record["app_name"]}</span>
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        console.log(selectedRow);
        setUiLabels({ "save": "Update", "title":"Update Config" });
        setTheObject({ ...selectedRow });
        theForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "edit-selected-record": "block", "new-record": true, "records-list-table": "block", "showInfo":false });
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const infoTableRow = (selectedRow) => {
        setTheObject({ ...selectedRow });
        showHideAllProps({"showInfo": true, "new-record": false});
    }

    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/common/approved-queries/configuration-distinct-genre` }, { "doc_name": tableName, }, afterGenreListLoaded);
        AxiosNetwork.axiosFetch({ "url": `api/common/apps` }, setApplicationList, afterApplicationListLoaded);
    }, []);

    const afterGenreListLoaded = (data) => {
        data.sort(function(a, b){ return ((a.name > b.name) ? 1 : -1);});
        setGenreList([...data]);
    }

    const afterApplicationListLoaded = (data) => {
        data.sort(function(a, b){ return ((a.name > b.name) ? 1 : -1);});
        applicationListGlobal = [...data];
        setApplicationList([...data]);
        findAll();
    }

    const newRecord = () => {
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        console.log({...theObject});
        theForm.resetFields();
        setUiLabels({ "save": "Save", "title": "New Config" });
        showHideAllProps({ "new-record": true, "records-list-table": "block" });
    }

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": false, "records-list-table": "block" });

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findAll = (filter = "all") => {
        console.log("findAll called");
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/config` }, { "doc_name": tableName }, setRecordsList, appListPostProcess);
    }

    const appListPostProcess = (data) => {
        data.forEach(eachRow => {
            const appIndex = applicationListGlobal.findIndex((eachApp => eachApp["app_id"] == eachRow["app_id"]));
            if (appIndex != -1) {
                eachRow["app_name"] = applicationListGlobal[appIndex]["name"];
            }
        });
        showHideAllProps({ "edit-selected-record": "none", "new-record": false, "records-list-table": "block" });
    }

    const onFilterFinish = (values) => {
        console.log(values);
        var filters = [];
        if (values["genre"] != undefined) {
            filters.push({ "col_name": "genre", "operator": "=", "data_type": "string", "string_value": values["genre"] });
        }
        if (values["app_id"] != undefined) {
            filters.push({ "col_name": "app_id", "operator": "=", "data_type": "string", "string_value": values["app_id"] });
        }
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/config` }, { "doc_name": tableName, "filters": filters }, setRecordsList, appListPostProcess);
        showHideAllProps({ "new-record": false, "records-list-table": "block" });
    }

    const onFinish = (values) => {
        values["value"] = "";
        if (Array.isArray(values["genre"])) {
            values["genre"] = values["genre"][0];
        }
        AxiosNetwork.axiosPutMono({ "url": `/api/admin/${appPath}/${theObject.id}/update` }, values, setTheObject, newRecordCallback);
    }

    const newRecordCallback = (data) => {
        message.info('Configuration has been created');
        theForm.resetFields();
        showHideAllProps({ "new-record": false, "records-list-table": "block" });
    }

    const configurationFormFailed = () => {

    }
    const onGenreSelect = (value) => {
        if (value.length > 1) {
            theForm.setFieldValue("genre", value.pop());
        }
    }
    const showOtherComponents = (sectionName) => {
        var toggleSections = {"skills": "none", "roles":"none","records-list-table": "none", "status": "none","severity":"none", "priority": "none"};
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }
    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll("all")} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin()} rel="noopener noreferrer">Bin</a>)
                            }
                        ]}>
                    </Menu>
                    <hr/>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={configMenus}>
                    </Menu>
                </Sider>
                {((presentObjects["status"] == "block") &&
                            <section>
                                <Status />
                            </section>
                        )}
                        {((presentObjects["priority"] == "block") &&
                            <section>
                                <Priority />
                            </section>
                        )}
                        {((presentObjects["severity"] == "block") &&
                            <section>
                                <Severity />
                            </section>
                        )}
                        {((presentObjects["roles"] == "block") &&
                            <section>
                                <Roles />
                            </section>
                        )}
                         {((presentObjects["units"] == "block") &&
                            <section>
                                <Units />
                            </section>
                        )}
                         {((presentObjects["skills"] == "block") &&
                            <section>
                                <Skills/>
                            </section>
                        )}
                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Card>
                                <Form size="large" form={filterForm} name="horizontal_login" layout="inline" onFinish={onFilterFinish}>
                                    <Form.Item style={{ width: '30%' }} name="app_id">
                                        <Select showSearch allowClear placeholder="Select Application"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {applicationList.map(eachItem => (
                                                <Option key={eachItem["id"]}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ width: '30%' }} name="genre">
                                        <Select
                                            showSearch allowClear
                                            showArrow={false}
                                            placeholder="Select Genre">
                                            {genreList.map(eachItem => (
                                                <Option key={eachItem.name}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faFilter} /></Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" onClick={() => { filterForm.resetFields(); onFilterFinish({}) }}><FontAwesomeIcon icon={faRefresh} /></Button>
                                    </Form.Item>
                                </Form>
                            </Card>

                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList} pagination={{ pageSize: 50}}/>
                        </section>

                        <section style={{ "display": presentObjects["edit-selected-record"] }}>

                        </section>

                        <Drawer size={500} title={uiLabels.title} placement="right" onClose={(e) => showHideProps("new-record", false)} open={presentObjects["new-record"]}>
                            <section>
                                <Card>
                                    <Form layout="vertical" labelAlign="left" key={"form-"+new Date().getTime()}
                                        name="basic"
                                        onFinish={onFinish}
                                        form={theForm} initialValues={theObject} size={"large"}
                                        onFinishFailed={configurationFormFailed} autoComplete="off">
                                        <Form.Item
                                            label="Name"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter your name',
                                                },
                                            ]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Genre"
                                            name="genre"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter your genre',
                                                }
                                            ]}
                                        >
                                            <Select mode="tags"
                                                showSearch
                                                onChange={onGenreSelect}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false} placeholder="Select Genre">
                                                {genreList.map(eachItem => (
                                                    <Option key={eachItem.name}>{eachItem.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name="app_id" label="Application" rules={[
                                            {
                                                required: true,
                                                message: 'Enter the application',
                                            }
                                        ]}>
                                            <Select showSearch style={{ width: '100%' }} placeholder="Select Application">
                                                {applicationList.map(eachItem => (
                                                    <Select.Option value={eachItem["id"]}>{eachItem["name"]}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Sequence" name="priority" >
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item label="Data Type" name="data_type" >
                                            <Select>
                                                <Select.Option key="string">String</Select.Option>
                                                <Select.Option key="int">Int</Select.Option>
                                                <Select.Option key="double">Double</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} />&#x2002; {uiLabels.save}</Button>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </section>
                        </Drawer>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideAllProps({"showInfo": false})} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}