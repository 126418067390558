import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTrashCan, faMessage,  faPeace, faEdit, faUndoAlt, faFolder, faSatellite, faUser, faClock, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons'
import { findUrlPath, appProfile, generateFuzzyId, profileBarItems, footerLine, menuBarItems, dateTimeUtils, socialAuthProps } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment-timezone';
import { Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import MiniQuestion from "./MiniQuestion";
import { v4 as uuid } from 'uuid';

let ckEditorData = "";
const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = 151;
const appPath = "questions";
const tableName = "questions";

var activeDataColumns = [];
const dataMassage = { "emailAddress": {}, "associateId": "1" };

//https://codesandbox.io/s/j3q07kyy8w?file=/index.js:538-546
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const cdnFileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/cdn-file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function Question() {
    const [theObject, setTheObject] = useState({ "body": "", "name": "Type your summary here", "mime_assets": [] });
    const [questionList, setQuestionList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "new-record": " block", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "composeQuestion": "none", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [questionForm] = Form.useForm();
    const [statusList, setStatusList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);


    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${appPath}`);
        findAll();
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders/appid/` + appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels/appid/` + appId }, setLabelList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/statuses` }, { "doc_name": "statuses" }, setStatusList);
        composeQuestion();
    }, []);

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }


    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            key: "info",
            width: 50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },

        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        questionForm.setFieldsValue({ ...selectedRow });
        formData = {};
        dataMassage["questionId"] = selectedRow.id;
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/${appPath}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "new-record": "block" });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }

    
    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        dataMassage["questionId"] = generateFuzzyId(tableName);
        formData = {};
        formData["parent_id"] = dataMassage["questionId"];
        formData["folder_id"] = dataMassage["questionId"];
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        showHideAllProps({ "new-record": "block", "records-list-table": "none", });
    }
    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        setTheFolder({ ...currentFolder });
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folder_id": currentFolder["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }


    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "label_id": currentLabel["id"] }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const findAll = (filter) => {
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "new-record": "none", "records-list-table": "block" });
    }
    const composeQuestion = () => {
        showHideProps("composeQuestion", "block");
        theObject["emailAddress"] = [];
        formData = {};
        dataMassage["questionId"] = "question-" + uuid();
        formData["parent_id"] = dataMassage["questionId"]
        formData["folder_id"] = dataMassage["questionId"];
    };

    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        AxiosNetwork.axiosPost({ "url": `api/common/questions/${dataMassage["questionId"]}/upsert` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values)
    }

    const afterNewRecordSubmit = () => {
        message.info('Your question has been saved.');
        findAll();
    }

    const questionFormFailed = () => {
    }


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-new-item',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            },
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <MiniQuestion key={"key"+new Date().getTime()} presentObjects={presentObjects}/>

                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}
