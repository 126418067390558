import React, { useState, useEffect } from 'react';

import './App.css';
import store from 'store2';
import { AxiosNetwork } from "./axiosService";
import { Button, Card, Form, Input, Typography, Layout, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
const { Header, Footer, Content } = Layout;

function Iframe(props) {

    useEffect(() => {
        
    }, []);


    return (
        <iframe src={props.src} height={props.height} width={props.width}/>
    );
}

export default Iframe;