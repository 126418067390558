import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons'
import {  appProfile, searchTextLength, menuBarItems, profileBarItems } from "../Config";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment';
import {Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form, Input, Card, Upload, Radio, message, Select, Cascader, DatePicker, InputNumber, TreeSelect, Mentions, Tag, TimePicker, Drawer, AutoComplete, Col, Checkbox } from 'antd';
import { AxiosNetwork } from "../axiosService";
import { v4 as uuid } from 'uuid';
import Deals from  "./Deals";
import MiniContact from '../contact/MiniContact';

const { Dragger } = Upload;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;
var formData = {};
const appId = "deals";
const dataMassage = {"emailAddress":{}, "associateId":"1"};
var activeDataColumns = [];
const tableName = "deals";

const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/upload/single',
    data: function(file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


export default function SalesCrm(props) {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [tempList, setTempList] = useState([]);
    const [emailAddressList, seteMailAddressList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({"crm-dashboard":"none","conacts":"none", "deals":"none", "newFolderDrawer":false, "newLabelDrawer": false, "compose":"none", "active":"none", "bin":"none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [form] = Form.useForm();
    const [theEmail, setTheEmail] = useState({"application":"EMAIL", "fileAssets":[], "body":"Type your content here"});
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [theObject, setTheObject] = useState(props.appDoc);


    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            title: 'Starts At',
            render: (record) => <span>{record["starts_at"] && moment(record["starts_at"]).format("lll")}</span>
        },
        {
            title: 'Ends At',
            render: (record) => <span>{record["ends_at"] && moment(record["ends_at"]).format("lll")}</span>
        },
        {
            title: 'Total',
            render: (record) => <span>{(record["total"] != null) && Math.round(record["total"] / 60000)} Min.</span>
        },
        {
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];



    const binDataColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Application',
            dataIndex: 'application',
        },
        {
            title: 'Restore',
            render: (record) => <a onClick={(e) => restoreTableRow(record)}>Restore</a>,
        },
        {
            title: 'Info',
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        }
    ];

    const editTableRow = (selectedRow) => {
        console.log(selectedRow);
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": "/api/crm/email/"+selectedRow.emailId, "id":selectedRow.id }, theEmail, setTheEmail);
        showHideProps("active", "display");
        setPresentObjects({ ...presentObjects });
    }


    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const sendEmailNow = (folderId) =>
    {
        theEmail["emailAddress"] = Object.values(dataMassage["emailAddress"]);
        theEmail["id"] = dataMassage["emailId"];
        theEmail["folderId"] = folderId;
        console.log(theEmail);
        AxiosNetwork.axiosPost({ "url": "/api/crm/create"}, theEmail, setTheEmail);
    }

    const emailAddress = (email, userType) =>
    {
        email.forEach(eachAddress => {
            eachAddress = eachAddress.trim().toLowerCase();
            dataMassage["emailAddress"][eachAddress] = {"userType":userType, "address":eachAddress};
        });
        console.log(dataMassage);
    }





    const onLabelFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "/api/template/create/label" }, values, labelList, setLabelList);
        console.log('Success:', values);
        showHideProps("newLabelDrawer", false);
    };

    const onLabelFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const infoTableRow = (selectedRow) => {
        setTheEmail({...selectedRow});
    }

    const onFolderFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": "/api/crm/create/folder" }, values, folderList, setFolderList);
        console.log('Success:', values);
        showHideProps("newFolderDrawer", false);
    };

    const onFolderFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const composeEmail = () => {
        showHideProps("compose","block");
        theEmail["emailAddress"] = [];
        formData = {};
        dataMassage["emailId"] = "email-" + uuid();
        formData["parent_id"] = dataMassage["emailId"]
        formData["folder_id"] = dataMassage["emailId"];
    };

    const findFromBin = (filter) => {
        activeDataColumns = columnSchema;
        presentObjects["new-form"] = "none";
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        setPresentObjects({ ...presentObjects });
    }

    const findAll = (filter) => {
        showOtherComponents("deals");
        presentObjects["new-form"] = "block";
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": "deals" }, setRecordsList);
        setPresentObjects({ ...presentObjects });
    }

    useEffect(() => {
        localStorage.setItem("rest-cloud-user-recent-app", "/apps/crm");
        AxiosNetwork.axiosFetch({ "url": "/api/common/folders/appid/"+appId }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": "/api/common/labels/appid/"+appId }, setLabelList);
        findAll();
    }, []);

    const updateFolder = (e) => {
        console.log(theFolder);
        AxiosNetwork.axiosUpdate({ "url": "/api/common/folder/update/" + theFolder.id, "id": theFolder.id }, theFolder, folderList, setTheFolder);
    }
    const updateLabel = (e) => {
        AxiosNetwork.axiosUpdate({ "url": "/api/common/label/update/" + theLabel.id, "id": theLabel.id }, theLabel, labelList, setTheLabel);
        console.log(theLabel);
    }

    const renameFolder = (e) => {
        theFolder["name"] = e.target.value;
        console.log(theFolder);
        setTheFolder(theFolder);
    }

    const renameLabel = (e) => {
        theLabel["name"] = e.target.value;
        console.log(theLabel);
        setTheLabel(theLabel);
    }

    const filterByFolder = (currentFolder) => {
        presentObjects.folderEdit = "block";
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "folderId": currentFolder.id }, setRecordsList);
    }

    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "labelId": currentLabel.id }, setRecordsList);
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": "/api/crm/delete/" + selectedRow.id, "id": selectedRow.id }, emailAddressList, seteMailAddressList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/template/restore/" + selectedRow.id, "id": selectedRow.id }, emailAddressList, seteMailAddressList);
    }

    

    const applyFolderToEmailAddress = (folderId) => {
        theEmail["folder_id"] = folderId;
        AxiosNetwork.axiosPost({ "url": "/api/crm/associate/folder", "id": folderId }, { "ids": selectedRows, "id": folderId }, tempList, setTempList);
    }
    const applyLabelToEmailAddress = (labelId) => {
        theEmail["label_id"] = labelId;
        AxiosNetwork.axiosPost({ "url": "/api/crm/associate/label", "id": labelId }, { "ids": selectedRows, "id": labelId }, tempList, setTempList);
    }


    const showOtherComponents = (sectionName) => {
        var toggleSections = {"crm-dashboard":"none", "deals":"none", "contacts":"none", "leads":"none", "records-list-table": "none" };
        toggleSections[sectionName] = "block";
        showHideAllProps(toggleSections);
    }

    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }


    let ckEditorData = "";

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
        <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
            <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                <Col style={{textAlign:"right"}} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
        </Header>
        <Layout className='sideLayout'>
            <Sider width={200}>
                <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                    items={[
                        { label: (<a onClick={e => showOtherComponents("crm-dashboard")} href="#">Dashboard</a>), "key": "crm-dashboard" },
                        {
                            key: 'all-records',
                            label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                        },
                        {
                            key: 'sub-folders',
                            label: (<a onClick={(e) => findAll()}>Folders</a>),
                            children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                        },
                        {
                            key: 'sub-labels',
                            label: (<a onClick={(e) => findAll()}>Labels</a>),
                            children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                        },
                        {
                            key: 'bin-records',
                            label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                        },
                        { type: 'divider' },
                        {
                            key: 'leads',
                            label: (<a onClick={e => showOtherComponents("leads")} href="#">Leads</a>)
                        },
                        {
                            key: 'contacts',
                            label: (<a onClick={e => showOtherComponents("contacts")} href="#">Contacts</a>)
                        }
                       
                    ]} />
            </Sider>

            <Layout className='contentLayout'>
                <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>
                             { (presentObjects["deals"]=="block") && 
                            <section>
                                <Deals />
                            </section>
                        }
                        { (presentObjects["leads"]==="block") && 
                            <section>
                                <MiniContact key={"key-" + new Date().getTime()} latest={true} />
                            </section>
                        }
                        { (presentObjects["contacts"]==="block") && 
                            <section>
                                <MiniContact key={"key-" + new Date().getTime()} latest={false} />
                            </section>
                        }
                        
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}