import './App.css';
import Login from "./Login";
import Logout from "./Logout";
import Register from "./Register";
import Forgot from "./Forgot";
import Apps from "./Apps";
import AppSuite from "./AppSuite";
import Dam from "./dam/Dam";
import Cdn from "./dam/Cdn";
import Ticket from "./ticket/Ticket";
import SalesCrm from "./crm/SalesCrm";
import Dummy from "./dummy/Dummy";
import Contact from "./contact/Contact";
import Calendar from "./calendar/Calendar";
import Template from "./template/Template";
import WebPage from "./web-page/WebPage";
import SiteSetting from "./site-setting/SiteSetting";
import Blog from "./blog/Blog";
import Note from "./note/Note";
import Guideline from "./guideline/Guideline";
import Task from "./task/Task";
import Forms from "./forms/Forms";
import FormsDataCollection from "./forms/FormsDataCollection"
import Todos from "./todo/Todos";
import Reminder from "./reminder/Reminder";
import Workflow from "./workflows/Workflow";
import Emails from "./emails/Emails";
import TimeTracker from "./time-tracker/TimeTracker";
import Snippet from "./snippet/Snippet";
import CustomFieldEditor from "./custom-field/CustomFieldEditor";
import React, { Component, useState, useEffect } from 'react';
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { AxiosNetwork } from "./axiosService";
import ResetPassword from "./ResetPassword";
import SocialAuthCallback from  "./SocialAuthCallback";
import HumanResource from "./human-resource/employee/HumanResource";
import HumanResourceAdmin from "./human-resource/admin/HumanResourceAdmin";
import Organization from "./organization/Organization";
import PosAdmin from "./pos/PosAdmin";
import PosOutlet from "./pos/PosOutlet";
import Profile from "./Profile";
import Reimbursement from "./reimbursement/Reimbursement";


//import { offlineData } from './Config';
import store2 from 'store2';
import Severity from './configuration/Severity';
import Status from './configuration/Status';
import Priority from './configuration/Priority';
import ApprovedQuery from './configuration/ApprovedQuery';
import Units from './configuration/Units';
import Roles from "./configuration/Roles";
import Skills from "./configuration/Skills";
import Configuration from "./configuration/Configuration";
import Experts from "./experts/Experts";
import Survey from "./survey/Survey";
import Project from "./project/Project";
import ShowReport from './configuration/ShowReport';
import Question from './question/Question';
import AssetManagement from "./asset-management/AssetManagement";


export default function App() {

  const [appsList, setAppsList] = useState([{ "id": 1, "name": "Waiting to load" }]);

  useEffect(() => {
  }, []);



  return (
    <BrowserRouter basename="/">
    <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/apps" element={<Apps />} />
        <Route path="/apps-suite" element={<AppSuite />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password-request" element={<ResetPassword />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/profile" element={<Profile />} />
        
        <Route path="/apps/reports" element={<ShowReport />} />
        <Route path="/apps/notes" element={<Note />} />
        <Route path="/apps/guidelines" element={<Guideline />} />
        <Route path="/apps/tasks" element={<Task />} />
        <Route path="/apps/questions" element={<Question />} />
        <Route path="/apps/asset-management" element={<AssetManagement />} />
        
        <Route path="/apps/calendar" element={<Calendar />} />
        <Route path="/apps/file-assets" element={<Dam />} />
        <Route path="/apps/cdn" element={<Cdn />} />
        <Route path="/apps/tickets" element={<Ticket />} />
        <Route path="/apps/contacts" element={<Contact />} />
        <Route path="/apps/crm/deals" element={<SalesCrm />} />
        <Route path="/apps/templates" element={<Template />} />
        <Route path="/apps/experts" element={<Experts />} />
        <Route path="/apps/survey" element={<Survey />} />
        <Route path="/apps/emails" element={<Emails />} />
        <Route path="/apps/dummy" element={<Dummy />} />
        <Route path="/apps/blogs" element={<Blog />} />
        <Route path="/apps/web-pages" element={<WebPage />} />
        <Route path="/apps/site-settings" element={<SiteSetting />} />
        <Route path="/apps/forms/form-data-collection" element={<FormsDataCollection />} />
        <Route path="/apps/forms" element={<Forms />} />
        <Route path="/apps/todos" element={<Todos />} />
        <Route path="/apps/reminders" element={<Reminder />} />
        <Route path="/apps/snippets" element={<Snippet />} />
        <Route path="/apps/pos-admin" element={<PosAdmin />} />
        <Route path="/apps/pos" element={<PosOutlet />} />
        <Route path="/apps/time-tracker" element={<TimeTracker />} />
        <Route path="/apps/projects" element={<Project />} />
        <Route path="/apps/reimbursements" element={<Reimbursement />} />
        <Route path="/utils/custom-fields" element={<CustomFieldEditor />} />
        
        
        
        {/* Configurations */}
        <Route path="/apps/configurations" element={<Configuration />} />
        <Route path="/apps/configurations/roles" element={<Roles />} />
        <Route path="/apps/configurations/severities" element={<Severity />} />
        <Route path="/apps/configurations/priorities" element={<Priority />} />
        <Route path="/apps/configurations/reports" element={<ApprovedQuery />} />
        <Route path="/apps/configurations/statuses" element={<Status />} />
        <Route path="/apps/configurations/units" element={<Units />} />
        <Route path="/apps/configurations/skills" element={<Skills />} />


        <Route path="/apps/workflows" element={<Workflow />} />
        <Route path="/apps/human-resource" element={<HumanResource />} />
        <Route path="/apps/human-resource-admin" element={<HumanResourceAdmin />} />
        <Route path="/apps/workflows" element={<Workflow />} />
        <Route path="/apps/organizations" element={<Organization />} />
        
        <Route path="/public/social/auth/email" element={<SocialAuthCallback />}>
          <Route path="google" element={<SocialAuthCallback />} />
          <Route path="apple" element={<SocialAuthCallback />} />
          <Route path="microsoft-exchange" element={<SocialAuthCallback />} />
          <Route path="aol" element={<SocialAuthCallback />} />
          <Route path="yahoo" element={<SocialAuthCallback />} />
        </Route>
    </Routes>
  </BrowserRouter>
  )
}