import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faTags } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, DatePicker, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';
const dataMassage = { "emailAddress": {}, "associateId": "1" };

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 131;
const appPath = "consignments";
const tableName = "consignments";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function PosConsignment() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [consignmentRecordsList, setConsignmentRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [supplierList, setSupplierList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [outletList, setOutletList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [consignmentProductForm] = Form.useForm();
    const [consignmentList, setConsignmentList] = useState([]);
    const [theConsignmentObject, setTheConsignmentObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [presentObjects, setPresentObjects] = useState({ "consignment-products": "none", "consignment-products-details": "none", "consignments": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [consignmentForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    console.log(queryParams);

    useEffect(() => {
        dataMassage["consignmentId"] = generateFuzzyId(tableName);
        setUiLabels({ "save": "Save" });
        setTheObject({ "id": generateFuzzyId(tableName), "mime_assets": [] });
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/suppliers` }, { "doc_name": "suppliers" }, setSupplierList);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setOutletList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/products` }, { "doc_name": "products" }, setProductList);
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/pos-admin/${tableName}`);
        findAll();
    }, []);


    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Outlet',
            dataIndex: 'organization_id'
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_id'
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "view",
            title: 'View',
            width: 50,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faEye} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
       

    ];
    const columns = [
        {
            title: 'Product',
            dataIndex: 'product_id'
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editConsignmentTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 50
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteConsignmentTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
       
    ];

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/pos/consignments/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const deleteConsignmentTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/pos/consignments/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        values["value"] = "";
        values["due_at"] = moment(values["due_at"]).format("yyyy-MM-DD ");
        values["received_at"] = moment(values["received_at"]).format("yyyy-MM-DD ");
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/consignments/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "consignment-products-details": "none", "edit-selected-record": "block", "consignments-details": "block", "records-list-table": "block" });
    }
    const afterNewRecordSubmit = (values) => {
        message.info('Your consignments details has been saved.');
        consignmentForm.resetFields();
        console.log("afterNewRecordSubmit", values);
        findAll();
    }
    const showFindAll = () => {
        showHideAllProps({ "consignment-records-list-table": "none", "consignment-products-details": "none", "edit-selected-record": "block", "consignments-details": "none", "records-list-table": "block" });
    }
    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/pos/consignments` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "consignment-records-list-table": "none", "consignment-products-details": "none", "edit-selected-record": "block", "consignments-details": "block", "records-list-table": "block" });
    }
    const editTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        dataMassage["consignmentId"] = selectedRow.id;
        dataMassage["consignmentProductId"] = generateFuzzyId("consignment-products");
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/consignments/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject, consignmentCallback);
        showHideAllProps({ "consignment-records-list-table": "none", "consignments-details": "block", "edit-selected-record": "block" });
    }
    const consignmentCallback = (data) => {
        if (data["due_at"] != undefined) data["due_at"] = moment(data["due_at"]);
        if (data["received_at"] != undefined) data["received_at"] = moment(data["received_at"]);
        setTheObject({ ...data });
        consignmentForm.setFieldsValue({ ...data });
    }

    const editConsignmentTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setUiLabels({ "save": "Update" });
        dataMassage["consignmentId"] = theObject.id;
        dataMassage["consignmentProductId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/pos/consignments/` + theObject["id"] + `/consignment-products/` + selectedRow.id, "id": selectedRow.id }, theConsignmentObject, setTheConsignmentObject, ConsignmentObjectCallback);
        showHideAllProps({ "consignment-products-details": "none", "edit-selected-record": "block" });
    }

    const ConsignmentObjectCallback = (data) => {
        consignmentProductForm.setFieldsValue({ ...data });
    }
    const viewTableRow = (selectedRow) => {
        dataMassage["consignmentId"] = selectedRow.id;
        dataMassage["consignmentProductId"] = generateFuzzyId("consignment-products");
        formData = {};
        setUiLabels({ "save": "Save" });
        formData["parent_id"] = selectedRow.id;

        AxiosNetwork.axiosPost({ "url": `/api/admin/pos/consignments/${selectedRow["id"]}/consignment-products` }, { "doc_name": "consignment-products" },consignmentRecordsList, setConsignmentRecordsList, afterViewData);
        showHideAllProps({"consignment-records-list-table":"block", "consignment-products-details": "block","consignments-details": "none", "edit-selected-record": "block", "records-list-table":"none" });
    }
    const afterViewData = (data) => {
        consignmentProductForm.setFieldsValue({ ...data });
    }
    const consignmentFormFailed = () => {

    }
    const consignmentProductFormFailed = () => {

    }
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    const onConsignmentFinish = (values) => {
        AxiosNetwork.axiosPut({ "url": "/api/admin/pos/consignments/" + theObject["id"] + "/consignment-products/" + theObject["id"] + "/update" }, values, consignmentRecordsList, setConsignmentRecordsList, afterConsignmentRecordSubmit);
        showHideAllProps({ "consignment-details": "none", "consignment-products-details": "block", "records-list-table": "block" });
    }
    const afterConsignmentRecordSubmit = (values) => {
        message.info('Your consignment products details has been saved.');
        consignmentProductForm.resetFields();
        viewTableRow({ ...theObject });
    }

    return (
        <section>
            <Layout className='contentLayout'>
                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                    </section>
                    <section style={{ "display": presentObjects["consignment-records-list-table"] }}>
                        <Button style={{ marginBottom: "50px" }} onClick={(e) => showFindAll()}>Back</Button>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columns} dataSource={consignmentRecordsList} pagination={{ pageSize: 50 }} />
                    </section>

                    <section className="thCard" style={{ "width": 750, "display": presentObjects["consignments-details"] }}>
                     <Form size="large" labelAlign="right" layout="vertical"
                                onFinish={onFinish}
                                form={consignmentForm} initialValues={theObject}
                                onFinishFailed={consignmentFormFailed} autoComplete="off">
                                <Form.Item label="Name" name="name"
                                    rules={[{ required: true, message: 'Enter your name', }]}>
                                    <Input />
                                </Form.Item>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Outlet" name="organization_id"
                                    rules={[{ required: true, message: 'Enter your Outlet', }]}>
                                    <Select showSearch allowClear  placeholder="Select Outlet">
                                        {outletList.map(eachItem => (
                                            <Option key={eachItem.name}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Supplier" name="supplier_id"
                                    rules={[{ required: true, message: 'Enter your Supplier', }]}>
                                    <Select showSearch allowClear style={{ width: '100%' }} placeholder="Change  supplier">
                                        {supplierList.map(eachItem => (
                                            <Option key={eachItem.id}>{eachItem.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Order Number" name="order_number">
                                    <Input />
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="Invoice Number" name="invoice_number">
                                    <Input />
                                </Form.Item>
                                </Col>
                                </Row>
                                <Row gutter={16}>
                                <Col span={8}>
                                <Form.Item label="Consignment at" name="due_at">
                                    <DatePicker format="yyyy-MM-DD " showTime={false} onOk={onOk} />
                                </Form.Item>
                                </Col>
                                <Col span={8}>
                                <Form.Item label="Received at" name="received_at">
                                    <DatePicker format="yyyy-MM-DD " showTime={false} onOk={onOk} />
                                </Form.Item>
                                </Col>
                                <Col span={8}>
                                <Form.Item label="Category" name="category" >
                                    <Select showSearch allowClear >
                                        <Select.Option key="Outlet Request">Outlet Request</Select.Option>
                                        <Select.Option key="Supplier Delivery"> Supplier Delivery</Select.Option>
                                        <Select.Option key="Outlet Return">Outlet Return</Select.Option>
                                    </Select>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Total Cost gain" name="total_cost_gain">
                                    <InputNumber style={{ width: 300 }}/>
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="Total Cost loss" name="total_cost_loss">
                                    <InputNumber style={{ width: 300 }}/>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item label="Total Count Loss" name="total_count_loss">
                                    <InputNumber style={{ width: 300 }}/>
                                </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label="Total Count gain" name="total_count_gain">
                                    <InputNumber style={{ width: 300 }}/>
                                </Form.Item>
                                </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                                </Form.Item>
                            </Form>
                        
                    </section>
                    <section className="thCard" style={{ "width": 750, "display": presentObjects["consignment-products-details"] }}>
                       <Form size="large" labelAlign="right" layout="vertical"
                            onFinish={onConsignmentFinish}
                            form={consignmentProductForm} initialValues={theObject}
                            onFinishFailed={consignmentProductFormFailed} autoComplete="off">
                            <Form.Item label="Product"name="product_id"
                                rules={[{required: true,message: 'Enter your Product',}]}>
                                <Select  showSearch allowClear  placeholder="Select Product ">
                                    {productList.map(eachItem => (
                                        <Option key={eachItem.name}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={12}>
                            <Form.Item label="Quantity" name="count">
                                <InputNumber  style={{ width: 300 }} />
                            </Form.Item>
                            </Col>
                                <Col span={12}>
                            <Form.Item label="Received"name="received">
                                <InputNumber  style={{ width: 300 }}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                            <Form.Item label="Price"name="cost">
                                <InputNumber style={{ width: 300 }} />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Total"name="cost">
                                <InputNumber  style={{ width: 300 }}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>
                        </Form>
                      </section>
               </Layout>
        </section>
    )
}