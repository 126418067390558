import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Divider, Form, Input, Button, message, Table, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { generateFuzzyId } from "../../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const { Content } = Layout;
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-salary-structure";
const tableName = "human-resource";
let ckEditorData = "";
var formData = {};

export default function HumanResourceSalaryStructure() {
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourcesalarystructureForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"] = generateFuzzyId("employees")
        setUiLabels({ "save": "Save" });
        findAll();
    }, []);

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const findAll = (filter) => {
        AxiosNetwork.axiosFetch({ "url": `/api/human-resource/salaries` },  setRecordsList);
    }
    const humanresourcesalarystructureFailed = () => {
    }

    const viewTableRow = () => {

    }
    const columnSchema = [
        {
            title: 'Remuneration Name',
            dataIndex: 'remuneration_name',
        },

        {
            title: 'Taxable',
            dataIndex: 'taxable',
            render: (taxable) => <span>{((taxable==true) ? "Yes" : "No" )}</span>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Genre',
            dataIndex: 'genre',
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
        },
        {
            key: "view",
            title: 'View',
            width: 50,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faEye} /></a>,
        }
    ];



    return (
        <section>
            <Layout className='contentLayout'>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}>

                    <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                    </section>
                </Content>
            </Layout>
        </section>
    )

}