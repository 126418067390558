import React, { Component, useState, useEffect } from 'react';
import {
    Layout, Menu, Typography, Breadcrumb, Row, Modal, Dropdown, Button, Table, Form,
    Input, Card, Upload,
    Radio, message,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Mentions,
    Tag,
    TimePicker, Drawer, AutoComplete, Col, Checkbox
} from 'antd';
import { AxiosNetwork } from "../axiosService";
import {  appProfile, searchTextLength, menuBarItems, profileBarItems } from "../Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHourglassStart, faHourglassEnd, faHourglassHalf, faCloudDownload } from '@fortawesome/free-solid-svg-icons'
import { InboxOutlined } from '@ant-design/icons';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import CommonShare from "../CommonShare"

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;
const { Dragger } = Upload;
const appId = 28;
const appPath = "file-assets";
const tableName = "file_assets";
var activeDataColumns = [];

export default function Dam(props) {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [newLabelDrawer, setNewLabelDrawer] = useState(false);
    const [newFolderDrawer, setNewFolderDrawer] = useState(false);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "showShareDialog":false, "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [theFolder, setTheFolder] = useState({});
    const [theLabel, setTheLabel] = useState({});
    const [theObject, setTheObject] = useState({ "body": "Type your content here" });
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        document.title = "Files";
        localStorage.setItem("rest-cloud-user-recent-app", "/apps/dam");
        AxiosNetwork.axiosFetch({ "url": `/api/common/folders` }, setFolderList);
        AxiosNetwork.axiosFetch({ "url": `/api/common/labels` }, setLabelList);
        findAll();
    }, []);

    const onLabelFinish = (values) => {
        AxiosNetwork.axiosPost({ "url": `/api/${appPath}/create/label` }, values, labelList, setLabelList);
        console.log('Success:', values);
        setNewLabelDrawer(false);
    };

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }


    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>
        },
        {
            title: 'Trash',
            width:50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
        {
            title: 'Download',
            width:50,
            render: (record) => <a onClick={(e) => downloadFile(record)}><FontAwesomeIcon icon={faCloudDownload} /></a>,
        },
        {
            title: 'Share',
            width:50,
            render: (record) => <a onClick={(e) => commonShare(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            title: 'Info',
            width:50,
            render: (record) => <a onClick={(e) => infoTableRow(record)}><FontAwesomeIcon icon={faInfoCircle} /></a>,
        },
        {
            title: 'Restore',
            width:50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];
    const deleteTableRow = (selectedRow) => {
        console.log(recordsList);
        AxiosNetwork.axiosDelete({ "url": `/api/${appPath}/delete/` + selectedRow.id, "id": selectedRow.id}, recordsList, setRecordsList);
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };


    const downloadFile = (selectedRow) => {
        console.log(selectedRow);
        var a = document.createElement('a');
        a.href = appProfile.serviceLocation + `api/${appPath}/download/` + selectedRow.id + "/" + selectedRow.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const infoTableRow = (selectedRow) => {
        console.log(selectedRow);
        setTheObject({ ...selectedRow });
        showHideProps("showInfo", true);
    }

    const commonShare = (selectedRow) => {
        console.log(selectedRow);
        setTheObject({ ...selectedRow });
        showHideProps("showShareDialog", true);
    }

    
    const findFromBin = (filter) => {
        activeDataColumns = columnSchema;
        presentObjects["new-form"] = "none";
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        setPresentObjects({ ...presentObjects });
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }

    const sharedWithMe = () =>
    {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-shared-with-me` }, { "doc_name": tableName }, setRecordsList);
    }

    const findAll = (filter) => {
        presentObjects["new-form"] = "block";
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName }, setRecordsList);
        setPresentObjects({ ...presentObjects });
    }

    const filterByFolder = (currentFolder) => {
        console.log(currentFolder);
        presentObjects.folderEdit = "block";
        setTheFolder({ ...currentFolder });
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}/list?folder=` + currentFolder.id }, setRecordsList);
    }
    const filterByLabel = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}/list?label=` + currentLabel.id }, setRecordsList);
    }

    const filterByRecent = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}/list?label=` + currentLabel.id }, setRecordsList);
    }

    const filterByDeleted = (currentLabel) => {
        console.log(currentLabel);
        presentObjects.labelEdit = "block";
        setTheLabel({ ...currentLabel });
        AxiosNetwork.axiosFetch({ "url": `/api/${appPath}/list?label=` + currentLabel.id }, setRecordsList);
    }

    const fileUploadProps = {
        name: 'file',
        multiple: true,
        action: appProfile.serviceLocation + `api/${appPath}/upload/single`,
        headers: {
            Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.recordsList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{textAlign:"right"}} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu mode="inline" theme="dark" defaultOpenKeys={['all-records']} style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'all-records',
                                label: (<a onClick={(e) => findAll()} rel="noopener noreferrer">All</a>)
                            },
                            {
                                key: 'sub-folders',
                                label: (<a onClick={(e) => findAll()}>Folders</a>),
                                children: folderList.map(eachItem => { eachItem["key"] = "folder-" + eachItem["id"]; eachItem["label"] = <a key={"folder-" + eachItem.id} onClick={(e) => filterByFolder(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'sub-labels',
                                label: (<a onClick={(e) => findAll()}>Labels</a>),
                                children: labelList.map(eachItem => { eachItem["key"] = "label-" + eachItem["id"]; eachItem["label"] = <a key={"label-" + eachItem.id} onClick={(e) => filterByLabel(eachItem)}>{eachItem.name}</a>; return eachItem; })
                            },
                            {
                                key: 'bin-records',
                                label: (<a onClick={(e) => findFromBin("deleted")} rel="noopener noreferrer">Bin</a>)
                            },
                            {
                                key: 'shared-with-me',
                                label: (<a onClick={(e) => sharedWithMe("shared-with-me")} rel="noopener noreferrer">Shared with me</a>)
                            },
                            {
                                key: 'cdn-file-assets-apps',
                                label: (<a href="/apps/cdn">Public File Assets</a>)
                            }
                        ]} />
                </Sider>

                <Layout className='contentLayout'>
                    <Content>
                        <section>
                            <Title level={5} style={{ marginBottom: 10 }}>Recent Files</Title>
                            <Table pagination={false} style={{ width:"100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList.slice(0,10).filter(item => item.mime != "application/folder")} />
                        </section>

                        <section style={{height:"150px"}}>
                            <Dragger {...fileUploadProps}>
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </section>

                        <Title level={5} style={{ marginTop: 40, marginBottom: 10 }}>Folder</Title>
                        <Table style={{ width:"100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList.filter(item => item.mime == "application/folder")} />

                        <section style={{height:"150px"}}>
                            <Dragger {...fileUploadProps}>
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </section>


                        <Title level={5} style={{ marginTop: 40, marginBottom: 10 }}>All Files</Title>
                        <Table style={{ width:"100%", "display": presentObjects["active"] }} rowKey="id" onRow={(record, rowIndex) => { return { onClick: event => { console.log(record); } } }} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={recordsList.filter(item => item.mime != "application/folder")} />
                        <section style={{height:"150px"}}>
                            <Dragger {...fileUploadProps}>
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </section>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>

                        <Modal title="Share Document" open={presentObjects.showShareDialog} footer={[]} onCancel={(e) => showHideProps("showShareDialog", false)}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CommonShare key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Modal>
                        

                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}