import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId, dateTimeUtils} from "../../Config";
import '../../App.css';
import { Divider, Form, Input, DatePicker, CheckboxChangeEvent, Space, Card,Row,Col, prefixSelector,Radio, Upload, Button, message, Checkbox, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faMessage, faBackward,faPen, faPeace, faEdit, faSave, faInfo, faCircleInfo, faInfoCircle, faCloudUploadAlt, faDownload, faCloudArrowDown, faHourglassStart, faHourglassEnd, faHourglassHalf, faUserClock, faShareAltSquare, faUser, faClock, faShareNodes, faFolder, faSatellite, faTimeline, faFastForward, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { View } from 'paper/dist/paper-core';
import moment from 'moment';

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { Dragger } = Upload;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-dependants";
const tableName = "human-resource";
var activeDataColumns = [];
let ckEditorData = "";
const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


export default function HumanResourceDependant(props) {
    const [theEmployee, setTheEmployee] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section":"none","edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [humanresourcedependantForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [addressesList, setAddressesList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [relationList, setRelationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        theObject["id"]=generateFuzzyId("dependants")
        setUiLabels({ "save": "Save" });
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/find-any-multi` }, { "doc_name": "addresses" }, setAddressesList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/configuration` }, { "genre": "employee-dependants" }, setRelationList);
        setTheEmployee(props.employee);
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url":`/api/human-resource/employees/dependants/`+ selectedRow.id, "id": selectedRow.id }, theObject, setTheObject,dependantCallback);
    }
    const dependantCallback = (data) => {
        if (data["birthday"] != undefined) data["birthday"] = moment(data["birthday"]);
        if (data["effective_from"] != undefined) data["effective_from"] = moment(data["effective_from"]);
        setTheObject({ ...data });
        humanresourcedependantForm.setFieldsValue({ ...data });
        showHideAllProps({"button-controls-section":"block", "records-list-table": "none", "dependant-details": "block", "edit-selected-record": "block", "human-resource-dashboard": "none" });
    }

    const findAll = (filter) => {
       AxiosNetwork.axiosFetch({ "url": `/api/human-resource/employees/dependants`}, setRecordsList);
        showHideAllProps({ "button-controls-section":"none","dependant-details": "none", "records-list-table": "block", "human-resource-dashboard": "none" });
    }
    const humanresourcedependantFailed = () => {
    }
    const onFinish = (values) => {
        values["description"] = ckEditorData;
        values["upsert"] = true;
        values["birthday"] = moment(values["birthday"]).format("yyyy-MM-DD HH:mm:ss");
        values["effective_from"] = moment(values["effective_from"]).format("yyyy-MM-DD HH:mm:ss");
        AxiosNetwork.axiosPut({ "url": "/api/human-resource/employees/dependants/" + theObject["id"] +   "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        console.log('Success:', values);
    }
    const afterNewRecordSubmit = () => {
        message.info('Your Dependent details has been saved.');
        findAll();
    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": "employees", "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    const onOk = (value) => {
        console.log("onOk: ", moment().format());
        console.log("onOk: ", typeof value);
        console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
    }
    
    const applyDependant = ( ) => {
        showHideAllProps({"button-controls-section":"block", "dependant-details": "block", "records-list-table": "none", "human-resource-dashboard": "none" });
        humanresourcedependantForm.resetFields();
    }

    const columnSchema = [
        {
            title: 'Name',
            width: 100,
            dataIndex: 'name',
        },
        {
            title: 'Relationship',
            dataIndex: 'relation_type_id',
        },
        {
            title: 'Date of birth',
            dataIndex: 'birthday',
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 60
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        },
    ];

    return (
        <section>
            <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "dependant-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>
               <section style={{ "display": presentObjects["records-list-table"] }}>
                        <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} />
                        <Button style={{ marginBottom: "50px" }} type="primary" onClick={(e) => applyDependant ()}>  Dependant  </Button>
                </section>
                    <section className="thCard" style={{ "width": 700, "display": presentObjects["dependant-details"] }} >
                  <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanresourcedependantForm} initialValues={theObject}
                            onFinishFailed={humanresourcedependantFailed} autoComplete="off">
                            <Form.Item label="Name" name="name"
                                rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                            <Col span={8}>
                            <Form.Item label="Relationship" name="relation_type_id"> 
                                     <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Relation">
                                    {relationList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Gender" name="gender" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="Male">Male</Select.Option>
                                    <Select.Option value="Female">Female</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Date of Birth" name="birthday">
                                <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Married Status" name="married_status" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="Married">Married</Select.Option>
                                    <Select.Option value="Unmarried">Unmarried</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Blood Group" name="blood_group" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="A+">A+</Select.Option>
                                    <Select.Option value="A-">A-</Select.Option>
                                    <Select.Option value="O+">O+</Select.Option>
                                    <Select.Option value="O-">O-</Select.Option>
                                    <Select.Option value="AB-">AB-</Select.Option>
                                    <Select.Option value="AB+">AB+</Select.Option>
                                    <Select.Option value="B+">B+</Select.Option>
                                    <Select.Option value="B-">B-</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Address" name="address_id">
                                <Select showSearch  allowClear style={{ width: '100%' }} placeholder="Select Addresses">
                                    {addressesList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item name="mobile" label="Mobile Number"
                                rules={[{ required: true, message: 'Please input your mobile number!' }]} >
                                <Input addonBefore={prefixSelector} style={{width: '100%'}}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Occupation" name="occupation"
                                rules={[{ required: true, message: 'Type  your occupation in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Effective From" name="effective_from">
                                <DatePicker style={{ width: '100%' }} format={dateTimeUtils["dateTimeFormat"]} showTime={true} onOk={onOk}/>

                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Disable"name="disable">
                                <Radio.Group name="radiogroup" >
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Attachement" name="attachement">
                            <Dragger {...fileUploadProps}>
                                <p className="ant-upload-drag-icon"><FontAwesomeIcon icon={faCloudUploadAlt} /></p>
                                <p className="ant-upload-text">Attach files by dragging & dropping, selecting or pasting them</p>
                            </Dragger>
                        </Form.Item>
                        </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                        </Form.Item>
                    </Form>
                   </section>
          </Layout>
        </section >
    )
}