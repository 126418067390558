import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faBorderNone, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, configMenus } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "all-reports";
const tableName = "all-reports";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function ShowReport() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [approvedQueryList, setApprovedQueryList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [filterForm] = Form.useForm();
    const [presentObjects, setPresentObjects] = useState({ "users-details": "none", "new-roles-section": "none", "user-roles-list-table": "none", "edit-selected-record": "none", "new-record": false, "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const [reportsForm] = Form.useForm();

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            widht: 200
        },
      
    ];

    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/reports`);
        AxiosNetwork.axiosFetch({ "url": `api/common/apps` }, setApplicationList);
        if(queryParams["app-id"]!==undefined) AxiosNetwork.axiosFetchMulti({ "url": `api/admin-approved-queries` }, { "doc_name": "admin-approved-queries", "filters":[{"col_name":"app_id", "operator":"=", "data_type":"string", "string_value":queryParams["app-id"]} ] }, setApprovedQueryList, approvedQueryLoadedCallback);
    }, []);

    const approvedQueryLoadedCallback = (data) =>
    {
        setApprovedQueryList([...data]);
        console.log(111);
    }
   
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": false, "records-list-table": "block" });
    }
   
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/common/roles/create` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "new-Record": true});
    }

    const afterNewRecordSubmit = () => {
        message.info('Your Reports details has been saved.');
    }
    
    const onFilterFinish = (values) => {
        console.log(values);
        AxiosNetwork.axiosFetchMulti({ "url": `api/common/approved-queries/${values["approved_query_id"]}` }, { }, afterQueryResultCallback);
    }

    const afterQueryResultCallback = (data) =>
    {
        console.log(data);
    }

    const  reportsFormFailed = () =>{

    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        items={
                            applicationList.map(eachItem => ({
                                key: 'show-report-'+eachItem.id,
                                label: (<a key={eachItem.id} onClick={(e) => window.location.href = window.location.origin+"/apps/reports?app-id="+eachItem.id} rel="noopener noreferrer">{eachItem.name}</a>)
                            }))
                        }>
                    </Menu>
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>
                            <Card>
                                <Form size="large" form={filterForm} name="horizontal_login" layout="inline" onFinish={onFilterFinish}>
                                    <Form.Item style={{ width: '30%' }} name="approved_query_id">
                                        <Select showSearch allowClear placeholder="Select Application"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                            {approvedQueryList.map(eachItem => (
                                                <Option key={eachItem["id"]}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faFilter} /></Button>
                                    </Form.Item>
                                </Form>
                            </Card>

                        </section>

                        <Drawer title={uiLabels.title} placement="right" onClose={(e) => showHideProps("new-record", false)} open={presentObjects["new-record"]}>
                            <section>
                                <Form layout="vertical" labelAlign="left" key={"form-" + new Date().getTime()}
                                    name="basic"
                                    onFinish={onFinish}
                                    form={reportsForm} initialValues={theObject}
                                    onFinishFailed={reportsFormFailed} autoComplete="off">
                                    <Form.Item label="Report" name="report_id"
                                        rules={[{ required: true, message: 'Enter your Report', }]}>
                                        <Select showSearch allowClear placeholder="Select Report">
                                            {approvedQueryList.map(eachItem => (
                                                <Option key={eachItem.id}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}>
                                        <Button type="primary" htmlType="submit">Submit</Button>
                                    </Form.Item>
                                </Form>
                            </section>
                        </Drawer>

                        <Drawer title="Info Label" placement="right" onClose={() => showHideAllProps({"showInfo": false})} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}