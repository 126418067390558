import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faInfoCircle, faFilter, faEraser, faRefresh, faSave, faEye, faBorderNone, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems, configMenus } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "roles";
const tableName = "roles";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function Roles() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [applicationList, setApplicationList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [presentObjects, setPresentObjects] = useState({ "users-details": "none", "new-roles-section": "none", "user-roles-list-table": "none", "edit-selected-record": "none", "new-record": false, "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [userList, setUserList] = useState([]);

    const [rolesForm] = Form.useForm();
    const [usersForm] = Form.useForm();

    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Position',
            dataIndex: 'position',
            width: 50
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "edit",
            title: 'Edit',
            width: 100,
            render: (record) => <a onClick={(e) => viewTableRow(record)}><FontAwesomeIcon icon={faUserGroup} /></a>,
        }
    ];

    const userRolesColumn = [
        {
            title: 'User',
            dataIndex: 'name'
        },
        {
            title: 'Created At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteRoleFromUser(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 100
        },
    ];

    useEffect(() => {
        console.log("UseEffect called");
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/configurations/roles`);
        AxiosNetwork.axiosFetch({ "url": `api/admin/common/roles` }, setRecordsList, afterRoleAreFetched);
    }, []);

    const afterRoleAreFetched = (data) => {

    }

    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "new-record": false, "records-list-table": "block" });

    }
    const deleteRoleFromUser = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/admin/common/users/roles/${selectedRow["user_role_id"]}`, "id": selectedRow.id }, userRecordsList, setUserRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/common/roles/create` }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "new-roles-section": "none", "records-list-table": "block", "user-roles-list-table": "none" });
    }

    const onUserFinish = (values) =>
    {
        AxiosNetwork.axiosPost({ "url": `api/admin/common/users/${values["user_id"]}/roles/${theObject.id}/create` }, values, userRecordsList, setUserRecordsList, afterUserRoleAddedCallback);
    }


    const afterUserRoleAddedCallback = (data) =>
    {
        viewTableRow({ ...theObject });
    }

    const afterNewRecordSubmit = () => {
        message.info('Your Roles details has been saved.');
        newRoles();
    }

    const rolesFormFailed = () => {

    }
    const usersFormFailed = () => {

    }
    const newRoles = () => {
        AxiosNetwork.axiosGet({ "url": `api/admin/common/roles` }, setRecordsList);
        showHideAllProps({ "users-details": "none", "records-list-table": "block", "user-roles-list-table": "none" });
    }

    const viewTableRow = (selectedRow) => {
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        AxiosNetwork.axiosFetch({ "url": `api/admin/common/roles/${selectedRow.id}/users` }, setUserRecordsList);
        showHideAllProps({ "users-details": "block", "new-roles-section": "none", "records-list-table": "block", "user-roles-list-table": "block" });
    }

    const newRecord = () => {
        showHideAllProps({ "new-roles-section": "block", "new-roles-section": true, "new-record": true });
        rolesForm.resetFields();
    }

    const findUserLike = (value) => {
        if (value === null || value === "") return;
        setUserList([]);
        var payload = { "doc_name": "users", "col_name": "name", "col_value": value, "select":["name"] };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/find-select-any-multi-like` }, payload, setUserList, setUserListCallBack);
    }

    const setUserListCallBack = (data) => {
        setUserList([...data]);
        showHideAllProps({ "users-details": "block", "new-roles-section": "none", "records-list-table": "block", "user-roles-list-table": "block" });
    }

    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Header style={{ minWidth: '100%', marginLeft: "0", position: 'fixed' }} className="header">
                <Row><Col span={12}><Menu theme="dark" mode="horizontal" items={menuBarItems} /></Col>
                    <Col style={{ textAlign: "right" }} span={12}><Menu theme="dark" mode="horizontal" items={profileBarItems} /></Col></Row>
            </Header>
            <Layout className='sideLayout'>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={[
                            {
                                key: 'create-roles',
                                label: (<a onClick={(e) => newRecord()} rel="noopener noreferrer">New</a>)
                            }
                        ]}>
                    </Menu>
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultOpenKeys={['sub-folders']}
                        style={{ borderRight: 0 }}
                        items={configMenus}>
                    </Menu>
                </Sider>

                <Layout className='contentLayout'>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}>

                        <section style={{ "display": presentObjects["records-list-table"] }}>

                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />

                        </section>

                        <section style={{ "marginBottom":"10px", "width":"800px", "display": presentObjects["user-roles-list-table"] }}>
                            <h3>User with the role</h3>
                            <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={userRolesColumn} dataSource={userRecordsList} pagination={{ pageSize: 50 }} />
                        </section>



                        <Drawer title={uiLabels.title} placement="right" onClose={(e) => showHideProps("new-record", false)} open={presentObjects["new-record"]}>
                            <section>
                                <Form layout="vertical" labelAlign="left" key={"form-" + new Date().getTime()}
                                    name="basic"
                                    onFinish={onFinish}
                                    form={rolesForm} initialValues={theObject}
                                    onFinishFailed={rolesFormFailed} autoComplete="off">
                                    <Form.Item
                                        label="Role"
                                        name="name"
                                        rules={[{ required: true, message: 'Type  your  name in this field' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </section>
                        </Drawer>

                        <section style={{ "display": presentObjects["users-details"] }}>
                            <Card style={{width:400}}>
                                <Form layout="vertical" labelAlign="left"
                                    name="basic"
                                    onFinish={onUserFinish}
                                    form={usersForm} initialValues={theObject}
                                    onFinishFailed={usersFormFailed} autoComplete="off">
                                    <Form.Item
                                        label="User"
                                        name="user_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Enter your User',
                                            }
                                        ]}>
                                        <Select showSearch allowClear onSearch={findUserLike} placeholder="Select User">
                                            {userList.map(eachItem => (
                                                <Option key={eachItem.id}>{eachItem.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </section>


                        <Drawer title="Info Label" placement="right" onClose={() => showHideAllProps({"showInfo": false})} open={presentObjects.showInfo}>
                            <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                                {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                            </div>
                        </Drawer>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}