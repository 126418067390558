import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faTrashCan, faEdit, faBackward, faFilter, faEraser, faRefresh, faSave, faEye, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { menuBarItems, generateFuzzyId, profileBarItems } from "../Config";
import '../App.css';
//import '../antd.css';
import moment from 'moment';
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor"
import { Layout, Menu, Card, Row, Space, Button, Table, Form, Input, Upload, message, Select, InputNumber, Drawer, Col } from 'antd';
import { AxiosNetwork } from "../axiosService";
import queryString from 'query-string';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
var formData = {};
const appId = 73;
const appPath = "manuals";
const tableName = "manuals";
var activeDataColumns = [];
var applicationListGlobal = [];
const queryParams = queryString.parse(window.location.search);

export default function Manual() {
    const [recordsList, setRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [userRecordsList, setUserRecordsList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [supplierList, setSupplierList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [presentObjects, setPresentObjects] = useState({ "button-controls-section": "none", "manual": "none", "edit-selected-record": "none", "new-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false, "new-form": "block" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [filter, setFilter] = useState([]);
    const [theLabel, setTheLabel] = useState({});
    const [manualsForm] = Form.useForm();
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    console.log(queryParams);

    const columnSchema = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'URL',
            dataIndex: 'url'
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_id'
        },

        {
            title: 'Category',
            dataIndex: 'category_id'
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            widht: 50
        },
        {
            key: "edit",
            title: 'Edit',
            width: 50,
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
        }

    ];

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        theObject["id"] = generateFuzzyId("manuals")
        localStorage.setItem("rest-cloud-user-recent-app", `/apps/${tableName}`);
        AxiosNetwork.axiosFetch({ "url": `/api/organizations` }, setSupplierList);
        AxiosNetwork.axiosFetchMulti({ "url": `api/admin/asset-management/categories` }, { "doc_name": "category" }, setCategoryList);
        findAll();
    }, []);


    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };
    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }
    const findFromBin = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setRecordsList);
        showHideAllProps({ "edit-selected-record": "none", "records-list-table": "block" });

    }
    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": `api/admin/asset-management/manuals/${selectedRow["id"]}`, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosCommonRestore({ "url": `/api/common/restore-any-record` }, { "doc_name": tableName, "id": selectedRow.id }, recordsList, setRecordsList);
    }
    const onFinish = (values) => {
        values["end_at"] = moment(values["end_at"]).format("yyyy-MM-DD");
        values["start_at"] = moment(values["start_at"]).format("yyyy-MM-DD");
        AxiosNetwork.axiosPut({ "url": "api/admin/asset-management/manuals/" + theObject["id"] + "/update" }, values, recordsList, setRecordsList, afterNewRecordSubmit);
        showHideAllProps({ "button-controls-section": "none", "manuals-details": "none", "records-list-table": "block" });

    }
    const afterNewRecordSubmit = () => {
        message.info('Your manuals has been saved.');
        manualsForm.resetFields();
        findAll();
    }

    const findAll = (filter) => {
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/asset-management/manuals` }, { "doc_name": tableName }, setRecordsList);
        showHideAllProps({ "button-controls-section": "none", "edit-selected-record": "none", "manuals-details": "none", "records-list-table": "block" });
    }
    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        manualsForm.setFieldsValue({ ...selectedRow });
        formData = {};
        formData["parent_id"] = selectedRow.id;
        AxiosNetwork.axiosGet({ "url": `/api/admin/asset-management/manuals/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "manuals-details": "block", "edit-selected-record": "block" });
    }

    const manualsFormFailed = () => {

    }
    const applyManual = () => {
        showHideAllProps({ "button-controls-section": "block", "records-list-table": "none", "manuals-details": "block", "edit-selected-record": "block" });

    }
    return (

        <Layout className='contentLayout'>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "manuals-details": "none", "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>

            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={columnSchema} dataSource={recordsList} pagination={{ pageSize: 50 }} />
                <div style={{ marginTop: 10 }}>
                    <Space size={10}>
                        <Button style={{ marginBottom: "20px" }} type="primary" onClick={(e) => applyManual()}>  Manual </Button>
                    </Space>
                </div>
            </section>

            <section className="thCard" style={{ "width": 700, "display": presentObjects["manuals-details"] }} >
                <Form form={manualsForm} labelAlign="right" layout="vertical" size={"large"} name="basic"
                    onFinish={onFinish} onFinishFailed={manualsFormFailed} initialValues={theObject} autoComplete="off">
                    <Form.Item label="Name" name="name" rules={[{ required: false, message: 'Enter your name' }]}>
                        <Input />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label="URL" name="url" rules={[{ required: true, message: 'Enter your URL', }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Category" name="category_id" rules={[{ required: true, message: 'Enter your category', }]}>
                                <Select allowClear showSearch placeholder="Select category">
                                    {categoryList.map(eachItem => (
                                        <Option key={eachItem.name}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label="Supplier" name="supplier_id" rules={[{ required: true, message: 'Enter your Supplier', }]}>
                                <Select allowClear showSearch placeholder="Select supplier">
                                    {supplierList.map(eachItem => (
                                        <Option key={eachItem.name}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Mime Asset" name="mime_asset_id" rules={[{ required: true, message: 'Enter your Mime Asset', }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </section>
        </Layout>
    )
}