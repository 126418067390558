import React, { useState, useEffect } from 'react';
import { appProfile, generateFuzzyId } from "../../Config";
import '../../App.css';
import {  Form, Input, DatePicker, Row, Button, Col, View, prefixSelector, suffixSelector, Card, message, Select, Table, InputNumber, Layout } from 'antd';
import { AxiosNetwork } from "../../axiosService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTrashCan,faSave} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1" };
const appPath = "human-resource-basic";
const tableName = "human-resource";
var activeDataColumns = [];

const style = { padding: '8px 0' };
let ckEditorData = "";

const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

export default function HumanResourceBasic() {
    const [presentObjects, setPresentObjects] = useState({ "edit-selected-record": "none", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [theObject, setTheObject] = useState({ "name": "", "mime_assets": [] });
    const [humanResourceBasicForm] = Form.useForm();
    const [recordsList, setRecordsList] = useState([{ "id": 1, "user_id": "guru", "mime_assets": [], "createdAt": new Date() }]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);

    useEffect(() => {
        setUiLabels({ "save": "Save" });
        findAll();
    }, []);
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }
    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const findAll = (filter) => {
        AxiosNetwork.axiosGet({ "url": `/api/human-resource/employees/self` }, theObject, setTheObject, afterEmployeeFetch);
    }

    const afterEmployeeFetch = (data) =>
    {
        console.log(data);
        humanResourceBasicForm.setFieldsValue({...data});
        setTheObject({...data});
    }

    const humanresourcebasicFailed = () => {
    }
    const onFinish = (values) => {
        AxiosNetwork.axiosPutMono({ "url": "/api/human-resource/employees/self/update"  }, values, setTheObject,afterNewRecordSubmit);
        console.log('Success:', values);
    }

    const afterNewRecordSubmit = () => {
        message.info('Your Basic details has been saved.');
        findAll();
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );

    return (
        <section>
            <Layout className='contentLayout'>
               <section className="thCard" style={{ "width": 700, "display": presentObjects["personal-information"] }} >
                      <Card style={{ marginBottom: "50px" }}>
                        <div>
                            <Row gutter={[16, 16]}><Col span={6}>Salutation:</Col><Col span={6}>{theObject.salutation}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Name:</Col><Col span={6}>{theObject.name}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Middle Name:</Col><Col span={6}>{theObject.middle_name}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Last Name:</Col><Col span={6}>{theObject.name}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Blood Group:</Col><Col span={6}>{theObject.blood_group}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Job Title:</Col><Col span={6}>{theObject.job_title}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Job Category:</Col><Col span={6}>{theObject.job_category}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Official Email:</Col><Col span={6}>{theObject.email}</Col></Row> &#x2002; 
                            <Row gutter={[16, 16]}><Col span={6}>Gender:</Col><Col span={6}>{theObject.gender}</Col></Row>
                        </div>
                        </Card>
                      
                        <Form size="large" labelAlign="right" layout="vertical" name="basic"
                            onFinish={onFinish}
                            form={humanResourceBasicForm} initialValues={theObject}
                            onFinishFailed={humanresourcebasicFailed} autoComplete="off">
                            <Row gutter={16}>
                            <Col span={12}>
                                  <Form.Item label="Salutation" name="salutation" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="Mr">Mr</Select.Option>
                                    <Select.Option value="Mrs">Mrs</Select.Option>
                                    <Select.Option value="Miss">Miss</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item  label="First Name"name="name"
                                rules={[{ required: true, message: 'Type  your  first name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Middle Name" name="middle_name"
                                rules={[{ required: false, message: 'Type  your  second name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Last Name" name="last_name"
                                rules={[{ required: true, message: 'Type  your  last name in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={8}>
                            <Form.Item  label="Date of Birth" name="birthday">
                                <DatePicker />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Gender" name="gender" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="Male">Male</Select.Option>
                                    <Select.Option value="Female">Female</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Blood Group" name="blood_group" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="A+">A+</Select.Option>
                                    <Select.Option value="A-">A-</Select.Option>
                                    <Select.Option value="O+">O+</Select.Option>
                                    <Select.Option value="O-">O-</Select.Option>
                                    <Select.Option value="AB-">AB-</Select.Option>
                                    <Select.Option value="AB+">AB+</Select.Option>
                                    <Select.Option value="B+">B+</Select.Option>
                                    <Select.Option value="B-">B-</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Married Status" name="married_status" >
                                <Select showSearch  allowClear>
                                    <Select.Option value="Married">Married</Select.Option>
                                    <Select.Option value="Unmarried">Unmarried</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item disabled={true} label="Employee Id" name="employee_code"
                                rules={[{ message: 'Type  your  employee id in this field' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item
                                label="Personal Email" name="personal_email"
                                rules={[{ message: 'Type  your personal email' }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item name="mobile"label="Mobile Number"
                                rules={[{ message: 'Please input your mobile number!' }]} >
                                <Input addonBefore={prefixSelector}style={{width: '100%'}}/>
                            </Form.Item>
                            </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                            </Form.Item>
                        </Form>
                        </section>
                </Layout>
        </section>
    )
}
