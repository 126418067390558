import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { appProfile, generateFuzzyId } from "../Config";
import { faUser, faTrashCan, faSave, faEdit, faUndoAlt, faBackward, faDownload, faCopy, faPlusSquare, faPlug, faPlus, faCirclePlay, faHourglass, faPlay, faPlaneSlash } from '@fortawesome/free-solid-svg-icons'
import '../App.css';
import moment from 'moment';
import { Space, Row, Divider, Button, Form, Input, Tag, message, Select, Table, InputNumber, Drawer, Col, Layout, } from 'antd';
import { AxiosNetwork } from "../axiosService";
import CustomFieldEmbedEditor from "../custom-field/CustomFieldEmbedEditor";
import WorkflowSwimlaneFabric from './WorkflowSwimlaneFabric';
import { faBots } from '@fortawesome/free-brands-svg-icons';


const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;
var formData = {};
const appId = 11;
const dataMassage = { "emailAddress": {}, "associateId": "1", "workflow": {}, "currentTask":{} };
const appPath = "tasks";
const tableName = "tasks";
var activeDataColumns = [];
var tatUnit = "MONTHS";
var tatUnitNum = 4;
var totalTatUnits = 0;

const tatUnits = {
    "MINUTE": { "NUM": 0, "MINUTE": 1, "HOUR": 60, "DAY": 1440, "WEEK": 10080, "MONTH": 302400 },
    "HOUR": { "NUM": 1, "HOUR": 1, "DAY": 24, "WEEK": 168, "MONTH": 5040 },
    "DAY": { "NUM": 2, "DAY": 1, "WEEK": 7, "MONTH": 30 },
    "WEEK": { "NUM": 3, "WEEK": 1, "MONTH": 4 },
    "MONTH": { "NUM": 4, "MONTH": 1 }
};


const nonTasks = ["START", "END"];
var roles = [];

const style = { padding: '8px 0' };
let ckEditorData = "";

const fileUploadProps = {
    name: 'file',
    multiple: true,
    action: appProfile.serviceLocation + 'api/file-assets/mime/upload/single',
    data: function (file) {
        console.log("Data being triggered");
        return formData;
    },
    headers: {
        Authorization: localStorage.getItem("rest-cloud-user-auth-token"),
    },
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};



export default function WorkFlowTasks(props) {
    const [presentObjects, setPresentObjects] = useState({ "user-task-assign":false,"button-controls-section": "none", "show-task-drawer": false, "create-new-record": "none", "new-sub-record": "none", "edit-selected-record": "none", "records-list-table": "block", "active": "block", "bin": "none", "showDownload": "none", "showUpload": "none", "folderEdit": "none", "labelEdit": "none", "showInfo": false, "contactForm": false });
    const [selectedRows, setSelectedRows] = useState([]);
    const [labelList, setLabelList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [folderList, setFolderList] = useState([{ "id": 1, "name": "Waiting to load" }]);
    const [theWorkflow, setTheWorkflow] = useState(props.workflow);
    const [theObject, setTheObject] = useState({ "name": "Type your summary here", "mime_assets": [] });
    const [taskForm] = Form.useForm();
    const [taskList, setTaskList] = useState([]);
    const [uiLabels, setUiLabels] = useState([{ "save": "Save" }]);
    const[recordsList, setRecordsList]=useState([]);
    const [roleList, setRoleList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [usersRolesForm] = Form.useForm();
    const [taskUserRoles, setTaskUserRoles] = useState([{ "id": 1, "name": "Waiting to load" }]);

    dataMassage["workflow"] = props.workflow;
    console.log(props);

    useEffect(() => {
        findAll();
    },[props.workflow.id]);


    const assignTaskUsersDialog = (selectedRow) => {
        dataMassage["currentTask"] = selectedRow;
        AxiosNetwork.axiosFetchMulti({ "url": `api/user/common/user-doc-roles` }, { "doc_name": "user-doc-roles", "filters": [{ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": selectedRow.id}] }, setTaskUserRoles, setTaskUserRolesFetched);
        showHideAllProps({"user-task-assign": true, "users-list-table": "block"});
    }

    const setTaskUserRolesFetched = (data) => {
        console.log(data);
        setTaskUserRoles([...data]);
    }


    
    const userColumns = [
        {
            title: 'Name',
            dataIndex: 'user_name'
        },
        {
            title: 'Role',
            dataIndex: 'role_name',
            width: 200
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["created_at"] && moment(record["created_at"]).format("lll")}</span>,
            width: 200
        },
        {
            key: "trash",
            title: 'Trash',
            render: (record) => <a onClick={(e) => deleteUserFromTask(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 60
        }
    ];

    const deleteUserFromTask = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosDelete({ "url": `api/user/common/user-doc-role/${selectedRow.id}`, "id": selectedRow.id }, taskUserRoles, setTaskUserRoles);
    }

    const columnSchema = [
        {
            title: 'Actions',
            render: (record) => <Space><a onClick={(e) => startTask(record)}><FontAwesomeIcon icon={faPlay} /></a> <a onClick={(e) => startTask(record)}><FontAwesomeIcon icon={faPlaneSlash} /></a> <a onClick={(e) => assignTaskUsersDialog(record)}><FontAwesomeIcon icon={faUser} /></a> </Space>,
        },
        {
            title: 'Name',
            render: (record) => <span>{record["name"]}</span>,
        },
        {
            title: 'Minutes',
            render: (record) => <span>{record["minute_max"] + " " + record["tat_unit"]}</span>,
            width: 200
        },
        {
            title: 'Planned Start',
            render: (record) => <span>{record["start_planned_at"] && moment(record["start_planned_at"]).format("lll")} <br /> {record["start_actual_at"] && moment(record["start_actual_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Planned End',
            render: (record) => <span>{record["end_planned_at"] && moment(record["end_planned_at"]).format("lll")} <br /> {record["end_actual_at"] && moment(record["end_actual_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: "Genre",
            render: (record) => <Tag color={record["css"]}>{record["genre"]}</Tag>,
            width: 100
        },
        {
            title: "Priority",
            render: (record) => <span>{record["priority"]}</span>,
            width: 100
        },
        {
            title: 'Modified At',
            render: (record) => <span>{record["updated_at"] && moment(record["updated_at"]).format("lll")}</span>,
            width: 200
        },
        {
            title: 'Edit',
            render: (record) => <a onClick={(e) => editTableRow(record)}><FontAwesomeIcon icon={faEdit} /></a>,
            width: 50
        },
        {
            title: 'Duplicate',
            render: (record) => <a onClick={(e) => duplicateTableRow(record)}><FontAwesomeIcon icon={faCopy} /></a>,
            width: 50
        },
        {
            key: "trash",
            title: 'Trash',
            width: 50,
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>
        },
        {
            title: 'Action',
            render: (record) => <a onClick={(e) => deleteTableRow(record)}><FontAwesomeIcon icon={faTrashCan} /></a>,
            width: 50
        },
        {
            key: "restore",
            title: 'Restore',
            width: 50,
            render: (record) => <a onClick={(e) => restoreTableRow(record)}><FontAwesomeIcon icon={faUndoAlt} /></a>
        }
    ];

    const findAll = (filter) => {
        setTaskList([]);
        activeDataColumns = columnSchema.slice(0, -1);
        AxiosNetwork.axiosFetch({ "url": `api/admin/common/roles` }, setRoleList);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/workflows/${dataMassage["workflow"]["id"]}/tasks`, "id": dataMassage["workflow"]["id"] }, setTaskList, afterTaskLoad);
        showHideAllProps({ "button-controls-section": "none", "create-new-record": "none", "records-list-table": "block" });
    }

    const afterTaskLoad = (tasks) => {
        tasks.forEach(eachTask => {
            roles.push(eachTask["role_id"]);
            if (tatUnits[eachTask["tat_unit"]]["NUM"] < tatUnitNum) {
                if (nonTasks.includes(eachTask["genre"])) return;
                tatUnit = eachTask["tat_unit"];
                tatUnitNum = tatUnits[tatUnit]["NUM"];
            }
        });
        roles = [...new Set(roles)];
        console.log(roles);

        tasks.forEach(eachTask => {
            //if(nonTasks.includes(eachTask["genre"])) return;
            eachTask["taskBreadth"] = tatUnits[tatUnit][eachTask["tat_unit"]];
            totalTatUnits += eachTask["taskBreadth"];
        });
        console.log("totalTatUnits", totalTatUnits);
        setTaskList(tasks);
    }
   
    const showHideProps = (prop, show) => {
        presentObjects[prop] = show;
        setPresentObjects({ ...presentObjects });
    }
    const showHideAllProps = (props) => {
        for (var prop in props) {
            presentObjects[prop] = props[prop];
        }
        setPresentObjects({ ...presentObjects });
    }

    const deleteTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPost({ "url": "/api/common/delete-any-by-owner", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, taskList, setTaskList);
    }

    const restoreTableRow = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosRestore({ "url": "/api/common/restore-any-record", "id": selectedRow.id }, { "doc_name": tableName, "id": selectedRow.id }, taskList, setTaskList);
    }

    const infoTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        showHideAllProps({ "showInfo": true });
        console.log(selectedRow);
    }

    const editTableRowCallback = (value) => {
        const findIndex = taskList.findIndex((obj => obj.id === value));
        editTableRow(taskList[findIndex]);
    }


    const startTask = (selectedRow) => {
        console.log(selectedRow);
        AxiosNetwork.axiosPutMono({ "url": `/api/${tableName}/${selectedRow.id}/start`, "id": selectedRow.id }, theObject, setTheObject);
    }

    const editTableRow = (selectedRow) => {
        setUiLabels({ "save": "Update" });
        console.log(selectedRow);
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        taskForm.setFieldsValue({ ...selectedRow });
        dataMassage["taskId"] = selectedRow.id;
        formData = {};
        formData["parent_id"] = selectedRow.id;
        formData["folder_id"] = dataMassage["taskId"];
        AxiosNetwork.axiosGet({ "url": `/api/${tableName}/` + selectedRow.id, "id": selectedRow.id }, theObject, setTheObject);
        showHideAllProps({ "button-controls-section": "block", "show-task-drawer": true, "new-sub-record": "block" });
    }

    const duplicateTableRow = (selectedRow) => {
        selectedRow["mime_assets"] = [];
        setTheObject({ ...selectedRow });
        AxiosNetwork.axiosGet({ "url": `/api/${tableName}/${selectedRow.id}/duplicate`, "id": selectedRow.id }, theObject, setTheObject, afterDuplicate);
    }

    const afterDuplicate = (data) => {
        editTableRow(data);
    }

    const onSelectChange = (selectedRowIds) => {
        console.log('selectedRowKeys changed: ', selectedRowIds);
        setSelectedRows(selectedRowIds);
    };

    const onTaskSubmit = (values) => {
        values["description"] = ckEditorData;
        values["workflow_id"] = props.workflow.id;
        AxiosNetwork.axiosPost({ "url": "/api/tasks/" + dataMassage["taskId"] + "/upsert" }, values, taskList, setTaskList);
        console.log('Success:', values);
        showHideAllProps({ "show-task-drawer": false });
        findAll();
    }

    const theworkflowFormFailed = () => {

    }

    const newRecord = () => {
        setUiLabels({ "save": "Save" });
        dataMassage["taskId"] = generateFuzzyId(tableName);
        showHideAllProps({ "button-controls-section": "none", "show-task-drawer": true });
    }

    const removeBinHeaders = (header) => {
        return header.filter(item => !["trash", "edit", "info"].includes(item.key));
    }

    const findFromBin = (filter) => {
        activeDataColumns = removeBinHeaders(columnSchema);
        AxiosNetwork.axiosFetchMulti({ "url": `/api/common/find-any-multi` }, { "doc_name": tableName, "deleted_at": new Date() }, setTaskList);
    }
   
    const onTaskUserRoleFinish = (values) =>
    {        
        values["doc_id"] = dataMassage["currentTask"]["id"];
        AxiosNetwork.axiosPost({ "url": `/api/user/common/user-doc-role` }, values, taskUserRoles, setTaskUserRoles, afterUserRoleAddedCallback);
    }


    //todo unnecessarily loading the data repeatedly
    const afterUserRoleAddedCallback = (data) =>
    {
        AxiosNetwork.axiosFetchMulti({ "url": `api/user/common/user-doc-roles` }, { "doc_name": "user-doc-roles", "filters": [{ "col_name": "doc_id", "operator": "=", "data_type": "string", "string_value": dataMassage["currentTask"]["id"] }] }, setTaskUserRoles, setTaskUserRolesFetched);
        showHideAllProps({"user-task-assign": true, "users-list-table": "block"});
    }

    const usersFormFailed = () => {

    }
    

    const findUserLike = (value) => {
        if (value === null || value === "") return;
        setUserList([]);
        var payload = { "doc_name": "users", "col_name": "name", "col_value": value, "select":["name"] };
        AxiosNetwork.axiosFetchMulti({ "url": `/api/admin/common/find-select-any-multi-like` }, payload, setUserList, setUserListCallBack);
    }

    const setUserListCallBack = (data) => {
        setUserList([...data]);
        showHideAllProps({"user-task-assign": true, "users-list-table": "block"});

    }

  
    return (
        <section>
            <section style={{ "display": presentObjects["button-controls-section"] }}>
                <Space style={{ marginBottom: "20px" }} size={10} className="buttonControls">
                    <Button onClick={(e) => showHideAllProps({ "edit-selected-record": "none", "show-task-drawer": false, "records-list-table": "block", "button-controls-section": "none" })} type="primary" size={10}><FontAwesomeIcon icon={faBackward} /></Button>
                </Space>
            </section>

            <section style={{ "display": presentObjects["records-list-table"] }}>
                <Table pagination={false} rowKey="id" rowSelection={{ selectedRows, onChange: onSelectChange }} columns={activeDataColumns} dataSource={taskList} />
            </section>


            <div style={{ marginTop: 10 }}>
                <Space size={10}>
                    <Button type="primary" onClick={(e) => newRecord()}><FontAwesomeIcon icon={faPlus} />TASKS</Button>
                    <Button type="primary" onClick={(e) => findFromBin()}> Bin </Button>
                    <Button type="primary" onClick={(e) => findAll()}> All </Button>
                </Space>
            </div>


            <Drawer title={uiLabels.title} width={720} placement="right" onClose={(e) => showHideProps("show-task-drawer", false)} open={presentObjects["show-task-drawer"]}>
                <Form size="large" labelAlign="right" layout="vertical" name="basic"
                    onFinish={onTaskSubmit} form={taskForm} onFinishFailed={theworkflowFormFailed}
                    autoComplete="off" initialValues={theObject}>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Enter your name' }]}>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12} >
                            <Form.Item name="start_checklist_id" label="CheckList (Start)" rules={[{ required: true, message: 'Enter the CheckList', }]}>
                                <Select style={{ width: '100%' }} placeholder="Select CheckList">
                                    {props.formList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item name="end_checklist_id" label="CheckList (End)" rules={[{ required: true, message: 'Enter the CheckList', }]}>
                                <Select style={{ width: '100%' }} placeholder="Select CheckList">
                                    {props.formList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="guideline_id" label="Guideline">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Guideline">
                                    {props.guidelineList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="function_id" label="Function">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Function">
                                    {props.functionList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="role_id" label="Role">
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Role">
                                    {props.roleList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="TAT" name="turn_around_time">
                                <InputNumber style={{ width: '100%' }} min={1} max={100} defaultValue={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="TAT Based On" name="tat_unit">
                                <Select showSearch allowClear defaultValue={"HOUR"}>
                                    <Select.Option value="MINUTE">Minute</Select.Option>
                                    <Select.Option value="HOUR">Hour</Select.Option>
                                    <Select.Option value="DAY">Day</Select.Option>
                                    <Select.Option value="WEEK">Week</Select.Option>
                                    <Select.Option value="MONTH">Month</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Assigned To" name="task_for" >
                                <Select showSearch allowClear defaultValue={"People"}>
                                    <Select.Option value="People">People</Select.Option>
                                    <Select.Option value="Bot">Bot</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Page view" name="page_view" >
                                <Select>
                                    <Select.Option value="Default">Default</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Start based on" name="start_based_on" >
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Plugins">
                                    {props.pluginList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="User" name="user_id" rules={[{ required: true, message: 'Select the user', }]}>
                                <Select showSearch allowClear defaultValue={{ value: "Employees" }}>
                                    <Select.Option value="Employees">Employees</Select.Option>
                                    <Select.Option value="Guest">Guest</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Sequence" name="priority" rules={[{ required: true, message: 'Set the Sequence' }]}>
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="inbox_id" label="Inbox" rules={[{ required: true, message: 'Select the inbox ' }]}>
                                <Select showSearch allowClear style={{ width: '100%' }} placeholder="Select Inbox">
                                    {props.inboxList.map(eachItem => (
                                        <Option key={eachItem.id}>{eachItem.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Disable" name="disable" >
                                <Select showSearch allowClear>
                                    <Select.Option value="Yes">Yes</Select.Option>
                                    <Select.Option value="No">No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"><FontAwesomeIcon icon={faSave} /> &#x2002; {uiLabels.save}</Button>
                    </Form.Item>
                </Form>
            </Drawer>
           

            <Drawer placement="right" width={720} onClose={(e) => showHideProps("user-task-assign", false)} open={presentObjects["user-task-assign"]}>
            <section className='thCard' style={{marginTop:20, width:400}}>
                    <Form layout="vertical" labelAlign="left" name="basic" onFinish={onTaskUserRoleFinish} form={usersRolesForm} initialValues={theObject}
                        onFinishFailed={usersFormFailed} autoComplete="off">
                        <Form.Item label="User" name="user_id" rules={[{required: true,message: 'Enter your User'}]}>
                            <Select showSearch allowClear onSearch={findUserLike} placeholder="Select User">
                                {userList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Role" name="role_id" rules={[{required: true,message: 'Enter the role'}]}>
                            <Select showSearch allowClear placeholder="Select Role">
                                {roleList.map(eachItem => (
                                    <Option key={eachItem.id}>{eachItem.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
            </section>
            <section style={{ "display": presentObjects["users-list-table"] }}>
            <Table style={{"marginTop":"50px"}} rowKey="id" pagination={false} rowSelection={{ selectedRows, onChange: onSelectChange }} columns={userColumns} dataSource={taskUserRoles} />
            </section>
        </Drawer>
            

            <Drawer title="Info Label" placement="right" onClose={() => showHideProps("showInfo", false)} open={presentObjects.showInfo}>
                <div className="row" style={{ fontFamily: "sans-serif", padding: 20, width: "100%", display: "block" }}>
                    {((theObject.id !== undefined) ? <CustomFieldEmbedEditor key={new Date().getTime()} present={setPresentObjects} table={tableName} labels={labelList} folders={folderList} id={theObject.id} /> : <span>dd</span>)}
                </div>
            </Drawer>

            <section style={{ overflowX: "scroll", overflowY: "scroll", width: "100%" }}>
                {(roles.length !== 0) && (<WorkflowSwimlaneFabric workflow={theWorkflow} taskList={taskList} roles={roles} totalTatUnits={totalTatUnits} callback={editTableRowCallback} />)}
            </section>
        </section>
    )
}